import { StationType } from '@.api/methods';
import { requiredRule, ValidateOptions } from '@.hooks';

export const validateOptions: ValidateOptions<StationType> = {
    identifier: {
        type: 'string',
        required: requiredRule,
    },
    name: {
        type: 'string',
        required: requiredRule,
    },
    store_id: {
        type: 'number',
        required: requiredRule,
    },
    status: {
        type: 'number',
        required: requiredRule,
    },
    currency_id: {
        type: 'number',
        required: requiredRule,
    },
    pin: {
        type: 'string',
        required: requiredRule,
    },
    min_whiff_interval_t1: {
        type: 'string',
        required: requiredRule,
    },
    min_whiff_interval_t2: {
        type: 'string',
        required: requiredRule,
    },
    is_brand_strict: {
        default: false,
        type: 'boolean',
        required: requiredRule,
    },
    // inactive_time: {
    //     type: 'number',
    //     required: requiredRule,
    // },
    move_interval_between_chambers: {
        type: 'number',
        required: requiredRule,
    },
    gold_level: {
        type: 'number',
        required: requiredRule,
    },
    yml_level: {
        type: 'number',
        required: requiredRule,
    },
    is_updated: {},
    last_online_at: {},
    offline_mode: {
        type: 'number',
        required: requiredRule,
    },
    show_price_range: {
        type: 'boolean',
        default: false,
    },
    languages: {
        type: 'string[]',
        required: requiredRule,
    },
};
