import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { Api, Code } from '@.api';
import { useForm } from '@.hooks';
import { Form } from '@.partials';
import { LinkHelper } from '@.services';
import { Button, Icon, Input } from '@.ui';
import { FormType } from '@/Components/Pages/PasswordRecovery/types';
import { validateOptions } from '@/Components/Pages/PasswordRecovery/validate';

export * from './NewPassword';
export * from './Sucess';

export const PasswordRecovery: React.FC = () => {
    const [field, form] = useForm<FormType>();
    // const {
    //     register,
    //     handleSubmit,
    //     setError,
    //     formState: { errors },
    // } = useForm<FormType>();
    const navigate = useNavigate();

    const handleSubmit = () => {
        form.reset.errors();

        form.ifValid((data) => {
            Api.user()
                .forgot_password(data)
                .onSuccess(() => navigate(LinkHelper.passwordRecoverySuccess))
                .onValidationError((res) => form.set.errors(res.errors));
        }, validateOptions);
    };

    return (
        <div className="password-recovery">
            <Form className="password-recovery__form" onSubmit={handleSubmit}>
                <div className="password-recovery__logo">logo</div>
                <h2>Password recovery</h2>
                <p>Enter username you used to register and we`ll send you the instruction</p>
                <Input {...field('email').register()} type="email" text="Email" color="secondary" required />
                <Button color="brown" text="Reset password" type="submit" />
                <Link to={LinkHelper.login}>
                    <Icon name="chevron-left" />
                    Back to sign in
                </Link>
            </Form>
        </div>
    );
};
