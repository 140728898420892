import { omit } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Api } from '@.api';
import { CreateStoresType, DictionariesResponseType } from '@.api/methods';
import { useForm } from '@.hooks';
import { Layout } from '@.layout';
import { LinkHelper } from '@.services';
import { Input, PhoneInput, Select } from '@.ui';
import { validateOptions } from '@/Components/Pages/Clients/Stores/Action/validate';
import { Buttons } from '@/Components/Partials';

import { StoreEntityScheme } from './types';

type StoresActionProps = {
    edit?: boolean;
};

export const StoresAction: React.FC<StoresActionProps> = ({ edit }) => {
    const [dictionaries, setDictionaries] = useState<DictionariesResponseType>();
    const { id } = useParams();
    const navigator = useNavigate();
    const [field, form] = useForm<StoreEntityScheme>();

    const breadcrumbs = edit
        ? [
              { name: 'Stores', link: LinkHelper.clientsStores },
              { name: form.state.name?.value ?? '', link: LinkHelper.clientsStoreId(Number(id)) },
              { name: 'Edit', current: true },
          ]
        : [
              { name: 'Stores', link: LinkHelper.clientsStores },
              { name: 'Add', current: true },
          ];

    const formTitle = edit ? <h1>Editing store</h1> : <h1>Adding store</h1>;

    const serializeDataToRequest = (data: StoreEntityScheme): CreateStoresType => {
        const { latitude, longitude } = data;

        const dataRequest: CreateStoresType = {
            ...omit(data, ['latitude', 'longitude']),
        };

        if (latitude && longitude) {
            dataRequest.gps_coords = {
                lat: latitude,
                lng: longitude,
            };
        }

        return dataRequest;
    };

    const createValidateOptions = () => {
        const { agent_phone, latitude, longitude } = form.state;

        return validateOptions(agent_phone?.value, Boolean(latitude?.value || longitude?.value));
    };

    const handleCreate = () => {
        form.reset.errors();

        form.ifValid((data) => {
            Api.stores()
                .create(serializeDataToRequest(data))
                .onSuccess(() => navigator(LinkHelper.clientsStores))
                .onValidationError((res) => form.set.errors(res.errors));
        }, createValidateOptions());
    };

    const handleUpdate = () => {
        form.reset.errors();

        form.ifValid((data) => {
            Api.stores()
                .update(serializeDataToRequest(data), Number(id))
                .onSuccess(() => navigator(LinkHelper.clientsStores))
                .onValidationError((res) => form.set.errors(res.errors));
        }, createValidateOptions());
    };

    useEffect(() => {
        if (id && edit) {
            Api.stores()
                .getById(Number(id))
                .onSuccess((res) => {
                    const { id, name, agent_name, agent_phone, agent_email, pin, secret_code, status, store_group, gps_coords, ...other } = res.data;
                    form.set.values({
                        name,
                        agent_name,
                        agent_phone,
                        agent_email,
                        pin,
                        status: status.id,
                        store_group_id: store_group.id,
                        latitude: gps_coords?.lat,
                        longitude: gps_coords?.lng,
                        ...other,
                    });
                });
        }
        Api.dictionaries()
            .get({ by_name: 'store_groups,store_statuses' })
            .onSuccess((res) => {
                setDictionaries(res.data);
            });
    }, []);

    return (
        <Layout name="clients" breadcrumbs={breadcrumbs} small>
            {formTitle}
            <div className="stores-action__form">
                <Input {...field('name').register()} text="Name" required />

                <Input {...field('pin').register()} text="PIN" required />

                <Input {...field('country').register()} text="Country" required />

                <Input {...field('street_address_1').register()} text="Street address 1" required />

                <Input {...field('street_address_2').register()} text="Street address 2" />

                <Input {...field('region').register()} text="State/Province/Region" required />

                <Input {...field('postal').register()} text="Zip/Postal Code" required />

                <Input {...field('city').register()} text="City" required />

                <div className="stores-action__form_gps">
                    <h3>GPS Coordinates (Lat, Long)</h3>

                    <div className="stores-action__form_gps_coords">
                        <Input {...field('latitude').register()} text="Latitude" type="number" />

                        <Input {...field('longitude').register()} text="Longitude" type="number" />
                    </div>
                </div>

                <Input {...field('agent_name').register()} text="Agent name" required />

                <PhoneInput {...field('agent_phone').register()} label="Phone" required />

                <Input {...field('agent_email').register()} text="Agent email" required />

                <Select placeholder="Store groups" {...field('store_group_id').register<number>()} theme="white" required>
                    {dictionaries?.store_groups?.map((group) => (
                        <Select.Option key={`group_${group.id}`} id={group.id}>
                            {group.name}
                        </Select.Option>
                    ))}
                </Select>

                {edit && (
                    <Select placeholder="Status" {...field('status').register<number>()} theme="white" required>
                        {dictionaries?.store_statuses?.map((store) => (
                            <Select.Option key={`store_${store.id}`} id={store.id}>
                                {store.name}
                            </Select.Option>
                        ))}
                    </Select>
                )}

                <Buttons text={edit ? 'Save' : 'Add'} onClickSave={edit ? handleUpdate : handleCreate} />
            </div>
        </Layout>
    );
};
