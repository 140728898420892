import useModal from '@fipnooone/react-modal';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Layout } from '@.layout';
import { useId } from '@/Components/Hooks';
import { ModalConfirm } from '@/Components/Modal/Dialogs';
import { Tab, Table, Table2, TableColumn, TableRow, Tabs } from '@/Components/Partials';
import { Button } from '@/Components/UI';
import { LinkHelper, Tools } from '@/Services';
import { Api } from '@/Services/api';
import { OrderProductResourceResponse, OrderResource } from '@/Services/api/methods';

export const Order: React.FC = () => {
    const [modal, controls] = useModal();
    const [order, setOrder] = useState<OrderResource>();
    const navigate = useNavigate();

    const refreshData = useCallback(
        (id: number) =>
            Api.orders()
                .show(id)
                .onSuccess(({ data }) => {
                    setOrder(data);
                }),
        []
    );

    const id = useId((id) => refreshData(id));

    const handleCancel = useCallback(() => {
        controls.set(
            <ModalConfirm
                message="Do you really want to cancel this order?"
                onCancel={() => controls.close()}
                onConfirm={() => {
                    controls.close();

                    Api.orders()
                        .cancel(id)
                        .then(() => refreshData(id));
                }}
            />
        );
    }, [id]);

    return (
        <Layout
            name="orders"
            breadcrumbs={[
                { name: 'New products orders', link: LinkHelper.ordersNew },
                { name: `Order #${order?.id ?? ''}`, current: true },
            ]}
        >
            {modal}
            {order && (
                <>
                    <h1>
                        {`Order #${order.id}`}
                        <div>
                            <Button color="transparent" text={'Cancel this order'} onClick={handleCancel} disabled={!order.is_cancelable} />
                            <Button color="brown" text="Edit" onClick={() => navigate(LinkHelper.ordersNewItemEditId(order.id))} />
                        </div>
                    </h1>
                    <Tabs>
                        <Tab is_default name="General" path="general">
                            <Table>
                                <TableRow>
                                    <TableColumn bold>Identifier</TableColumn>
                                    <TableColumn>{order.id}</TableColumn>
                                </TableRow>
                                <TableRow>
                                    <TableColumn bold>Company</TableColumn>
                                    <TableColumn>{order.company?.name ?? ''}</TableColumn>
                                </TableRow>
                                <TableRow>
                                    <TableColumn bold>Status</TableColumn>
                                    <TableColumn>{order.status.name}</TableColumn>
                                </TableRow>
                                <TableRow>
                                    <TableColumn bold>Sender</TableColumn>
                                    <TableColumn>{order.user.name}</TableColumn>
                                </TableRow>
                                <TableRow>
                                    <TableColumn bold>Created at</TableColumn>
                                    <TableColumn>{Tools.date.autoFormat(order.created_at * 1000)}</TableColumn>
                                </TableRow>
                                <TableRow>
                                    <TableColumn bold>Updated at</TableColumn>
                                    <TableColumn>{Tools.date.autoFormat(order.updated_at * 1000)}</TableColumn>
                                </TableRow>
                            </Table>
                        </Tab>
                        <Tab name="Products" path={`products`}>
                            <Table2<OrderResource, OrderProductResourceResponse[]> data={order} getData={(d) => d.products}>
                                {{
                                    items: [
                                        (item) => ({
                                            name: 'Name',
                                            content: item.name,
                                        }),
                                        (item) => ({
                                            name: 'Description',
                                            content: item.description,
                                        }),
                                        (item) => ({
                                            name: 'File',
                                            content: item.file.name,
                                        }),
                                    ],
                                    actions: [
                                        (item) => ({
                                            type: 'download',
                                            onClick: () => {
                                                const w = window.open(item.file.url, '_blank', 'noopener,noreferrer');
                                                if (w) w.opener = null;
                                            },
                                        }),
                                    ],
                                }}
                            </Table2>
                        </Tab>
                    </Tabs>
                </>
            )}
        </Layout>
    );
};
