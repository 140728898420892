import { FC, useMemo } from 'react';
import { NavLink } from 'react-router-dom';

import { Auth, LinkHelper, RoutePageK, useAuthPages } from '@.services';
import { Logo } from '@/Assets/Icons/logo';
import { pagesByUser } from '@/Lib/pagesByUser';
import { NonNullableRoutePage, RoutePage } from '@/Routes/types';

import { Item, NestedItem } from './item';
import { SidebarProps } from './types';

const clear = (url: string): string => url.replaceAll('/*', '');

export const Sidebar: FC<SidebarProps> = () => {
    const auth = Auth.Use();

    const routes = useAuthPages(pagesByUser(auth?.user));

    const generatePage = (page: RoutePage & RoutePageK) => {
        if (!page.sidebar) return null;

        const url = `/${clear(page.url)}`;

        const children: RoutePage[] | undefined = page.children?.reduce<RoutePage[]>((prev, curr) => {
            if (!curr.sidebar) return prev;

            if (curr.children && curr.children.length) {
                const current = { ...curr };
                current.children = undefined;

                return [...prev, ...curr.children, current];
            }

            return [...prev, curr];
        }, []);

        if (children?.length) {
            return (
                <NestedItem
                    key={`Sidebar_${page.key}`}
                    url={url}
                    name={page.sidebar.name ?? ''}
                    icon={page.sidebar.icon}
                    items={(children.filter((item) => item.sidebar) as NonNullableRoutePage[]).map((item) => ({
                        url: `${url}/${clear(item.url)}`,
                        name: item.sidebar.name,
                        key: item.url,
                        icon: item.sidebar.icon,
                    }))}
                />
            );
        }

        return <Item url={url} icon={page.sidebar.icon} name={page.sidebar.name} key={`Sidebar_${page.key}`} />;
    };

    const generatePages = (pages: (RoutePage & RoutePageK)[]) => pages.map((page) => generatePage(page));

    const sidebared = useMemo(() => generatePages(routes), [routes]);

    return (
        <aside className="sidebar">
            <NavLink to={LinkHelper.myPage} className="sidebar__logo">
                <Logo />
            </NavLink>
            <div className="sidebar__links">{sidebared}</div>
        </aside>
    );
};

// const NestedLink1: React.FC<NestedLinkProps> = (props) => {
//     return (
//         // 'sidebar__links-nested' + (active === 1 ? ' active' : '')
//         <div onClick={() => props.onClick(props.name)} className={'sidebar__links-nested' + props.className}>
//             <div>
//                 {props.children} <Icon name="chevron-down" size={10} />
//             </div>
//             <nav>
//                 {props.links.map((link) => {
//                     if (link.hasRights !== false)
//                         return (
//                             <NavLink key={`link_${link.name}`} to={link.path}>
//                                 {link.name}
//                             </NavLink>
//                         );
//                 })}
//                 {/* <NavLink to={LinkHelper.clientsCompanies}>Companies</NavLink>
//                 <NavLink to={LinkHelper.clientsStoreGroups}>Store groups</NavLink>
//                 <NavLink to={LinkHelper.clientsStores}>Stores</NavLink> */}
//             </nav>
//         </div>
//     );
// };

// export const Sidebar1: React.FC<SidebarProps> = ({ currentPage }) => {
//     const [active, setActive] = useState<string>(currentPage);
//     const auth = Auth.Use();

//     const handleClick = (value: string) => {
//         active === value ? setActive('') : setActive(value);
//     };

//     return (
//         <aside className="sidebar">
//             <NavLink to={LinkHelper.myPage} className="sidebar__logo">
//                 <svg>
//                     <use href={`${Logo}#logo`} />
//                 </svg>
//             </NavLink>
//             <div className="sidebar__links">
//                 <NavLink to={LinkHelper.dashboard}>
//                     <p>
//                         <Icon name="dashboard" size={19} />
//                         Dashboard
//                     </p>
//                 </NavLink>
//                 <NavLink to={LinkHelper.marketplace}>
//                     <p>
//                         <Icon name="marketplace" size={19} />
//                         Marketplace
//                     </p>
//                 </NavLink>
//                 <NavLink to={LinkHelper.stations}>
//                     <p>
//                         <Icon name="irs-station" size={19} />
//                         iRomaScents Station
//                     </p>
//                 </NavLink>
//                 <NestedLink
//                     name="clients"
//                     links={[
//                         { name: 'Companies', path: LinkHelper.clientsCompanies, hasRights: auth?.hasRights('Companies') },
//                         { name: 'Store groups', path: LinkHelper.clientsStoreGroups, hasRights: auth?.hasRights('Store_groups') },
//                         { name: 'Stores', path: LinkHelper.clientsStores, hasRights: auth?.hasRights('Stores') },
//                     ]}
//                     className={active === 'clients' ? ' active' : ''}
//                     onClick={handleClick}
//                 >
//                     <p>
//                         <Icon name="clients" size={19} />
//                         Clients
//                     </p>
//                 </NestedLink>
//                 <NestedLink
//                     name="orders"
//                     links={[
//                         { name: 'New products orders', path: LinkHelper.ordersNewOrders },
//                         { name: 'Ordering devices', path: LinkHelper.ordersDevices },
//                         { name: 'Orders history', path: LinkHelper.ordersHistory },
//                     ]}
//                     className={active === 'orders' ? ' active' : ''}
//                     onClick={handleClick}
//                 >
//                     <p>
//                         <Icon name="orders" size={19} />
//                         Orders
//                     </p>
//                 </NestedLink>
//                 <NestedLink
//                     name="data"
//                     links={[
//                         { name: 'Files management', path: LinkHelper.dataFiles },
//                         // { name: 'Products', path: LinkHelper.dataProducts },
//                         { name: 'Brands', path: LinkHelper.dataBrands },
//                         { name: 'Currencies', path: LinkHelper.dataCurrencies },
//                         { name: 'Product types', path: LinkHelper.dataProductTypes },
//                         { name: 'Special offers', path: LinkHelper.dataSpecialOffers },
//                     ]}
//                     className={active === 'data' ? ' active' : ''}
//                     onClick={handleClick}
//                 >
//                     <p>
//                         <Icon name="data" size={19} />
//                         Data
//                     </p>
//                 </NestedLink>
//                 {/* <div className={'sidebar__links-nested'}>
//                     <div>
//                         Data <Icon name="chevron-down" size={10} />
//                     </div>
//                     <nav>
//                         <NavLink to={LinkHelper.dataFiles}>Files management</NavLink>
//                         <NavLink to={LinkHelper.dataBrands}>Tablets</NavLink>
//                         <NavLink to={LinkHelper.dataBrands}>Product</NavLink>
//                         <NavLink to={LinkHelper.dataBrands}>Brands</NavLink>
//                         <NavLink to={LinkHelper.dataCurrencies}>Currencies</NavLink>
//                         <NavLink to={LinkHelper.dataBrands}>Products types</NavLink>
//                         <NavLink to={LinkHelper.dataBrands}>Age</NavLink>
//                         <NavLink to={LinkHelper.dataBrands}>Sex</NavLink>
//                         <NavLink to={LinkHelper.dataBrands}>Strength</NavLink>
//                         <NavLink to={LinkHelper.dataBrands}>Occasions</NavLink>
//                         <NavLink to={LinkHelper.dataBrands}>Ingredients</NavLink>
//                         <NavLink to={LinkHelper.dataSpecialOffers}>Special Offers</NavLink>
//                     </nav>
//                 </div> */}
//                 <NestedLink
//                     name="logs"
//                     links={[{ name: 'System logs', path: LinkHelper.dataBrands }]}
//                     className={active === 'logs' ? ' active' : ''}
//                     onClick={handleClick}
//                 >
//                     <p>
//                         <Icon name="logs" size={19} />
//                         Logs
//                     </p>
//                 </NestedLink>
//                 <NestedLink
//                     name="settings"
//                     links={[
//                         { name: 'Users', path: LinkHelper.settingsUsers },
//                         { name: 'User roles', path: LinkHelper.settingsRoles },
//                     ]}
//                     className={active === 'settings' ? ' active' : ''}
//                     onClick={handleClick}
//                 >
//                     <p>
//                         <Icon name="settings" size={19} />
//                         Settings
//                     </p>
//                 </NestedLink>
//             </div>
//         </aside>
//     );
// };
