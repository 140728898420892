/**
 * TODO: tests?)
 */
export const loop = <T>(from: number, to: number, fn: (index: number) => T, step = 1) => {
    const result: T[] = [];

    for (let index = from; index <= to; index += step) {
        result.push(fn(index));
    }

    return result;
};
