import { Routes } from '../types';
import { ImportBOPage } from './import-bo';
import { ScenticonUsersPage } from './scenticon-users';
// import { RolesPage } from './roles';
import { UsersPage } from './users';

export const SettingsPages: Routes = [
    {
        url: 'settings',
        permissions: {
            entity: 'Users',
            right: 'read',
        },
        sidebar: {
            icon: 'settings',
            name: 'Settings',
        },
        // children: [UsersPage, RolesPage],
        children: [UsersPage, ImportBOPage, ScenticonUsersPage],
    },
];
