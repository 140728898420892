import useModal from '@fipnooone/react-modal';
import { MouseEvent, ReactElement, useEffect, useMemo, useReducer, useRef, useState } from 'react';

import { useObjectEffect, useObjectMemo, usePagination } from '@/Components/Hooks';
import { ModalList } from '@/Components/Modal/Dialogs';
import { ModalUnsaved } from '@/Components/Modal/Dialogs/Unsaved';
import { Checkbox, Filter3, Pagination } from '@/Components/UI';
import { FilterData } from '@/Components/UI/Filters.v3/modules';
import { Response } from '@/Types';

import { Table2 } from '..';
import { Buttons } from '../Buttons';
import { TableColumn } from '../Table';
import { TableChildItem } from '../Table.V2/types';
import { ImportTableProps } from './types';

type Changed = {
    [name: string]: {
        from: boolean;
        to: boolean;
    };
};

type Current = {
    [name: string]: boolean;
};

export const ImportTable = <
    T extends {
        pagination: Response.Pagination;
        products: object[];
    },
    Columns extends readonly string[]
>({
    itemRender,
    get,
    post,
    headers,
    onChange,
    isDeleted,
    actions,
    children,
    getId,
    getDefault,
    edit = true,
    ...props
}: ImportTableProps<T, Columns>): ReactElement<ImportTableProps<T, Columns>> => {
    type Item = (T['products'][Exclude<keyof T['products'], 'pagination'>] & object[])[number];

    const [data, setData] = useState<T['products']>();
    const [filters, setFilters] = useState<FilterData>();
    const { currentPage, lastPage, changePage, nextPage, prevPage, process, total } = usePagination(8);
    const [changed, setChanged] = useState<Changed>({});
    const [override, setOverride] = useState<Current>({});
    const [modal, controls] = useModal();
    const isUnsavedRef = useRef(false);
    const [resetState, resetTableState] = useReducer((p) => p + 1, 0);

    const getData = () => {
        if (!get) return;

        get({ ...filters, ...props.filters, page: currentPage, per_page: 8 })?.onSuccess((res) => {
            process(res.data.pagination);
            setData(res.data.products);
            setOverride({});
            setChanged({});
        });
    };

    const isUnsaved = useObjectMemo(() => {
        const values = Object.values(changed);

        if (!values.length) return false;

        return values.some((item) => item.from !== item.to);
    }, [changed]);

    useObjectEffect(() => {
        getData();
    }, [filters, currentPage, props.filters]);

    const handleSave = () => {
        const ids = Object.entries(changed)
            .filter(([, value]) => value.from !== value.to)
            .map(([id]) => Number(id));

        onChange && onChange(ids);

        post &&
            ids.length &&
            post(ids)
                ?.onCreated((res) => {
                    res = res as { data: { products: { id: number; name: string }[] }; message: string; code: 201 };

                    controls?.set(
                        <ModalList
                            message={res.message}
                            list={
                                (
                                    res.data as {
                                        products: { id: number; name: string }[];
                                    }
                                )?.products?.map((item) => item.name) ?? []
                            }
                        />
                    );
                })
                .then(() => {
                    getData();
                })
                .catch(() => {
                    getData();
                });

        setChanged({});
    };

    const handleLink = (id: number, from: boolean, to: boolean) => {
        setOverride((prev) => ({ ...prev, [id]: to }));

        setChanged((prev) => {
            if (prev[id]?.from !== undefined) {
                prev[id].to = to;
                return { ...prev };
            }

            return { ...prev, [id]: { from, to } };
        });
    };

    const handleConfirm = () => {
        controls.close();
    };

    const handleConfirmSave = () => {
        handleSave();
        handleConfirm();
    };

    const handleConfirmDiscard = () => {
        setChanged({});
        setOverride({});
        getData();
        handleConfirm();
        resetTableState();
    };

    const handleConfirmation = (event: MouseEvent) => {
        if (!isUnsavedRef.current) {
            return;
        }

        event.preventDefault();

        controls.set(<ModalUnsaved onSave={() => handleConfirmSave()} onDiscard={() => handleConfirmDiscard()} onCancel={() => handleConfirm()} />);

        return false;
    };

    const tableItems = useMemo(() => {
        const items: TableChildItem<Item>[] = [
            ...(headers ?? []).map((column, index) => {
                return (item: Item) => ({
                    name: column,
                    content: itemRender(column, item, { index, data: data as T['products'] }),
                    sort: column.toLocaleLowerCase(),
                    max: (() => {
                        switch (column) {
                            case 'UDI':
                                return 15;
                            case 'Name':
                                return 40;
                            case 'Brand':
                                return 40;
                        }
                    })(),
                });
            }),
        ];

        if (getId && getDefault) {
            items.push((item) => {
                const itemId = getId(item);
                const itemDefault = getDefault(item);

                return {
                    name: 'Linked',
                    sort: 'is_connected',
                    content: (
                        <TableColumn key={`checkbox-item-${itemId}`}>
                            <Checkbox
                                value={override[itemId] === undefined ? itemDefault : override[itemId]}
                                onChange={(value) => handleLink(itemId, itemDefault, value)}
                                isDisabled={!edit}
                            />
                        </TableColumn>
                    ),
                };
            });
        }

        return items;
    }, [data, edit, getDefault, getId, headers, itemRender, override]);

    const filterWrapperRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (isUnsaved) {
            filterWrapperRef.current?.classList.add('fit-content', 'user-no-select_child');
        } else {
            filterWrapperRef.current?.classList.remove('fit-content', 'user-no-select_child');
        }

        isUnsavedRef.current = isUnsaved;
    }, [isUnsaved]);

    return (
        <>
            <span className="import-table-counter">{`Total count - ${total}`}</span>

            {/** TODO: fix types. */}
            <Table2 get={setFilters as any} data={data} key={resetState}>
                {{
                    filters: (handleChange) => (
                        <div ref={filterWrapperRef} onMouseDown={handleConfirmation}>
                            <Filter3
                                onChange={(filters) => {
                                    setFilters(filters);
                                    handleChange(filters);
                                }}
                            >
                                {children}
                            </Filter3>
                        </div>
                    ),
                    items: tableItems,
                    actions,
                }}
            </Table2>

            <Pagination current={currentPage} last={lastPage} changePage={changePage} nextPage={nextPage} prevPage={prevPage} />

            <Buttons onClickSave={handleSave} text={'Save'} noSave={!isUnsaved} noCancel />

            {modal}
        </>
    );
};
