import cn from 'classnames';
import { FC, useEffect } from 'react';

import { useForm, useObjectEffect } from '@/Components/Hooks';
import { Icon, NumberInput, Select } from '@/Components/UI';
import { isNumber } from '@/Lib';

import { PriceData, PriceProps } from './types';

/**
 * TODO: what is coeff?
 */
const coeff = 0.033814;

export const Price: FC<PriceProps> = ({ currencies, stores, uuid, onDelete, onChange, controls, value }) => {
    const [field, form] = useForm<PriceData>(value?.data);

    const handleDelete = () => onDelete(uuid);

    useObjectEffect(() => {
        const { volume_fl_oz, volume_ml } = form.state;

        let fl_oz = volume_fl_oz?.value;
        let ml = volume_ml?.value;

        if (volume_fl_oz && isNumber(volume_fl_oz?.value) && !isNumber(volume_ml?.value)) {
            ml = volume_fl_oz.value / coeff;
            form.set.fieldValue('volume_ml', ml);
        } else if (volume_ml && isNumber(volume_ml?.value) && !isNumber(volume_fl_oz?.value)) {
            fl_oz = volume_ml.value * coeff;
            form.set.fieldValue('volume_fl_oz', fl_oz);
        }

        controls &&
            controls(uuid, {
                ifValid: form.ifValid,
                setValues: form.set.values,
                clearErrors: form.reset.errors,
                errors: form.set.errors,
            });

        if (!form.isTrusted.current) return;

        const stateData = Object.fromEntries(
            Object.entries(form.state)
                .filter(([key]) => key !== 'error')
                .map(([key, state]) => (typeof state === 'string' ? [key, state] : [key, state.value]))
        );

        const calcData = {
            volume_fl_oz: fl_oz,
            volume_ml: ml,
        };

        onChange?.({
            uuid,
            data: isNumber(fl_oz) && isNumber(ml) ? { ...stateData, ...calcData } : stateData,
        });
    }, [form.state]);

    useObjectEffect(() => {
        if (!value?.data) {
            return;
        }

        form.set.values(Object.fromEntries(Object.entries(value.data).filter(([key]) => key !== 'error')), false, false);
    }, [value]);

    useEffect(() => {
        if (!currencies.length) {
            form.set.fieldValue('currency_id', undefined);
        }
    }, [currencies.length]);

    useEffect(() => {
        if (!stores.length) {
            form.set.fieldValue('store_id', undefined);
        }
    }, [stores.length]);

    const productClassName = cn('product-price', form.state.error && 'error');

    return (
        <div className={productClassName}>
            <NumberInput {...field('volume_ml').register()} text="Volume ml" round={(value) => Math.ceil(value)} />

            <NumberInput {...field('volume_fl_oz').register()} text="Volume fl OZ" round={(value) => Math.ceil(value * 10) / 10} />

            <Select {...field('store_id').register<number>()} placeholder="Store" theme="white" absolute>
                {stores.map((store) => (
                    <Select.Option key={`price-store-${store.id}`} id={store.id}>
                        {store.name}
                    </Select.Option>
                ))}
            </Select>

            <Select {...field('currency_id').register<number>()} placeholder="Currency" theme="white" absolute>
                {currencies.map((currency) => (
                    <Select.Option key={`price-currency-${currency.id}`} id={currency.id}>
                        {currency.name}
                    </Select.Option>
                ))}
            </Select>

            <NumberInput {...field('price').register()} type="number" text="Price" />

            <Icon name="trash" onClick={handleDelete} />

            {Boolean(form.state.error) && <span className="product-price__error">{form.state.error}</span>}
        </div>
    );
};
