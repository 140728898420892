import { Language } from '@/Config';

import { defaultLanguage } from '..';

export const currentLanguage = () => {
    const userLanguage = window.navigator.language;

    if (!userLanguage) {
        return defaultLanguage();
    }

    const found = Language.languagesList.find((lang) => lang.code === userLanguage);

    if (!found) {
        return defaultLanguage();
    }

    return found;
};
