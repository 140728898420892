import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { Api } from '@.api';
import { Layout } from '@.layout';
import { Table2 } from '@.partials';
import { Auth, LinkHelper } from '@.services';
import { Button, Filter3 } from '@.ui';
import { ProductsResponseType } from '@/Services/api/methods';
import { PermissionProps, Request } from '@/Types';

import { ProductsTableProps } from './types';

export const Products: React.FC<PermissionProps> = (props) => {
    const navigator = useNavigate();
    const auth = Auth.Use();

    return (
        <Layout name="data" breadcrumbs={[{ name: 'Products', current: true }]}>
            <h1>
                Products
                <div>
                    {auth?.user?.permissions?.product_imports.read && (
                        <Button
                            color="brown"
                            text="Import from GDB"
                            onClick={() => navigator(LinkHelper.dataProductsImport)}
                            disabled={!auth?.user?.permissions?.product_imports.read}
                        />
                    )}
                    <Button color="brown" text="Add product" onClick={() => navigator(LinkHelper.dataProductsAdd)} disabled={!props.permissions?.create} />
                </div>
            </h1>
            <ProductsTable permissions={auth?.user?.permissions?.product_imports} />
        </Layout>
    );
};

export const ProductsTable: React.FC<ProductsTableProps> = ({ brand_id, hideActions, permissions }) => {
    const auth = Auth.Use();

    const get = useCallback((q?: Request.All) => Api.products().getAll(q), []);

    const getData = useCallback((data: ProductsResponseType) => data.products, []);
    const item = useCallback((item: ProductsResponseType['products'][number]) => ({ className: item.deleted_at ? 'deleted' : '' }), []);

    const handleDelete = useCallback((id: number) => Api.products().delete(id), []);
    const handleRestore = useCallback((id: number) => Api.products().restore(id), []);

    const filters = useMemo(() => ({ brand_id }), [brand_id]);

    return (
        <>
            <Table2 filters={filters} get={get} getData={getData} export="products" item={item}>
                {{
                    filters: (handler) => (
                        <Filter3 onChange={handler}>
                            <Filter3.Search />
                            <Filter3.Brands related />
                            <Filter3.ProductTypes related />
                            <Filter3.Ages related />
                            <Filter3.Sexes related />
                            <Filter3.Strengths related />
                            <Filter3.Occasions related />
                            <Filter3.Ingredients related />
                            <Filter3.Tags related />
                            {auth?.user?.is_super && <Filter3.Boolean placeholder="Archived" name="is_archived" />}
                            <Filter3.Boolean placeholder="Tested" name="is_tested" />
                        </Filter3>
                    ),
                    items: [
                        (item) => ({
                            name: 'UDI',
                            content: item.udi,
                            max: 8,
                            sort: 'udi',
                        }),
                        (item) => ({
                            name: 'Name',
                            content: item.name,
                            max: 8,
                            sort: 'name',
                            link: LinkHelper.dataProductId(item.id),
                        }),
                        (item) => ({
                            name: 'Companies',
                            content: item.companies?.map((company) => company.name).join(', '),
                            max: 8,
                            sort: 'companies',
                        }),
                        (item) => ({
                            name: 'Brand',
                            content: item.brand.name,
                            max: 8,
                            sort: 'brand',
                        }),
                        (item) => ({
                            name: 'Type',
                            content: item.type.name,
                            max: 8,
                            sort: 'type',
                        }),
                        (item) => ({
                            name: 'Age',
                            content: item.ages.map(({ name }) => name).join(', '),
                            max: 8,
                            sort: 'ages',
                        }),
                        (item) => ({
                            name: 'Sex',
                            content: item.sexes.map(({ name }) => name).join(', '),
                            max: 8,
                            sort: 'sexes',
                        }),
                        (item) => ({
                            name: 'Strength',
                            content: item.strength.name,
                            max: 8,
                            sort: 'strength',
                        }),
                        (item) => ({
                            name: 'Occasion',
                            content: item.occasions.map(({ name }) => name).join(', '),
                            max: 8,
                            sort: 'occasions',
                        }),
                        (item) => ({
                            name: 'Ingredients',
                            content: item.ingredients.map(({ name }) => name).join(', '),
                            max: 8,
                            sort: 'ingredients',
                        }),
                        (item) => ({
                            name: 'Characters',
                            content: item.characters.map(({ name }) => name).join(', '),
                            max: 8,
                            sort: 'characters',
                        }),
                        (item) => ({
                            name: 'Tag',
                            content: item.tags.map(({ name }) => name).join(', '),
                            max: 8,
                            sort: 'tags',
                        }),
                    ],
                    actions: [
                        (item) => ({
                            type: 'prices',
                            link: LinkHelper.dataProductPricesId(item.id),
                            disabled: !!item.deleted_at && !auth?.user?.permissions?.product_prices.read,
                            hide: hideActions || auth?.user?.is_super,
                        }),
                        (item) => ({
                            type: 'edit',
                            link: LinkHelper.dataProductEditId(item.id),
                            disabled:
                                !!item.deleted_at &&
                                !(auth?.user?.permissions?.products || auth?.user?.permissions?.product_descriptions || auth?.user?.is_super),
                            hide: hideActions,
                        }),
                        (item) => ({
                            type: item.deleted_at ? 'restore' : 'delete',
                            onClick: item.deleted_at ? () => handleRestore(item.id) : () => handleDelete(item.id),
                            disabled: !permissions?.delete && !auth?.user?.is_super,
                            hide: hideActions,
                        }),
                        (item) => ({
                            type: 'open',
                            link: LinkHelper.dataProductId(item.id),
                        }),
                    ],
                }}
            </Table2>
        </>
    );
};

export { Product } from './Product';
