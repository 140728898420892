import { Base } from '@.api/methods';
import { SystemLogResponse, SystemLogsResponse } from '@/Services/api/methods/systemLogs/types';

export class SystemLogs extends Base {
    public getAll(query?: { page?: number; per_page?: number; search?: string; start_date?: number; end_date?: number; type?: number }) {
        this.setPath('system-logs');
        if (query) this.setQuery(query);

        return this.getRequest<SystemLogsResponse>();
    }

    public getById(id: number) {
        this.setPath('system-logs');
        this.setId(id);

        return this.getRequest<SystemLogResponse>();
    }
}
