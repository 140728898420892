import React, { FC, useMemo } from 'react';

import { Layout } from '@.layout';
import { Input, Select } from '@.ui';
import { PermissionsSelect } from '@/Components/Pages/Settings/Users/Action/PermissionsSelect';
import { useUserFormAction } from '@/Components/Pages/Settings/Users/Action/useUserFormAction';
import { Buttons } from '@/Components/Partials';
import { StatisticObjectEnum } from '@/Services/StatisticPermissionService';

type UsersActionProps = {
    isEdit?: boolean;
};

/**
 * TODO: we need to come up with something
 */
const DICTIONARY_SUPER_ADMIN_ID = 1;

export const UsersAction: FC<UsersActionProps> = ({ isEdit = false }) => {
    const { field, form, breadcrumbs, fetchFormAction, dictionaries, groups } = useUserFormAction(isEdit);

    const isDisabled = useMemo<boolean>(() => {
        const statisticObjectType = form.state.statistic_object_type?.value;

        if (statisticObjectType === StatisticObjectEnum.COMPANY) {
            return !form.state.company_id?.value;
        } else if (statisticObjectType === StatisticObjectEnum.STORE_GROUP) {
            return !form.state.statistic_object_store_group_id?.value;
        } else if (statisticObjectType === StatisticObjectEnum.STORE) {
            return !form.state.statistic_object_store_id?.value;
        }

        return false;
    }, [form.state]);

    const isHiddenStoreGroup = form.state.role_id?.value === DICTIONARY_SUPER_ADMIN_ID;

    return (
        <Layout name="settings" breadcrumbs={breadcrumbs} small>
            {isEdit ? <h1>Editing user</h1> : <h1>Adding user</h1>}
            <div className="users-action__form">
                <Input {...field('name').register()} text="Name" required />
                <Input {...field('username').register()} text="Username" required />
                <Input {...field('email').register()} text="Email" type="email" required />
                <Input {...field('password').register()} autoComplete="new-password" text="New password" type="password" required />
                <Input {...field('repeat_password').register()} autoComplete="new-password" text="Confirm password" type="password" required />
                <Select {...field('company_id').register<number>()} placeholder="Company" theme="white" required={true} autoClose>
                    {dictionaries?.companies?.map((company) => (
                        <Select.Option id={company.id} key={`company_${company.id}`}>
                            {company.name}
                        </Select.Option>
                    ))}
                </Select>

                <PermissionsSelect form={form} field={field} dictionaries={dictionaries} />

                <Select {...field('role_id').register<number>()} placeholder="Role" theme="white" required={true} autoClose>
                    {dictionaries?.roles?.map((role) => (
                        <Select.Option key={role.id} id={role.id}>
                            {role.name}
                        </Select.Option>
                    ))}
                </Select>

                {!isHiddenStoreGroup && (
                    <div className="store-groups">
                        <Select
                            {...field('store_groups_ids').register<number[]>()}
                            placeholder="Store groups"
                            localSearch
                            multiple
                            disabled={Boolean(form.state.is_company_admin?.value)}
                        >
                            {groups?.map((group) => (
                                <Select.Option key={`group_${group.id}`} id={group.id}>
                                    {group.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </div>
                )}

                {isEdit && (
                    <Select {...field('status').register<number>()} placeholder="Status" autoClose theme="white" required>
                        {dictionaries?.user_statuses?.map((status) => (
                            <Select.Option key={`status_${status.id}`} id={status.id}>
                                {status.name}
                            </Select.Option>
                        ))}
                    </Select>
                )}

                <Buttons text={isEdit ? 'Save' : 'Add'} onClickSave={fetchFormAction} saveProps={{ disabled: isDisabled }} />
            </div>
        </Layout>
    );
};
