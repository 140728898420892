import { requiredRule, ValidateOptions } from '@.hooks';
import { IrcUsersData } from '@/Components/Pages/Settings/ScenticonUsers/Action/types';

export const validateOptions: ValidateOptions<IrcUsersData> = {
    name: {
        type: 'string',
        required: requiredRule,
    },
    login: {
        type: 'string',
        required: requiredRule,
    },
};
