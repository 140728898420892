import { optionalPasswords, requiredEmail, requiredRule, ValidateOptions } from '@.hooks';

import { ProfileData } from './types';

export const validateOptions: ValidateOptions<ProfileData> = {
    name: {
        type: 'string',
    },
    username: {
        type: 'string',
        required: requiredRule,
    },
    email: requiredEmail,
    ...optionalPasswords,
};
