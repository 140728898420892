import { AuthLogs } from '@/Components/Pages/Logs';
import { AuthLog } from '@/Components/Pages/Logs/AuthLogs/Log';
import { RoutePage } from '@/Routes/types';

export const AuthLogsPage: RoutePage = {
    url: 'auth-logs',
    component: <AuthLogs />,
    permissions: 'admin',
    sidebar: {
        name: 'Auth logs',
    },
    children: [
        {
            url: ':id',
            component: <AuthLog />,
            permissions: 'admin',
            children: [],
        },
    ],
};
