import { User, Users, UsersAction } from '@.pages';

import { RoutePage } from '../types';

export const UsersPage: RoutePage = {
    url: 'users',
    component: <Users />,
    permissions: {
        entity: 'Users',
        right: 'read',
    },
    sidebar: {
        name: 'Users',
    },
    children: [
        {
            url: ':id',
            component: <User />,
            children: [
                {
                    url: 'edit',
                    component: <UsersAction isEdit />,
                    permissions: {
                        entity: 'Users',
                        right: 'update',
                    },
                },
            ],
        },
        {
            url: 'add',
            component: <UsersAction />,
            permissions: {
                entity: 'Users',
                right: 'create',
            },
        },
    ],
};
