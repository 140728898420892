import { useState } from 'react';

import { useObjectEffect } from '@/Components/Hooks';
import { Bars, Board } from '@/Components/Partials';
import { LinkHelper } from '@/Services';
import { Api } from '@/Services/api';
import { DashboardStoresResponse } from '@/Services/api/methods/dashboard/types';

import { DashboardItem } from '../../types';

export const Stores: DashboardItem = ({ filters }) => {
    const [stores, setStores] = useState<DashboardStoresResponse>();
    // const [localFilters, setLocalFilters] = useState<FilterData>();

    useObjectEffect(() => {
        Api.dashboard()
            .stores({ ...filters })
            .onSuccess((res) => setStores(res.data));
    }, [filters]);

    return (
        <Board.Item title="Popular stores" more={LinkHelper.dashboardPopularStores}>
            {/*<Filter3 onChange={setLocalFilters} fromData={dictionaries ?? null}>*/}
            {/*    <Filter3.Companies />*/}
            {/*    <Filter3.StoreGroups />*/}
            {/*</Filter3>*/}
            <Bars data={stores?.stores ?? []} name="Whiffs" />
        </Board.Item>
    );
};
