import React, { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Layout } from '@.layout';
import { Board } from '@.partials';
import { debounce } from '@/Lib';
import { Auth, URLUtils } from '@/Services';
import { StatisticObjectEnum } from '@/Services/StatisticPermissionService';
import { analyticsStoreSelectors } from '@/Store';
import { AnalyticsWidgetTabsEnum } from '@/Types/analytics/AnalyticsWidgetTabsEnum';

import { AnalyticsPageCharts } from './AnalyticsPageCharts';
import { AnalyticsPageHeader } from './AnalyticsPageHeader';

export const useAnalyticsPage = () => {
    const auth = Auth.Use();

    const [searchParams] = useSearchParams();

    const loadAllStatistics = analyticsStoreSelectors.use.loadAllStatistics();
    const loadUserStatistics = analyticsStoreSelectors.use.loadUserStatistics();
    const loadProductStatistics = analyticsStoreSelectors.use.loadProductStatistics();
    const updatePageFilters = analyticsStoreSelectors.use.updatePageFilters();

    const statisticRequest = analyticsStoreSelectors.use.statisticRequest();
    const statisticData = analyticsStoreSelectors.use.statistic();

    const fetchStatisticDataDebounced = useMemo(
        () =>
            debounce((tab: string | null) => {
                switch (tab) {
                    case AnalyticsWidgetTabsEnum.ALL:
                        loadAllStatistics();
                        break;
                    case AnalyticsWidgetTabsEnum.USERS:
                        loadUserStatistics();
                        break;
                    case AnalyticsWidgetTabsEnum.PRODUCT:
                        loadProductStatistics();
                        break;
                    default:
                        loadAllStatistics();
                        break;
                }
            }, 300),
        [loadProductStatistics, loadUserStatistics, loadAllStatistics]
    );

    /**
     * Once after open page.
     */
    useEffect(() => {
        const id = Number(URLUtils.getQueryValueFromWindowLocation('id'));
        const type = URLUtils.getQueryValueFromWindowLocation('type') as StatisticObjectEnum;

        if (id && !isNaN(id) && type) {
            updatePageFilters({ statistic_object: { type, id } });
            return;
        }

        const statisticObject = auth?.user?.statistic_object;

        if (statisticObject && statisticObject?.id && statisticObject?.type) {
            updatePageFilters({ statistic_object: { id: statisticObject.id, type: statisticObject.type } });
        }
    }, [updatePageFilters, auth?.user?.statistic_object]);

    useEffect(() => {
        const tab = searchParams.get('tab');

        fetchStatisticDataDebounced(tab);
    }, [fetchStatisticDataDebounced, searchParams, statisticRequest.filters]);

    return {
        statisticData,
    };
};

export const AnalyticsPage: React.FC = () => {
    const breadcrumbs = [{ name: 'Analytics', current: true }];

    const { statisticData } = useAnalyticsPage();

    const isShowCharts = Object.keys(statisticData).length > 0;

    return (
        <Layout nobg name="analytics" breadcrumbs={breadcrumbs}>
            <Board>
                <AnalyticsPageHeader />

                {isShowCharts && <AnalyticsPageCharts statisticDataset={statisticData} />}
            </Board>
        </Layout>
    );
};
