import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { Api } from '@.api';
import { Layout } from '@.layout';
import { Table2 } from '@.partials';
import { LinkHelper } from '@.services';
import { Button, Filter3 } from '@.ui';
import { IngredientsResponseType } from '@/Services/api/methods';
import { PermissionProps, Request } from '@/Types';

export * from './Action';
export * from './Ingredient';

export const Ingredients: React.FC<PermissionProps> = (props) => {
    const navigate = useNavigate();

    const get = useCallback((q?: Request.All) => Api.ingredients().getAll(q), []);
    const handleRestore = useCallback((id: number) => Api.ingredients().restore(id), []);
    const handleDelete = useCallback(
        (id: number) =>
            Api.ingredients()
                .delete(id)
                .onError((res) => alert(res.message)),
        []
    );

    const getData = useCallback((data: IngredientsResponseType) => data.ingredients, []);
    const items = useCallback((item: IngredientsResponseType['ingredients'][number]) => ({ className: item.deleted_at ? 'deleted' : '' }), []);

    return (
        <Layout name="data" breadcrumbs={[{ name: 'Ingredients', current: true }]}>
            <h1>
                Ingredients
                <Button color="brown" text="Add ingredient" onClick={() => navigate(LinkHelper.dataIngredientsAdd)} disabled={!props.permissions?.create} />
            </h1>
            <Table2 get={get} getData={getData} item={items}>
                {{
                    filters: (handler) => (
                        <Filter3 onChange={handler}>
                            <Filter3.Search />
                            <Filter3.Boolean placeholder="Archived" name="is_archived" />
                        </Filter3>
                    ),
                    items: [
                        (item) => ({
                            link: LinkHelper.dataIngredientId(item.id),
                            name: 'Name',
                            content: item.name,
                            sort: 'name',
                        }),
                    ],
                    actions: [
                        (item) => ({
                            type: 'edit',
                            link: LinkHelper.dataIngredientsEditId(item.id),
                        }),
                        (item) => ({
                            type: item.deleted_at ? 'restore' : 'delete',
                            onClick: item.deleted_at ? () => handleRestore(item.id) : () => handleDelete(item.id),
                        }),
                        (item) => ({
                            type: 'open',
                            link: LinkHelper.dataIngredientId(item.id),
                        }),
                    ],
                }}
            </Table2>
        </Layout>
    );
};
