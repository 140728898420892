import { useState } from 'react';

import { Api } from '@.api';
import { useForm } from '@.hooks';
import { ApproveProductProposalFormScheme } from '@/Components/Partials/ProductProposals/ApproveProductProposalModal/ApproveProductProposalFormScheme';
import { ProductProposal } from '@/Services/api/methods/ProductsProposals';
import { ProductProposalService } from '@/Services/ProductProposal';

interface UseApproveProductParameters {
    productProposal: ProductProposal;
    updateProductProposal: () => void;
    close: () => void;
}

export const useApproveProduct = ({ productProposal, updateProductProposal, close }: UseApproveProductParameters) => {
    const [field, form] = useForm<ApproveProductProposalFormScheme>({});

    const [isShowUpdatedProductProposalMessage, setShowUpdatedProductProposalMessage] = useState<boolean>(false);

    const approve = () => {
        form.ifValid(async (data) => {
            const productProposalService = new ProductProposalService(Api.productsProposals());

            try {
                await productProposalService.approve(productProposal, data.udi);

                updateProductProposal();
                close();
            } catch (error) {
                setShowUpdatedProductProposalMessage(true);
            }
        });
    };

    return {
        approve,
        isShowUpdatedProductProposalMessage,
        field,
    };
};
