const to = (string: string | number, lenght = 2) => `${Array.from({ length: lenght - 1 }, (_) => '0').join('')}${string}`.slice(-lenght);

export const format = (time: number) => {
    let seconds = time;

    const days = Math.floor(seconds / (3600 * 24));
    seconds -= days * 3600 * 24;

    const hours = Math.floor(seconds / 3600);
    seconds -= hours * 3600;

    const minutes = Math.floor(seconds / 60);
    seconds -= minutes * 60;

    return `${to(days, 3)}d ${to(hours)}h ${to(minutes)}m ${to(seconds)}s`;
};
