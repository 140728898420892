import { useContext } from '@fipnooone/react-modal';

import { Button, Icon } from '@/Components/UI';

interface ListProps {
    message: string;
    list?: string[];
}

export const ModalMessage: React.FC<ListProps> = ({ list, message }) => {
    const modal = useContext();

    return (
        <div className="unsaved">
            <Icon name="close" size={20} onClick={() => modal.close()} />
            <h1>{message}</h1>
            {list && (
                <div className="unsaved__list">
                    <span>{list.join(', ')}</span>
                    {/* {list.map((item) => (
                    <span key={`m-u-${item}`}>{item}</span>
                ))} */}
                </div>
            )}
            <Button color="brown" text="Ok" onClick={() => modal.close()} />
        </div>
    );
};
