import { useEffect, useMemo } from 'react';

type ReactElement = { $$typeof: string; key?: string };
type Known = string | number | undefined | boolean | null | bigint | ((args: unknown) => unknown) | symbol;
type RegularDependency = Known | object | unknown;
type Dependency = RegularDependency | RegularDependency[] | ReactElement[] | null;
type Dep = RegularDependency | object;

const beautify = (str: string) => str.replaceAll(/\s+/g, '').replaceAll('\\', '+');

const stringify = (value: Dep): Known => {
    const seen = new WeakSet();

    if (value === null || typeof value !== 'object') return value as Known;

    if (Array.isArray(value)) return JSON.stringify(value.map(stringify));

    return beautify(
        JSON.stringify(value, (_key, val) => {
            if (typeof val === 'object' && val !== null) {
                if (seen.has(val) || Object.keys(val).length >= 20) return '[Circular]';

                seen.add(val);
            }

            return val;
        })
    );
};

const transformDeps = (deps: Dependency[]) => deps.map(stringify);

export const useObjectEffect = (effect: () => void | (() => void), deps: Dependency[]) => {
    const dependencies = transformDeps(deps);

    useEffect(effect, dependencies);
};

export const useObjectMemo = <T>(effect: () => T, deps: Dependency[]) => {
    const dependencies = transformDeps(deps);

    return useMemo(effect, dependencies);
};
