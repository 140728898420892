import { FormEvent } from 'react';

import { FormProps } from './types';

export const Form: React.FC<FormProps> = ({ className, id, name, onSubmit, children }) => {
    const handleSubmit = (event: FormEvent) => {
        event.preventDefault();

        if (onSubmit) onSubmit(event);
    };

    return (
        <form id={id} name={name} className={'form' + (className ? ` ${className}` : '')} onSubmit={handleSubmit}>
            {children}
        </form>
    );
};
