import { useRef } from 'react';
import React from 'react';

import { useRefEffect } from '@/Components/Hooks';

import { WrapperProps } from './types';

export const ButtonWrapper = React.forwardRef<HTMLButtonElement, WrapperProps>(({ className, contents, onClick, children, ...props }, ref) => {
    const thisRef = useRef<HTMLButtonElement>(null);
    const wrapperRef = typeof ref == 'object' && ref ? ref : thisRef;

    useRefEffect(
        (wrapper) => {
            if (contents) wrapper.style.display = 'contents';
            else wrapper.style.display = '';
        },
        [contents],
        wrapperRef
    );

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        if (onClick) onClick(event);
    };

    return (
        <button {...props} className={'button_wrapper' + (className ? ` ${className}` : '')} onClick={handleClick} ref={wrapperRef}>
            {children}
        </button>
    );
});

// ButtonWrapper.displayName = 'Wrapper';

// export { ButtonWrapper };
