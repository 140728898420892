import { Permission, PermissionEntity } from '@.types';

import { User } from '../../../types';

export const checkRights = (user: User, page: keyof typeof PermissionEntity, permission?: keyof Permission) => {
    if (!user) return false;

    if (user.is_super) return true;

    // if (user.is_company_admin && page === 'Companies') return true;

    if (permission) return user.permissions?.[PermissionEntity[page]][permission] ?? false;

    return user.permissions?.[PermissionEntity[page]].read;
};
