import { useState } from 'react';

export type TrustedValue<T> = {
    value: T;
    isTrusted?: boolean;
};

type prevValueType<T> = (value: T) => T;

interface IUseTrustedState {
    <T>(initialState: T, isTrusted?: boolean): [T, (value: prevValueType<T> | T, isTrusted?: boolean) => void, boolean | undefined];
    <T = undefined>(initialState?: T, isTrusted?: boolean): [
        T | undefined,
        (value: prevValueType<T | undefined> | T | undefined, isTrusted?: boolean) => void,
        boolean | undefined
    ];
}

export const useTrustedState: IUseTrustedState = (initialState = undefined, isTrusted) => {
    type S = typeof initialState;

    const [state, setState] = useState<TrustedValue<S>>({ value: initialState, isTrusted });

    const setValue = (value: S | prevValueType<S>, isTrusted = true) => {
        if (typeof value === 'function') {
            setState((prev) => {
                return {
                    value: (value as prevValueType<S>)(prev.value),
                    isTrusted: isTrusted,
                };
            });
        } else {
            setState({
                value: value,
                isTrusted: isTrusted,
            });
        }
    };

    return [state.value, setValue, state.isTrusted];
};
