import { useContext } from '@fipnooone/react-modal';
import { useEffect, useRef } from 'react';

import { Button, Icon } from '@/Components/UI';

interface ConfirmProps {
    onCancel?: () => void;
    onConfirm?: () => void;
    message?: string;
}

export const ModalConfirm: React.FC<ConfirmProps> = ({ onCancel, onConfirm, message = 'Are you sure?' }) => {
    const modal = useContext();
    const isConfirmedRef = useRef(false);

    useEffect(() => {
        if (modal.isOpen) {
            isConfirmedRef.current = false;
        } else if (!isConfirmedRef.current) {
            onCancel && onCancel();
        }
    }, [modal.isOpen]);

    const handleCancel = () => {
        onCancel && onCancel();
        modal.close();
    };

    const handleConfirm = () => {
        onConfirm && onConfirm();
    };

    return (
        <div className="unsaved">
            <Icon name="close" size={20} onClick={() => modal.close()} />
            <h1>{message}</h1>
            <div className="unsaved__buttons">
                <Button text="No" color="gray-border" onClick={handleCancel} />
                <Button text="Yes" color="brown" onClick={handleConfirm} />
            </div>
        </div>
    );
};
