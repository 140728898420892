import { Base } from '../abstract/base';
import { SingleProps, SingleState } from './types';

export class Single<Props extends SingleProps = SingleProps, State extends SingleState = SingleState> extends Base<Props, State> {
    state: Readonly<State> = {
        ...this.state,
        selected: undefined,
    };

    props: Readonly<Props> = {
        ...this.props,
        autoClose: true,
    };

    handleOptionClick(id: number): void {
        this.setState((prev) => ({
            ...prev,
            selected: prev.selected?.id === id ? undefined : this.state.options?.find((option) => option.id === id),
            isTrusted: true,
        }));
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
        super.componentDidUpdate(prevProps, prevState);

        if (prevState.selected !== this.state.selected) {
            this.updateStyles([prevState.selected], [this.state.selected]);

            this.setState((prev) => ({ ...prev, inputValue: this.state.selected?.selectedContent ?? '' }));

            if (this.state.isTrusted) {
                this.props.onChange && this.props.onChange(this.state.selected?.id, this.state.selected?.value);
                (this.props.autoClose === undefined || this.props.autoClose === true) &&
                    (this.state.selected || !this.props.required) &&
                    this.setState((prev) => ({ ...prev, isOpen: false }));
            }
        }

        if (prevState.options !== this.state.options) {
            this.setState((prev) => ({ ...prev, visibleOptions: this.state.options }));
        }

        if (prevProps.value !== this.props.value || prevState.options !== this.state.options) {
            const found = this.state.options?.find((option) => option.id === this.props.value);

            if (found) this.setState((prev) => ({ ...prev, isTrusted: false, selected: found }));
            else this.setState((prev) => ({ ...prev, isTrusted: false, selected: undefined }));
        }
    }
}
