import React, { FC, ReactNode } from 'react';

import S from './styles.module.scss';

export type LegendItemProps = {
    children?: ReactNode;
};

export const LegendItem: FC<LegendItemProps> = ({ children }) => {
    return <div className={S.legend_item}>{children}</div>;
};
