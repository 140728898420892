import { ControlsProps } from './types';

export const Controls = <T,>({ tabs, onChange, current }: ControlsProps<T>): React.ReactElement<ControlsProps<T>> => {
    return (
        <div className="tabs">
            <div className="tabs__list">
                {tabs.map((tab) => (
                    <span className={'tabs__tab' + (current === tab.path ? ' active' : '')} key={`tabs-tab${tab.path}`} onClick={() => onChange(tab.path)}>
                        {tab.name}
                    </span>
                ))}
            </div>
        </div>
    );
};
