import { useCallback, useEffect, useRef, useState } from 'react';

import {
    Form,
    FormField,
    IField,
    IIfValid,
    IRegister,
    IReset,
    ISet,
    ISetErrors,
    ISetFieldError,
    ISetFieldValue,
    ISetValues,
    IValidate,
    IVoid,
    ReturnValue,
    validator,
} from '@.hooks';

import { toState } from './functions';

export const useForm = <T extends object>(initialState: Partial<T> = {}): ReturnValue<T> => {
    const [state, setState] = useState<Form<T>>(toState<T>(initialState));
    const isTrusted = useRef<boolean>(true);

    // Reset
    const resetErrors: IVoid = () => {
        setState((prev) =>
            __mapState(prev, ([name, field]) => [
                name,
                typeof field === 'string' || name === 'error'
                    ? undefined
                    : {
                          ...field,
                          errors: undefined,
                      },
            ])
        );
    };

    const resetForm: IVoid = () => {
        setState({});
        isTrusted.current = true;
    };

    const resetToInitialState: IVoid = () => {
        setState(toState<T>(initialState));
        isTrusted.current = true;
    };

    // Set Errors
    const fieldError: ISetFieldError<T> = useCallback(
        function (name, errors) {
            if (arguments.length === 1) return state[name]?.errors;

            setState((prev) => ({
                ...prev,
                [name]: { ...prev[name], errors },
            }));
        },
        [state]
    );

    const setErrors: ISetErrors<T> = (errs) => {
        if (typeof errs === 'string') {
            setState((prev) => ({ ...prev, error: errs }));
            return;
        }

        setState((prev) => {
            Object.entries(errs).forEach(([key, value]) => {
                const name = key as keyof T;

                const errors = value as string[];

                prev[name] = { ...prev[name], errors };
            });

            return { ...prev };
        });
    };

    // Set Values
    const fieldValue: ISetFieldValue<T> = useCallback(
        function (name, value, isTrustedN = true) {
            if (arguments.length === 1) return state[name]?.value;

            isTrusted.current = isTrustedN;

            if (typeof value === 'string' && value === '') value = undefined;

            setState((prev) => ({ ...prev, [name]: { ...prev[name], value } }));
        },
        [state]
    );

    const __mapState = <S>(data: Form<T>, cb: ([name, field]: [keyof T, FormField<S> | string | undefined]) => [keyof T, FormField<S> | string | undefined]) =>
        Object.fromEntries(Object.entries(data).map((field) => cb(field as unknown as [keyof T, FormField<S> | string | undefined]))) as Form<T>;

    const setValues: ISetValues<T> = (data, reset, isTrustedN = true) => {
        isTrusted.current = isTrustedN;

        setState((prev) => {
            const inStateType = toState(data);

            if (reset) return inStateType;

            return { ...prev, ...inStateType };
        });
    };

    // Field methods

    const field: IField<T> = useCallback(
        (name) => ({
            value: (value) => fieldValue(name, value),
            errors: (errors) => fieldError(name, errors as null), // TODO: fix types
            register: (options: any) => register(name, options) as any,
        }),
        [state]
    );

    const reset: IReset = {
        errors: resetErrors,
        form: resetForm,
        toInitial: resetToInitialState,
    };

    const set: ISet<T> = {
        fieldValue: fieldValue,
        fieldError: fieldError,
        errors: setErrors,
        values: setValues,
    };

    const register: IRegister<T> = useCallback(
        (name, options) => {
            return {
                name: name as string,
                value: options?.noValue ? undefined : (state[name]?.value as any), // TODO: fix types,
                errors: options?.noErrors ? undefined : state[name]?.errors,
                onChange: options?.noHandler ? undefined : (value) => fieldValue(name, value),
            };
        },
        [state]
    );

    const validate: IValidate<T> = useCallback(
        (options) => {
            const { data, errors } = validator(state, options);

            resetErrors();

            setErrors(errors);

            if (data === false) return false;

            Object.keys(state).forEach((key) => {
                const name = key as keyof T;
                const field = state[name];

                if (!data[name] && field && field.value !== undefined) data[name] = field.value as T[keyof T];
            });

            return data;
        },
        [state]
    );

    const ifValid: IIfValid<T> = useCallback(
        (fn, options) => {
            const result = validate(options);

            if (result !== false) fn(result);
        },
        [state]
    );

    return [field, { reset, set, validate, state, ifValid, isTrusted }];
};
