import { withTooltip } from '@visx/tooltip';

import { Tooltip } from '@.partials';

import { TooltipData } from '../../Bars/types';
import { TooltipProps } from './types';

export const WithTooltip = withTooltip<TooltipProps, TooltipData>(
    ({ children, hideTooltip, showTooltip, tooltipOpen, tooltipData, tooltipLeft, tooltipTop, name, color }) => (
        <>
            {children(showTooltip, hideTooltip)}
            {tooltipOpen && tooltipData && (
                <Tooltip top={tooltipTop} left={tooltipLeft} name={tooltipData.name} color={color} title={tooltipData.title} value={tooltipData.value} />
            )}
        </>
    )
);
