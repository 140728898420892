export enum PermissionEntity {
    Users = 'users',
    Stations = 'stations',
    Companies = 'companies',
    Store_groups = 'store_groups',
    Stores = 'stores',
    Products = 'products',
    Currencies = 'currencies',
    Types = 'types',
    Ages = 'ages',
    Sexes = 'sexes',
    Strengths = 'strengths',
    Occasions = 'occasions',
    Ingredients = 'ingredients',
    ProductImports = 'product_imports',
    ProductDescriptions = 'product_descriptions',
    ProductPrices = 'product_prices',
    Roles = 'roles',
    ProductProposals = 'product_proposals',
}

export enum PermissionKey {
    Read = 'read',
    Create = 'create',
    Update = 'update',
    Delete = 'delete',
}

/**
 * TODO: change key on enum PermissionKey.
 */
export interface Permission {
    read: boolean;
    create: boolean;
    update: boolean;
    delete: boolean;
}

export type Permissions = Record<PermissionEntity, Permission>;
