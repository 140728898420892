import cn from 'classnames';
import React, { useEffect, useState } from 'react';

import { Api } from '@.api';
import { useForm } from '@.hooks';
import { Layout } from '@.layout';
import { Buttons, Form } from '@.partials';
import { AuthorizedUser } from '@.types';
import { Input } from '@.ui';

import { ProfileData } from './types';
import { validateOptions } from './validate';

export const Profile: React.FC = () => {
    const [profileSettings, setProfileSettings] = useState<AuthorizedUser>();
    const [field, form] = useForm<ProfileData>();

    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const [isVisibleAlert, setIsVisibleAlert] = useState<boolean>(false);

    useEffect(() => {
        Api.user()
            .profile()
            .onSuccess((res) => {
                setProfileSettings(res.data);
            });
    }, []);

    const handleSubmit = () => {
        form.reset.errors();
        const data = form.validate(validateOptions);

        if (!data) return;

        Api.user()
            .profile_update(data)
            .onSuccess((res) => {
                setProfileSettings(res.data);

                setIsSuccess(true);
                setTimeout(() => setIsVisibleAlert(true), 100);

                setTimeout(() => setIsVisibleAlert(false), 3000);
                setTimeout(() => setIsSuccess(false), 3500);
            })
            .onValidationError((res) => form.set.errors(res.errors));
    };

    useEffect(() => {
        if (!profileSettings) return;

        form.set.values(profileSettings);
    }, [profileSettings]);

    return (
        <Layout name="profile" breadcrumbs={[{ name: 'My profile', current: true }]} small>
            <div className="profile-wrapper">
                {isSuccess && <div className={cn('profile-wrapper__alert', isVisibleAlert && 'active')}>Changes are saved</div>}
                <h1>My profile</h1>
                <Form className="profile__form">
                    <Input {...field('name').register()} text="Name" required />
                    <Input {...field('username').register()} text="Username" required />
                    <Input {...field('email').register()} text="Email" required />
                    <Input {...field('password').register()} text="New password" type="password" />
                    <Input {...field('repeat_password').register()} text="Confirm password" type="password" />
                    {/* <div className="profile__form-buttons">
                    <Button text="Save" color="brown" type="submit" />
                    <Button text="Cancel" color="transparent" />
                </div> */}

                    <Buttons className="profile__buttons" text={'Save'} onClickSave={handleSubmit} noCancel />
                </Form>
            </div>
        </Layout>
    );
};
