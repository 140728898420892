import { LanguageIcon } from './types';

export const Russia: LanguageIcon = ({ id = 'clip0_970_19659' }) => (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath={`url(#${id})`}>
            <path d="M11 22C17.0745 22 22 17.0745 22 11C22 4.92551 17.0745 0 11 0C4.92551 0 0 4.92551 0 11C0 17.0745 4.92551 22 11 22Z" fill="white" />
            <path
                d="M21.3155 14.8242C22.2282 12.3555 22.2282 9.64189 21.3155 7.1732H0.684497C-0.228166 9.64189 -0.228166 12.3555 0.684497 14.8242L11 15.7854L21.3155 14.8242Z"
                fill="#0052B4"
            />
            <path d="M11 22C15.73 22 19.7631 19.018 21.3155 14.8242H0.684494C2.23867 19.018 6.27 22 11 22Z" fill="#D80027" />
        </g>
        <defs>
            <clipPath id={id}>
                <rect width="22" height="22" fill="white" />
            </clipPath>
        </defs>
    </svg>
);
