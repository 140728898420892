import { Base } from '@.api/methods';
import { User as U } from '@/Types';
export class User extends Base {
    public login(data: U.LoginParams) {
        this.setPath('users/login');
        this.setData(data);

        return this.postRequest<U.LoginResponse>();
    }

    public refresh(refresh_token: string) {
        this.setPath('users/refresh');
        this.setData({ refresh_token: refresh_token });
        this.setRefresh(false);

        return this.postRequest<U.RefreshResponse>();
    }

    public logout() {
        this.setPath('users/logout');

        return this.postRequest();
    }

    public forgot_password(data: { email: string }) {
        this.setPath('users/forgot-password');
        this.setData(data);

        return this.postRequest();
    }

    public reset_password(data: { link: string | null; password: string }) {
        this.setPath('users/reset-password');
        this.setData(data);

        return this.postRequest<U.ResetPasswordResponse>();
    }

    public profile = () => {
        this.setPath('users/profile');

        return this.getRequest<U.ProfileResponse>();
    };

    public profile_update(data: U.UpdateProfileParams) {
        this.setPath('users/profile');
        this.setData(data);

        return this.putRequest<U.UpdateProfileResponse>();
    }
}
