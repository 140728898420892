// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import React from 'react';

import { Icon } from '@.ui';

import { Multiple } from '../multiple';
import { Single } from '../single';
import { SearchMultipleProps, SearchMultipleState, SearchSingleProps, SearchSingleState } from './types';

interface ISearchExtend {
    <Props extends SearchSingleProps = SearchSingleProps, State extends SearchSingleState = SearchSingleState>(
        multiple?: undefined | false
    ): typeof React.Component<Props, State>;
    <Props extends SearchMultipleProps = SearchMultipleProps, State extends SearchMultipleState = SearchMultipleState>(multiple: true): typeof React.Component<
        Props,
        State
    >;
}

// TODO: fix types if possible
export const SearchExtend: ISearchExtend = <
    Props extends SearchSingleProps | SearchMultipleProps = SearchSingleProps,
    State extends SearchSingleState | SearchMultipleState = SearchSingleState
>(
    multiple = false
) => {
    let extend: typeof Single<SearchSingleProps, SearchSingleState> | typeof Multiple<SearchMultipleProps, SearchMultipleState> = Single<
        SearchSingleProps,
        SearchSingleState
    >;

    if (multiple) {
        extend = Multiple<SearchMultipleProps, SearchMultipleState>;
    }

    abstract class Search extends (extend as unknown as typeof Single<Props, State>) {
        state: Readonly<State> = {
            ...this.state,
            inputValue: this.props.searchValue ?? '',
            before: <Icon name="search" className="select__search" size={18} />,
            search: this.props.searchValue ?? '',
            inTimeout: false,
            hasTooltip: false,
        };

        displayItems = undefined;
        updateTooltip = undefined;

        handleInput(value: string) {
            this.setState((prev) => ({ ...prev, search: value }));

            if (this.props.onSearch) {
                this.setState((prev) => ({ ...prev, inTimeout: true }));

                setTimeout(() => {
                    this.setState((prev) => ({ ...prev, inTimeout: false }));
                }, this.props.callbackDelay ?? 1000);
            }
        }

        componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
            super.componentDidUpdate(prevProps, prevState);

            if (prevState.search !== this.state.search) {
                this.setState((prev) => ({ ...prev, inputValue: this.state.search }));
            }

            if ((prevState.search !== this.state.search || prevState.inTimeout !== this.state.inTimeout) && !this.state.inTimeout) {
                this.props.onSearch && this.props.onSearch(this.state.search);
            }
        }
    }

    return Search;
};
