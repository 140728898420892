import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Api } from '@.api';
import { CurrenciesType } from '@.api/methods';
import { useForm } from '@.hooks';
import { Layout } from '@.layout';
import { LinkHelper } from '@.services';
import { Input } from '@.ui';
import { Buttons } from '@/Components/Partials';

import { CurrenciesActionProps } from './types';
import { validateOptions } from './validate';

export const CurrenciesAction: React.FC<CurrenciesActionProps> = ({ edit }) => {
    // const [currency, setCurrency] = useState<CurrencyResponseType>();
    const [field, form] = useForm<CurrenciesType>();
    const navigate = useNavigate();
    const { id } = useParams();

    const handleCreate = () => {
        form.reset.errors();

        form.ifValid((data) => {
            Api.currencies()
                .create(data)
                .onSuccess(() => navigate(LinkHelper.dataCurrencies))
                .onValidationError((res) => form.set.errors(res.errors));
        }, validateOptions);
    };

    const handleUpdate = () => {
        form.reset.errors();

        form.ifValid((data) => {
            Api.currencies()
                .update(Number(id), data)
                .onSuccess(() => navigate(LinkHelper.dataCurrencies))
                .onValidationError((res) => form.set.errors(res.errors));
        }, validateOptions);
    };

    useEffect(() => {
        if (id && edit) {
            Api.currencies()
                .getById(Number(id))
                .onSuccess((res) => {
                    // setCurrency(res.data);
                    const { id, sign, name, iso_code } = res.data;
                    form.set.values({ id, sign, name_en: name.en, iso_code });
                });
        }
    }, []);

    return (
        <Layout
            name="data"
            breadcrumbs={
                edit
                    ? [{ name: 'Currencies', link: LinkHelper.dataCurrencies }, { name: form.state.name_en?.value ?? '' }, { name: 'Edit', current: true }]
                    : [
                          { name: 'Currencies', link: LinkHelper.dataCurrencies },
                          { name: 'Add', current: true },
                      ]
            }
            small
        >
            {edit ? <h1>Editing currency</h1> : <h1>Adding currency</h1>}
            <div className="currencies-action__form">
                <Input {...field('name_en').register()} text="Name" required />
                <Input {...field('iso_code').register()} text="ISO code" required />
                <Input {...field('sign').register()} text="Sign" required />
                {/* <div className="currencies-action__form-buttons">
                    <Button color="brown" text="Save" onClick={edit ? handleUpdate : handleCreate} />
                    <Link to={LinkHelper.dataCurrencies}>
                        <Button color="transparent" text="Cancel" />
                    </Link>
                </div> */}
                <Buttons text={edit ? 'Save' : 'Add'} onClickSave={edit ? handleUpdate : handleCreate} />
            </div>
        </Layout>
    );
};
