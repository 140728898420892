import cn from 'classnames';
import { memo, useCallback, useMemo } from 'react';
import { PhoneInput as PhoneInputCore } from 'react-international-phone';

import { BaseInput } from '../BaseInput';
import { PhoneInputProps } from './types';

export const PhoneInput = memo<PhoneInputProps>(({ className, errors, onChange, label, ...props }) => {
    const hasErrors = useMemo(() => !!errors?.length, [errors]);

    const handleChange = useCallback((value: string) => onChange && onChange(value), [onChange]);

    return (
        <BaseInput className={cn({ error: hasErrors })}>
            <div className={'input-block'}>
                <label className="input-block__label">
                    {hasErrors ? errors?.[0] : label}
                    {props.required && <span>*</span>}
                </label>
                <PhoneInputCore initialCountry="us" {...props} onChange={handleChange} />
            </div>
        </BaseInput>
    );
});
