import { Group } from '@visx/group';
import { Pie } from '@visx/shape';
import cn from 'classnames';
import React from 'react';

import { DonutLegend } from '@/Components/Partials/Charts/Donut/DonutLegend';
import { LegendCircle } from '@/Components/Partials/Charts/Donut/LegendCircle';
import { LegendItem } from '@/Components/Partials/Charts/Donut/LegendItem';
import S from '@/Components/Partials/Charts/Donut/styles.module.scss';
import { DonutType } from '@/Components/Partials/Charts/Donut/types';

const Donut: DonutType = ({ data, className, legend, contentCenter }) => {
    const width = 200,
        height = 200,
        margin = { top: 20, right: 20, bottom: 20, left: 20 };

    const innerWidth = width - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;
    const radius = width / 2 - 30;
    const centerY = innerHeight / 2;
    const centerX = innerWidth / 2;
    const top = centerY + margin.top;
    const left = centerX + margin.left;

    const pieSortValues = (a: number, b: number) => a - b;

    const donutClassNames = cn(S.donut, className);

    return (
        <div className={donutClassNames}>
            <svg style={{ top, left, overflow: 'visible', position: 'relative', width: 200, height: 200 }}>
                {data && (
                    <Group>
                        <Pie
                            data={data}
                            pieSortValues={pieSortValues}
                            outerRadius={radius}
                            innerRadius={radius + 30}
                            pieValue={(d) => d.value}
                            startAngle={(3 * Math.PI) / 2}
                            endAngle={-Math.PI}
                        >
                            {(pie) =>
                                pie.arcs.map((arc) => {
                                    const { color } = arc.data;
                                    const arcPath = pie.path(arc);

                                    return (
                                        <g key={`arc-d-${color}-${arc.data.value}`}>
                                            <path d={arcPath ?? undefined} fill={color} />
                                        </g>
                                    );
                                })
                            }
                        </Pie>
                        {contentCenter}
                    </Group>
                )}
            </svg>
            {legend && <DonutLegend {...legend} />}
        </div>
    );
};

Donut.LegendItem = LegendItem;
Donut.LegendCircle = LegendCircle;

export { Donut };
