import { Marketplace } from '@.pages';
import { Routes } from '@/Routes/types';

export const MarketplaceRoutes: Routes = [
    {
        url: 'marketplace',
        permissions: 'authorized',
        component: <Marketplace />,
        sidebar: {
            icon: 'marketplace',
            name: 'Marketplace',
        },
    },
];
