import React, { useEffect } from 'react';

type Destructor = () => void;

export function useRefEffect<B>(effect: (element: B) => void | Destructor, deps: React.DependencyList, ref: React.RefObject<B>): void;
export function useRefEffect<B, T extends Array<B>>(effect: (element: T) => void | Destructor, deps: React.DependencyList, ref: React.RefObject<B>[]): void;

// interface IUseRefEffect {
//     <T>(effect: (element: T) => void | Destructor, deps: React.DependencyList, ref: React.RefObject<T>): void;
//     <T>(effect: (element: Array<T>) => void | Destructor, deps: React.DependencyList, ref: Array<React.RefObject<T>>): void;
// }

export function useRefEffect<B, T extends Array<B | null> | undefined = undefined>(
    effect: (element: T extends Array<B | null> ? NonNullable<T> : NonNullable<B>) => void,
    deps: React.DependencyList,
    ref: T extends Array<B> ? React.RefObject<B>[] : React.RefObject<B>
): void {
    useEffect(() => {
        if (Array.isArray(ref)) {
            if (!ref.every((element) => element.current)) return;

            return effect(ref.map((r) => r.current) as T extends Array<B | null> ? NonNullable<T> : NonNullable<B>);
        } else if (!ref.current) return;

        return effect(ref.current as T extends Array<B | null> ? NonNullable<T> : NonNullable<B>);
    }, deps);
}
