import { useState } from 'react';

import { useObjectEffect } from '@/Components/Hooks';
import { Bars, Board } from '@/Components/Partials';
import { LinkHelper } from '@/Services';
import { Api } from '@/Services/api';
import { DashboardSpecialOffersResponse } from '@/Services/api/methods/dashboard/types';

import { DashboardItem } from '../../types';

export const Offers: DashboardItem = ({ filters }) => {
    const [offers, setOffers] = useState<DashboardSpecialOffersResponse>();

    useObjectEffect(() => {
        Api.dashboard()
            .specialOffers({ ...filters })
            .onSuccess((res) => setOffers(res.data));
    }, [filters]);

    return (
        <Board.Item title="Popular special offers" more={LinkHelper.dashboardPopularSpecialOffers}>
            <Bars data={offers?.special_offers ?? []} name="Views" isHorizontal />
        </Board.Item>
    );
};
