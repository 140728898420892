import React, { useCallback } from 'react';

import { Api } from '@.api';
import { Layout } from '@.layout';
import { Table2 } from '@.partials';
import { Filter3 } from '@.ui';
import { LinkHelper } from '@/Services';
import { DashboardWizardAnswersDetailedResponse } from '@/Services/api/methods/dashboard/types';
import { Request } from '@/Types';

export const WizardAnswers: React.FC = () => {
    const get = useCallback((q?: Request.All) => Api.dashboard().wizardDetailed(q), []);
    const getData = useCallback((data: DashboardWizardAnswersDetailedResponse) => data.answers, []);

    return (
        <Layout
            breadcrumbs={[
                { name: 'Dashboard', link: LinkHelper.dashboard },
                { name: 'Wizard answers', current: true },
            ]}
        >
            <h1>Wizard answers</h1>
            <Table2 get={get} getData={getData} export="dashboard_wizard_answers">
                {{
                    filters: (handler) => (
                        <Filter3 onChange={handler}>
                            <Filter3.Search />
                            <Filter3.WizardSteps />
                            <Filter3.Date />
                        </Filter3>
                    ),
                    items: [
                        (item) => ({
                            max: 50,
                            content: item.answer_name,
                            name: 'Answer',
                        }),
                        (item) => ({
                            content: item.count,
                            name: 'Views',
                        }),
                    ],
                }}
            </Table2>
        </Layout>
    );
};

/*
 const [answers, setAnswers] = useState<Dashboard.Wisard.DetailedResponse>();
    const [filters, setFilters] = useState<{ search?: string; step_id?: number; start_date?: number; end_date?: number }>();
    const { currentPage, lastPage, perPage, prevPage, nextPage, changePage, total, process } = usePagination();
    // const navigator = useNavigate();

    const updateAnswers = () => {
        Api.dashboard()
            .wizardDetailed({ page: currentPage, per_page: perPage, ...filters })
            .onSuccess((res) => {
                setAnswers(res.data);
                process(res.data.pagination);
            });
    };

    useEffect(() => {
        updateAnswers();
    }, [currentPage, filters]);


 <Table headers={['Answer', 'Views']} total={total}>
                {answers?.answers.map((answer) => (
                    <TableRow key={`row_${answer.answer_id}&${answer.question_id}`}>
                        <TableColumn maxLength={50}>{answer.answer_name}</TableColumn>
                        <TableColumn>{answer.count}</TableColumn>
                    </TableRow>
                ))}
            </Table>
            <div className="page-table__footer">
                <Pagination
                    className={total && total <= perPage ? 'hide' : ''}
                    current={currentPage}
                    last={lastPage ?? 1}
                    prevPage={prevPage}
                    nextPage={nextPage}
                    changePage={changePage}
                />
                <ExportBlock model="dashboard_wizard_answers" filters={filters} />
            </div>
*/
