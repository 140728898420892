import { requiredEmail, requiredPassword, requiredRule, ValidateOptions } from '@.hooks';

import { LoginData } from './types';

export const validateOptions: ValidateOptions<LoginData> = {
    email: {
        type: 'string',
        required: requiredRule,
        regex: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9-]+.+.[A-Z]{2,4}$/i,
            message: 'Field email is invalid',
        },
    },
    password: requiredPassword,
    remember: {
        type: 'boolean',
    },
};
