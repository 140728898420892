import { FC, useCallback } from 'react';

import { Layout } from '@/Components/Layouts';
import { Table2 } from '@/Components/Partials';
import { Filter3 } from '@/Components/UI';
import { LinkHelper } from '@/Services';
import { Api } from '@/Services/api';
import { DevicesResponseType } from '@/Services/api/methods/devices/types';
import { Request } from '@/Types';

export const Devices: FC = () => {
    const get = useCallback((q: Request.All) => Api.devices().getAll(q), []);
    const getData = useCallback((data: DevicesResponseType) => data.devices, []);

    return (
        <Layout name="devices" breadcrumbs={[{ name: 'Connected devices', current: true }]}>
            <h1>Connected devices</h1>
            <Table2 get={get} getData={getData}>
                {{
                    filters: (handleChange) => (
                        <Filter3 onChange={handleChange}>
                            <Filter3.Companies related />
                            <Filter3.StoreGroups related />
                            <Filter3.Stores related />
                        </Filter3>
                    ),
                    items: [
                        ({ station }) => ({
                            content: station?.identifier.slice(-4),
                            name: 'Tablet ID',
                            sort: 'station',
                            link: station?.id ? LinkHelper.stationId(station?.id) : '',
                            max: 12,
                        }),
                        ({ mac_address }) => ({
                            content: mac_address.slice(-5).replace(/:/g, ''),
                            name: 'Device MAC',
                            sort: 'id',
                            max: 12,
                        }),
                        ({ wlm_firmware_version }) => ({
                            content: wlm_firmware_version?.name,
                            name: 'Current WLM firmware version',
                            sort: 'firmware_version',
                            max: 20,
                        }),
                        ({ app_version }) => ({
                            content: app_version?.name,
                            name: 'Current app version',
                            sort: 'app_version',
                            max: 12,
                        }),
                        ({ lmp_firmware_version }) => ({
                            content: lmp_firmware_version?.name,
                            name: 'Current LMP hardware version',
                            sort: 'hardware_version',
                            max: 20,
                        }),
                    ],
                }}
            </Table2>
        </Layout>
    );
};
