import { useCallback, useEffect, useState } from 'react';

import { PaginationType } from '@.api/methods';

type StateParameter<T> = T | ((previousValue: T) => T);

export const usePagination = (perPage = 5) => {
    const [currentPage, setPage] = useState<number>(1);
    const [lastPage, setLastPage] = useState<number>();
    const [left, setLeft] = useState<number>();
    const [total, setTotal] = useState<number>();

    useEffect(() => {
        if (currentPage !== 1 && lastPage && lastPage < currentPage) changePage(lastPage);
    }, [lastPage]);

    const changePage = useCallback(
        (page: StateParameter<number>) => {
            setPage((prev) => {
                let newPage: number;

                if (typeof page === 'function') newPage = page(prev);
                else newPage = page;

                if ((lastPage && lastPage < newPage) || newPage < 0) return prev;

                return newPage;
            });
        },
        [lastPage]
    );

    const nextPage = useCallback(() => changePage((prev) => prev + 1), []);
    const prevPage = useCallback(() => changePage((prev) => (prev === 1 ? prev : prev - 1)), []);

    const process = useCallback(
        (pagination: PaginationType) => {
            const inList = currentPage * perPage;
            const totalItems = pagination.total;

            setLeft(totalItems < inList ? undefined : totalItems - inList);
            setTotal(totalItems);
            setLastPage(pagination.last_page);
        },
        [currentPage, perPage]
    );

    return {
        currentPage,
        changePage,
        nextPage,
        prevPage,
        lastPage,
        left,
        process,
        perPage,
        total,
    };
};
