import { BOImport } from '@.pages';

import { RoutePage } from '../types';

export const ImportBOPage: RoutePage = {
    url: 'import',
    component: <BOImport />,
    permissions: 'admin',
    sidebar: {
        name: 'Import from BO',
    },
};
