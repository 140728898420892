import { Base, Response } from '@.api/methods';

import { CreateStoresType, StoreResponseType, StoresResponseType } from './types';

export class Stores extends Base {
    public getAll(query?: { page?: number; per_page?: number; store_group_id?: number; company_id?: number; is_archived?: boolean }) {
        this.setPath('stores');
        if (query) this.setQuery(query);

        return this.getRequest() as Response<StoresResponseType>;
    }

    public getById(id: number) {
        this.setPath('stores');
        this.setId(id);

        return this.getRequest() as Response<StoreResponseType>;
    }

    public create(data: CreateStoresType) {
        this.setPath('stores');
        this.setData(data);

        return this.postRequest();
    }

    public update(data: CreateStoresType, id: number) {
        this.setPath('stores');
        this.setId(id);
        this.setData(data);

        return this.putRequest();
    }

    public delete(id: number) {
        this.setPath('stores');
        this.setId(id);

        return this.deleteRequest();
    }

    public restore(id: number) {
        this.setPath('stores');
        this.setId(`${id}/restore`);

        return this.postRequest();
    }
}
