import React from 'react';

import { BaseInput, Icon, Scroll } from '@.ui';

import { Option } from '../../option';
import { OptionProps } from '../../option/types';
import { Toolput } from './toolput';
import { BaseProps, BaseState, Class, idsClassActions, Option as OptionType, OptionListRefs } from './types';

export abstract class Base<Props extends BaseProps = BaseProps, State extends BaseState = BaseState> extends React.Component<Props, State> {
    protected blockRef: React.RefObject<HTMLDivElement>;
    protected inputRef: React.RefObject<HTMLInputElement>;
    protected placeholderRef: React.RefObject<HTMLLabelElement>;
    protected optionRefs: OptionListRefs;

    state: Readonly<State> = {
        isOpen: false,
        options: undefined,
        visibleOptions: undefined,
        after: null,
        before: <Icon name="chevron-down" size={12} className="select__icon" />,
        inputValue: '',
        selected: undefined,
        isTrusted: true,
        hasTooltip: false,
    } as State;

    constructor(props: Props) {
        super(props);

        this.blockRef = React.createRef();
        this.inputRef = React.createRef();
        this.placeholderRef = React.createRef();
        this.optionRefs = React.createRef() as OptionListRefs;

        this.optionRefs = { current: {} };

        this.handleOptionClick = this.handleOptionClick.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleInput = this.handleInput?.bind(this);
        // this.isSelected = this.isSelected.bind(this);
    }

    abstract handleOptionClick<T>(id: number, value?: T): void;

    updateStyles(idsRemove: idsClassActions | undefined, idsAdd: idsClassActions | undefined): void {
        const getIds = (ids: idsClassActions | undefined) => {
            if (!ids || !ids.length) return;

            return ids.map((option) => (typeof option === 'object' ? option.id : option));

            // if (Array.isArray(ids)) return ids.map((option) => option.)
        };

        this.removeStyles(getIds(idsRemove));
        this.addStyles(getIds(idsAdd));
    }

    removeStyles(ids: (number | string | undefined)[] | undefined) {
        if (ids) ids.forEach((id) => id !== undefined && this.optionRefs.current[id]?.classList.remove(Class.Selected));
    }

    addStyles(ids: (number | string | undefined)[] | undefined) {
        if (ids) ids.forEach((id) => id !== undefined && this.optionRefs.current[id]?.classList.add(Class.Selected));
    }

    handleInput?(value: string): void;

    handleClick() {
        this.setState((prev) => ({ ...prev, isOpen: !prev.isOpen }));
    }

    handleDocumentClick = (event: Event): void => {
        const target = event.target as Node;

        if (this.blockRef.current && !this.blockRef.current.contains(target)) {
            this.setState((prev) => ({ ...prev, isOpen: false }));
        }
    };

    protected updateOptions = () => {
        this.setState((prev) => ({ ...prev, options: undefined }));

        if (!this.props.children) return;

        const options: OptionType[] = [];

        React.Children.forEach(this.props.children, (child) => {
            if (!React.isValidElement(child) || !child.props) return;

            const { id, children, value } = child.props as OptionProps;
            if (id === undefined || id === null) return;

            let content: React.ReactNode;
            let selectedContent = '';

            switch (typeof children) {
                case 'function': {
                    const result = children();

                    if (Array.isArray(result)) {
                        content = result[0];
                        selectedContent = result[1];
                    } else {
                        content = result;
                    }

                    break;
                }
                case 'string':
                    content = selectedContent = children;
                    break;
                default:
                    content = children;
                    break;
            }

            options.push({ id, content, selectedContent, value });
        });

        this.setState((prev) => ({ ...prev, options }));
    };

    componentDidMount(): void {
        this.updateOptions();

        if (this.props.disabled) this.blockRef.current?.classList.add('disabled');

        if (this.props.absolute) this.blockRef.current?.classList.add(Class.Absolute);

        document.addEventListener('click', this.handleDocumentClick);

        // else this.blockRef.current?.classList.remove(Class.Absolute);

        // if (this.props.theme) this.blockRef.current?.classList.add(this.props.theme);
        // else this.blockRef.current?.classList.remove(this.props.theme);
    }

    componentWillUnmount(): void {
        document.removeEventListener('click', this.handleDocumentClick);
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
        //Props

        if (prevProps.children !== this.props.children) {
            this.updateOptions();
        }

        if (prevProps.disabled !== this.props.disabled) {
            if (this.props.disabled) this.blockRef.current?.classList.add('disabled');
            else this.blockRef.current?.classList.remove('disabled');
        }

        //State
        if (prevState.isOpen !== this.state.isOpen) {
            if (this.state.isOpen) this.blockRef.current?.classList.add('open');
            else this.blockRef.current?.classList.remove('open');
        }

        if (prevState.selected !== this.state.selected) {
            if (this.state.selected && !Array.isArray(this.state.selected)) this.placeholderRef.current?.classList.add('hide');
            else this.placeholderRef.current?.classList.remove('hide');
        }

        if (prevState.inputValue !== this.state.inputValue) {
            if (this.state.inputValue) this.placeholderRef.current?.classList.add('hide');
            else this.placeholderRef.current?.classList.remove('hide');
        }

        if (prevProps.errors !== this.props.errors) {
            if (this.props.errors) this.blockRef.current?.classList.add('error');
            else this.blockRef.current?.classList.remove('error');
        }
    }

    render(): React.ReactNode {
        return (
            <BaseInput className={`select ${this.props.theme ?? 'default'}`} ref={this.blockRef}>
                {this.props.errors?.length || this.props.label ? (
                    <label className="select__label">
                        <p>{this.props.errors?.[0] ?? this.props.label}</p>
                    </label>
                ) : (
                    <></>
                )}
                <div className="select__input">
                    <Toolput
                        ref={this.inputRef}
                        readOnly={this.state.search === undefined}
                        value={this.state.inputValue}
                        // readOnly={!props.search}
                        onClick={this.handleClick}
                        onChange={this.handleInput}
                        tooltip={this.state.hasTooltip}
                    />
                    {!this.state.inputValue && (
                        <label className="select__placeholder">
                            <p>{this.props.placeholder}</p>
                            {this.props.required && (
                                <>
                                    <span>*</span>
                                </>
                            )}
                        </label>
                    )}

                    {this.state.before}
                </div>

                {/* {!props.search ? <Icon name="chevron-down" size={12} className="select__icon" /> : <Icon name="search" className="select__search" size={18} />} */}
                <Scroll className="select__options">
                    {this.state.visibleOptions?.map((option) => (
                        <Option
                            id={option.id}
                            onClick={this.handleOptionClick}
                            ref={(ref) => (this.optionRefs.current[option.id] = ref)}
                            key={`Select_Option_${option.id}`}
                        >
                            {option.content}
                        </Option>
                    ))}
                </Scroll>
                {!this.props.hideList && this.state.after}
            </BaseInput>
        );
    }
}
