import React, { FC } from 'react';

import { ModalInfo } from '@.modal';
import { Button, Input } from '@.ui';
import { createPreventDefault } from '@/Lib';
import { StationFirmwareDevice } from '@/Types/stations';

import S from './styles.module.scss';
import { useEditLicenseKey } from './useEditLicenseKey';

type EditLicenseKeyModalProps = {
    deviceId: number;
    licenseKeyInitial: string;
    updateDevice: (device: StationFirmwareDevice) => void;
};

export const EditLicenseKeyModal: FC<EditLicenseKeyModalProps> = ({ deviceId, licenseKeyInitial, updateDevice }) => {
    const { isLoading, close, field, requestSaveLicenseKey } = useEditLicenseKey({
        deviceId,
        licenseKeyInitial,
        updateDevice,
    });

    const ModalContentData = (
        <div className={S.edit_license_key_modal}>
            <h1>Edit license key</h1>
            <Input {...field('licenseKey').register()} required text={'License key'} />

            <div className={S.edit_license_key_modal_actions}>
                <Button color="brown" disabled={isLoading} onClick={requestSaveLicenseKey}>
                    Save
                </Button>
                <Button onClick={createPreventDefault(close)}>Cancel</Button>
            </div>
        </div>
    );

    return <ModalInfo data={ModalContentData} />;
};
