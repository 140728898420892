import { ProductProposals, ProductProposalsCreate, ProductProposalsDetail } from 'src/Components/Pages/Data/ProductProposalsPages';

import { RoutePage } from '@/Routes/types';

export const ProductProposalsPage: RoutePage = {
    url: 'product-proposals',
    component: <ProductProposals />,
    sidebar: {
        name: 'Product proposals',
    },
    permissions: {
        entity: 'ProductProposals',
        right: 'read',
    },
    children: [
        {
            url: 'create',
            component: <ProductProposalsCreate />,
            permissions: {
                entity: 'ProductProposals',
                right: 'create',
            },
        },
        {
            url: ':id',
            component: <ProductProposalsDetail />,
            children: [
                {
                    url: 'edit',
                    component: <ProductProposalsCreate edit />,
                    permissions: {
                        entity: 'ProductProposals',
                        right: 'create',
                    },
                },
            ],
        },
    ],
};
