import React, { useCallback } from 'react';

import { Api } from '@.api';
import { Layout } from '@.layout';
import { Table2 } from '@.partials';
import { Filter3 } from '@.ui';
import { LinkHelper } from '@/Services';
import { Tools } from '@/Services';
import { SystemLogsResponse } from '@/Services/api/methods/systemLogs/types';
import { Request } from '@/Types';

export const SystemLogs: React.FC = () => {
    const get = useCallback((q?: Request.All) => Api.system_logs().getAll(q), []);
    const getData = useCallback((d: SystemLogsResponse) => d.system_logs, []);

    return (
        <Layout breadcrumbs={[{ name: 'System logs', current: true }]}>
            <h1>System logs</h1>
            {/*<Filters filters={['SystemLogTypes']} onChange={setFilters} date />*/}
            <Table2 get={get} getData={getData} export="system_logs">
                {{
                    filters: (handler) => (
                        <Filter3 onChange={handler}>
                            <Filter3.Search />
                            <Filter3.SystemLogTypes />
                            <Filter3.Date />
                        </Filter3>
                    ),
                    items: [
                        (item) => ({
                            name: 'ID',
                            max: 20,
                            content: item?.id,
                            sort: 'id',
                            link: LinkHelper.systemLogId(item.id),
                        }),
                        (item) => ({
                            name: 'Time',
                            max: 20,
                            content: Tools.date.autoFormat(item.created_at * 1000),
                            sort: 'created_at',
                        }),
                        (item) => ({
                            name: 'Type',
                            max: 20,
                            content: item?.type?.name,
                        }),
                        (item) => ({
                            name: 'User',
                            max: 20,
                            content: item?.user?.name,
                            sort: 'user',
                        }),
                        (item) => ({
                            name: 'Description',
                            max: 40,
                            content: item?.description,
                            sort: 'description',
                        }),
                    ],
                    actions: [
                        (item) => ({
                            type: 'open',
                            link: LinkHelper.systemLogId(item.id),
                        }),
                    ],
                }}
            </Table2>
        </Layout>
    );
};
