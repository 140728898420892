import { Pages, PagesForProductManager, PagesForServiceManager } from '@/Routes';
import { Routes } from '@/Routes/types';
import { User } from '@/Services/Auth/types';
import { UserRoleEnum } from '@/Types/api/User';

export const pagesByUser = (user?: User): Routes => {
    if (user?.role?.key === UserRoleEnum.SERVICE_ENGINEER) {
        return PagesForServiceManager;
    }

    if (user?.role?.key === UserRoleEnum.PRODUCT_MANAGER) {
        return PagesForProductManager;
    }

    return Pages;
};
