import { useEffect, useState } from 'react';

import { AllDictionaries, Api as A, CustomDictionaries, CustomDictionary, Dictionary, DictionaryList, StateValue } from '@.types';
import { Api } from '@/Services/api';

import { DictionaryState } from './types';

const request = (list: DictionaryList) => Api.dictionaries().byNames(list);

const customs: A.Dictionaries.DictionaryItem[] = [
    { id: 1, name: 'Yes' },
    { id: 0, name: 'No' },
];

/**
 * TODO: add cache?
 *
 * TODO: fix example below
 * const [dict, setDict] = useDictionaries(Dictionary.Companies, Dictionary.Users) // this doest not work.
 *
 */
export const useDictionaries = (
    ...initialList: AllDictionaries
): [DictionaryState | undefined, (list: StateValue<AllDictionaries | undefined>) => void, (state: StateValue<DictionaryState | undefined>) => void] => {
    const [state, setState] = useState<DictionaryState>();
    const [list, setList] = useState<AllDictionaries | undefined>(initialList);

    useEffect(() => {
        if (!list) {
            setList(undefined);
            return;
        }

        const customKeys = Object.keys(CustomDictionary) as CustomDictionaries;

        const data: DictionaryState = {};
        const requestList: DictionaryList = [];

        list.forEach((item) => {
            if (customKeys.includes(item as keyof typeof CustomDictionary)) data[CustomDictionary[item as keyof typeof CustomDictionary]] = customs;
            else requestList.push(item as keyof typeof Dictionary);
        });

        if (requestList.length !== 0) request(requestList).onSuccess((res) => setState({ ...res.data, ...data }));
    }, [JSON.stringify(list)]);

    return [state, setList, setState];
};

export type { DictionaryState };
