import { useMemo } from 'react';

import { BaseProps } from './modules/abstract/base/types';
import { Multiple } from './modules/multiple';
import { FakeOption } from './modules/option';
import { SearchExtend } from './modules/search/extend';
import { SearchLocalExtend } from './modules/search/local';
import { Single } from './modules/single';
import { SelectObject, SelectProps } from './types';

const SearchSingle = SearchExtend(false);
const SearchMultiple = SearchExtend(true);
const SearchLocalSingle = SearchLocalExtend(false);
const SearchLocalMultiple = SearchLocalExtend(true);

/**
 * TODO: in future needed new select... (refactor...)
 */
export const Select: SelectObject = (props: SelectProps): React.ReactElement<SelectProps> => {
    const commonProps: BaseProps = useMemo(
        () => ({
            errors: props.errors,
            absolute: props.absolute,
            autoClose: props.autoClose,
            children: props.children,
            disabled: props.disabled,
            hideList: props.hideList,
            placeholder: props.placeholder,
            theme: props.theme,
            required: props.required,
            label: props.label,
        }),
        [props]
    );

    if (props.search) {
        if (props.multiple) {
            return (
                <SearchMultiple
                    {...commonProps}
                    onSearch={props.onSearch}
                    callbackDelay={props.callbackDelay}
                    isLoading={props.isLoading}
                    onChange={props.onChange}
                    value={props.value}
                    hideList={props.hideList}
                    required={props.required}
                    searchValue={props.searchValue}
                >
                    {props.children}
                </SearchMultiple>
            );
        }

        return (
            <SearchSingle
                {...commonProps}
                onSearch={props.onSearch}
                callbackDelay={props.callbackDelay}
                isLoading={props.isLoading}
                placeholder={props.placeholder}
                onChange={props.onChange}
                value={props.value}
                hideList={props.hideList}
                required={props.required}
                searchValue={props.searchValue}
            >
                {props.children}
            </SearchSingle>
        );
    }

    if (props.localSearch) {
        if (props.multiple) {
            return (
                <SearchLocalMultiple
                    {...commonProps}
                    onSearch={props.onSearch}
                    callbackDelay={props.callbackDelay}
                    isLoading={props.isLoading}
                    onChange={props.onChange}
                    value={props.value}
                    hideList={props.hideList}
                    required={props.required}
                >
                    {props.children}
                </SearchLocalMultiple>
            );
        }

        return (
            <SearchLocalSingle
                {...commonProps}
                onSearch={props.onSearch}
                callbackDelay={props.callbackDelay}
                isLoading={props.isLoading}
                onChange={props.onChange}
                value={props.value}
                hideList={props.hideList}
                required={props.required}
            >
                {props.children}
            </SearchLocalSingle>
        );
    }

    if (props.multiple) {
        return (
            <Multiple {...commonProps} onChange={props.onChange} value={props.value} hideList={props.hideList} required={props.required}>
                {props.children}
            </Multiple>
        );
    }

    return (
        <Single {...commonProps} onChange={props.onChange} value={props.value} required={props.required}>
            {props.children}
        </Single>
    );
};

Select.Option = FakeOption;
