import useModal from '@fipnooone/react-modal';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { Api } from '@.api';
import { Layout } from '@.layout';
import { Table2 } from '@.partials';
import { LinkHelper } from '@.services';
import { Request } from '@.types';
import { Button, Filter3 } from '@.ui';
import { ModalConfirm } from '@/Components/Modal/Dialogs';
import { UsersTableProps } from '@/Components/Pages/Settings/Users/types';
import { UsersResponseType } from '@/Services/api/methods';
import { PermissionProps } from '@/Types';

export * from './Action';
export * from './User';

export const Users: React.FC<PermissionProps> = (props) => {
    const navigator = useNavigate();

    return (
        <Layout name="settings" breadcrumbs={[{ name: 'Users', current: true }]}>
            <h1>
                Users
                <Button color="brown" text="Add user" onClick={() => navigator(LinkHelper.settingsUsersAdd)} disabled={!props.permissions?.create} />
            </h1>
            <UsersTable permissions={props.permissions} />
        </Layout>
    );
};

export const UsersTable: React.FC<UsersTableProps> = ({ company_id, hideActions, permissions, store_group_id }) => {
    const [modal, modalControls] = useModal();
    const get = useCallback((q?: Request.All) => Api.users().getAll(q), []);

    const handleBlock = useCallback((id: number) => Api.users().blockById(id), []);
    const handleUnblock = useCallback((id: number) => Api.users().unblockById(id), []);
    const handleDestroy = useCallback((id: number) => Api.users().destroyById(id), []);

    const handleDestroyConfirm = useCallback(
        (id: number) => {
            return new Promise<unknown>((resolve) => {
                const handleResolve = () => {
                    resolve(null);
                };

                const handleConfirm = () => {
                    handleDestroy(id).onSuccess(() => {
                        modalControls.close();
                        handleResolve();
                    });
                };

                modalControls.set(
                    <ModalConfirm message="Do you really want to permanently delete this user?" onConfirm={handleConfirm} onCancel={handleResolve} />
                );
            });
        },
        [handleDestroy]
    );

    const getData = useCallback((d: UsersResponseType) => d.users, []);
    const item = useCallback((item: UsersResponseType['users'][number]) => ({ className: item.status.name === 'Blocked' ? 'deleted' : '' }), []);

    return (
        <>
            {modal}
            <Table2 filters={{ company_id: company_id, store_group_id: store_group_id }} get={get} getData={getData} item={item} export="users">
                {{
                    filters: (handler) => (
                        <Filter3 onChange={handler}>
                            <Filter3.Search />
                            <Filter3.Companies related />
                            <Filter3.StoreGroups related />
                            <Filter3.Roles />
                            <Filter3.UserStatuses />
                        </Filter3>
                    ),
                    items: [
                        (item) => ({
                            name: 'Name',
                            max: 20,
                            content: item?.name,
                            link: LinkHelper.settingsUserId(item.id),
                            sort: 'name',
                        }),
                        (item) => ({
                            name: 'Email',
                            max: 20,
                            content: item?.email,
                            sort: 'email',
                        }),
                        (item) => ({
                            name: 'Company',
                            max: 10,
                            content: item?.company?.name,
                            sort: 'company',
                        }),
                        (item) => ({
                            name: 'Store groups',
                            max: 10,
                            content: item?.store_groups?.map((group) => group.name).join(', '),
                            sort: 'store_groups',
                        }),
                        (item) => ({
                            name: 'Role',
                            max: 10,
                            content: item?.role?.name,
                            sort: 'role',
                        }),
                        (item) => ({
                            name: 'Status',
                            max: 10,
                            content: item?.status?.name,
                        }),
                    ],
                    actions: [
                        (item) => ({
                            type: 'edit',
                            link: LinkHelper.settingsUsersEditId(item.id),
                            disabled: !permissions?.update,
                            hide: hideActions?.includes('update'),
                        }),
                        (item) => ({
                            type: item.status.name === 'Active' ? 'lock' : 'unlock',
                            onClick: item.status.name === 'Active' ? () => handleBlock(item.id) : () => handleUnblock(item.id),
                            disabled: !permissions?.update,
                            hide: hideActions?.includes('update'),
                        }),
                        ({ id, is_company_admin }) => ({
                            type: 'delete',
                            onClick: () => handleDestroyConfirm(id),
                            disabled: is_company_admin,
                            hide: !permissions?.companyAdmin && !permissions?.admin,
                            tooltip: 'Destroy',
                        }),
                        (item) => ({
                            type: 'open',
                            link: LinkHelper.settingsUserId(item.id),
                        }),
                    ],
                }}
            </Table2>
        </>
    );
};
