import React from 'react';

import { Icon } from '@.ui';

import { Base } from '../abstract/base';
import { MultipleProps, MultipleState } from './types';

const SelectList: React.FC<{ children?: React.ReactNode }> = ({ children }) => <div className="select__list">{children}</div>;

export class Multiple<Props extends MultipleProps = MultipleProps, State extends MultipleState = MultipleState> extends Base<Props, State> {
    state: Readonly<State> = {
        ...this.state,
        selected: [],
        after: <SelectList />,
    };

    updateSelectedList() {
        this.setState((prev) => ({
            ...prev,
            after: (
                <SelectList>
                    {this.state.selected?.map((option) => (
                        <div key={`Select-list-item-${option.id}`} className="select__list-item">
                            {option.selectedContent}
                            <Icon name="close" size={20} onClick={() => this.handleOptionRemove(option.id)} />
                        </div>
                    ))}
                </SelectList>
            ),
        }));
    }

    handleOptionRemove(id: number): void {
        this.setState((prev) => {
            const selected = [...prev.selected];

            const foundIndex = selected.findIndex((option) => option.id === id);

            if (foundIndex !== -1) selected.splice(foundIndex, 1);

            return { ...prev, selected, isTrusted: true };
        });
    }

    handleOptionClick(id: number): void {
        this.setState((prev) => {
            const selected = [...prev.selected];

            const found = this.state.options?.find((option) => option.id === id);
            const dubIndex = selected.findIndex((option) => option.id === id);

            if (dubIndex !== -1) {
                selected.splice(dubIndex, 1);
            } else if (found) {
                selected.push(found);
            }

            return { ...prev, selected, isTrusted: true };
        });
    }

    displayItems?(): void {
        this.setState((prev) => ({ ...prev, inputValue: this.state.selected.map((item) => item.content).join(', ') }));
    }
    updateTooltip?(): void {
        const input = this.inputRef.current;

        this.setState((prev) => ({ ...prev, hasTooltip: input && input.clientWidth <= input.scrollWidth ? true : false }));
    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>): void {
        super.componentDidUpdate(prevProps, prevState);

        if (JSON.stringify(prevState.selected) !== JSON.stringify(this.state.selected)) {
            this.updateStyles(prevState.selected, this.state.selected);

            this.updateSelectedList();

            this.props.onChange && this.state.isTrusted && this.props.onChange(this.state.selected.map((option) => option.id));

            this.displayItems && this.displayItems();
        }

        if (prevState.options !== this.state.options) {
            this.setState((prev) => ({ ...prev, visibleOptions: this.state.options }));
        }

        if (JSON.stringify(prevProps.value) !== JSON.stringify(this.props.value) || prevState.options !== this.state.options) {
            if (!this.props.value || this.props.value.length === 0) this.setState((prev) => ({ ...prev, isTrusted: false, selected: [] }));
            else {
                const found = this.props.value?.map((option) => this.state.options?.find((o) => o.id === option)).filter((o) => o !== undefined);

                if (found?.length) this.setState((prev) => ({ ...prev, isTrusted: false, selected: found }));
            }
        }

        if (prevState.inputValue !== this.state.inputValue) {
            this.updateTooltip && this.updateTooltip();
        }
    }
}
