import { AxiosRequestConfig } from 'axios';

import { Code } from '@.api';
import { DataType, FieldType, functions, LocalResponseType, QueryType, Responses } from '@.api/methods';
import { AuthContextValue } from '@.services';

import { __axios } from './modules/interceptors';
import { createResponse } from './modules/response';

const toString = (value: FieldType) => {
    if (typeof value === 'number' || value === null) return String(value);
    return value;
};

export interface ApiHandlers {
    onUploadProgress?: (event: ProgressEvent) => void;
}

export class ApiCall {
    private url?: string;
    private data?: DataType;
    private formData?: DataType;
    private query?: QueryType;
    private auth?: AuthContextValue;
    private download = false;
    private requestRefresh = true;
    private handlers: ApiHandlers = {};

    public constructor(options: {
        path?: string;
        data?: DataType;
        formData?: DataType;
        query?: QueryType;
        id?: number | string;
        auth?: AuthContextValue;
        download?: boolean;
        requestRefresh?: boolean;
        handlers?: ApiHandlers;
    }) {
        this.url = (options.path ? `/${options.path}` : '') + (options.id ? `/${options.id}` : '');
        this.data = options.data;
        this.formData = options.formData;
        this.query = options.query;
        this.auth = options.auth;
        this.download = options.download ?? false;
        this.requestRefresh = this.requestRefresh ?? true;

        if (options.handlers) {
            const { onUploadProgress } = options.handlers;

            this.handlers.onUploadProgress = onUploadProgress;
        }

        return this;
    }

    private processRequest = <T = undefined>(method?: 'get' | 'post' | 'put' | 'delete'): functions<T> => {
        if (!this.url)
            return createResponse<T>(
                new Promise((resolve) => {
                    resolve({ message: '', code: Code.Local, data: {} } as LocalResponseType);
                })
            );

        const axiosOptions: AxiosRequestConfig = {};

        if (this.data) {
            axiosOptions.data = this.data;
        } else if (this.formData) {
            const formData = new FormData();
            axiosOptions.headers = { ...axiosOptions.headers, 'Content-Type': 'multipart/form-data' };

            Object.entries(this.formData).forEach(([key, value]) => {
                if (Array.isArray(value)) {
                    value.forEach((v) => formData.append(key + '[]', toString(v)));
                } else {
                    formData.append(key, toString(value));
                }
            });

            axiosOptions.data = formData;

            if (this.handlers.onUploadProgress) axiosOptions.onUploadProgress = this.handlers.onUploadProgress;
        }

        if (this.query) {
            axiosOptions.params = this.query;
        }

        if (this.download) {
            axiosOptions.responseType = 'blob';
        }

        axiosOptions.url = this.url;
        axiosOptions.method = method;

        const response = __axios.request<Responses<T>>(axiosOptions) as unknown as Promise<Responses<T>>;

        return createResponse<T>(response)
            .onForbidden((res) => document.dispatchEvent(new CustomEvent('forbidden-api', { detail: { message: res.message } })))
            .onUnauthorized(() => document.dispatchEvent(new Event('unauthorized-api')));
    };

    public get = <T>() => {
        return this.processRequest<T>('get');
    };

    public post = <T>() => {
        return this.processRequest<T>('post');
    };

    public put = <T>() => {
        return this.processRequest<T>('put');
    };

    public delete = <T>() => {
        return this.processRequest<T>('delete');
    };
}
