import { _RuleMessageObject, RuleMessage } from '@.hooks';

export const ruleValue = <S>(rule: RuleMessage<S>): S => {
    if (typeof rule === 'object') {
        //TODO: fix types
        rule = rule as _RuleMessageObject<S>;

        if (rule.message !== undefined && rule.value !== undefined) {
            return rule.value;
        }
    }

    return rule as S;
};
