import { useState } from 'react';

import { Api } from '@.api';
import { ValidationErrorResponseType } from '@.api/methods';
import { useForm } from '@.hooks';
import { ProductProposal } from '@/Services/api/methods/ProductsProposals';
import { ProductProposalChangedError, ProductProposalService } from '@/Services/ProductProposal';

import { RejectProposalModalForm, validateOptions } from './RejectProposalModalForm';

interface UseRejectProposalModalParameters {
    updateProductProposal: () => void;
    close: () => void;
}

export const useRejectProposalModal = ({ updateProductProposal, close }: UseRejectProposalModalParameters) => {
    const [isProductWillChanged, setIsProductWillChanged] = useState<boolean>(false);

    const [field, form] = useForm<RejectProposalModalForm>({ reason: '' });

    const reject = (productProposal: ProductProposal) => {
        form.ifValid(async (data) => {
            try {
                const productProposalService = new ProductProposalService(Api.productsProposals());

                await productProposalService.reject(productProposal, data.reason);

                updateProductProposal();
                close();
            } catch (error) {
                if (error instanceof ProductProposalChangedError) {
                    setIsProductWillChanged(true);
                    return;
                }

                form.set.errors({ reason: (error as ValidationErrorResponseType)?.errors?.reject_reason });
            }
        }, validateOptions);
    };

    return {
        field,
        reject,
        isProductWillChanged,
    };
};
