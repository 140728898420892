import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Api } from '@.api';
import { useForm } from '@.hooks';
import { Auth, LinkHelper } from '@.services';
import { Button, Checkbox, Input } from '@.ui';
import { FormType } from '@/Components/Pages/PasswordRecovery/NewPassword/types';
import { validateOptions } from '@/Components/Pages/PasswordRecovery/NewPassword/validate';

export const NewPassword: React.FC = () => {
    const [query] = useSearchParams();
    const navigate = useNavigate();
    const [field, form] = useForm<FormType>();
    const auth = Auth.Use();

    const handleSubmit = () => {
        form.reset.errors();

        if (query.get('link') !== null) {
            form.ifValid((data) => {
                Api.user()
                    .reset_password({ ...data, link: query.get('link') })
                    .onSuccess((res) => {
                        const { access_token, refresh_token } = res.data;

                        auth?.setTokens({ access: access_token, refresh: refresh_token });
                        navigate(LinkHelper.myPage);
                    })
                    .onValidationError((res) => form.set.errors(res.errors))
                    .onUnauthorized(() => navigate(LinkHelper.login));
            }, validateOptions);
        }
    };

    return (
        <div className="new-password">
            <div className="new-password__form">
                <h2>Password recovery</h2>
                <p>Create a new password</p>
                <Input {...field('password').register()} text="New password" type="password" color="secondary" required />
                <Input {...field('confirm_password').register()} text="Confirm password" type="password" color="secondary" required />
                <Checkbox text="Remember me" />
                <Button onClick={handleSubmit} color="brown" text="Save and log in" />
            </div>
        </div>
    );
};
