import { FC, useEffect, useRef, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';

import { Auth, Language, useAuthRoutes } from '@.services';
import { pagesByUser } from '@/Lib/pagesByUser';

export const AuthRoutes: FC = () => {
    const executed = useRef<boolean>(false);
    const [refreshed, setRefreshed] = useState(false);
    const auth = Auth.Use();
    const routes = useAuthRoutes(pagesByUser(auth?.user));

    useEffect(() => {
        if (!auth || executed.current) {
            return;
        }

        executed.current = true;

        auth.refresh().finally(() => setRefreshed(true));
    }, [auth]);

    return <>{Boolean(refreshed && auth) && routes}</>;
};

export const Router: FC = () => (
    <Language.Provider>
        <BrowserRouter>
            <AuthRoutes />
        </BrowserRouter>
    </Language.Provider>
);
