import { Base, Response } from '@.api/methods';

export class Export extends Base {
    public excel(data: { model: string; filters: { [name: string]: string | number | boolean | number[] } }) {
        this.setPath('export');
        this.setData(data);
        this.setDownload();

        return this.postRequest() as Response<Blob>;
    }
}
