import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Api } from '@.api';
import { ProductType } from '@.api/methods';
import { Layout } from '@.layout';
import { CreateProductForm } from '@/Components/Partials/Product';
import { LinkHelper } from '@/Services';

import S from './styles.module.scss';
import { ProductsActionProps } from './types';

export const ProductsAction: FC<ProductsActionProps> = ({ edit, permissions }) => {
    const { id } = useParams();

    const [product, setProduct] = useState<ProductType | undefined>(undefined);

    const numberId = Number(id);

    const breadcrumbs = edit
        ? [
              { name: 'Products', link: LinkHelper.dataProducts },
              { name: product?.name.en ?? '', link: LinkHelper.dataProductId(numberId) },
              { name: 'Edit', current: true },
          ]
        : [
              { name: 'Products', link: LinkHelper.dataProducts },
              { name: 'Add', current: true },
          ];

    useEffect(() => {
        if (edit) {
            Api.products()
                .getById(numberId)
                .onSuccess((response) => setProduct(response.data));
        }
    }, [edit, numberId]);

    return (
        <Layout breadcrumbs={breadcrumbs} nobg contentClassName={S.layout}>
            <CreateProductForm id={numberId} permissions={permissions} edit={edit} initialProductState={product} />
        </Layout>
    );
};
