import { Base } from '@.api/methods';
import { StatisticCompaniesResponse } from '@/Services/api/methods/analytics/StatisticCompaniesTypes';
import { StatisticStoreGroupsResponse } from '@/Services/api/methods/analytics/StatisticStoreGroupsTypes';
import { StatisticStoresResponse } from '@/Services/api/methods/analytics/StatisticStoresTypes';
import { StatisticRequest, StatisticsResponse } from '@/Services/api/methods/analytics/StatisticTypes';
import { Request } from '@/Types';

export class AnalyticsApiService extends Base {
    public getStatistics(queryParameters: StatisticRequest) {
        this.setPath('statistics');

        if (queryParameters) {
            this.setData(queryParameters);
        }

        return this.postRequest<StatisticsResponse>();
    }

    public getStatisticStoreGroups(query?: Request.All) {
        this.setPath('statistic-store-groups');

        if (query) {
            this.setQuery(query);
        }

        return this.getRequest<StatisticStoreGroupsResponse>();
    }

    public getStatisticStores(query?: Request.All) {
        this.setPath('statistic-stores');

        if (query) {
            this.setQuery(query);
        }

        return this.getRequest<StatisticStoresResponse>();
    }

    public getStatisticCompanies(query?: Request.All) {
        this.setPath('statistic-companies');

        if (query) {
            this.setQuery(query);
        }

        return this.getRequest<StatisticCompaniesResponse>();
    }
}
