import { useCallback, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { functions } from '@.api/methods';
import { LinkHelper } from '@.services';

type Destructor = () => void;

export const useId = (effect?: (id: number) => void | functions | Destructor, shouldRedirect?: boolean) => {
    const { id } = useParams();
    const navigate = useNavigate();

    const redirect = useCallback(() => {
        if (!shouldRedirect) {
            return;
        }

        navigate(LinkHelper.notFound, { replace: true });
    }, [navigate, shouldRedirect]);

    const realId = useMemo(() => {
        if (!id || !/^([0-9]+)$/.test(id)) {
            redirect();
            return;
        }

        if (effect) {
            const result = effect(id as unknown as number);

            if (result) {
                if (typeof result === 'function') return result;

                result.onNotFound(redirect);
            }
        }

        return id;
    }, [id, redirect]);

    return realId as unknown as number;
};
