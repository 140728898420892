import { Layout } from '@.layout';

export const Marketplace: React.FC = () => {
    return (
        <Layout name="marketplace" breadcrumbs={[{ name: 'Marketplace', current: true }]}>
            <div className="marketplace__items-top"></div>
            <div className="marketplace__content"></div>
        </Layout>
    );
};
