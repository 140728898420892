import { AuthLogsPage } from '@/Routes/logs/authLogs';
import { SystemLogsPage } from '@/Routes/logs/systemLogs';

import { RoutePage } from '../types';

export const LogsPages: RoutePage[] = [
    {
        url: 'logs',
        permissions: 'admin',
        sidebar: {
            icon: 'logs',
            name: 'Logs',
        },
        children: [SystemLogsPage, AuthLogsPage],
    },
];
