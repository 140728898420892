import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { Api } from '@.api';
import { Layout } from '@.layout';
import { Table2 } from '@.partials';
import { LinkHelper } from '@.services';
import { Request } from '@.types';
import { PermissionProps } from '@.types';
import { Button, Filter3 } from '@.ui';
import { CompaniesResponseType } from '@/Services/api/methods';

import { CompaniesAction } from './Action';
import { Company } from './Company';

export { CompaniesAction, Company };

export const Companies: React.FC<PermissionProps> = (props) => {
    const navigator = useNavigate();

    const handleBlock = useCallback((id: number) => Api.companies().block(id), []);
    const handleUnblock = useCallback((id: number) => Api.companies().unblock(id), []);
    const get = useCallback((q: Request.All) => Api.companies().getAll(q), []);
    const getData = useCallback((data: CompaniesResponseType) => data.companies, []);
    const item = useCallback((item: CompaniesResponseType['companies'][number]) => ({ className: item.status.name === 'Blocked' ? 'deleted' : '' }), []);

    return (
        <Layout name="clients" breadcrumbs={[{ name: 'Companies', current: true }]}>
            <h1>
                Companies
                <Button color="brown" text="Add company" onClick={() => navigator(LinkHelper.clientsCompaniesAdd)} disabled={!props.permissions?.create} />
            </h1>
            <Table2 get={get} getData={getData} item={item} export="companies">
                {{
                    filters: (handleChange) => (
                        <Filter3 onChange={handleChange}>
                            <Filter3.Search />
                            <Filter3.CompanyStatuses />
                        </Filter3>
                    ),
                    items: [
                        (item) => ({
                            content: item.name,
                            name: 'Name',
                            sort: 'name',
                            link: LinkHelper.clientsCompanyId(item.id),
                            max: 12,
                        }),
                        (item) => ({
                            content: item.street_address_1,
                            name: "Address of a company's headquarters",
                            sort: 'address',
                            max: 30,
                        }),
                        (item) => ({
                            content: item.store_groups_count,
                            name: 'Number of store groups',
                            sort: 'store_groups_count',
                            max: 4,
                        }),
                        (item) => ({
                            content: item.stores_count,
                            name: 'Number of stores',
                            sort: 'stores_count',
                            max: 4,
                        }),
                        (item) => ({
                            content: item.users_count,
                            name: 'Number of users',
                            sort: 'users_count',
                            max: 4,
                        }),
                        (item) => ({
                            content: `${item.agent_name}, ${item.agent_phone}, ${item.agent_email}`,
                            name: 'Contact person',
                            sort: 'agent_name',
                            max: 30,
                        }),
                    ],
                    actions: [
                        (item) => ({
                            type: 'edit',
                            link: LinkHelper.clientsCompaniesEditId(item.id),
                            disabled: !props.permissions?.update,
                        }),
                        (item) => ({
                            type: item.status.name === 'Active' ? 'lock' : 'unlock',
                            onClick: item.status.name === 'Active' ? () => handleBlock(item.id) : () => handleUnblock(item.id),
                            disabled: !props.permissions?.delete,
                        }),
                        (item) => ({
                            type: 'open',
                            link: LinkHelper.clientsCompanyId(item.id),
                        }),
                    ],
                }}
            </Table2>
        </Layout>
    );
};
