import { Base, Response } from '@.api/methods';

import { IngredientResponseType, IngredientsResponseType, IngredientsType } from './types';

export class Ingredients extends Base {
    public getAll(query?: { page?: number; per_page?: number; search?: string; is_archived?: boolean }) {
        this.setPath('ingredients');
        if (query) this.setQuery(query);

        return this.getRequest() as Response<IngredientsResponseType>;
    }

    public getById(id: number) {
        this.setPath('ingredients');
        this.setId(id);

        return this.getRequest() as Response<IngredientResponseType>;
    }

    public create(data: IngredientsType) {
        this.setPath('ingredients');
        this.setData(data);

        return this.postRequest();
    }

    public delete(id: number) {
        this.setPath('ingredients');
        this.setId(id);

        return this.deleteRequest();
    }

    public update(data: IngredientsType, id: number) {
        this.setPath('ingredients');
        this.setId(id);
        this.setData(data);

        return this.putRequest();
    }

    public restore(id: number) {
        this.setPath('ingredients');
        this.setId(`${id}/restore`);

        return this.postRequest();
    }
}
