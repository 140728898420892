import React from 'react';
import { FC, useEffect, useRef, useState } from 'react';

import { useRefEffect } from '@/Components/Hooks';

import { Icon } from '../Icon';
import { Base } from './base';
import { ItemProps, UploadProps } from './types';

export const Image: FC<UploadProps> = (props) => {
    return (
        <Base
            {...props}
            className={'image'}
            sizeRender={(size) => `${size}, best ratio 3:4`}
            uploadRender={({ ref, handleClick, isDragging, error }) => (
                <div
                    className={'uploader__item uploader__item_upload' + (isDragging ? ' dragging' : '') + (error ? ' error' : '')}
                    onClick={handleClick}
                    ref={ref}
                >
                    <Icon name="download" className="uploader-icon " size={20} />
                    <p>Upload</p>
                </div>
            )}
            itemRender={(item, _index, { handleRemove }) => (
                <Item key={`${item.uuid}`} id={item.id} uuid={item.uuid} name={item.name} progress={item.progress} url={item.url} onRemove={handleRemove} />
            )}
        />
    );
};

const Item: React.FC<ItemProps> = ({ progress, url, name, id, onRemove, uuid }) => {
    const loaderRef = useRef<HTMLDivElement>(null),
        currentLoaderRef = useRef<HTMLDivElement>(null);

    const [percent, setPercent] = useState<number | undefined>(id ? undefined : 0),
        [loaded, setLoaded] = useState(false);

    const handleLoad = () => setLoaded(true);
    const handleRemove = () => onRemove(uuid, id);

    const image = React.createElement('img', { onLoad: handleLoad, src: url, alt: name });

    useEffect(() => {
        if (loaded) setPercent(undefined);
        else if (progress === 100) setPercent(100);
        else if (progress) setPercent(progress);
    }, [loaded, progress]);

    useRefEffect(
        (currentLoader) => {
            if (progress) currentLoader.style.width = `${progress}%`;
        },
        [progress],
        currentLoaderRef
    );

    return (
        <div className={'uploader__item' + (percent !== undefined ? ' uploader__item_loading' : ' uploader__item_file')}>
            {percent !== undefined ? (
                <>
                    <div className="uploader__loader" ref={loaderRef}>
                        <div className="uploader__loader-current" ref={currentLoaderRef} />
                    </div>
                    <p>Uploading</p>
                </>
            ) : (
                <>
                    <Icon name="close" className="uploader-icon uploader__item_file-remove" size={20} onClick={handleRemove} />
                </>
            )}
            {image}
        </div>
    );
};

Image.defaultProps = { accepts: [{ name: 'Images', type: ['jpg', 'jpeg', 'png', 'gif', 'bmp'] }] };
