import useModal from '@fipnooone/react-modal';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Layout } from '@.layout';
import { useId } from '@/Components/Hooks';
import { ModalConfirm } from '@/Components/Modal/Dialogs';
import { Table, TableColumn, TableRow } from '@/Components/Partials';
import { Button } from '@/Components/UI';
import { LinkHelper, Tools } from '@/Services';
import { Api } from '@/Services/api';
import { OrderResource } from '@/Services/api/methods/deviceOrders/types';

export const DevicesOrder: React.FC = () => {
    const [modal, controls] = useModal();
    const [order, setOrder] = useState<OrderResource>();
    const navigate = useNavigate();

    const refreshData = useCallback(
        (id: number) =>
            Api.deviceOrders()
                .show(id)
                .onSuccess(({ data }) => {
                    setOrder(data);
                }),
        []
    );

    const id = useId((id) => refreshData(id));

    const handleCancel = useCallback(() => {
        controls.set(
            <ModalConfirm
                message="Do you really want to cancel this order?"
                onCancel={() => controls.close()}
                onConfirm={() => {
                    controls.close();

                    Api.deviceOrders()
                        .cancel(id)
                        .then(() => refreshData(id));
                }}
            />
        );
    }, [id]);

    return (
        <Layout
            name="orders"
            breadcrumbs={[
                { name: 'Ordering devices', link: LinkHelper.ordersDevices },
                { name: `Order #${order?.id ?? ''}`, current: true },
            ]}
        >
            {modal}
            {order && (
                <>
                    <h1>
                        {`Order #${order.id}`}
                        <div>
                            <Button color="transparent" text={'Cancel this order'} onClick={handleCancel} disabled={!order.is_cancelable} />
                            <Button color="brown" text="Edit" onClick={() => navigate(LinkHelper.ordersDevicesItemEditId(order.id))} />
                        </div>
                    </h1>
                    <Table>
                        <TableRow>
                            <TableColumn bold>Identifier</TableColumn>
                            <TableColumn>{order.id}</TableColumn>
                        </TableRow>
                        <TableRow>
                            <TableColumn bold>Company</TableColumn>
                            <TableColumn>{order.company?.name ?? ''}</TableColumn>
                        </TableRow>
                        <TableRow>
                            <TableColumn bold>Store</TableColumn>
                            <TableColumn>{order.store?.name ?? ''}</TableColumn>
                        </TableRow>
                        <TableRow>
                            <TableColumn bold>Status</TableColumn>
                            <TableColumn>{order.status?.name}</TableColumn>
                        </TableRow>
                        <TableRow>
                            <TableColumn bold>Shipping address</TableColumn>
                            <TableColumn>{order.address}</TableColumn>
                        </TableRow>
                        <TableRow>
                            <TableColumn bold>Sender</TableColumn>
                            <TableColumn>{order.user?.name}</TableColumn>
                        </TableRow>
                        <TableRow>
                            <TableColumn bold>Number of devices</TableColumn>
                            <TableColumn>{order.devices_count}</TableColumn>
                        </TableRow>
                        <TableRow>
                            <TableColumn bold>Comment</TableColumn>
                            <TableColumn>{order.comment}</TableColumn>
                        </TableRow>
                        <TableRow>
                            <TableColumn bold>Created at</TableColumn>
                            <TableColumn>{Tools.date.autoFormat(order.created_at * 1000)}</TableColumn>
                        </TableRow>
                    </Table>
                </>
            )}
        </Layout>
    );
};
