import { PackagesUpdateAction, PackagesUpdateItem, PackagesUpdateList } from '@.pages';

import { RoutePage } from '../types';

export const PackagesUpdatePage: RoutePage = {
    url: 'update-packages',
    component: <PackagesUpdateList />,
    permissions: 'admin',
    sidebar: {
        name: 'Update packages',
    },
    children: [
        {
            url: 'add',
            permissions: 'admin',
            component: <PackagesUpdateAction />,
        },
        {
            url: ':id',
            component: <PackagesUpdateItem />,
            children: [
                {
                    url: 'edit',
                    component: <PackagesUpdateAction edit />,
                },
            ],
        },
    ],
};
