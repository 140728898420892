import { Ingredient, Ingredients, IngredientsAction } from '@.pages';

import { RoutePage } from '../types';

export const IngredientsPage: RoutePage = {
    url: 'ingredients',
    component: <Ingredients />,
    permissions: 'admin',
    sidebar: {
        name: 'Ingredients',
    },
    children: [
        {
            url: ':id',
            component: <Ingredient />,
            children: [
                {
                    url: 'edit',
                    component: <IngredientsAction edit />,
                    permissions: 'admin',
                },
            ],
        },
        {
            url: 'add',
            component: <IngredientsAction />,
            permissions: 'admin',
        },
    ],
};
