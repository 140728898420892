import useModal from '@fipnooone/react-modal';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { Layout } from '@.layout';
import { Table2 } from '@.partials';
import { ModalConfirm } from '@/Components/Modal/Dialogs';
import { Button, Filter3 } from '@/Components/UI';
import { Auth, LinkHelper, Tools } from '@/Services';
import { Api } from '@/Services/api';
import { OrdersReponseType } from '@/Services/api/methods/deviceOrders/types';
import { Request } from '@/Types';

export * from './Action';
export * from './Order';

export const Devices: React.FC = () => {
    const navigate = useNavigate();
    const [modal, controls] = useModal();

    const auth = Auth.Use();

    const get = useCallback((q?: Request.All) => Api.deviceOrders().list(q), []);

    const handleCancel = useCallback((id: number) => Api.deviceOrders().cancel(id), []);

    const getData = useCallback((d: OrdersReponseType) => d.device_orders, []);

    return (
        <Layout name="orders" breadcrumbs={[{ name: 'Ordering devices', current: true }]}>
            {modal}
            <h1>
                Ordering devices
                {!auth?.user?.is_super && <Button color="brown" text="Create order" onClick={() => navigate(LinkHelper.ordersDevicesNew)} />}
            </h1>
            <Table2 get={get} getData={getData} export="device_orders">
                {{
                    filters: (handler) => (
                        <Filter3 onChange={handler}>
                            <Filter3.Companies />
                            <Filter3.ProductOrderStatuses />
                        </Filter3>
                    ),
                    items: [
                        (item) => ({
                            name: 'Id',
                            max: 20,
                            content: item.id,
                            sort: 'id',
                        }),
                        (item) => ({
                            name: 'Created at',
                            max: 40,
                            content: Tools.date.autoFormat(item.created_at * 1000),
                            sort: 'created_at',
                        }),
                        (item) => ({
                            name: 'Company',
                            max: 20,
                            content: item.company ? item.company.name : '',
                            sort: 'company',
                        }),
                        (item) => ({
                            name: 'Store',
                            max: 20,
                            content: item.store.name,
                            sort: 'store',
                        }),
                        (item) => ({
                            name: 'Sender',
                            max: 20,
                            content: item.user.name,
                            sort: 'user',
                        }),
                        (item) => ({
                            name: 'Status',
                            max: 20,
                            content: item.status.name,
                            sort: 'status',
                        }),
                        (item) => ({
                            name: 'Number of devices',
                            max: 20,
                            content: item.devices_count,
                            sort: 'products_count',
                        }),
                    ],
                    actions: [
                        (item) => ({
                            type: 'edit',
                            link: LinkHelper.ordersDevicesItemEditId(item.id),
                        }),
                        (item) => ({
                            type: 'cancel',
                            onClick: () =>
                                new Promise((resolve, reject) => {
                                    controls.set(
                                        <ModalConfirm
                                            message="Do you really want to cancel this order?"
                                            onCancel={() => {
                                                controls.close();
                                                reject();
                                            }}
                                            onConfirm={() => {
                                                controls.close();

                                                handleCancel(item.id).then(() => {
                                                    resolve(null);
                                                });
                                            }}
                                        />
                                    );
                                }),
                            disabled: !item.is_cancelable,
                            hide: auth?.user?.is_super,
                            tooltip: 'Cancel this order',
                        }),
                        (item) => ({
                            type: 'open',
                            link: LinkHelper.ordersDevicesItemId(item.id),
                        }),
                    ],
                }}
            </Table2>
        </Layout>
    );
};
