import React, { FC, useMemo } from 'react';

import { CircleDivisionData, CircleDivisions } from '@.partials';
import { DEFAULT_ANALYTICS_CHART_COLORS } from '@/Components/Partials/Analytics/constants';
import { ChartDatasetValues } from '@/Types/analytics';

import S from './styles.module.scss';

type HowManyUsersHaveCompletedWizardChartProps = {
    data: ChartDatasetValues;
};

export const HowManyUsersHaveCompletedWizardChart: FC<HowManyUsersHaveCompletedWizardChartProps> = ({ data }) => {
    const circleDivisionsData = useMemo<CircleDivisionData[]>(
        () =>
            data.map((dataItem, index) => ({
                value: dataItem.value,
                color: DEFAULT_ANALYTICS_CHART_COLORS[index],
                label: dataItem.label,
            })),
        [data]
    );

    return (
        <div>
            <h3>How many users have completed Wizard</h3>
            <CircleDivisions className={S.how_many_have_completed_wizard} data={circleDivisionsData} textTotal="Total Customers" />
        </div>
    );
};
