import React, { FC } from 'react';

import S from './styles.module.scss';

export type LegendCircleProps = {
    color: string;
};

export const LegendCircle: FC<LegendCircleProps> = ({ color }) => {
    return <div className={S.legend_circle} style={{ backgroundColor: color }} />;
};
