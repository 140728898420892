import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Api } from '@.api';
import { BrandResponseType } from '@.api/methods';
import { useForm, useId } from '@.hooks';
import { Layout } from '@.layout';
import { getLingualErrors, LinkHelper } from '@.services';
import { LingualInput, Upload } from '@.ui';
import { BrandsActionProps, BrandsData } from '@/Components/Pages/Data/Brands/Action/types';
import { validateOptions } from '@/Components/Pages/Data/Brands/Action/validate';
import { Buttons } from '@/Components/Partials';

export const BrandsAction: React.FC<BrandsActionProps> = ({ edit }) => {
    const [brand, setBrand] = useState<BrandResponseType>();
    const [field, form] = useForm<BrandsData>();
    const navigator = useNavigate();

    const get = (id: number) => {
        if (!edit) return;

        return Api.brands()
            .getById(id)
            .onSuccess((res) => {
                setBrand(res.data);
                const { id, name, image } = res.data;
                form.set.values({ id, name, image_id: image?.id });
            });
    };

    const id = useId(get, edit);

    const handleCreate = () => {
        form.reset.errors();

        form.ifValid((data) => {
            Api.brands()
                .create(data)
                .onSuccess(() => navigator(LinkHelper.dataBrands))
                .onValidationError((res) => {
                    form.set.errors({ ...res.errors, name: getLingualErrors(res.errors, 'name') });
                });
        }, validateOptions);
    };

    const handleUpdate = () => {
        form.reset.errors();

        form.ifValid((data) => {
            Api.brands()
                .update(data, id)
                .onSuccess(() => navigator(LinkHelper.dataBrands))
                .onValidationError((res) => {
                    form.set.errors({ ...res.errors, name: getLingualErrors(res.errors, 'name') });
                });
        }, validateOptions);
    };

    return (
        <Layout
            name="data"
            breadcrumbs={
                edit
                    ? [
                          { name: 'Brands', link: LinkHelper.dataBrands },
                          { name: brand?.name.en ?? '', link: LinkHelper.dataBrandId(Number(id)) },
                          { name: 'Edit', current: true },
                      ]
                    : [
                          { name: 'Brands', link: LinkHelper.dataBrands },
                          { name: 'Add', current: true },
                      ]
            }
            small
        >
            {edit ? <h1>Editing brand</h1> : <h1>Adding brand</h1>}
            <div className="brands-action__form">
                <LingualInput {...field('name').register()} text="Name" />
                {/* <Input {...field('name_en').register()} text="Name" /> */}
                <Upload.Image {...field('image_id').register<number>({ noValue: true })} value={brand?.image} single />
                {/* <div className="brands-action__form-buttons">
                    <Button color="brown" text="Save" onClick={edit ? handleUpdate : handleCreate} />
                    <Button color="transparent" text="Cancel" onClick={() => navigator(-1)} />
                </div> */}
                <Buttons text={edit ? 'Save' : 'Add'} onClickSave={edit ? handleUpdate : handleCreate} />
            </div>
        </Layout>
    );
};
