import { scaleLinear } from '@visx/scale';
import { extent } from 'd3-array';
import { useEffect, useMemo, useRef, useState } from 'react';

import { Tools } from '@/Services';

import { WithTooltip } from '../Tools';
import { Axis } from '../Tools/Axis';
import { Curve } from './Curve';
import { CurvesProps } from './types';

export const Curves: React.FC<CurvesProps> = ({ color, data }) => {
    const height = 480;

    const svgRef = useRef<SVGSVGElement | null>(null);
    const [width, setWidth] = useState<number>(0);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if (loaded) updateWidth();
    }, [loaded]);

    const updateWidth = () => setWidth(svgRef.current?.clientWidth ?? 0);

    const curveData = useMemo(
        () =>
            data
                .map((d) => ({
                    ...d,
                    date: Tools.date.toJs(d.date),
                }))
                .sort((a, b) => a.date - b.date),
        [data]
    );

    const [scaleX, scaleY] = useMemo(() => {
        const extentX = extent(curveData, (d) => d.date) as [number, number],
            extentY = extent(curveData, (d) => d.value) as [number, number];

        const diffX = Math.abs(extentX[1] - extentX[0]) * 0.08,
            diffY = Math.abs(extentY[1] - extentY[0]) * 0.05;

        return [
            scaleLinear({
                range: [width, 0],
                domain: [extentX[1] + diffX, extentX[0] - diffX],
                round: true,
                nice: true,
                // clamp: true,
                // reverse: true,
            }),
            scaleLinear({
                range: [height, 0],
                round: true,
                nice: true,
                clamp: true,
                // zero: false,
                domain: [extentY[0] - diffY, extentY[1] + diffY],
            }),
        ];
    }, [curveData, width]);

    useEffect(() => {
        const handleResize = () => updateWidth();

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div className="chart_bars">
            <WithTooltip color={color}>
                {(show, hide) => (
                    <svg
                        width={'100%'}
                        height={height + 25}
                        ref={(ref) => {
                            setLoaded(true);
                            svgRef.current = ref;
                        }}
                    >
                        {data.length !== 0 && (
                            <Axis scale={scaleY} width={width} height={height} numTicks={8} labelEnd={'Count'} labelStart={' '}>
                                <Axis
                                    tickFormat={(value) => Tools.date.format(value as number | Date, 'DD.MM.YY').toString()}
                                    scale={scaleX}
                                    width={width}
                                    height={height + 20}
                                    numTicks={9}
                                    labelEnd={'Name'}
                                    odd
                                    isHorizontal
                                    nolines
                                    borderBottomOffset={25}
                                >
                                    <Curve color={color} data={curveData} scaleX={scaleX} scaleY={scaleY} hideTooltip={hide} showTooltip={show}></Curve>
                                </Axis>
                            </Axis>
                        )}
                    </svg>
                )}
            </WithTooltip>
        </div>
    );
};
