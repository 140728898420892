import { requiredRule, ValidateOptions } from '@/Components/Hooks';

import { UpdatePackageData } from './types';

export const validateOptions: ValidateOptions<UpdatePackageData> = {
    name: {
        type: 'string',
        required: requiredRule,
    },
    changelog: {
        type: 'string',
        required: requiredRule,
    },
    version: {
        type: 'string',
        required: requiredRule,
    },
    date: {
        type: 'date_from',
        required: requiredRule,
    },
    wlm_firmware_management_file_id: {
        type: 'number',
    },
    lmp_firmware_management_file_id: {
        type: 'number',
    },
};
