import React, { useEffect, useState } from 'react';

interface Props {
    onDrop?: (files: File[]) => void;
    areaRef: React.RefObject<HTMLDivElement>;
}

export const useDrag = (props: Props, deps?: React.DependencyList) => {
    const [isDragging, setDragging] = useState(false);
    const [dragCount, setCount] = useState(0);

    const handleDragIn = (event: DragEvent) => {
        event.preventDefault();

        if (event.dataTransfer && event.dataTransfer.items.length) {
            event.dataTransfer.effectAllowed = 'all';
            event.dataTransfer.dropEffect = 'move';

            setCount((prevState) => {
                const calc = prevState + 1;

                return calc >= 3 ? 1 : calc;
            });
        }
    };

    const handleDragOut = (event: DragEvent) => {
        event.preventDefault();

        setCount((prevState) => {
            const calc = prevState - 1;

            return calc < 0 ? 0 : calc;
        });
    };

    const handleDrag = (event: DragEvent) => {
        event.preventDefault();
    };

    const handleDrop = (event: DragEvent) => {
        event.preventDefault();

        setCount(0);

        if (props.onDrop && event.dataTransfer) {
            props.onDrop(Array.from(event.dataTransfer.files));
        }
    };

    useEffect(() => {
        if (dragCount <= 0) {
            setDragging(false);
        } else {
            setDragging(true);
        }
    }, [dragCount]);

    useEffect(() => {
        const area = props.areaRef.current;

        if (area) {
            // area.addEventListener('click', handleClick);
            area.addEventListener('dragenter', handleDragIn);
            area.addEventListener('dragleave', handleDragOut);
            area.addEventListener('dragover', handleDrag);
            area.addEventListener('drop', handleDrop);

            return () => {
                // area.removeEventListener('click', handleClick);
                area.removeEventListener('dragenter', handleDragIn);
                area.removeEventListener('dragleave', handleDragOut);
                area.removeEventListener('dragover', handleDrag);
                area.removeEventListener('drop', handleDrop);
            };
        }
    }, deps ?? []);

    return isDragging;
};
