import cn from 'classnames';
import React, { FC } from 'react';

import S from './styles.module.scss';

interface FormItemWrapperProps {
    className?: string;
    children: React.ReactNode;
}

export const FormItemWrapper: FC<FormItemWrapperProps> = ({ className, children }) => {
    return <div className={cn(S.formItem, className)}>{children}</div>;
};
