import React, { useCallback, useMemo } from 'react';

import { Api } from '@.api';
import { Board, ImportTable } from '@.partials';
import { LinkHelper } from '@.services';
import { Request } from '@/Types';

import { IrcUsersProductsTableProps } from './types';
export const ScenticonProductsTable: React.FC<IrcUsersProductsTableProps> = ({ irc_user_id, hideActions, permissions }) => {
    const get = useCallback((q?: Request.All) => {
        if (!irc_user_id) return;

        return Api.ircUsers().getProducts(irc_user_id, q);
    }, []);

    const post = (ids: number[]) => {
        if (!irc_user_id) return;

        return Api.ircUsers().updateProducts(irc_user_id, ids);
    };

    const filters = useMemo(() => ({ irc_user_id }), [irc_user_id]);

    return (
        <Board.Line>
            <Board.Item>
                <ImportTable
                    headers={['UDI', 'Name'] as const}
                    get={get}
                    post={post}
                    getId={(item) => item.id}
                    itemRender={(column, item) => {
                        switch (column) {
                            case 'UDI':
                                return item.udi.toString();
                            case 'Name':
                                return item.name;
                        }
                    }}
                    getDefault={(item) => item.is_linked}
                    getData={(data) => data.products}
                    filters={filters}
                    edit={!permissions?.admin}
                    actions={[
                        (item) => ({
                            type: 'open',
                            link: LinkHelper.dataProductId(item.id),
                        }),
                    ]}
                />
            </Board.Item>
        </Board.Line>
    );
};
