import { Devices, DevicesAction, DevicesOrder } from '@.pages';

import { RoutePage } from '../types';

export const DevicesPage: RoutePage = {
    url: 'devices',
    component: <Devices />,
    permissions: 'readOnly',
    sidebar: {
        name: 'Ordering devices',
    },
    children: [
        {
            url: ':id',
            component: <DevicesOrder />,
            children: [
                {
                    url: 'edit',
                    component: <DevicesAction edit />,
                    permissions: {
                        entity: 'Stores',
                        right: 'update',
                    },
                },
            ],
        },
        {
            url: 'add',
            component: <DevicesAction />,
            permissions: {
                entity: 'Stores',
                right: 'create',
            },
        },
    ],
};
