import { requiredRule, ValidateOptions } from '@.hooks';
import { StoreGroupsData } from '@/Components/Pages/Clients/StoreGroups/Action/types';

export const validateOptions: ValidateOptions<StoreGroupsData> = {
    id: {},
    name: {
        type: 'string',
        required: requiredRule,
    },
    company_id: {
        type: 'number',
        required: requiredRule,
    },
    status: {
        type: 'number',
    },
};
