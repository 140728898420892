import { Base64, Decode, Encode, Parse } from './types';

const encode: Encode = (item) => {
    const __ = (value: string) => window.btoa(value);

    if (typeof item === 'string') return __(item);

    return __(JSON.stringify(item));
};

const decode: Decode = (encoded) => window.atob(encoded);

const parse: Parse = (encoded) => {
    const decoded = decode(encoded);

    try {
        return JSON.parse(decoded);
    } catch {
        return undefined;
    }
};

export const base64: Base64 = {
    decode,
    encode,
    parse,
};
