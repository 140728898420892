import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Api } from '@.api';
import { Language, StationType } from '@.api/methods';
import { useDictionaries, useForm, useId } from '@.hooks';
import { Layout } from '@.layout';
import { Auth, LinkHelper } from '@.services';
import { Checkbox, Input, NumberInput, Select } from '@.ui';
import { validateOptions } from '@/Components/Pages/Stations/Action/validate';
import { Buttons } from '@/Components/Partials';
import { BaseResource } from '@/Types';

export const StationsAction: React.FC = () => {
    const [dictionaries] = useDictionaries('Stores', 'StationStatuses', 'Currencies');
    const [field, form] = useForm<StationType>();
    const [languages, setLanguages] = useState<Language[]>([]);
    const navigator = useNavigate();

    const auth = Auth.Use();

    const [data, setData] = useState<BaseResource>();

    useEffect(() => {
        Api.languages()
            .getAll()
            .onSuccess((res) => {
                setLanguages(res.data.languages);
            });
    }, []);

    const get = (id: number) => {
        return Api.stations()
            .getById(Number(id))
            .onSuccess((res) => {
                const {
                    identifier,
                    name,
                    pin,
                    min_whiff_interval_t1,
                    min_whiff_interval_t2,
                    status,
                    currency,
                    store_id,
                    is_brand_strict,
                    move_interval_between_chambers,
                    offline_mode,
                    show_price_range,
                    languages,
                    gold_level,
                    yml_level,
                } = res.data;
                form.set.values({
                    identifier,
                    name,
                    move_interval_between_chambers,
                    offline_mode,
                    min_whiff_interval_t1,
                    min_whiff_interval_t2,
                    pin,
                    is_brand_strict,
                    status: status?.id,
                    store_id,
                    currency_id: currency?.id,
                    show_price_range,
                    languages,
                    gold_level,
                    yml_level,
                });

                setData({ id, name });
            });
    };

    const id = useId(get);

    const handleUpdate = () => {
        if (!id) return;

        form.ifValid((data) => {
            Api.stations()
                .update(data, id)
                .onSuccess(() => navigator(LinkHelper.stations))
                .onValidationError((res) => form.set.errors(res.errors));
        }, validateOptions);
    };

    return (
        <Layout
            name="stations"
            breadcrumbs={[
                { name: 'Stations', link: LinkHelper.stations },
                data && { name: data.name, link: LinkHelper.stationId(data.id) },
                { name: 'Edit', current: true },
            ]}
            small
        >
            <h1>Editing iRomaScents Station</h1>
            <div className="stations-action__form">
                <Input {...field('identifier').register()} disabled={!auth?.user?.is_super} text="iRomaScents Station ID" />
                <Input {...field('name').register()} text="Name" disabled={!auth?.user?.is_super} required />
                <Select {...field('store_id').register<number>()} placeholder="Store" disabled={!auth?.user?.is_super} required>
                    {dictionaries?.stores?.map((store) => (
                        <Select.Option key={`store_${store.id}`} id={store.id}>
                            {store.name}
                        </Select.Option>
                    ))}
                </Select>
                <Select {...field('status').register<number>()} placeholder="Status" disabled={!auth?.user?.is_super} required>
                    {dictionaries?.station_statuses?.map((status) => (
                        <Select.Option id={status.id}>{status.name}</Select.Option>
                    ))}
                </Select>
                <NumberInput
                    {...field('move_interval_between_chambers').register()}
                    disabled={!auth?.user?.is_super}
                    text="Move intervals between chambers, ms"
                    required
                />
                <NumberInput {...field('offline_mode').register()} disabled={!auth?.user?.is_super} text="Offline, s" required />
                <Input {...field('min_whiff_interval_t1').register()} text="Min whiff interval (T1), s" disabled={!auth?.user?.is_super} required />
                <Input {...field('min_whiff_interval_t2').register()} text="Min whiff interval (T2), s" disabled={!auth?.user?.is_super} required />
                <Input {...field('pin').register()} text="PIN" disabled={!auth?.user?.is_super} required />
                <NumberInput {...field('gold_level').register()} regex={/^\d+(\.\d{0,2})?$/} text="Gold Level" disabled={!auth?.user?.is_super} required />
                <NumberInput {...field('yml_level').register()} regex={/^\d+(\.\d{0,2})?$/} text="YML Level" disabled={!auth?.user?.is_super} required />

                <Select {...field('currency_id').register<number>()} placeholder="Currency" disabled={!auth?.user?.is_super} required>
                    {dictionaries?.currencies?.map((currency) => (
                        <Select.Option id={currency.id}>{currency.name}</Select.Option>
                    ))}
                </Select>

                <Select
                    {...(field('languages').register() as { onChange?: (value?: number[]) => void; value?: number[] })}
                    placeholder="Languages"
                    multiple
                    disabled={!auth?.user?.is_super}
                    required
                >
                    {languages.map((item) => (
                        <Select.Option id={item.code as unknown as number}>{item.name}</Select.Option>
                    ))}
                </Select>

                <Checkbox {...field('is_brand_strict').register()} isDisabled={!auth?.user?.is_super} text="Brand strict dependency" />
                <Checkbox {...field('show_price_range').register()} isDisabled={!auth?.user?.is_super} text="Show price range" />

                <Buttons text={'Save'} noSave={!auth?.user?.is_super} onClickSave={handleUpdate} />
            </div>
        </Layout>
    );
};
