import { ValidateFieldOptionObj, ValidateOptions } from '@.hooks';
interface Email {
    email: string;
}

interface Password {
    password: string;
}

interface RepeatPassword {
    repeat_password: string;
}

type Passwords = Password & RepeatPassword;

export const requiredRule = {
    value: true,
    message: 'Field $field is required',
};

export const requiredImage = {
    value: true,
    message: 'Image is required',
};

export const regexRule = (regex: RegExp) => ({
    value: regex,
    message: '$field is invalid',
});

export const requiredEmail: ValidateFieldOptionObj<Email> = {
    type: 'string',
    required: requiredRule,
    regex: /^[A-Z0-9._%+-]+@[A-Z0-9-]+.+.[A-Z]{2,4}$/i,
    message: '$field is invalid',
};

export const optionalPassword: ValidateFieldOptionObj<Password> = {
    type: 'string',
};

export const requiredPassword: ValidateFieldOptionObj<Password> = {
    ...optionalPassword,
    required: requiredRule,
};

export const optionalPasswords: ValidateOptions<Passwords> = {
    password: optionalPassword,
    repeat_password: {
        type: 'string',
        requiredIfField: {
            value: 'password',
            message: 'Field $field is required',
        },
        sameAs: {
            value: 'password',
            message: 'Passwords do not match',
        },
    },
};
