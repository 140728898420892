import { useState } from 'react';

import { Api } from '@.api';
import { useId } from '@.hooks';
import { Layout } from '@.layout';
import { LinkHelper } from '@/Services';
import { Tools } from '@/Services';
import { SystemLogResponse } from '@/Services/api/methods/systemLogs/types';

export const SystemLog: React.FC = () => {
    const [log, setLog] = useState<SystemLogResponse>();

    const get = (id: number) => {
        return Api.system_logs()
            .getById(id)
            .onSuccess((res) => setLog(res.data));
    };

    useId(get);

    return (
        <Layout
            breadcrumbs={[
                { name: 'System logs', link: LinkHelper.systemLogs },
                { name: `#${log?.id}`, current: true },
            ]}
        >
            <h1>log #{log?.id}</h1>
            {log && (
                <div className="entity-page">
                    <div className="entity-page__item">
                        <div className="bold">Time</div>
                        <div>{Tools.date.autoFormat(log?.created_at * 1000)}</div>
                    </div>
                    <div className="entity-page__item">
                        <div className="bold">Type</div>
                        <div>{log?.type?.name}</div>
                    </div>
                    <div className="entity-page__item">
                        <div className="bold">User</div>
                        <div>{log?.user?.name}</div>
                    </div>
                    <div className="entity-page__item">
                        <div className="bold">Description</div>
                        <div>{Tools.string.lineWrap(log.description)}</div>
                    </div>
                </div>
            )}
        </Layout>
    );
};
