import { Base, Response } from '@.api/methods';
import { ProductTypeResponse, ProductTypesResponse, ProductTypesType } from '@/Services/api/methods/productTypes/types';

export class ProductTypes extends Base {
    public getAll(query?: { page?: number; per_page?: number; search?: string; is_archived?: boolean }) {
        this.setPath('product-types');
        if (query) this.setQuery(query);

        return this.getRequest() as Response<ProductTypesResponse>;
    }

    public getById(id: number) {
        this.setPath('product-types');
        this.setId(id);

        return this.getRequest() as Response<ProductTypeResponse>;
    }

    public create(data: ProductTypesType) {
        this.setPath('product-types');
        this.setData(data);

        return this.postRequest();
    }

    public update(data: ProductTypesType, id: number) {
        this.setPath('product-types');
        this.setId(id);
        this.setData(data);

        return this.putRequest();
    }

    public delete(id: number) {
        this.setPath('product-types');
        this.setId(id);

        return this.deleteRequest();
    }

    public restore(id: number) {
        this.setPath('product-types');
        this.setId(`${id}/restore`);

        return this.postRequest();
    }
}
