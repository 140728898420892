import { requiredRule, ValidateOptions } from '@.hooks';
import { BrandsData } from '@/Components/Pages/Data/Brands/Action/types';

export const validateOptions: ValidateOptions<BrandsData> = {
    name: {
        type: 'lingual',
        required: requiredRule,
    },
    image_id: {
        type: 'number',
        // required: requiredImage,
    },
};
