import React, { FC, useCallback } from 'react';

import { Api } from '@.api';
import { Layout } from '@.layout';
import { Table2 } from '@.partials';
import { LinkHelper } from '@/Services';
import { StatisticObjectEnum } from '@/Services/StatisticPermissionService';
import { Request } from '@/Types';

const useRetailChainsPage = () => {
    const fetchData = useCallback((query: Request.All) => {
        return Api.analytics().getStatisticStoreGroups(query);
    }, []);

    return {
        fetchData,
    };
};

/**
 * Same as 'store groups'.
 */
export const RetailChainsPage: FC = () => {
    const { fetchData } = useRetailChainsPage();

    return (
        <Layout name="RetailChainsPage" breadcrumbs={[{ name: 'Analytics' }, { name: 'My retail chains', current: true }]}>
            <h1>My retail chains</h1>

            <Table2 get={fetchData} getData={(data) => data.store_groups} export="store_groups">
                {{
                    items: [
                        (item) => ({
                            content: item.name,
                            name: 'Name of chain',
                            sort: 'name',
                        }),
                        (item) => ({
                            content: item.company?.street_address_1 || '',
                            name: "Address of a company's headquarters",
                            sort: 'company',
                        }),
                        (item) => ({
                            content: item.stores_count,
                            name: 'Number of stores',
                            sort: 'stores_count',
                        }),
                        (item) => ({
                            content: item.users_count,
                            name: 'Number of users',
                            sort: 'users_count',
                        }),
                    ],
                    actions: [
                        (item) => ({
                            type: 'analytics',
                            link: LinkHelper.analyticsDetailsPage(item.id, StatisticObjectEnum.STORE_GROUP),
                        }),
                        (item) => ({
                            type: 'open',
                            link: LinkHelper.analyticsMyStoresPage(item.id),
                        }),
                    ],
                }}
            </Table2>
        </Layout>
    );
};
