import { ProductTypes } from '@.pages';
import { ProductsTypesAction } from '@/Components/Pages/Data/ProductTypes/Action';

import { RoutePage } from '../types';

export const ProductTypesPage: RoutePage = {
    url: 'products-types',
    component: <ProductTypes />,
    permissions: 'authorized',
    sidebar: {
        name: 'Product types',
    },
    children: [
        {
            url: ':id',
            children: [
                {
                    url: 'edit',
                    component: <ProductsTypesAction edit />,
                },
            ],
        },
        {
            url: 'add',
            component: <ProductsTypesAction />,
        },
    ],
};
