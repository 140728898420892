import cn from 'classnames';
import React, { FC } from 'react';

import S from './styles.module.scss';

type AnalyticChartFilterInfoProps = {
    className?: string;
    filters: React.ReactNode[];
    cardsInfo: React.ReactNode[];
};

export const AnalyticChartFilterInfo: FC<AnalyticChartFilterInfoProps> = ({ cardsInfo, filters, className }) => {
    const classNames = cn(S.analytic_chart_filter_info, className);

    return (
        <div className={classNames}>
            {filters}

            <div className={S.analytic_chart_filter_info_cards}>{cardsInfo}</div>
        </div>
    );
};
