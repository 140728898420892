import { AxisBottom, AxisLeft, AxisScale, SharedAxisProps } from '@visx/axis';
import { Group } from '@visx/group';
import { useMemo } from 'react';

import { AxisProps } from './types';

const ClassNames = {
    axis: 'chart_bars__axis',
    tick: 'chart_bars__axis-tick',
    tickNoLine: 'no_line',
    horisontal: 'horisontal',
};

export const Axis = <Scale extends AxisScale>({
    children,
    width,
    isHorizontal = false,
    height,
    even,
    odd,
    nolines = false,
    borderBottomOffset = 20,
    labelStart,
    labelEnd,
    ...props
}: AxisProps<Scale>) => {
    const [axisBottomProps, axisTopProps] = useMemo<[SharedAxisProps<Scale>, SharedAxisProps<Scale>]>(() => {
        const axisProps: SharedAxisProps<Scale> = {
            axisClassName: ClassNames.axis,
            scale: props.scale,
            numTicks: props.numTicks,
        };

        if (isHorizontal) {
            axisProps.axisClassName += ` ${ClassNames.horisontal}`;
            axisProps.left = 0;
            axisProps.tickLength = height;
            axisProps.tickLineProps = { y2: `${height - 20}` };
        } else {
            axisProps.left = width;
            axisProps.tickLength = width;
        }

        return [
            {
                ...axisProps,
                tickClassName: ClassNames.tick,
                tickLabelProps: () => ({ display: 'none' }),
            },
            {
                ...axisProps,
                tickClassName: `${ClassNames.tick} ${ClassNames.tickNoLine}`,
                tickLabelProps: (_, index, values) => ({
                    dy: '0.33em',
                    textAnchor: isHorizontal && index === values.length - 1 ? 'end' : 'start',
                }),
                tickFormat: (value, index, ticks) => {
                    const isEvenIndex = index % 2 === 0;
                    const isOddIndex = index % 0 !== 0;

                    if (index === 0 && props.hideZero) {
                        return null;
                    }

                    if (index === 0 && labelStart) {
                        return labelStart;
                    }

                    if (index === ticks.length - 1 && labelEnd && !isHorizontal) {
                        return labelEnd;
                    }

                    if (isEvenIndex && even) {
                        return null;
                    }

                    if (isOddIndex && odd) {
                        return null;
                    }

                    return props.tickFormat ? props.tickFormat(value, index, ticks) : value.toString();
                },
            },
        ];
    }, [even, height, isHorizontal, labelEnd, labelStart, odd, props, width]);

    const VAxis = isHorizontal ? AxisBottom : AxisLeft;

    return (
        <Group width={width} height={height}>
            <>
                {!nolines && <VAxis {...axisBottomProps} />}
                <Group>{children}</Group>
                <Group width={width} height={height}>
                    <rect x={0} y={0} width={1} height={`${height - (isHorizontal ? borderBottomOffset : 0)}px`} className="chart_bars__axis-left" />
                    <VAxis {...axisTopProps} />
                    {isHorizontal && (
                        <rect
                            key={height}
                            x={0}
                            y={`calc(100% - ${borderBottomOffset}px)`}
                            width={width}
                            height={1}
                            style={{ position: 'absolute', bottom: 0 }}
                            className="chart_bars__axis-left"
                        />
                    )}
                </Group>
            </>
        </Group>
    );
};
