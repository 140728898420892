import { Pie } from '@visx/shape';
import cn from 'classnames';
import React, { FC } from 'react';

import { CircleDivisionsLegend } from '@/Components/Partials/Charts/CircleDivisions/CircleDivisionsLegend';
import { DefaultCircleDivisionsCenterContent } from '@/Components/Partials/Charts/CircleDivisions/DefaultCircleDivisionsCenterContent';
import { calculateTotalValue } from '@/Components/Partials/Charts/CircleDivisions/lib/calculateTotalValue';
import { CircleDivisionProps } from '@/Components/Partials/Charts/CircleDivisions/types';

import S from './styles.module.scss';

export const CircleDivisions: FC<CircleDivisionProps> = ({ data, size = 200, className, textTotal = 'total' }) => {
    const pieSortValues = (a: number, b: number) => a - b;

    const width = size;
    const height = size;

    const innerWidth = width;
    const innerHeight = height;
    const radius = width / 2 - 12;

    const centerY = innerHeight / 2;
    const centerX = innerWidth / 2;

    const top = centerY;
    const left = centerX;

    const containerClassName = cn(S.circle_divisions, className);

    return (
        <div className={containerClassName}>
            <div className={S.circle_divisions_chart}>
                <svg style={{ top, left, width, height, overflow: 'visible', position: 'relative' }}>
                    <Pie
                        data={data}
                        pieSortValues={pieSortValues}
                        outerRadius={radius}
                        innerRadius={radius + 12}
                        pieValue={(d) => d.value}
                        padAngle={0.05}
                        cornerRadius={30}
                        startAngle={Math.PI}
                        endAngle={-Math.PI}
                    >
                        {(pie) =>
                            pie.arcs.map((arc) => {
                                const { color } = arc.data;
                                const arcPath = pie.path(arc) ?? undefined;

                                return (
                                    <g key={`arc-${color}`}>
                                        <path d={arcPath} fill={color} />
                                    </g>
                                );
                            })
                        }
                    </Pie>
                    <DefaultCircleDivisionsCenterContent total={calculateTotalValue(data)} text={textTotal} />
                </svg>
            </div>
            <CircleDivisionsLegend legendData={data} />
        </div>
    );
};
