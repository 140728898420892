import { LanguageIcon } from './types';

export const Spain: LanguageIcon = ({ id = 'clip0_970_19658' }) => (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath={`url(#${id})`}>
            <path
                d="M5.45138e-06 11.0001C-0.00129612 12.306 0.230486 13.6017 0.684451 14.8262L11 15.7826L21.3156 14.8262C21.7695 13.6017 22.0013 12.306 22 11.0001C22.0013 9.69411 21.7695 8.39837 21.3156 7.17389L11 6.2175L0.684451 7.1745C0.230558 8.3988 -0.0012234 9.6943 5.45138e-06 11.0001Z"
                fill="#FFDA44"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11 0C15.73 0 19.7615 2.98528 21.3156 7.17444H0.684448C2.2385 2.98528 6.27 0 11 0ZM11 22C6.27 22 2.2385 19.0147 0.684448 14.8256H21.3156C19.7615 19.0147 15.73 22 11 22Z"
                fill="#D80027"
            />
        </g>
        <defs>
            <clipPath id={id}>
                <rect width="22" height="22" fill="white" />
            </clipPath>
        </defs>
    </svg>
);
