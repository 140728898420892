import React, { useCallback } from 'react';

import { Api } from '@.api';
import { Layout } from '@.layout';
import { Table2 } from '@.partials';
import { Auth, LinkHelper } from '@.services';
import { PermissionProps, Request } from '@.types';
import { Filter3 } from '@.ui';
import { ProductTypesResponse } from '@/Services/api/methods/productTypes/types';

export const ProductTypes: React.FC<PermissionProps> = () => {
    const auth = Auth.Use();
    // const navigator = useNavigate();

    const get = useCallback((q?: Request.All) => Api.product_types().getAll(q), []);

    const handleRestore = useCallback((id: number) => Api.product_types().restore(id), []);
    const handleDelete = useCallback((id: number) => Api.product_types().delete(id), []);

    const getData = useCallback((d: ProductTypesResponse) => d.product_types, []);
    const item = useCallback((item: ProductTypesResponse['product_types'][number]) => ({ className: item.deleted_at ? 'deleted' : '' }), []);

    return (
        <Layout name="data" breadcrumbs={[{ name: 'Product types', current: true }]}>
            <h1>Product Types</h1>
            <Table2 get={get} getData={getData} export="product_types" item={item}>
                {{
                    filters: (handler) => (
                        <Filter3 onChange={handler}>
                            <Filter3.Search />
                            {auth?.user?.is_super && <Filter3.Boolean placeholder="Archived" name="is_archived" />}
                        </Filter3>
                    ),
                    items: [
                        (item) => ({
                            name: 'Name',
                            content: item.name,
                            link: LinkHelper.dataProductTypeEditId(item.id),
                            disabled: !!item.deleted_at || !auth?.user?.is_super,
                            sort: 'name',
                        }),
                    ],
                    actions: [
                        (item) => ({
                            type: 'edit',
                            link: LinkHelper.dataProductTypeEditId(item.id),
                            disabled: !!item.deleted_at || !auth?.user?.is_super,
                        }),
                        (item) => ({
                            type: item.deleted_at ? 'restore' : 'delete',
                            onClick: item.deleted_at ? () => handleRestore(item.id) : () => handleDelete(item.id),
                            disabled: !auth?.user?.is_super,
                        }),
                    ],
                }}
            </Table2>
        </Layout>
    );
};
