import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { Layout } from '@.layout';
import { Button } from '@.ui';
import { LinkHelper } from '@/Services';

import S from './ProductProposals.module.scss';
import { ProductProposalsTable } from './ProductProposalsTable';

export const ProductProposals: FC = () => {
    const navigator = useNavigate();

    return (
        <Layout name="ProductProposals">
            <h1 className={S.PageTitle}>
                Product Proposals
                <Button color="brown" text="Create product" onClick={() => navigator(LinkHelper.productProposalsCreate)} />
            </h1>
            <ProductProposalsTable />
        </Layout>
    );
};
