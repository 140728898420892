import React, { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Controls } from './controls';
import { TabItem } from './types';

export const useTabs = <T = React.ReactNode,>(tabs: TabItem<T>[]): [React.ReactNode, T, string] => {
    const [searchParams, setSearchParams] = useSearchParams();

    const handleChange = (tab: string) => {
        searchParams.set('tab', tab);
        setSearchParams(searchParams);
    };

    const defaultTab = () => {
        const tab = tabs.find((tab) => tab.is_default === true) ?? tabs[0];

        handleChange(tab.path);

        return tab;
    };

    const currentTab = useMemo(() => {
        const param = searchParams.get('tab');

        if (!param) {
            return defaultTab();
        }

        const found = tabs.find((tab) => tab.path === param);

        if (found) {
            return found;
        }

        return defaultTab();
    }, [searchParams, tabs]);

    const controls = useMemo(() => {
        return <Controls tabs={tabs} onChange={handleChange} current={currentTab?.path} />;
    }, [searchParams]);

    const content = useMemo(() => currentTab && currentTab.content, [currentTab]);

    return [controls, content, currentTab.path];
};
