import useModal from '@fipnooone/react-modal';

import { ApproveProductProposalModal } from '@/Components/Partials/ProductProposals';
import { PendProductProposalModal } from '@/Components/Partials/ProductProposals/PendProductProposalModal';
import { RejectProductProposalModal } from '@/Components/Partials/ProductProposals/RejectProductProposalModal';
import { ProductProposal } from '@/Services/api/methods/ProductsProposals';

interface UseApproveProductProposalParameters {
    updateProductProposal: () => void;
}

export const useProductManagerActions = ({ updateProductProposal }: UseApproveProductProposalParameters) => {
    const [modalActionContent, controls] = useModal();

    const close = () => {
        controls.set(null);
        controls.close();
    };

    const openApproveModal = (productProposal: ProductProposal) => {
        controls.set(<ApproveProductProposalModal productProposal={productProposal} close={close} updateProductProposal={updateProductProposal} />);
    };

    const openPendModal = (productProposal: ProductProposal) => {
        controls.set(<PendProductProposalModal productProposal={productProposal} close={close} updateProductProposal={updateProductProposal} />);
    };

    const openRejectModal = (productProposal: ProductProposal) => {
        controls.set(<RejectProductProposalModal productProposal={productProposal} close={close} updateProductProposal={updateProductProposal} />);
    };

    return {
        modalActionContent,
        openApproveModal,
        openPendModal,
        openRejectModal,
    };
};
