import { RefObject, useEffect } from 'react';

export const useOutsideAlerter = (ref: RefObject<HTMLElement> | RefObject<HTMLElement>[], onClose: () => void, deps: React.DependencyList = []): void => {
    useEffect(() => {
        const onClick = (event: Event): void => {
            const target = event.target as Node;

            if (Array.isArray(ref)) {
                if (ref.every((r) => r.current && !r.current.contains(target))) onClose();
            } else if (ref.current && !ref.current.contains(target)) onClose();
        };

        document.addEventListener('click', onClick);

        return () => {
            document.removeEventListener('click', onClick);
        };
    }, deps);
};
