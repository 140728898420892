import { AuthContextValue } from '@.services';

import { ApiCall, ApiHandlers } from '../../ApiCall';
import { DataType, QueryType } from './types';

export abstract class Base {
    private readonly auth?: AuthContextValue;
    private path?: string = undefined;
    private data?: DataType = undefined;
    private formData?: DataType = undefined;
    private query?: QueryType = undefined;
    private id?: number | string = undefined;
    private download = false;
    /**
     * TODO: needed? maybe remove?
     */
    private requestRefresh = true;
    private handlers: ApiHandlers = {};
    protected basePath?: string;

    public constructor(auth?: AuthContextValue | null) {
        if (auth) {
            this.auth = auth;
        }
    }

    protected setData(data: DataType) {
        this.data = data;
    }

    protected setQuery<T extends QueryType = QueryType>(data: T) {
        this.query = data;
    }

    protected setPath(...path: (string | number)[]) {
        const joinedPath = path.join('/');
        this.path = this.basePath ? `${this.basePath}/${joinedPath}` : joinedPath;
    }

    protected setId(id: number | string) {
        this.id = id;
    }

    protected setDownload() {
        this.download = true;
    }

    protected setFormData(data: DataType) {
        this.formData = data;
    }

    protected setRefresh(requestRefresh: boolean) {
        this.requestRefresh = requestRefresh;
    }

    protected handleUploadProgress(fn: (event: ProgressEvent) => void) {
        this.handlers.onUploadProgress = fn;
    }

    protected baseRequest = () => {
        return new ApiCall({
            path: this.path,
            id: this.id,
            data: this.data,
            formData: this.formData,
            query: this.query,
            auth: this.auth,
            download: this.download,
            handlers: this.handlers,
        });
    };

    protected getRequest = <T>() => {
        return this.baseRequest().get<T>();
    };

    protected postRequest = <T>() => {
        return this.baseRequest().post<T>();
    };

    protected putRequest = <T>() => {
        return this.baseRequest().put<T>();
    };

    protected deleteRequest = <T>() => {
        return this.baseRequest().delete<T>();
    };
}
