import { Navigate, Outlet } from 'react-router-dom';

import { useObjectMemo } from '@.hooks';
import { Auth } from '@.services';
import { Permission } from '@.types';
import { IHasRights, User } from '@/Services/Auth/types';
import { StatisticPermissionService } from '@/Services/StatisticPermissionService';

import { Page, PageList, PageListK, PagePermissions, RoutePage, RoutePageK } from './types';

/**
 * TODO: for this condition? check on admin, or rules for every permission type?
 */
const checkPermission = (perms: PagePermissions, user: User | undefined, hasRights?: IHasRights): boolean | undefined => {
    if (!hasRights) {
        return false;
    }

    if (!perms && !user) {
        return true;
    }

    if (!user || !perms) {
        return false;
    }

    switch (typeof perms) {
        case 'object':
            if (perms.admin === false && user.is_super) {
                return false;
            }

            return hasRights(perms.entity, perms.right);
        case 'string':
            switch (perms) {
                case 'admin':
                    return user.is_super ?? false;
                case 'authorized':
                    return Boolean(user);
                case 'notAdmin':
                    return !user.is_super;
                case 'readOnly':
                    return true;
                case 'admins':
                    return user.is_company_admin || user.is_super;
            }
    }
};

/**
 * TODO: refactor.
 */
export const useAuthPages = (pageList: PageList): PageListK => {
    const auth = Auth.Use();

    const generatePage = (page: RoutePage, parentPerms?: PagePermissions): RoutePageK | null => {
        const permissions = page.permissions ?? parentPerms;

        if (typeof permissions === 'object' && permissions.admin === undefined) {
            permissions.admin = true;
        }

        const statisticPermissionService = new StatisticPermissionService(auth?.user?.statistic_object?.type || null, auth?.user?.is_super);

        const isAllowShowStatisticsObjects = statisticPermissionService.isAllowStatisticObjectPermission(page?.statisticPermissions);

        if (checkPermission(permissions, auth?.user, auth?.hasRights) && isAllowShowStatisticsObjects) {
            let { children, component, url } = page;

            if (children?.length) {
                children = [{ url: '*', component: page.component }, ...children];
                component = <Outlet />;
                url = url + '/*';
            }

            if (component === undefined) {
                component = <Navigate to="/" />;
            }

            const userPerms: Permission = {
                create: false,
                delete: false,
                read: false,
                update: false,
            };

            const setAllUserPermissions = (state = true) => (userPerms.create = userPerms.delete = userPerms.read = userPerms.update = state);

            if (permissions) {
                switch (typeof permissions) {
                    case 'object':
                        Object.keys(userPerms).forEach((key) => {
                            const name = key as keyof Permission;

                            return (userPerms[name] = auth?.hasRights(permissions.entity, name) ?? false);
                        });
                        break;
                    case 'string':
                        switch (permissions) {
                            case 'admin':
                                if (auth?.user?.is_super) {
                                    setAllUserPermissions();
                                }
                                break;
                            case 'authorized':
                                setAllUserPermissions();
                                break;
                            case 'readOnly':
                                if (auth?.user?.is_super) {
                                    setAllUserPermissions();
                                } else {
                                    userPerms.read = true;
                                }

                                break;
                        }
                        break;
                }
            }

            return {
                ...page,
                url,
                component,
                key: `Router_Page_${page.url}`,
                children: children ? generatePages(children, permissions) : undefined,
                userPermissions: {
                    ...userPerms,
                    admin: auth?.user?.is_super ?? false,
                    companyAdmin: auth?.user?.is_company_admin ?? false,
                },
            };
        }

        return null;
    };

    const generatePages = (pages: PageList, parentPerms?: PagePermissions): PageListK => {
        return pages.map((page) => generatePage(page, parentPerms)).filter(Boolean) as PageListK;
    };

    const routes: PageListK = useObjectMemo(() => {
        if (auth?.user === undefined) {
            return [];
        }

        return generatePages(pageList);
    }, [auth]);

    return routes;
};

export type { Page, PageList, PageListK, RoutePage, RoutePageK };
