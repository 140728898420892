export const fromString = (date: string) => {
    const splitted = date.split(/\.|\/|,|-|\s/gi);

    if (splitted.length < 3) return;

    const [day, month, year] = splitted;

    let parsed = Date.parse(`${day} ${month} ${year}`);

    if (isNaN(parsed)) parsed = Date.parse(`${month} ${day} ${year}`);

    if (isNaN(parsed)) return;

    return parsed;
};
