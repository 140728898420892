import { LanguageIcon } from './types';

export const Israel: LanguageIcon = ({ id = 'clip0_970_19656' }) => (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath={`url(#${id})`}>
            <path d="M11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22Z" fill="white" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.8475 2.39133H4.15862C3.15207 3.19294 2.2932 4.16411 1.62067 5.26111H20.3867C19.714 4.16418 18.8552 3.19303 17.8487 2.39133H17.8475ZM12.3811 8.60879H15.1421H15.1433L13.7622 11.0001L15.1421 13.3913H12.3811L11 15.7826L9.62012 13.3913H6.8579L8.23901 11.0001L6.85667 8.60879H9.6189L11 6.21751L12.3811 8.60879ZM11.8482 12.4692L12.6965 11.0001L11.8488 9.53095H10.1524L9.30417 11.0001L10.1518 12.4692H11.8482ZM11 8.06184L11.316 8.60879H10.6847L11 8.06184ZM9.08723 9.53095H8.45595H8.45534L8.77129 10.0779L9.08723 9.53095ZM8.45595 12.4692L8.7719 11.9222L9.08784 12.4692H8.45656H8.45595ZM10.6847 13.3913L11 13.9383L11.316 13.3913H10.6847ZM13.5441 12.4692H12.9128L13.2287 11.9222L13.5441 12.4692ZM13.5441 9.53095H12.9128H12.9122L13.2281 10.0779L13.5441 9.53095ZM17.842 19.6088H4.15312H4.15251C3.14604 18.8071 2.28718 17.836 1.61456 16.739H20.38C19.7074 17.836 18.8486 18.8072 17.842 19.6088Z"
                fill="#0052B4"
            />
        </g>
        <defs>
            <clipPath id={id}>
                <rect width="22" height="22" fill="white" />
            </clipPath>
        </defs>
    </svg>
);
