import { NewOrders, NewOrdersAction, Order, OrdersHistory } from '@.pages';

import { RoutePage } from '../types';
import { DevicesPage } from './devices';

export const OrdersPages: RoutePage[] = [
    {
        url: 'orders',
        permissions: 'readOnly',
        sidebar: {
            icon: 'orders',
            name: 'Orders',
        },
        children: [
            {
                url: 'new',
                component: <NewOrders />,
                sidebar: {
                    name: 'New products orders',
                },
                children: [
                    {
                        url: ':id',
                        component: <Order />,
                        children: [
                            {
                                url: 'edit',
                                component: <NewOrdersAction edit />,
                            },
                        ],
                    },
                    {
                        url: 'add',
                        component: <NewOrdersAction />,
                    },
                ],
            },
            DevicesPage,
            {
                url: 'history',
                component: <OrdersHistory />,
                sidebar: {
                    name: 'Orders history',
                },
            },
        ],
    },
];
