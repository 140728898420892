import { Base } from '@.api/methods';
import { UpdatePackage, UpdatePackageEditableFields } from '@/Types';

import { UpdatePackagesListRequestQuery, UpdatePackagesListResponse } from './types';

export class UpdatePackages extends Base {
    basePath = 'update-packages';

    public getAll(query?: UpdatePackagesListRequestQuery) {
        this.setPath();
        if (query) this.setQuery(query);

        return this.getRequest<UpdatePackagesListResponse>();
    }

    public create(data: UpdatePackageEditableFields) {
        this.setPath();
        this.setData(data);

        return this.postRequest<UpdatePackage>();
    }

    public getById(updatePackageId: string | number) {
        this.setPath(updatePackageId);

        return this.getRequest<UpdatePackage>();
    }

    public updateByid(updatePackageId: string | number, data: UpdatePackageEditableFields) {
        this.setPath(updatePackageId);
        this.setData(data);

        return this.putRequest<UpdatePackage>();
    }

    public deleteById(updatePackageId: string | number) {
        this.setPath(updatePackageId);

        return this.deleteRequest<UpdatePackage>();
    }

    public restoreById(updatePackageId: string | number) {
        this.setPath(updatePackageId, 'restore');

        return this.postRequest<UpdatePackage>();
    }

    public destroy(updatePackageId: string | number) {
        this.setPath(updatePackageId, 'destroy');

        return this.deleteRequest();
    }
}
