import useModal from '@fipnooone/react-modal';
import React from 'react';
import { NavLink } from 'react-router-dom';

import { Api } from '@.api';
import { ModalFeedback } from '@.modal';
import { Auth, LinkHelper } from '@.services';
import { Breadcrumb, Breadcrumbs, Icon, LanguageSelectContainer } from '@.ui';
import { UserRoleEnum } from '@/Types/api/User';

type HeaderProps = {
    name?: string;
    breadcrumbs?: Breadcrumb[];
    onClick?: () => void;
};

export const Header: React.FC<HeaderProps> = (props) => {
    const [modal, controls] = useModal();
    const auth = Auth.Use();

    const handleLogout = () => {
        Api.user().logout();
        auth?.forget();
    };

    const createHeaderUsername = (): string => {
        const userCompanyName = auth?.user?.company?.name;
        const authUsername = auth?.user?.username;
        const authUserRoleKey = auth?.user?.role?.key;

        /**
         * Super user
         */
        if (auth?.user?.is_super) {
            return 'Admin of iRomaScents';
        }

        /**
         * If user is company admin
         */
        if (auth?.user?.is_company_admin) {
            if (auth.user.company && userCompanyName) {
                return `${authUsername}, admin of ${userCompanyName}`;
            }

            return `${authUsername}, admin of company`;
        }

        if (authUserRoleKey === UserRoleEnum.PRODUCT_MANAGER && userCompanyName) {
            return `${authUsername}, product manager of ${userCompanyName}`;
        }

        if (authUserRoleKey === UserRoleEnum.SERVICE_ENGINEER && userCompanyName) {
            return `${authUsername}, service engineer of ${userCompanyName}`;
        }

        /**
         * If default user has a company
         */
        if (authUsername && userCompanyName) {
            return `${authUsername}, user of ${userCompanyName}`;
        }

        // eslint-disable-next-line no-console
        console.error("Can't detect display username text");

        return '';
    };

    return (
        <header className="header">
            {modal}
            {props.breadcrumbs ? <Breadcrumbs path={props.breadcrumbs} /> : <div></div>}
            <div className="header__actions">
                <p>{createHeaderUsername()}</p>
                <div className="header__lang">
                    <LanguageSelectContainer />
                </div>
                <div>
                    <span className="header__actions_action" onClick={() => controls?.set(<ModalFeedback />)}>
                        <Icon name="mail" size={20} />
                    </span>
                    <span className="header__actions_action" onClick={props.onClick}>
                        <Icon name="cart" size={20} />
                    </span>
                    <NavLink className="header__actions_action" to={LinkHelper.myPage}>
                        <Icon name="profile" size={20} />
                    </NavLink>
                </div>
                <div className="header__log-out" onClick={handleLogout}>
                    log out
                </div>
            </div>
        </header>
    );
};
