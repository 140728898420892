import useModal from '@fipnooone/react-modal';
import { useEffect } from 'react';

// import React from 'react';
// import { useNavigate } from 'react-router-dom';
import { ModalForbidden } from '@/Components/Modal/Dialogs/Forbidden';

export const useForbidden = () => {
    // const navigate = useNavigate();
    const [modal, controls] = useModal();

    const handleForbidden = ((event: CustomEvent) => {
        controls.set(<ModalForbidden message={event.detail.message} />);
        // navigate(LinkHelper.notFound, { replace: true, state: {} });
    }) as EventListener;

    useEffect(() => {
        document.addEventListener('forbidden-api', handleForbidden);

        return () => {
            document.removeEventListener('forbidden-api', handleForbidden);
        };
    }, []);

    return modal;
};
