import { useContext } from '@fipnooone/react-modal';

import { Icon } from '@/Components/UI/Icon';

interface InfoProps {
    data: React.ReactNode;
}

export const ModalInfo: React.FC<InfoProps> = ({ data }) => {
    const modal = useContext();

    return (
        <div className="info">
            <Icon name="close" size={20} onClick={() => modal.close()} />
            {data}
        </div>
    );
};
