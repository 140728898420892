import { Base, Response } from '@.api/methods';
import { StationFirmwareDevice } from '@/Types/stations';

import { DeviceChambersResponse, DevicesResponseType } from './types';

export class Devices extends Base {
    public getAll(query?: {
        page?: number;
        per_page?: number;
        company_id?: number | string;
        store_group_id?: number | string;
        store_id?: number | string;
        sort?: string;
        order_by?: string;
    }) {
        this.setPath('devices');
        if (query) this.setQuery(query);

        return this.getRequest() as Response<DevicesResponseType>;
    }

    public getChambers(id: number) {
        this.setPath('devices');
        this.setId(`${id}/chambers`);

        return this.getRequest() as Response<DeviceChambersResponse>;
    }

    public updateLicenseKey(deviceId: number, key: string): Response<StationFirmwareDevice> {
        this.setPath('devices');
        this.setId(`${deviceId}/license-key`);
        this.setData({ license_key: key });

        return this.putRequest<StationFirmwareDevice>();
    }
}
