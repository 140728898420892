import { useCallback, useEffect, useRef, useState } from 'react';

type callbackType<T> = (state: T) => void;

type setStateCallbackType<T> = (state: T | ((state: T) => T), callback?: callbackType<T>) => void;

interface IUseStateCallback {
    <T>(initialState: T): [T, setStateCallbackType<T>];
    <T = undefined>(initialState?: T | undefined): [T | undefined, setStateCallbackType<T | undefined>];
}

export const useStateCallback: IUseStateCallback = (initialState = undefined) => {
    type S = typeof initialState;

    const [state, setState] = useState(initialState);
    const cbRef = useRef<callbackType<S> | undefined>(undefined);

    const setStateCallback = useCallback<setStateCallbackType<S>>((value, callback) => {
        cbRef.current = callback;
        setState(value);
    }, []);

    useEffect(() => {
        if (!cbRef.current) return;

        cbRef.current(state);
        cbRef.current = undefined;
    }, [state]);

    return [state, setStateCallback];
};
