import { Base, Response } from '@.api/methods';

import { StoreGroupResponseType, StoreGroupsResponseType, StoreGroupsType } from './types';

export class StoreGroups extends Base {
    public getAll(query?: { page?: number; per_page?: number; company_id?: number; is_archived?: boolean }) {
        this.setPath('store-groups');
        if (query) this.setQuery(query);

        return this.getRequest() as Response<StoreGroupsResponseType>;
    }

    public getById(id: number) {
        this.setPath('store-groups');
        this.setId(id);

        return this.getRequest() as Response<StoreGroupResponseType>;
    }

    public create(data: StoreGroupsType) {
        this.setPath('store-groups');
        this.setData(data);

        return this.postRequest();
    }

    public update(data: StoreGroupsType, id: number) {
        this.setPath('store-groups');
        this.setId(id);
        this.setData(data);

        return this.putRequest();
    }

    public block(id: number) {
        this.setPath('store-groups');
        this.setId(`${id}/block`);

        return this.postRequest();
    }

    public unblock(id: number) {
        this.setPath('store-groups');
        this.setId(`${id}/unblock`);

        return this.postRequest();
    }
}
