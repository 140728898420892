import React, { FC, useCallback } from 'react';

import { Api } from '@.api';
import { Table2 } from '@.partials';
import { Filter3 } from '@.ui';
import { joinCollectionByProp } from '@/Lib';
import { Auth, LinkHelper, useLanguage } from '@/Services';
import { AllProductProposalsResponse, ProductProposal } from '@/Services/api/methods/ProductsProposals';
import { getLingualPropertyValue } from '@/Services/language/modules/functions/getLingualPropertyValue';
import { ProductProposalPermissionUtils } from '@/Services/ProductProposal';
import { Request } from '@/Types';

import S from './ProductProposalsTable.module.scss';

const useProductsProposals = () => {
    const fetchProductProposals = useCallback((q?: Request.All) => Api.productsProposals().fetchAll(q), []);

    const deleteProductProposal = useCallback((id: number | string) => Api.productsProposals().delete(id), []);

    const getData = useCallback((data: AllProductProposalsResponse) => data.product_proposals, []);

    const auth = Auth.Use();

    const isAllowToUpdateProductProposal = ProductProposalPermissionUtils.isAllowToUpdateProductProposal(auth?.user);

    const isAllowEditProduct = (product: ProductProposal): boolean => {
        return ProductProposalPermissionUtils.isCanEditProductProposal(product.status, auth?.user);
    };

    return {
        fetchProductProposals,
        deleteProductProposal,
        getData,
        isAllowToUpdateProductProposal,
        isAllowEditProduct,
    };
};

export const ProductProposalsTable: FC = () => {
    const [current] = useLanguage();

    const { fetchProductProposals, deleteProductProposal, getData, isAllowToUpdateProductProposal, isAllowEditProduct } = useProductsProposals();

    return (
        <div>
            <Table2 get={fetchProductProposals} getData={getData}>
                {{
                    filters: (handler) => (
                        <Filter3 onChange={handler}>
                            <Filter3.Search />
                            <Filter3.Brands related />
                            <Filter3.ProductTypes related />
                            <Filter3.Ages related />
                            <Filter3.Sexes related />
                            <Filter3.Strengths related />
                            <Filter3.Occasions related />
                            <Filter3.ProductProposalStatuses related />
                            <Filter3.Ingredients related />
                            <Filter3.Tags related />
                            <Filter3.Boolean placeholder="Tested" name="is_tested" />
                        </Filter3>
                    ),
                    items: [
                        (item) => ({
                            name: 'id',
                            content: item.id,
                            sort: 'id',
                        }),
                        (item) => ({
                            name: 'Name',
                            content: getLingualPropertyValue(item.name, current.code),
                            sort: 'id',
                        }),
                        (item) => ({
                            name: 'Companies',
                            content: item.company.name,
                            sort: 'id',
                        }),
                        (item) => ({
                            name: 'status',
                            content: <span className={S.row__status}>{item.status}</span>,
                            sort: 'status',
                        }),
                        (item) => ({
                            name: 'Reject reason',
                            content: item.reject_reason,
                        }),
                        (item) => ({
                            name: 'Brand',
                            content: item.brand.name,
                            max: 8,
                            sort: 'brand',
                        }),
                        (item) => ({
                            name: 'Type',
                            content: item.type.name,
                            max: 8,
                            sort: 'type',
                        }),
                        (item) => ({
                            name: 'Age',
                            content: joinCollectionByProp(item.ages, 'name'),
                            max: 8,
                            sort: 'ages',
                        }),
                        (item) => ({
                            name: 'Sex',
                            content: joinCollectionByProp(item.sexes, 'name'),
                            max: 8,
                            sort: 'sexes',
                        }),
                        (item) => ({
                            name: 'Strength',
                            content: item.strength.name,
                            max: 8,
                            sort: 'strength',
                        }),
                        (item) => ({
                            name: 'Occasion',
                            content: joinCollectionByProp(item.occasions, 'name'),
                            max: 8,
                            sort: 'occasions',
                        }),
                        (item) => ({
                            name: 'Ingredients',
                            content: joinCollectionByProp(item.ingredients, 'name'),
                            max: 8,
                            sort: 'ingredients',
                        }),
                        (item) => ({
                            name: 'Characters',
                            content: joinCollectionByProp(item.characters, 'name'),
                            max: 8,
                            sort: 'characters',
                        }),
                        (item) => ({
                            name: 'Tag',
                            content: joinCollectionByProp(item.tags, 'name'),
                            max: 8,
                            sort: 'tags',
                        }),
                    ],
                    actions: [
                        (item) => ({
                            type: 'edit',
                            link: LinkHelper.productProposalsEdit(item.id),
                            disabled: !isAllowEditProduct(item),
                        }),
                        (item) => ({
                            type: 'open',
                            link: LinkHelper.productProposalsDetail(item.id),
                        }),
                    ],
                }}
            </Table2>
        </div>
    );
};
