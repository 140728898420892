import cn from 'classnames';
import React, { FC } from 'react';

import { ButtonWrapper } from '@.ui';

import S from './styles.module.scss';

interface CheckboxPickerProps {
    value?: (number | string)[];
    onChange?: (value: (string | number)[]) => void;
    checkboxValues: { [key: string]: number | string };
    classNames?: string;
    errors?: string[];
}

export const CheckboxPicker: FC<CheckboxPickerProps> = ({ checkboxValues, onChange, value: values = [], classNames, errors }) => {
    const handleChange = (value: string | number, isActive: boolean) => {
        if (onChange && values) {
            if (isActive) {
                onChange(values.filter((item) => item !== value));
            } else {
                onChange([...values, value]);
            }
        }
    };

    return (
        <div>
            {errors && <span className={S.errors}>{errors[0]}</span>}
            <div className={cn(S.switcher, errors && S.switcher__errors, classNames)}>
                {Object.entries(checkboxValues).map(([key, value]) => {
                    const isActive = (values as (typeof value)[])?.includes(value);
                    return (
                        <ButtonWrapper onClick={() => handleChange(value, isActive)} key={`switcher-item-${key}`} contents>
                            <div
                                className={cn(S.switcher__item, {
                                    [S.active]: isActive,
                                })}
                            >
                                {key}
                            </div>
                        </ButtonWrapper>
                    );
                })}
            </div>
        </div>
    );
};
