import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { Api } from '@.api';
import { useForm } from '@.hooks';
import { Auth, LinkHelper } from '@.services';
import { Button, Checkbox, Input } from '@.ui';
import { Form } from '@/Components/Partials';
import { navigateUserToDefaultPage } from '@/Lib';

import { LoginData } from './types';
import { validateOptions } from './validate';

export const Login: React.FC = () => {
    const [isRemember, setIsRemember] = useState<boolean>(false);

    const auth = Auth.Use();

    const [field, form] = useForm<LoginData>();

    const handleSubmit = () => {
        form.reset.errors();

        form.ifValid((data) => {
            Api.user()
                .login({
                    email: data.email,
                    password: data.password,
                })
                .onSuccess((res) => {
                    const { refresh_token, access_token, is_company_admin, is_super, permissions, name, company, username, statistic_object, role } = res.data;

                    auth?.remember(
                        {
                            tokens: { refresh: refresh_token, access: access_token },
                            is_company_admin,
                            is_super,
                            permissions,
                            name,
                            company,
                            username,
                            statistic_object,
                            role,
                        },
                        isRemember
                    );

                    navigateUserToDefaultPage(role.key);
                })
                .onValidationError((res) => form.set.errors(res.errors))
                .onUnauthorized((res) => form.set.errors(res?.message));
        }, validateOptions);
    };

    const toggleRememberMe = () => setIsRemember((prev) => !prev);

    return (
        <div className="login">
            <Form id="login" onSubmit={handleSubmit} className="login__form">
                <div className="login__logo">logo</div>
                <h2>Welcome</h2>
                <p>Please enter your login information</p>
                <Input {...field('email').register()} type="email" text="Email" color="secondary" name="email" required />
                <Input {...field('password').register()} type="password" text="Password" color="secondary" name="password" required />
                {form.state.error && <p className="login__error">{form.state.error}</p>}
                <div className="login__form-container">
                    <Checkbox text="Remember me" onClick={toggleRememberMe} />
                    <Link to={LinkHelper.passwordRecovery}>Forgot password?</Link>
                </div>
                <Button text="Log In" color="brown" type="submit" />
            </Form>
        </div>
    );
};
