import { Dashboard } from '@.pages';
import { PopularProducts, PopularSpecialOffers, PopularStores, WizardAnswers } from '@/Components/Pages/Dashboard/more';
import { Routes } from '@/Routes/types';

export const DashboardRoutes: Routes = [
    {
        url: 'dashboard',
        permissions: 'authorized',
        component: <Dashboard />,
        sidebar: {
            name: 'Dashboard',
            icon: 'dashboard',
        },
        children: [
            {
                url: 'wizard-answers',
                component: <WizardAnswers />,
            },
            {
                url: 'popular-products',
                component: <PopularProducts />,
            },
            {
                url: 'popular-stores',
                component: <PopularStores />,
            },
            {
                url: 'popular-special-offers',
                component: <PopularSpecialOffers />,
            },
        ],
    },
];
