import { Devices as DevicesPage } from '@/Components/Pages/Devices';

import { Routes } from '../types';

export const Devices: Routes = [
    {
        url: 'devices',
        permissions: 'authorized',
        component: <DevicesPage />,
    },
];
