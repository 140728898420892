import { Text } from '@visx/text';
import React, { useEffect, useMemo, useState } from 'react';

import { Device } from '@/Components/Pages/Stations/Station/tabs/statistics/parts';
import { Board, DonutLegendType, WhiffsCircle } from '@/Components/Partials';
import { Donut } from '@/Components/Partials/Charts';
import { Api } from '@/Services/api';
import { StationStatisticsResponse } from '@/Services/api/methods';
import { ColorUtils } from '@/Services/ColorUtils';

import { StationTab } from '../types';
import { GraphItem } from './types';

const colors = ['#D3DC6C', '#BCCC80', '#A3CA74', '#73B962', '#F5CC61', '#F6AA59', '#ED715D', '#E95283', '#66C0BE', '#D9D9D9'];

export const Statistics: StationTab = ({ station, filters }) => {
    const [statistic, setStatistic] = useState<StationStatisticsResponse | null>(null);

    useEffect(() => {
        if (!station?.id || !filters || Object.keys(filters).length === 0) return;

        Api.stations()
            .getStatistics(station?.id, filters)
            .onSuccess((res) => setStatistic(res.data));
    }, [station?.id, filters]);

    const [graphData, productsTotal] = useMemo<[GraphItem[], number]>(() => {
        if (!statistic || !statistic.top_products.length) {
            return [[], 0];
        }

        const allColors = Array.from({ length: statistic.top_products.length }, (v, index) => colors[index] ?? ColorUtils.randomHexColor());

        const graphData: GraphItem[] = statistic.top_products
            .sort((a, b) => b.whiffs_count - a.whiffs_count)
            .map((item, index) => ({ name: item.name, color: allColors[index], value: item.whiffs_count }));

        const productsTotal = statistic.top_products.reduce((acc, product) => acc + product.whiffs_count, 0);

        return [graphData, productsTotal];
    }, [statistic]);

    const legend = useMemo<DonutLegendType>(
        () => ({
            columns: 2,
            legendItems: graphData.map((data) => ({
                label: data.name + ` ${((data.value / productsTotal) * 100).toFixed(1)}%`,
                color: data.color,
            })),
        }),
        [graphData, productsTotal]
    );

    const ContentCenter = (
        <Text fontFamily="DM Sans" fontWeight={500} fontSize={20} lineHeight={26} fill="#000" textAnchor="middle" verticalAnchor="middle">
            100%
        </Text>
    );

    const isDisplayDonutChart = graphData.length > 0;

    return (
        statistic && (
            <>
                <Board.Line>
                    <Board.Item size="1/3">
                        <h3>Number of whiffs</h3>
                        <WhiffsCircle total={statistic?.whiffs_count} noStats />
                    </Board.Item>
                    <Board.Item size="2/3">
                        <h3>Top of products</h3>
                        {isDisplayDonutChart && (
                            <div className="station_statistics">
                                <Donut data={graphData} legend={legend} contentCenter={ContentCenter} />
                            </div>
                        )}
                    </Board.Item>
                </Board.Line>
                <Board.Line>
                    {statistic?.devices.map((device, index) => {
                        return <Device device={device} index={index} />;
                    })}
                </Board.Line>
            </>
        )
    );
};
