import { useState } from 'react';

import { Api } from '@.api';
import { useId } from '@.hooks';
import { Layout } from '@.layout';
import { Checkbox } from '@.ui';
import { LinkHelper } from '@/Services';
import { Tools } from '@/Services';
import { AuthLogResponse } from '@/Services/api/methods/authLogs/types';

export const AuthLog: React.FC = () => {
    const [log, setLog] = useState<AuthLogResponse>();
    const get = (id: number) => {
        return Api.auth_logs()
            .getById(id)
            .onSuccess((res) => setLog(res.data));
    };

    useId(get);

    return (
        <Layout
            breadcrumbs={[
                { name: 'Auth logs', link: LinkHelper.authLogs },
                { name: `#${log?.id}`, current: true },
            ]}
        >
            {log && (
                <>
                    <h1>log #{log?.id}</h1>
                    <div className="entity-page">
                        <div className="entity-page__item">
                            <div className="bold">Time</div>
                            <div>{log?.created_at ? Tools.date.autoFormat(log?.created_at * 1000) : ''}</div>
                        </div>
                        <div className="entity-page__item">
                            <div className="bold">User</div>
                            <div>{log?.login}</div>
                        </div>
                        <div className="entity-page__item">
                            <div className="bold">Success</div>
                            <div>
                                <Checkbox value={log?.is_successfully} isDisabled />
                            </div>
                        </div>
                    </div>
                </>
            )}
        </Layout>
    );
};
