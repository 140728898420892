import React, { FC } from 'react';

import { CircleDivisionData, Dot } from '@.partials';

import S from './styles.module.scss';

type CircleDivisionsLegendProps = {
    legendData: CircleDivisionData[];
};

export const CircleDivisionsLegend: FC<CircleDivisionsLegendProps> = ({ legendData }) => {
    return (
        <div className={S.circle_divisions_legend}>
            {legendData.map((legend, index) => (
                <div key={index} className={S.circle_divisions_legend_item}>
                    <Dot color={legend.color} />
                    <div>
                        <p>{legend.label}</p>
                        <p>{legend.value}</p>
                    </div>
                </div>
            ))}
        </div>
    );
};
