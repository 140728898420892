import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Api } from '@.api';
import { StoreResponseType } from '@.api/methods';
import { Layout } from '@.layout';
import { Table, TableColumn, TableRow } from '@.partials';
import { LinkHelper } from '@.services';
import { Button } from '@.ui';
import { PermissionProps } from '@/Types';

export const Store: React.FC<PermissionProps> = (props) => {
    const [store, setStore] = useState<StoreResponseType>();
    const navigator = useNavigate();
    const { id } = useParams();

    const updateStore = () => {
        Api.stores()
            .getById(Number(id))
            .onSuccess((res) => {
                setStore(res.data);
            });
    };

    const handleDelete = () => {
        Api.stores()
            .delete(Number(id))
            .onSuccess(() => updateStore());
    };

    const handleRestore = () => {
        Api.stores()
            .restore(Number(id))
            .onSuccess(() => updateStore());
    };

    useEffect(() => {
        updateStore();
    }, []);

    return (
        <Layout
            name="clients"
            breadcrumbs={[
                { name: 'Stores', link: LinkHelper.clientsStores },
                { name: store?.name ?? '', current: true },
            ]}
        >
            {store && (
                <>
                    <h1>
                        {store?.name}
                        <div>
                            <Button
                                color="transparent"
                                text={store?.deleted_at ? 'Restore' : 'Archive'}
                                onClick={store?.deleted_at ? handleRestore : handleDelete}
                                disabled={!props.permissions?.delete}
                            />
                            <Button
                                color="brown"
                                text="Edit"
                                onClick={() => navigator(LinkHelper.clientsStoresEditId(Number(id)))}
                                disabled={!!store?.deleted_at || !props.permissions?.update}
                            />
                        </div>
                    </h1>
                    <Table>
                        <TableRow>
                            <TableColumn bold>Company</TableColumn>
                            <TableColumn>{store?.company.name}</TableColumn>
                        </TableRow>
                        <TableRow>
                            <TableColumn bold>Store group</TableColumn>
                            <TableColumn>{store?.store_group.name}</TableColumn>
                        </TableRow>
                        <TableRow>
                            <TableColumn bold>Address</TableColumn>
                            <TableColumn>{store?.street_address_1}</TableColumn>
                        </TableRow>
                        <TableRow>
                            <TableColumn bold>Sales</TableColumn>
                            <TableColumn>{store?.sales_count}</TableColumn>
                        </TableRow>
                        <TableRow>
                            <TableColumn bold>Whiffs</TableColumn>
                            <TableColumn>{store?.whiffs_count}</TableColumn>
                        </TableRow>
                        <TableRow>
                            <TableColumn bold>Connected iRomaScents Station</TableColumn>
                            <TableColumn>{store?.stations_count}</TableColumn>
                        </TableRow>
                        <TableRow>
                            <TableColumn bold>PIN</TableColumn>
                            <TableColumn>{store?.pin}</TableColumn>
                        </TableRow>
                        <TableRow>
                            <TableColumn bold>Secret code</TableColumn>
                            <TableColumn>{store?.secret_code}</TableColumn>
                        </TableRow>
                        <TableRow>
                            <TableColumn bold>Contact person info</TableColumn>
                            <TableColumn>{[store?.agent_name, store?.agent_phone, store?.agent_email].join(', ')}</TableColumn>
                        </TableRow>
                    </Table>
                </>
            )}
        </Layout>
    );
};
