import { FC } from 'react';

import { useLanguage } from '@/Services';

import { LanguageSelect } from './render';

export const LanguageSelectContainer: FC = () => {
    const [current, setCurrent] = useLanguage();

    return <LanguageSelect current={current} onChange={setCurrent} key={'current-language-select'} />;
};

export { LanguageSelect as LanguageSelectBase };
