import { useContext } from '@fipnooone/react-modal';

import { Button, Icon } from '@/Components/UI';

interface UnsavedProps {
    onCancel?: () => void;
    onDiscard?: () => void;
    onSave?: () => void;
}

export const ModalUnsaved: React.FC<UnsavedProps> = ({ onCancel, onDiscard, onSave }) => {
    const modal = useContext();

    const handleCancel = () => {
        onCancel && onCancel();
        modal.close();
    };

    const handleDiscard = () => {
        onDiscard && onDiscard();
    };

    const handleSave = () => {
        onSave && onSave();
    };

    return (
        <div className="unsaved">
            <Icon name="close" size={20} onClick={() => modal.close()} />
            <h1>You have unsaved changes that will be lost</h1>
            <div className="unsaved__buttons">
                <Button text="Discard" color="dark-gray" onClick={handleDiscard} />
                <Button text="Save" color="brown" onClick={handleSave} />
            </div>
            <Button text="Cancel" color="gray-border" onClick={handleCancel} />
        </div>
    );
};
