import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Api } from '@.api';
import { StoreGroupResponseType } from '@.api/methods';
import { Layout } from '@.layout';
import { StoresTable, UsersTable } from '@.pages';
import { Tab, Table, TableColumn, TableRow, Tabs } from '@.partials';
import { LinkHelper } from '@.services';
import { Button } from '@.ui';
import { PermissionProps } from '@/Types';

export const StoreGroup: React.FC<PermissionProps> = (props) => {
    const [group, setGroup] = useState<StoreGroupResponseType>();
    const navigator = useNavigate();
    const { id } = useParams();

    const updateGroup = () => {
        Api.store_groups()
            .getById(Number(id))
            .onSuccess((res) => {
                setGroup(res.data);
            });
    };

    const handleBlock = () => {
        Api.store_groups()
            .block(Number(id))
            .onSuccess(() => updateGroup());
    };

    const handleUnblock = () => {
        Api.store_groups()
            .unblock(Number(id))
            .onSuccess(() => updateGroup());
    };

    useEffect(() => {
        if (id) {
            updateGroup();
        }
    }, []);

    return (
        <Layout
            name="clients"
            breadcrumbs={[
                { name: 'Store groups', link: LinkHelper.clientsStoreGroups },
                { name: group?.name ?? '', current: true },
            ]}
        >
            {group && (
                <>
                    <h1>
                        {group?.name}
                        <div>
                            <Button
                                color="transparent"
                                text={group?.status.name === 'Active' ? 'Block' : 'Unblock'}
                                onClick={group?.status.name === 'Active' ? handleBlock : handleUnblock}
                                disabled={!props.permissions?.update}
                            />
                            <Button
                                color="brown"
                                text="Edit"
                                onClick={() => navigator(LinkHelper.clientsStoreGroupsEditId(Number(id)))}
                                disabled={!props.permissions?.update}
                            />
                        </div>
                    </h1>
                    <Tabs>
                        <Tab is_default name="General" path="general">
                            <Table>
                                <TableRow>
                                    <TableColumn bold>Company</TableColumn>
                                    <TableColumn>{group?.company.name}</TableColumn>
                                </TableRow>
                                <TableRow>
                                    <TableColumn bold>Number of stores</TableColumn>
                                    <TableColumn>{group?.stores_count}</TableColumn>
                                </TableRow>
                                <TableRow>
                                    <TableColumn bold>Number of users</TableColumn>
                                    <TableColumn>{group?.users_count}</TableColumn>
                                </TableRow>
                            </Table>
                        </Tab>
                        <Tab name="Stores" path="stores">
                            <StoresTable store_group_id={group?.id} filters={['Companies', 'StoreStatuses']} hideActions={['update', 'delete']} />
                        </Tab>
                        <Tab name="Users" path="users">
                            <UsersTable store_group_id={group?.id} filters={['Roles', 'UserStatuses']} hideActions={['update']} />
                        </Tab>
                    </Tabs>
                </>
            )}
        </Layout>
    );
};
