import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Api } from '@.api';
import { StationLogResponse } from '@.api/methods';
import { Layout } from '@.layout';
import { Icon } from '@.ui';
import { LinkHelper } from '@/Services';
import { Tools } from '@/Services';

export const StationLog: React.FC = () => {
    const [log, setLog] = useState<StationLogResponse>();
    const { id, log_id } = useParams();
    const navigator = useNavigate();

    useEffect(() => {
        if (!id || !log_id) return;

        Api.stations()
            .getLogById(BigInt(id), BigInt(log_id))
            .onSuccess((res) => setLog(res.data));
    }, []);

    return (
        <Layout
            breadcrumbs={[
                { name: 'Stations', link: LinkHelper.stations },
                { name: `${log?.id}` ?? '', current: true },
            ]}
        >
            {log && (
                <>
                    <h1>
                        log #{log?.id}
                        <div className="back" onClick={() => navigator(-1)}>
                            <Icon name="chevron-right" />
                            <span>Back</span>
                        </div>
                    </h1>
                    <div className="entity-page">
                        <div className="entity-page__item">
                            <div className="bold">Created at</div>
                            <div>{Tools.date.format(log.created_at, 'DD.MM.YYYY hh:mm:ss')}</div>
                        </div>
                        <div className="entity-page__item">
                            <div className="bold">Type</div>
                            <div>{log.type.name}</div>
                        </div>
                        <div className="entity-page__item">
                            <div className="bold">Description</div>
                            <div>{log.response_message ? Tools.string.lineWrap(log.response_message) : ''}</div>
                        </div>
                    </div>
                </>
            )}
        </Layout>
    );
};
