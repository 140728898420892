import { Code } from '@.api';
import { ICreateFn } from '@.api/methods';

export const createResponse: ICreateFn = (promise) => {
    return {
        onSuccess: (fn) => {
            promise.then((res) => {
                if (res.code === Code.Success) fn(res);
            });
            return createResponse(promise);
        },
        onCreated: (fn) => {
            promise.then((res) => {
                if (res.code === Code.Created) fn(res);
            });
            return createResponse(promise);
        },
        onErrors: (fn) => {
            promise.then((res) => {
                if (
                    res.code === Code.Error ||
                    res.code === Code.Forbidden ||
                    res.code === Code.Local ||
                    res.code === Code.NotFound ||
                    res.code === Code.ValidationError
                )
                    fn(res);
            });
            return createResponse(promise);
        },

        onUnauthorized: (fn) => {
            promise.then((res) => {
                if (res.code === Code.Unauthorized) fn(res);
            });
            return createResponse(promise);
        },
        onForbidden: (fn) => {
            promise.then((res) => {
                if (res.code === Code.Forbidden) fn(res);
            });
            return createResponse(promise);
        },
        onError: (fn) => {
            promise.then((res) => {
                if (res.code === Code.Error) fn(res);
            });
            return createResponse(promise);
        },
        onNotFound: (fn) => {
            promise.then((res) => {
                if (res.code === Code.NotFound) fn(res);
            });
            return createResponse(promise);
        },
        onValidationError: (fn) => {
            promise.then((res) => {
                if (res.code === Code.ValidationError) fn(res);
            });
            return createResponse(promise);
        },
        onLocal: (fn) => {
            promise.then((res) => {
                if (res.code === Code.Local) fn(res);
            });
            return createResponse(promise);
        },
        onCode: (codes, fn) => {
            promise.then((res) => {
                if (codes.includes(res.code)) fn(res);
            });
            return createResponse(promise);
        },
        then: (fn) => {
            promise.then((res) => fn(res));
            return createResponse(promise);
        },
        catch: (fn) => {
            promise.then((res) => fn(res));
            return createResponse(promise);
        },
    };
};
