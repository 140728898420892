import { Maybe } from '@/Types/Maybe';

export enum Switch {
    Day = 'day',
    Month = 'month',
    Year = 'year',
}

export interface SwitcherProps {
    value?: Maybe<Switch>;
    initialValue?: Switch;
    onChange?: (value: Switch) => void;
}
