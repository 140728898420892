import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { Api } from '@.api';
import { Layout } from '@.layout';
import { Table2 } from '@.partials';
import { Auth, LinkHelper } from '@.services';
import { PermissionProps, Request } from '@.types';
import { Button, Filter3 } from '@.ui';
import { CurrenciesResponseType } from '@/Services/api/methods';

export * from './Action';

export const Currencies: React.FC<PermissionProps> = (props) => {
    const auth = Auth.Use();
    const navigator = useNavigate();

    const get = useCallback((q?: Request.All) => Api.currencies().getAll(q), []);

    const handleDelete = useCallback((id: number) => Api.currencies().delete(id), []);
    const handleRestore = useCallback((id: number) => Api.currencies().restore(id), []);

    const getData = useCallback((d: CurrenciesResponseType) => d.currencies, []);
    const item = useCallback((item: CurrenciesResponseType['currencies'][number]) => ({ className: item.deleted_at ? 'deleted' : '' }), []);

    return (
        <Layout name="data" breadcrumbs={[{ name: 'Currencies', current: true }]}>
            <h1>
                Currencies
                <Button color="brown" text="Add currency" onClick={() => navigator(LinkHelper.dataCurrenciesAdd)} disabled={!props.permissions?.create} />
            </h1>
            <Table2 get={get} getData={getData} export="currencies" item={item}>
                {{
                    filters: (handler) => (
                        <Filter3 onChange={handler}>{auth?.user?.is_super && <Filter3.Boolean placeholder="Archived" name="is_archived" />}</Filter3>
                    ),
                    items: [
                        (item) => ({
                            name: 'Sign',
                            content: item.sign,
                            sort: 'sign',
                        }),
                        (item) => ({
                            name: 'ISO Code',
                            content: item.iso_code,
                            sort: 'iso_code',
                        }),
                        (item) => ({
                            name: 'Name',
                            content: item.name,
                            link: LinkHelper.dataCurrencyEditId(item.id),
                            disabled: !!item.deleted_at || !props.permissions?.update,
                            sort: 'name',
                        }),
                    ],
                    actions: [
                        (item) => ({
                            type: 'edit',
                            link: LinkHelper.dataCurrencyEditId(item.id),
                            disabled: !!item.deleted_at || !props.permissions?.update,
                        }),
                        (item) => ({
                            type: item.deleted_at ? 'restore' : 'delete',
                            onClick: item.deleted_at ? () => handleRestore(item.id) : () => handleDelete(item.id),
                            disabled: !props.permissions?.delete,
                        }),
                    ],
                }}
            </Table2>
        </Layout>
    );
};
