import cn from 'classnames';
import React, { CSSProperties, FC } from 'react';

import S from './styles.module.scss';

type DotProps = {
    color: string;
    className?: string;
};

/**
 * Just color dot.
 */
export const Dot: FC<DotProps> = ({ color, className }) => {
    const dotCSSProperties: CSSProperties = {
        backgroundColor: color,
    };

    const classNames = cn(S.dot, className);

    return <div className={classNames} style={dotCSSProperties} />;
};
