import cn from 'classnames';
import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import { useObjectEffect } from '@/Components/Hooks';
import { Board, WhiffsCircle } from '@/Components/Partials';
import { Icon } from '@/Components/UI';
import { LinkHelper } from '@/Services';
import { Api } from '@/Services/api';
import { MainInfoResponse } from '@/Services/api/methods/dashboard/types';

import { DashboardItem } from '../types';
import S from './styles.module.scss';
import { Wisard } from './wisard';

export const IndexWizard: DashboardItem = ({ filters }) => {
    const [index, setIndex] = useState<MainInfoResponse>();

    useObjectEffect(() => {
        Api.dashboard()
            .index(filters)
            .onSuccess((res) => setIndex(res.data));
    }, [filters]);

    const filtersParams = useMemo(() => {
        const params = new URLSearchParams();

        if (!filters) return params;

        if (filters.company_id) params.append('company_id', filters.company_id.toString());
        if (filters.store_group_id) params.append('store_group_id', filters.store_group_id.toString());
        if (filters.store_id) params.append('store_id', filters.store_id.toString());

        if (params.size === 0) return '';

        return `?${params}`;
    }, [filters]);
    return (
        <>
            <Board.Line>
                <Board.Item size="1/3" className={S.block}>
                    <Link to={`${LinkHelper.stations}${filtersParams}`} className={cn(S.item, S.item_link)}>
                        <Icon name="tablet" size={50} />
                        <div>
                            <h2>{index?.installed_tablets}</h2>
                            <h5>Installed tablets</h5>
                        </div>
                    </Link>
                </Board.Item>
                <Board.Item size="1/3" className={S.block}>
                    <Link to={`${LinkHelper.devices}${filtersParams}`} className={cn(S.item, S.item_link)}>
                        <Icon name="module" size={50} />
                        <div>
                            <h2>{index?.connected_devices}</h2>
                            <h5>Connected devices</h5>
                        </div>
                    </Link>
                </Board.Item>
                <Board.Item size="1/3" className={S.block}>
                    <Link to={`${LinkHelper.dataProducts}?is_tested=true`} className={cn(S.item, S.item_link)}>
                        <Icon name="parfume" size={50} />
                        <div>
                            <h2>{index?.tested_products}</h2>
                            <h5>Tested products</h5>
                        </div>
                    </Link>
                </Board.Item>
            </Board.Line>
            <Board.Line>
                <Board.Item size="1/3" title="Work of devices">
                    {index?.devices_work && (
                        <WhiffsCircle
                            total={index?.devices_work.whiffs}
                            active={index?.devices_work.active}
                            errors={index?.devices_work.errors_count}
                            failovers={index?.devices_work.failovers_count}
                            inactive={index?.devices_work.inactive}
                            warnings={index?.devices_work.warnings_count}
                        />
                    )}
                </Board.Item>
                <Wisard filters={filters} />
            </Board.Line>
        </>
    );
};
