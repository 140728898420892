import { SystemLogs } from '@/Components/Pages/Logs';
import { SystemLog } from '@/Components/Pages/Logs/SystemLogs/Log';
import { RoutePage } from '@/Routes/types';

export const SystemLogsPage: RoutePage = {
    url: 'system-logs',
    component: <SystemLogs />,
    sidebar: {
        name: 'System logs',
    },
    permissions: 'admin',
    children: [
        {
            url: ':id',
            component: <SystemLog />,
            permissions: 'admin',
        },
    ],
};
