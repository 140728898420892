import { Base, ProductsQuery, ProductsResponseType, Response, SpecialOffersResponseType } from '@.api/methods';
import { FilterData } from '@/Components/UI';
import {
    DashboardNewOrdersResponse,
    DashboardNewProductsResponse,
    DashboardProductsResponse,
    DashboardSearchesResponse,
    DashboardSpecialOffersResponse,
    DashboardStoresDetailedResponse,
    DashboardStoresResponse,
    DashboardWizardAnswersDetailedResponse,
    DashboardWizardAnswersResponse,
    MainInfoResponse,
} from '@/Services/api/methods/dashboard/types';
import { Request } from '@/Types';

export class Dashboard extends Base {
    public index(query?: FilterData) {
        this.setPath('dashboard');
        query && this.setQuery(query);

        return this.getRequest() as Response<MainInfoResponse>;
    }

    public wizard(query?: FilterData) {
        this.setPath('dashboard/wizard-answers');
        query && this.setQuery(query);

        return this.getRequest<DashboardWizardAnswersResponse>();
    }

    public wizardDetailed(query?: { page?: number; per_page?: number; search?: string; step_id?: number; start_date?: number; end_date?: number }) {
        this.setPath('dashboard/wizard-answers/detailed');
        if (query) this.setQuery(query);

        return this.getRequest<DashboardWizardAnswersDetailedResponse>();
    }

    public products(query?: FilterData) {
        this.setPath('dashboard/products');
        query && this.setQuery(query);

        return this.getRequest<DashboardProductsResponse>();
    }

    public productsDetailed(query?: ProductsQuery) {
        this.setPath('dashboard/products/detailed');
        if (query) this.setQuery(query);

        return this.getRequest<ProductsResponseType>();
    }

    public stores(query?: FilterData) {
        this.setPath('dashboard/stores');
        query && this.setQuery(query);

        return this.getRequest<DashboardStoresResponse>();
    }

    public storesDetailed(query?: { page?: number; per_page?: number; search?: string; company_id?: number; store_group_id?: number; store_id?: number }) {
        this.setPath('dashboard/stores/detailed');
        if (query) this.setQuery(query);

        return this.getRequest<DashboardStoresDetailedResponse>();
    }

    public specialOffers(query?: FilterData) {
        this.setPath('dashboard/special-offers');
        query && this.setQuery(query);

        return this.getRequest<DashboardSpecialOffersResponse>();
    }

    public specialOffersDetailed(query?: Request.All) {
        this.setPath('dashboard/special-offers/detailed');
        if (query) this.setQuery(query);

        return this.getRequest<SpecialOffersResponseType>();
    }

    public newProducts(query?: FilterData) {
        this.setPath('dashboard/new-products');
        query && this.setQuery(query);

        return this.getRequest<DashboardNewProductsResponse>();
    }

    public newOrders(query?: FilterData) {
        this.setPath('dashboard/new-orders');
        query && this.setQuery(query);

        return this.getRequest<DashboardNewOrdersResponse>();
    }

    public searches(query?: FilterData) {
        this.setPath('dashboard/searches');
        query && this.setQuery(query);

        return this.getRequest<DashboardSearchesResponse>();
    }
}
