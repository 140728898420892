import React, { useEffect } from 'react';
import { useState } from 'react';

import { useTrustedState } from '@.hooks';
import { BaseInput, Icon } from '@.ui';

import { InputProps } from './types';

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
    ({ color, errors, onChange, onClick, placeholder, readOnly, text, type, value, defaultValue, name, regex, required, ...props }, ref) => {
        const [inputText, setInputText, isTrusted] = useTrustedState<string>(value ?? '');
        const [isHide, setIsHide] = useState<boolean>(true);

        const handleChange = (value: string) => {
            setInputText(value, true);
        };

        const handleInputChange = (event: React.ChangeEvent<HTMLInputElement & HTMLTextAreaElement>) => {
            const value = event.target.value;

            if (value && regex && !regex.test(value)) {
                event.preventDefault();
                return;
            }

            handleChange(value);
        };

        const handleHideChange = () => {
            setIsHide((prev) => !prev);
        };

        useEffect(() => {
            setInputText(value ?? '', false);
        }, [value]);

        useEffect(() => {
            if (isTrusted && onChange) onChange(inputText);
        }, [inputText]);

        return (
            <BaseInput defaultValue={defaultValue} name={name} className={errors?.length ? ' error' : ''}>
                <div className={'input-block' + (color ? ` ${color}` : '')}>
                    <label className="input-block__label">
                        {errors?.[0] ?? text ?? ''}
                        {required && <span>*</span>}
                    </label>
                    <input
                        {...props}
                        type={type !== 'password' ? type : isHide ? 'password' : 'text'}
                        placeholder={placeholder}
                        onChange={handleInputChange}
                        readOnly={readOnly}
                        onClick={onClick}
                        ref={ref}
                        value={inputText}
                    />
                    {type === 'password' && <Icon name={isHide ? 'eye-off' : 'eye'} className={isHide ? 'opacity' : ''} onClick={handleHideChange} />}
                </div>
            </BaseInput>
        );
    }
);
