import { NotFound } from '@.pages';

import { Routes } from '../types';

export const NotFoundPages: Routes = [
    {
        url: '404',
        component: <NotFound />,
    },
    {
        url: '404',
        permissions: 'authorized',
        component: <NotFound />,
    },
];
