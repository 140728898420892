import useModal from '@fipnooone/react-modal';
import cn from 'classnames';
import { FC, useCallback } from 'react';

import { Layout } from '@/Components/Layouts';
import { ModalConfirm } from '@/Components/Modal/Dialogs';
import { Table2 } from '@/Components/Partials';
import { TableRowOptions } from '@/Components/Partials/Table.V2/types';
import { Button, Filter3 } from '@/Components/UI';
import { LinkHelper, Tools } from '@/Services';
import { Api } from '@/Services/api';
import { UpdatePackagesListResponse } from '@/Services/api/methods/updatePackages/types';
import { PermissionProps, Request, UpdatePackage } from '@/Types';

import { useBreadcrumbs } from '../useBreadcrumbs';

export const PackagesUpdateList: FC<PermissionProps> = ({ permissions }) => {
    const [modal, modalControls] = useModal();
    const breadcrumbs = useBreadcrumbs({
        type: 'list',
    });

    const handleRestoreClick = useCallback((id: number) => Api.updatePackages().restoreById(id), []);
    const handleDeleteClick = useCallback((id: number) => Api.updatePackages().deleteById(id), []);
    const handleDestroy = useCallback((id: number) => Api.updatePackages().destroy(id), []);

    const handleDestroyConfirm = useCallback(
        (id: number) => {
            return new Promise<unknown>((resolve) => {
                const handleResolve = () => {
                    resolve(null);
                };

                const handleConfirm = () => {
                    handleDestroy(id).onSuccess(() => {
                        modalControls.close();
                        handleResolve();
                    });
                };

                modalControls.set(
                    <ModalConfirm message="Do you really want to permanently delete this package?" onConfirm={handleConfirm} onCancel={handleResolve} />
                );
            });
        },
        [handleDestroy]
    );

    // Table methods
    const requestTableData = useCallback((query?: Request.All) => Api.updatePackages().getAll(query), []);
    const getTableData = useCallback((d: UpdatePackagesListResponse) => d.update_packages, []);
    const getTableItemProperties = useCallback((item: UpdatePackage): TableRowOptions => ({ className: cn({ deleted: !!item.deleted_at }) }), []);

    return (
        <Layout breadcrumbs={breadcrumbs}>
            {modal}
            <h1>
                Update packages
                <Button color="brown" href={LinkHelper.updatePackagesAdd}>
                    Add package
                </Button>
            </h1>
            <Table2 get={requestTableData} getData={getTableData} item={getTableItemProperties}>
                {{
                    filters: (handler) => (
                        <Filter3 onChange={handler}>
                            <Filter3.Search />
                            <Filter3.Boolean placeholder="Archived" name="is_archived" />
                        </Filter3>
                    ),
                    items: [
                        ({ id, name }) => ({
                            name: 'Name',
                            max: 20,
                            content: name,
                            sort: 'name',
                            link: LinkHelper.updatePackagesItemId(id),
                        }),
                        ({ version }) => ({
                            name: 'Version',
                            max: 20,
                            content: version,
                            sort: 'version',
                        }),
                        ({ date }) => ({
                            name: 'Date',
                            max: 20,
                            sort: 'date',
                            content: Tools.date.format(date * 1000, 'DD.MM.YYYY'),
                        }),
                    ],
                    actions: [
                        ({ id }) => ({
                            type: 'edit',
                            link: LinkHelper.updatePackagesItemEditId(id),
                        }),
                        ({ id, deleted_at }) =>
                            deleted_at
                                ? {
                                      type: 'restore',
                                      onClick: () => handleRestoreClick(id),
                                  }
                                : {
                                      type: 'delete',
                                      onClick: () => handleDeleteClick(id),
                                  },
                        ({ id }) => ({
                            type: 'destroy',
                            onClick: () => handleDestroyConfirm(id),
                            hide: !permissions?.admin,
                        }),
                        ({ id }) => ({
                            type: 'open',
                            link: LinkHelper.updatePackagesItemId(id),
                        }),
                    ],
                }}
            </Table2>
        </Layout>
    );
};
