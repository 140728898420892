import { Base, Response, SpecialOfferProductsResponseType, SpecialOfferRequest, SpecialOfferResponseType, SpecialOfferStatisticsResponse } from '@.api/methods';
import { FilterData } from '@/Components/UI/Filters.v3/modules';
import { Request } from '@/Types';

import { SpecialOffersResponseType } from './types';

export class SpecialOffers extends Base {
    public getAll(query?: {
        page?: number;
        per_page?: number;
        search?: string;
        company_id?: number;
        store_group_id?: number;
        store_id?: number;
        type?: number;
        is_enabled?: boolean;
    }) {
        this.setPath('special-offers');
        if (query) this.setQuery(query);

        return this.getRequest() as Response<SpecialOffersResponseType>;
    }

    public getById(id: number) {
        this.setPath('special-offers');
        this.setId(id);

        return this.getRequest() as Response<SpecialOfferResponseType>;
    }

    public create(data: SpecialOfferRequest) {
        this.setPath('special-offers');
        this.setData(data);

        return this.postRequest();
    }

    public update(data: SpecialOfferRequest, id: number) {
        this.setPath('special-offers');
        this.setId(id);
        this.setData(data);

        return this.putRequest();
    }

    public getProducts(id: number, query?: FilterData & Request.Pagination) {
        query && this.setQuery(query);
        this.setPath('special-offers');
        this.setId(`${id}/products`);

        return this.getRequest() as Response<SpecialOfferProductsResponseType>;
    }

    public deleteProduct(id: number, product_id: number) {
        this.setPath(`special-offers/${id}/products/${product_id}`);

        return this.deleteRequest<SpecialOfferProductsResponseType>();
    }

    public disable(id: number) {
        this.setPath('special-offers');
        this.setId(`${id}/disable`);

        return this.postRequest();
    }

    public enable(id: number) {
        this.setPath('special-offers');
        this.setId(`${id}/enable`);

        return this.postRequest();
    }

    public getStatistics(id: number) {
        this.setPath('special-offers');
        this.setId(`${id}/statistics`);

        return this.getRequest() as Response<SpecialOfferStatisticsResponse>;
    }

    public updateProducts(id: number, ids: number[]) {
        this.setPath(`special-offers/${id}/products`);
        this.setData({ products_ids: ids });

        return this.postRequest<object>();
    }
}
