import React, { useCallback, useEffect } from 'react';

import { useObjectEffect, useSessionStorage, useStateCallback } from '@.hooks';

import {
    AuthCookies,
    AuthStorage,
    checkRights,
    checkTokens,
    checkUser,
    Page,
    PageList,
    PageListK,
    refresh,
    RoutePage,
    RoutePageK,
    toUserType,
    useAuthPages,
    useAuthRoutes,
} from './modules';
import { AuthContextValue, AuthProviderProps, IHasRights, IRemember, ISetTokens, User } from './types';

const AuthContext = React.createContext<AuthContextValue | null>(null);

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
    const [authUser, setAuthUser] = useStateCallback<User>();
    const [storage] = useSessionStorage('permissions', {});

    useEffect(() => {
        const handleUnauthorized = () => setAuthUser(null);

        document.addEventListener('unauthorized-api', handleUnauthorized);

        return () => {
            document.removeEventListener('unauthorized-api', handleUnauthorized);
        };
    }, [setAuthUser]);

    const refreshTokens = () =>
        new Promise((resolve, reject) => {
            refresh()
                .then((res) => remember(res).then(resolve))
                .catch(() => {
                    forget();
                    reject();
                });
        });

    useObjectEffect(() => {
        const user = storage as User;

        if (checkUser(user)) remember(user);
    }, [storage]);

    const setTokens: ISetTokens = (tokens, remember) => {
        return new Promise((resolve, reject) => {
            if (!checkTokens(tokens)) {
                reject();
                return;
            }

            Auth.Cookies.set(tokens, remember);

            setAuthUser(
                (prev) => ({ ...prev, tokens }),
                () => resolve(undefined)
            );
        });
    };

    const remember: IRemember = (user, remember) => {
        return new Promise((resolve, reject) => {
            if (!user || !checkTokens(user.tokens)) {
                reject();
                return;
            }

            Auth.Cookies.set(user.tokens, remember);

            setAuthUser(user, () => resolve(undefined));
        });
    };

    const forget = () => {
        setAuthUser(null);

        Auth.Cookies.clear();
    };

    const hasRights: IHasRights = useCallback((page, permission) => checkRights(authUser, page, permission), [authUser]);

    return (
        <AuthContext.Provider
            value={{
                user: authUser,
                forget,
                setTokens,
                remember,
                hasRights,
                refresh: refreshTokens,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export const Auth = {
    Use: () => React.useContext(AuthContext),
    Cookies: AuthCookies,
    Storage: AuthStorage,
    Provider: AuthProvider,
    Tools: {
        refreshTokens: refresh,
        toUserType: toUserType,
    },
};

export type { AuthContextValue, Page, PageList, PageListK, RoutePage, RoutePageK };
export { useAuthPages, useAuthRoutes };
