import { Board, ImportTable } from '@/Components/Partials';
import { Api } from '@/Services/api';
import { Request } from '@/Types';

import { StationTab } from '../types';

export const Products: StationTab = ({ station, filters, permissions }) => {
    const getTable = (query?: Request.All) => {
        if (!station) return;

        return Api.stations().getProducts(station.id, query);
    };

    const postTable = (ids: number[]) => {
        if (!station) return;

        return Api.stations().updateProducts(station.id, ids);
    };

    return (
        <Board.Line>
            <Board.Item>
                <ImportTable
                    headers={['UDI', 'Name', 'Brand'] as const}
                    get={getTable}
                    post={postTable}
                    getId={(item) => item.id}
                    itemRender={(column, item) => {
                        switch (column) {
                            case 'UDI':
                                return item.udi;
                            case 'Name':
                                return item.name;
                            case 'Brand':
                                return item.brand.name;
                        }
                    }}
                    getDefault={(item) => item.is_connected}
                    getData={(data) => data.products}
                    filters={filters}
                    edit={!permissions?.admin}
                />
            </Board.Item>
        </Board.Line>
    );
};

/*
// const [products, setProducts] = useState<StationProductsResponse>();
    // const { currentPage, lastPage, perPage, prevPage, nextPage, changePage, total, process } = usePagination();

    // const update = () => {
    //     if (!station) return;

    //     Api.stations()
    //         .getProducts(station.id, { page: currentPage, per_page: perPage, ...filters })
    //         .onSuccess((res) => {
    //             setProducts(res.data);
    //             process(res.data.pagination);
    //         });
    // };

    // const handleDelete = (product_id: number) => {
    //     if (!station) return;

    //     Api.stations()
    //         .deleteProduct(station.id, product_id)
    //         .onSuccess((res) => {
    //             setProducts(res.data);
    //             process(res.data.pagination);
    //         });
    // };
  // useEffect(() => {
    //     if (currentPage !== 1 && total && total / perPage < currentPage) changePage(1);
    // }, [total]);
    // useEffect(() => update(), [station?.id, currentPage, filters]);
<Table headers={['UDI', 'Name', 'Brand']} total={total}>
                    {products?.products.map((product) => (
                        <TableRow key={`row_${product.id}`}>
                            <TableColumn>{product.udi}</TableColumn>
                            <TableColumn>{product.name}</TableColumn>
                            <TableColumn>{product.brand.name}</TableColumn>
                            <TableActions>
                                <TableAction>
                                    <Icon name="trash" onClick={() => handleDelete(product.id)} />
                                </TableAction>
                            </TableActions>
                        </TableRow>
                    ))}
                </Table>
                <div className="page-table__footer">
                    <Pagination
                        className={total && total < perPage ? 'hide' : ''}
                        current={currentPage}
                        last={lastPage ?? 1}
                        prevPage={prevPage}
                        nextPage={nextPage}
                        changePage={changePage}
                    />
                    {<ExportBlock model="products" />}
                </div> */
