import { SpecialOffer, SpecialOffers, SpecialOffersAction } from '@.pages';

import { RoutePage } from '../types';

export const SpecialOffersPage: RoutePage = {
    url: 'special-offers',
    component: <SpecialOffers />,
    permissions: 'readOnly',
    sidebar: {
        name: 'Special offers',
    },
    children: [
        {
            url: 'add',
            component: <SpecialOffersAction />,
        },
        {
            url: ':id',
            component: <SpecialOffer />,
            children: [
                {
                    url: 'edit',
                    component: <SpecialOffersAction edit />,
                },
            ],
        },
    ],
};
