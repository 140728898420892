import { DEFAULT_LANGUAGE } from '@/Config/languages';

export const getLingualPropertyValue = <
    T extends object & {
        [key: string]: string | null | undefined;
    }
>(
    obj: T,
    languageCode = DEFAULT_LANGUAGE.code
): string | undefined => {
    return obj[languageCode] || undefined;
};
