import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Api } from '@.api';
import { useForm, useId } from '@.hooks';
import { Layout } from '@.layout';
import { Auth, LinkHelper } from '@.services';
import { DatePicker, Input, Select, Upload } from '@.ui';
import { validateOptions } from '@/Components/Pages/Data/Files/FilesAction/validate';
import { Buttons } from '@/Components/Partials';
import { ManagementFilesType } from '@/Services/api/methods';
import { FileType } from '@/Types';
import { DictionaryResponse } from '@/Types/api/Dictionaries';

import { FilesManagementActionProps, ManagementFilesData } from './types';

export const FilesAction: React.FC<FilesManagementActionProps> = ({ edit }) => {
    const auth = Auth.Use();
    const [file, setFile] = useState<FileType>();
    const [types, setTypes] = useState<DictionaryResponse>();
    // const [file, setFile] = useState<File[]>();
    const [field, form] = useForm<ManagementFilesData>();
    const navigate = useNavigate();

    const handleCreate = () => {
        form.reset.errors();

        form.ifValid((data) => {
            Api.managementFiles()
                .create({
                    ...data,
                    date: (() => {
                        const dateValue = data.date?.at(0);
                        if (!dateValue) return undefined;

                        return Math.round(dateValue / 1000);
                    })(),
                } as ManagementFilesType)
                .onSuccess(() => navigate(LinkHelper.dataFiles))
                .onValidationError((res) => form.set.errors(res.errors));
        }, validateOptions);
    };

    const handleUpdate = () => {
        form.reset.errors();

        // if (!file) form.set.errors({ file_id: ['Field file id is required'] });

        form.ifValid((data) => {
            Api.managementFiles()
                .update(
                    {
                        ...data,
                        date: (() => {
                            const dateValue = data.date?.at(0);
                            if (!dateValue) return undefined;

                            return Math.round(dateValue / 1000);
                        })(),
                    } as ManagementFilesType,
                    id
                )
                .onSuccess(() => navigate(LinkHelper.dataFiles))
                .onValidationError((res) => form.set.errors(res.errors));
        }, validateOptions);
    };

    // const handleFile = (file: File[]) => {
    //     Api.files()
    //         .upload({ file: file[0] })
    //         .onSuccess((file_res) => {
    //             field('file_id').value(file_res.data.id);
    //         });
    // };

    const get = (id: number) => {
        if (!edit) return;

        Api.managementFiles()
            .getById(id)
            .onSuccess((res) => {
                const { id, name, type, description, file, version, date } = res.data;

                setFile(file);

                form.set.values({ id, name, type: type.id, file_id: file.id, description, version, date: [date ? date * 1000 : undefined] });
            });
    };

    const id = useId(get, edit);

    useEffect(() => {
        Api.dictionaries()
            .get({ by_name: 'management_file_types' })
            .onSuccess((res) => setTypes(res.data));
    }, []);

    return (
        <Layout
            name="data"
            breadcrumbs={
                edit
                    ? [{ name: 'Files management', link: LinkHelper.dataFiles }, { name: 'File name' }, { name: 'Edit', current: true }]
                    : [
                          { name: 'Files management', link: LinkHelper.dataFiles },
                          { name: 'Add', current: true },
                      ]
            }
            small
        >
            {edit ? <h1>Editing file</h1> : <h1>Adding file</h1>}
            <div className="files-action__form">
                <Input {...field('name').register()} text="Name" required />
                <Select {...field('type').register<number>()} placeholder="Update type" theme="white" required>
                    {types?.management_file_types?.map((type) => (
                        <Select.Option key={`type_${type.id}`} id={type.id}>
                            {type.name}
                        </Select.Option>
                    ))}
                </Select>
                <Input {...field('version').register()} text="Version" required />
                <Input {...field('description').register()} text="Description" />
                <DatePicker {...field('date').register()} single placeholderFrom="Date" />
                <Upload.File {...field('file_id').register<number>()} value={file} maxSize={0} single />
                {/* <div className="files-action__form-buttons">
                    <Link to={LinkHelper.dataFiles}>
                        <Button color="transparent" text="Cancel" />
                    </Link>
                    <Button color="brown" text={edit ? 'Save' : 'Add'} onClick={edit ? handleUpdate : handleCreate} />
                </div> */}
                <Buttons
                    linkCancel={LinkHelper.dataFiles}
                    text={edit ? 'Save' : 'Add'}
                    onClickSave={edit ? handleUpdate : handleCreate}
                    noSave={!auth?.user?.is_super}
                />
            </div>
        </Layout>
    );
};
