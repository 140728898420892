import { Files, FilesAction } from '@.pages';

import { RoutePage } from '../types';

export const FilesPage: RoutePage = {
    url: 'files-management',
    component: <Files />,
    permissions: 'admins',
    sidebar: {
        name: 'Files management',
    },
    children: [
        {
            url: ':id',
            children: [
                {
                    url: 'edit',
                    component: <FilesAction edit />,
                },
            ],
        },
        {
            url: 'add',
            permissions: 'admin',
            component: <FilesAction />,
        },
    ],
};
