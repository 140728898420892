import React, { FC } from 'react';

import { IField, IForm } from '@.hooks';
import { Select } from '@.ui';
import { UsersData } from '@/Components/Pages/Settings/Users/Action/types';
import { StatisticObjectEnum } from '@/Services/StatisticPermissionService/StatisticObjectEnum';
import { DictionaryResponseKnown } from '@/Types/api/Dictionaries';

import { StatisticObjectNumberMap } from '../StatisticObjectNumberMap';

type PermissionsSelectProps = {
    field: IField<UsersData>;
    form: IForm<UsersData>;
    dictionaries?: Partial<DictionaryResponseKnown>;
};

export const PermissionsSelect: FC<PermissionsSelectProps> = ({ field, form, dictionaries }) => {
    const selectedCompanyId = form.state.company_id?.value;
    const selectedStoreGroupId = form.state.statistic_object_store_group_id?.value;

    const statisticObjectTypeValue = form.state.statistic_object_type?.value;

    const storeGroups = dictionaries?.store_groups?.filter((storeGroup) => storeGroup.company_id === selectedCompanyId);

    const stores =
        statisticObjectTypeValue === StatisticObjectEnum.STORE && selectedStoreGroupId
            ? dictionaries?.stores?.filter((store) => store.store_group_id === selectedStoreGroupId)
            : [];

    const isStoresExists = stores && stores.length > 0;

    const isShowStoreGroupEmptyStoresError = statisticObjectTypeValue === StatisticObjectEnum.STORE && !isStoresExists;

    const isShowStoresByStoreGroup = statisticObjectTypeValue === StatisticObjectEnum.STORE && selectedStoreGroupId && isStoresExists;

    const isShowStoreGroupSelect = statisticObjectTypeValue === StatisticObjectEnum.STORE_GROUP || statisticObjectTypeValue === StatisticObjectEnum.STORE;

    const onStatisticObjectTypeChange = (_?: number, value?: StatisticObjectEnum | unknown) => {
        form.set.fieldValue('statistic_object_type', value);
    };

    return (
        <>
            <Select
                {...field('statistic_object_type').register<number>()}
                onChange={onStatisticObjectTypeChange}
                value={statisticObjectTypeValue ? StatisticObjectNumberMap[statisticObjectTypeValue] : undefined}
                label="View statistics permissions"
                theme="white"
            >
                <Select.Option id={StatisticObjectNumberMap[StatisticObjectEnum.COMPANY]} value={StatisticObjectEnum.COMPANY}>
                    By companies
                </Select.Option>
                <Select.Option id={StatisticObjectNumberMap[StatisticObjectEnum.STORE_GROUP]} value={StatisticObjectEnum.STORE_GROUP}>
                    By store group
                </Select.Option>
                <Select.Option id={StatisticObjectNumberMap[StatisticObjectEnum.STORE]} value={StatisticObjectEnum.STORE}>
                    By store
                </Select.Option>
            </Select>

            {isShowStoreGroupSelect && (
                <Select
                    {...field('statistic_object_store_group_id').register<number>()}
                    label="Store groups"
                    theme="white"
                    required
                    placeholder="Select store groups"
                    errors={isShowStoreGroupEmptyStoresError ? ['This store group has no stores'] : undefined}
                >
                    {storeGroups?.map((storeGroup) => (
                        <Select.Option key={storeGroup.id} id={storeGroup.id}>
                            {storeGroup.name}
                        </Select.Option>
                    ))}
                </Select>
            )}

            {isShowStoresByStoreGroup && (
                <Select {...field('statistic_object_store_id').register<number>()} label="Stores" placeholder="Select store" theme="white" required>
                    {stores?.map((store) => (
                        <Select.Option key={store.id} id={store.id}>
                            {store.name}
                        </Select.Option>
                    ))}
                </Select>
            )}
        </>
    );
};
