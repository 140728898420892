import { RuleMessage } from '@.hooks';
import { Tools } from '@.services';

import { ruleValue } from './index';

export const replacer = <T extends object, S>(message: string, fieldName: keyof T, rule: RuleMessage<S>): string => {
    const name = fieldName
        .toString()
        .replaceAll(/_id(s)?/gi, '')
        .replaceAll('_', ' ');

    let replaced = message.replaceAll('$field', name);

    if (rule) replaced = replaced.replaceAll('$value', JSON.stringify(ruleValue(rule)));

    return Tools.string.uppercaseFirstLetter(replaced);
};
