import { User } from '@/Services/Auth/types';

const storageName = 'permissions';

const fireEvent = (): boolean => window.dispatchEvent(new Event('session-storage'));

const set = (user: User): void => {
    sessionStorage.setItem(storageName, JSON.stringify(user));

    fireEvent();
};

const clear = (): void => {
    sessionStorage.removeItem(storageName);

    fireEvent();
};

export const AuthStorage = {
    fireEvent,
    set,
    clear,
};
