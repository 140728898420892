import React, { FC } from 'react';

import { Upload } from '@.ui';
import { AdminFields } from '@/Components/Partials/Product/CreateProductForm/components/AdminField';
import { FormItemHeader } from '@/Components/Partials/Product/CreateProductForm/components/FormItemHeader';
import { FormItemWrapper } from '@/Components/Partials/Product/CreateProductForm/components/FormItemWrapper';
import { FileType } from '@/Types';

interface ImageFieldProps {
    headerText: string;
    isAllowed: boolean;
    value?: FileType[];
}

export const ImageField: FC<ImageFieldProps> = ({ headerText, isAllowed, value, ...props }) => {
    return (
        <FormItemWrapper>
            <FormItemHeader>{headerText}</FormItemHeader>
            <AdminFields isAllowed={isAllowed}>
                <Upload.Image {...props} value={value} />
            </AdminFields>
        </FormItemWrapper>
    );
};
