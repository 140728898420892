import env from '@fipnooone/react-env';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

import { Code } from '@.api';
import { Responses } from '@.api/methods';
import { Auth, Cookie } from '@.services';
import { Language, User } from '@/Types';
type ErrorObj = { name: string; config: AxiosRequestConfig; response: { status: number; data: { message: string; errors: { [name: string]: string[] } } } };

let refreshPromise: Promise<string | null> | undefined | null;

export const authHeader = (accessToken: string) => `Bearer ${accessToken}`;

export const requestInterceptor = (axiosOptions: AxiosRequestConfig) => {
    const tokens = Auth.Cookies.get();
    const basepath = env('BACKEND_URL');

    if (tokens)
        axiosOptions.headers = {
            Authorization: authHeader(tokens.access),
            'Accept-Language': Cookie<Language.Store>({ name: '_lang' }).get()?.code ?? 'en',
            'Content-Type': 'application/json',
        };
    if (basepath) axiosOptions.baseURL = basepath;

    // axiosOptions.timeout = 1000 * 10;

    return axiosOptions;
};

const __axios = axios.create();

__axios.interceptors.request.use(requestInterceptor);

__axios.interceptors.response.use(
    (res: AxiosResponse) => {
        return {
            message: res?.data?.message ?? '',
            data: res?.data?.data ?? res?.data,
            code: res?.status,
        };
    },
    (error: ErrorObj) => {
        if (error.config && (error.config.url === '/users/refresh' || error.config.url === 'users/refresh')) {
            document.dispatchEvent(new Event('unauthorized-api'));
            return { message: error.response?.data?.message, errors: error.response?.data?.errors, code: error.response?.status };
        }

        if (
            error.config &&
            error.response &&
            error.response.status === Code.Unauthorized &&
            error.config.url !== '/users/refresh' &&
            error.config.url !== '/users/login'
        ) {
            if (!refreshPromise) {
                const refRes = refreshTokens();

                if (refRes === null) {
                    document.dispatchEvent(new Event('unauthorized-api'));
                } else {
                    refreshPromise = refRes.then((res) => {
                        if (!res) document.dispatchEvent(new Event('unauthorized-api'));

                        refreshPromise = null;
                        return res;
                    });
                }
            }

            return refreshPromise?.then((res) => {
                if (res) return __axios.request({ ...error.config, headers: { ...error.config.headers, Authorization: authHeader(res) } });
            });
        }

        Promise.resolve({ message: error.response?.data?.message, errors: error.response?.data?.errors, code: error.response?.status });

        return { message: error.response?.data?.message, errors: error.response?.data?.errors, code: error.response?.status };
    }
);

const refreshTokens = () => {
    const tokens = Auth.Cookies.get();

    if (!tokens) {
        Auth.Cookies.clear();
        return null;
    }

    return __axios.post('users/refresh', { refresh_token: tokens.refresh }).then((response) => {
        const res = response as unknown as Responses<User.RefreshResponse>;

        if (res.code === Code.Success) {
            const user = Auth.Tools.toUserType(res.data);

            Auth.Cookies.set(user.tokens);
            Auth.Storage.set(user);

            return user.tokens.access;
        }

        return null;
    });
};

export { __axios };
