import { FC, useState } from 'react';

import { useId } from '@/Components/Hooks';
import { Layout } from '@/Components/Layouts';
import { Table, TableColumn, TableRow } from '@/Components/Partials';
import { Tools } from '@/Services';
import { Api } from '@/Services/api';
import { UpdatePackage } from '@/Types';

import { useBreadcrumbs } from '../useBreadcrumbs';

export const PackagesUpdateItem: FC = () => {
    const [data, setData] = useState<UpdatePackage>();

    useId((id) => {
        Api.updatePackages()
            .getById(id)
            .onSuccess(({ data }) => {
                setData(data);
            });
    }, true);

    const breadcrumbs = useBreadcrumbs({
        type: 'view',
        id: data?.id,
        name: data?.name,
    });

    return (
        <Layout breadcrumbs={breadcrumbs}>
            {data && (
                <>
                    <h1>{data.name}</h1>
                    <Table>
                        <TableRow>
                            <TableColumn bold>ID</TableColumn>
                            <TableColumn>{data.id}</TableColumn>
                        </TableRow>
                        <TableRow>
                            <TableColumn bold>Name</TableColumn>
                            <TableColumn>{data.name}</TableColumn>
                        </TableRow>
                        <TableRow>
                            <TableColumn bold>Version</TableColumn>
                            <TableColumn>{data.version}</TableColumn>
                        </TableRow>
                        <TableRow>
                            <TableColumn bold>Date</TableColumn>
                            <TableColumn>{Tools.date.format(data.date * 1000, 'DD.MM.YYYY')}</TableColumn>
                        </TableRow>
                    </Table>
                </>
            )}
        </Layout>
    );
};
