import { useState } from 'react';

import { useObjectEffect } from '@/Components/Hooks';
import { Board, Circles, FilterData, Filters } from '@/Components/Partials';
import { LinkHelper } from '@/Services';
import { Api } from '@/Services/api';
import { DashboardProductsResponse } from '@/Services/api/methods/dashboard/types';

import { DashboardItem } from '../../types';

export const Products: DashboardItem = ({ dictionaries, filters }) => {
    const [products, setProducts] = useState<DashboardProductsResponse>();
    const [localFilters, setLocalFilters] = useState<FilterData>();

    useObjectEffect(() => {
        if (!filters && !localFilters) return;

        Api.dashboard()
            .products({ ...localFilters, ...filters })
            .onSuccess((res) => setProducts(res.data));
    }, [filters, localFilters]);

    return (
        <Board.Item title="Popular products" more={LinkHelper.dashboardPopularProducts}>
            <Filters
                filters={[
                    'Brands',
                    'ProductTypes',
                    'Ages',
                    'Sexes',
                    'Strengths',
                    'Occasions',
                    'Ingredients',
                    'Characters',
                    'IsArchived',
                    'Tags',
                    'ProductUdis',
                ]}
                fromData={dictionaries ?? null}
                search={false}
                onChange={setLocalFilters}
            />
            <Circles data={products?.products ?? []} />
        </Board.Item>
    );
};
