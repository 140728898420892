import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Api } from '@.api';
import { useForm } from '@.hooks';
import { Layout } from '@.layout';
import { LingualInput } from '@.ui';
import { ProductsTypesActionProps, ProductTypesData } from '@/Components/Pages/Data/ProductTypes/Action/types';
import { validateOptions } from '@/Components/Pages/Data/ProductTypes/Action/validate';
import { Buttons } from '@/Components/Partials';
import { LingualData } from '@/Components/UI/LingualInput/types';
import { getLingualErrors, LinkHelper, useLingualField } from '@/Services';

export const ProductsTypesAction: React.FC<ProductsTypesActionProps> = ({ edit }) => {
    const [data, setData] = useState<{ name: LingualData }>();
    const [field, form] = useForm<ProductTypesData>();
    const navigator = useNavigate();

    const { id } = useParams();

    const handleCreate = () => {
        form.reset.errors();

        form.ifValid((data) => {
            Api.product_types()
                .create(data)
                .onSuccess(() => navigator(LinkHelper.dataProductTypes))
                .onValidationError((res) => form.set.errors({ ...res.errors, name: getLingualErrors(res.errors, 'name') }));
        }, validateOptions);
    };

    const handleUpdate = () => {
        form.reset.errors();

        form.ifValid((data) => {
            Api.product_types()
                .update(data, Number(id))
                .onSuccess(() => navigator(LinkHelper.dataProductTypes))
                .onValidationError((res) => form.set.errors({ ...res.errors, name: getLingualErrors(res.errors, 'name') }));
        }, validateOptions);
    };

    useEffect(() => {
        Api.product_types()
            .getById(Number(id))
            .onSuccess((res) => {
                const { name } = res.data;
                form.set.values({ name });
                setData({ name });
            });
    }, []);

    const typeName = useLingualField(data);

    return (
        <Layout
            name="data"
            breadcrumbs={
                edit
                    ? [{ name: 'Product types', link: LinkHelper.dataProductTypes }, { name: typeName }, { name: 'Edit', current: true }]
                    : [
                          { name: 'Product types', link: LinkHelper.dataProductTypes },
                          { name: 'Add', current: true },
                      ]
            }
            small
        >
            {edit ? <h1>Editing product type</h1> : <h1>Adding product type</h1>}
            <div className="action-form">
                <LingualInput {...field('name').register()} text="Name" required />
                {/* <div className="action-form-buttons">
                    <Button color="brown" text={edit ? 'Save' : 'Add'} onClick={edit ? handleUpdate : handleCreate} />
                    <Button color="transparent" text="Cancel" onClick={() => navigator(-1)} />
                </div> */}
                <Buttons text={edit ? 'Save' : 'Add'} onClickSave={edit ? handleUpdate : handleCreate} />
            </div>
        </Layout>
    );
};
