import React, { FC } from 'react';

import { Bars } from '@.partials';
import { Filter3, FilterData } from '@.ui';
import { AnalyticChartCardInfo } from '@/Components/Partials/Analytics/AnalyticChartCardInfo';
import { AnalyticChartFilterInfo } from '@/Components/Partials/Analytics/AnalyticChartFilterInfo';
import { isNumber } from '@/Lib';
import { analyticsStoreSelectors } from '@/Store';
import { ChartDatasetValues, StatisticBestProduct, StatisticBrand, WhiffTotalsValue } from '@/Types/analytics/chartsTypes';

import S from './styles.module.scss';

type WhiffCountsPerItemChartProps = {
    data: ChartDatasetValues;
    brands: StatisticBrand[];
    bestProduct?: StatisticBestProduct;
    totalWhiffs: WhiffTotalsValue;
};

export const WhiffCountsPerItemChart: FC<WhiffCountsPerItemChartProps> = ({ data, bestProduct, brands, totalWhiffs }) => {
    const filterWhiffByBrand = analyticsStoreSelectors.use.whiffFilterByBrand();

    const bestProductDescription = (
        <>
            <p>{bestProduct?.name}</p>
            <p>most popular per period</p>
        </>
    );

    const onChangeBrandFilter = (item: FilterData) => {
        const brandId = item.brand_id ? Number(item.brand_id) : null;

        if (!brandId) {
            filterWhiffByBrand(null);
        }

        if (isNumber(brandId) && !isNaN(brandId)) {
            filterWhiffByBrand(brandId);
        }
    };

    const filters = [
        <Filter3
            key={brands.length}
            className={S.info_filters}
            onChange={onChangeBrandFilter}
            fromData={{
                brands,
            }}
            isSaveInQuery={false}
            resetOptionName="All brands"
        >
            <Filter3.Brands visibleLabel={''} visibleName="All brands" />
        </Filter3>,
    ];

    const cardsInfo = [
        bestProduct && <AnalyticChartCardInfo key={bestProduct.value} title={bestProduct.value.toString()} description={bestProductDescription} />,
        <AnalyticChartCardInfo key={totalWhiffs} title={totalWhiffs.toString()} description="Total completed operation" />,
    ];

    return (
        <div className={S.whiffs_chart}>
            <h3>Whiff counts per item (per specific custom brand)</h3>

            <AnalyticChartFilterInfo filters={filters} cardsInfo={cardsInfo} />

            <Bars data={data} isHorizontal axisDataName="Product name" />
        </div>
    );
};
