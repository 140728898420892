import { Api } from '@.api';
import { StatisticRequest, StatisticsResponse } from '@/Services/api/methods/analytics/StatisticTypes';

export class AnalyticsService {
    public fetchStatistics(statisticRequest: StatisticRequest): Promise<StatisticsResponse> {
        return new Promise<StatisticsResponse>((resolve, reject) => {
            Api.analytics()
                .getStatistics(statisticRequest)
                .onSuccess((response) => {
                    if (response.data) {
                        resolve(response.data);
                    } else {
                        reject(response.data);
                    }
                })
                .onError(reject);
        });
    }
}
