import cn from 'classnames';
import { useMemo } from 'react';

import { Icon } from '@/Components/UI';

import S from './styles.module.scss';
import { ChamberProps } from './types';

export const ChamberCard: React.FC<ChamberProps> = ({ chamber }) => {
    const calcColor = useMemo(() => {
        let fillPercent;

        if (chamber.spent_value === 0) {
            fillPercent = 1;
        }
        if (chamber.init_value === 0) {
            fillPercent = 0;
        }

        fillPercent = 1 - chamber.spent_value / chamber.init_value;

        const left = chamber.init_value - chamber.spent_value;

        if (fillPercent >= 0.75) return 'Green';
        if (fillPercent < 0.75 && fillPercent >= 0.5) return 'Yellow';
        if (left >= 5 && fillPercent < 0.5) return 'Orange';
        return 'Red';
    }, [chamber.spent_value, chamber.init_value]);

    return (
        <div className={S.Card}>
            <div className={S.Info}>
                <div className={cn(S.Index, S[`Index_${calcColor}`])}>{chamber.index + 1}</div>
                <p className={S.Id}>ID: {chamber.product_udi}</p>
            </div>
            <div className={S.Values}>
                <div className={S.Value}>
                    <Icon name="whiffs-total" size={18} />
                    <span className={S.Count}>{chamber.init_value}</span>
                </div>
                <div className={S.Value}>
                    <Icon name="whiffs-left" size={18} />
                    <span className={S.Count}>{chamber.init_value - chamber.spent_value}</span>
                </div>
                <div className={S.Value}>
                    <Icon name="whiffs-spent" size={18} />
                    <span className={S.Count}>{chamber.spent_value}</span>
                </div>
            </div>
            {chamber.products.length > 0 && (
                <div className={S.Products}>
                    <p>
                        <b>Products:</b> {chamber.products.map((product) => product.name).join(', ')}
                    </p>
                </div>
            )}
        </div>
    );
};
