import React, { FC } from 'react';
import { HowManyUsersHaveCompletedWizardChart } from 'src/Components/Partials/Analytics/Charts/HowManyUsersHaveCompletedWizardChart';

import { Board } from '@.partials';
import { AgeChart, FragranceTypeChart, GenderChart, StrengthChart, WhiffCountsPerItemChart } from '@/Components/Partials/Analytics';
import { CustomersWizardStepsChart } from '@/Components/Partials/Analytics/Charts/CustomersWizardStepsChart';
import { FavoriteHeartChart } from '@/Components/Partials/Analytics/Charts/FavoriteHeartChart';
import { Statistic } from '@/Store';

type AnalyticsPageChartsProps = {
    statisticDataset: Statistic;
};

/**
 * TODO: I think in future charts will be separate by tariffs.
 */
export const AnalyticsPageCharts: FC<AnalyticsPageChartsProps> = ({ statisticDataset }) => {
    return (
        <>
            {statisticDataset.age && (
                <Board.Item>
                    <AgeChart data={statisticDataset.age.dataset} />
                </Board.Item>
            )}

            {statisticDataset.favorite_heart && (
                <Board.Item>
                    <FavoriteHeartChart
                        data={statisticDataset.favorite_heart.dataset}
                        bestProduct={statisticDataset.favorite_heart.best_product}
                        totalHearts={statisticDataset.favorite_heart.total_hearts}
                        totalProducts={statisticDataset.favorite_heart.total_products}
                        limit={statisticDataset.favorite_heart.limit}
                    />
                </Board.Item>
            )}

            <Board.Line>
                {statisticDataset.strength && (
                    <Board.Item size="1/3">
                        <StrengthChart data={statisticDataset.strength.dataset} />
                    </Board.Item>
                )}

                {statisticDataset.fragrance_type && (
                    <Board.Item size="2/3">
                        <FragranceTypeChart data={statisticDataset.fragrance_type.dataset} />
                    </Board.Item>
                )}
            </Board.Line>

            {statisticDataset.gender && (
                <Board.Item size="1/3">
                    <GenderChart data={statisticDataset.gender.dataset} />
                </Board.Item>
            )}

            {statisticDataset.whiff_counts_per_item_brand && (
                <Board.Item>
                    <WhiffCountsPerItemChart
                        data={statisticDataset.whiff_counts_per_item_brand.dataset}
                        bestProduct={statisticDataset.whiff_counts_per_item_brand.best_product}
                        brands={statisticDataset.whiff_counts_per_item_brand.brands}
                        totalWhiffs={statisticDataset.whiff_counts_per_item_brand.total_whiffs}
                    />
                </Board.Item>
            )}

            <Board.Line>
                {statisticDataset.how_many_users_have_completed_wizard && (
                    <Board.Item size="1/3">
                        <HowManyUsersHaveCompletedWizardChart data={statisticDataset.how_many_users_have_completed_wizard.dataset} />
                    </Board.Item>
                )}

                {statisticDataset.customers_and_wizard_steps && (
                    <Board.Item size="2/3">
                        <CustomersWizardStepsChart data={statisticDataset.customers_and_wizard_steps.dataset} />
                    </Board.Item>
                )}
            </Board.Line>
        </>
    );
};
