import useModal from '@fipnooone/react-modal';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { Api } from '@.api';
import { Layout } from '@.layout';
import { Table2 } from '@.partials';
import { LinkHelper } from '@.services';
import { Request } from '@.types';
import { Button, Filter3 } from '@.ui';
import { ModalConfirm } from '@/Components/Modal/Dialogs';
import { UsersTableProps } from '@/Components/Pages/Settings/Users/types';
import { IrcUsersResponseType } from '@/Services/api/methods';
import { PermissionProps } from '@/Types';

export * from './Action';
export * from './ScenticonUser';

export const ScenticonUsers: React.FC<PermissionProps> = (props) => {
    const navigator = useNavigate();

    return (
        <Layout name="settings" breadcrumbs={[{ name: 'Scenticon Users', current: true }]}>
            <h1>
                Scenticon Users
                <Button color="brown" text="Add user" onClick={() => navigator(LinkHelper.settingsIrcUsersAdd)} disabled={!props.permissions?.create} />
            </h1>
            <ScenticonUsersTable permissions={props.permissions} />
        </Layout>
    );
};

export const ScenticonUsersTable: React.FC<UsersTableProps> = ({ hideActions, permissions }) => {
    const [modal, modalControls] = useModal();
    const get = useCallback((q?: Request.All) => Api.ircUsers().getAll(q), []);

    const handleBlock = useCallback((id: number) => Api.ircUsers().blockById(id), []);
    const handleUnblock = useCallback((id: number) => Api.ircUsers().unblockById(id), []);
    const handleDestroy = useCallback((id: number) => Api.ircUsers().destroyById(id), []);

    const handleDestroyConfirm = useCallback(
        (id: number) => {
            return new Promise<unknown>((resolve) => {
                const handleResolve = () => {
                    resolve(null);
                };

                const handleConfirm = () => {
                    handleDestroy(id).onSuccess(() => {
                        modalControls.close();
                        handleResolve();
                    });
                };

                modalControls.set(
                    <ModalConfirm message="Do you really want to permanently delete this user?" onConfirm={handleConfirm} onCancel={handleResolve} />
                );
            });
        },
        [handleDestroy]
    );

    const getData = useCallback((d: IrcUsersResponseType) => d.irc_users, []);
    const items = useCallback((item: IrcUsersResponseType['irc_users'][number]) => ({ className: item.deleted_at ? 'deleted' : '' }), []);

    return (
        <>
            {modal}
            <Table2 get={get} getData={getData} item={items} export="irc-users">
                {{
                    filters: (handler) => (
                        <Filter3 onChange={handler}>
                            <Filter3.Search />
                            <Filter3.Boolean placeholder="Deleted" name="deleted_at" />
                        </Filter3>
                    ),
                    items: [
                        (item) => ({
                            name: 'Name',
                            max: 20,
                            content: item?.name,
                            link: LinkHelper.settingsIrcUserId(item.id),
                            sort: 'name',
                        }),
                        (item) => ({
                            name: 'Login',
                            max: 20,
                            content: item?.login,
                            sort: 'login',
                        }),
                    ],
                    actions: [
                        (item) => ({
                            type: 'edit',
                            link: LinkHelper.settingsIrcUsersEditId(item.id),
                        }),
                        (item) => ({
                            type: item.deleted_at ? 'restore' : 'delete',
                            onClick: item.deleted_at ? () => handleUnblock(item.id) : () => handleBlock(item.id),
                        }),
                        ({ id }) => ({
                            type: 'destroy',
                            onClick: () => handleDestroyConfirm(id),
                        }),
                        (item) => ({
                            type: 'open',
                            link: LinkHelper.settingsIrcUserId(item.id),
                        }),
                    ],
                }}
            </Table2>
        </>
    );
};
