import { requiredRule, ValidateOptions } from '@.hooks';

import { EditLicenseKeyFormState } from './EditLicenseKeyFormState';

export const EditLicenseKeyValidateOptions: ValidateOptions<EditLicenseKeyFormState> = {
    licenseKey: {
        type: 'string',
        required: requiredRule,
    },
};
