import cn from 'classnames';
import React, { useEffect, useState } from 'react';

import { Icon } from '@.ui';
import { Tools } from '@/Services';

import { InputProps } from './types';

export const Input = React.forwardRef<HTMLDivElement, InputProps>(({ onChange, value, placeholder, onClick, current }, ref) => {
    const [focused, setFocused] = useState(false);
    const [input, setInput] = useState<string>('');

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => setInput(event.currentTarget.value);

    useEffect(() => {
        const handleEnter = (event: KeyboardEvent) => {
            if (!onChange || event.key !== 'Enter') {
                return;
            }

            if (!input) {
                onChange(undefined);
            } else {
                const timestamp = Tools.date.fromString(input);
                timestamp && onChange(timestamp);
            }
        };

        if (focused) document.addEventListener('keypress', handleEnter);

        return () => document.removeEventListener('keypress', handleEnter);
    }, [focused]);

    useEffect(() => setInput(value ? Tools.date.format(value, 'DD MON YYYY') : ''), [value]);

    const className = cn('datepicker__input datepicker__input-from', current && 'current');

    return (
        <div className={className} ref={ref} onClick={onClick}>
            <input onChange={handleChange} value={input} placeholder={placeholder} onFocus={() => setFocused(true)} onBlur={() => setFocused(false)} disabled />
            <Icon name="calendar" />
        </div>
    );
});
