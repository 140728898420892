import React, { useCallback } from 'react';

import { Api } from '@.api';
// import { useForm } from '@.hooks';
import { Layout } from '@.layout';
import { Filter3 } from '@.ui';
import { ImportTable } from '@/Components/Partials/ImportTable';
import { LinkHelper } from '@/Services';
import { PermissionProps, Request } from '@/Types';

export const ProductsImport: React.FC<PermissionProps> = ({ permissions }) => {
    const getImported = useCallback((query?: Request.All) => {
        return Api.products().importGet(query);
    }, []);

    const updateImport = (ids: number[]) => Api.products().import({ products_ids: ids });

    return (
        <Layout
            breadcrumbs={[
                { name: 'Products', link: LinkHelper.dataProducts },
                { name: 'Import from GDB', current: true },
            ]}
        >
            <h1>Import from GDB</h1>
            <ImportTable
                headers={['UDI', 'Name', 'Brand'] as const}
                get={getImported}
                post={updateImport}
                getData={(data) => data.products}
                getId={(item) => item.id}
                getDefault={(item) => item.is_imported}
                itemRender={(header, item) => {
                    switch (header) {
                        case 'UDI':
                            return item.udi;
                        case 'Name':
                            return item.name;
                        case 'Brand':
                            return item.brand.name;
                    }
                }}
                edit={permissions?.admin || permissions?.update}
            >
                <Filter3.Search key={'p-i-f-search'} />
                <Filter3.Brands key={'p-i-f-brands'} />
                <Filter3.Boolean key={'p-i-f-imported'} name="is_imported" placeholder="Linked" true="Yes" false="No" />
            </ImportTable>
        </Layout>
    );
};
