import useModal from '@fipnooone/react-modal';
import React, { useEffect, useState } from 'react';
import { EditLicenseKeyModal } from 'src/Components/Pages/Stations/Station/tabs/firmware/EditLicenseKeyModal';

import { Api } from '@.api';
import { StationFirmwareResponse, StationResponseType } from '@.api/methods';
import { StationFirmwareDevice } from '@/Types/stations';

export const useFirmware = (station?: StationResponseType) => {
    const [firmware, setFirmware] = useState<StationFirmwareResponse | null>();

    const [modal, controls] = useModal();

    useEffect(() => {
        if (!station?.id) return;

        Api.stations()
            .getFirmware(station.id)
            .onSuccess((res) => setFirmware(res.data));
    }, [station?.id]);

    const updateDevice = (device: StationFirmwareDevice): void => {
        if (!firmware) {
            return;
        }

        const devices = firmware.devices.map((deviceItem) => {
            if (deviceItem.id === device.id) {
                return device;
            }

            return deviceItem;
        });

        setFirmware({
            ...firmware,
            devices,
        });
    };

    const showEditLicenseKeyModal = (device: StationFirmwareDevice) => {
        controls.set(<EditLicenseKeyModal key={device.id} deviceId={device.id} licenseKeyInitial={device.license_key || ''} updateDevice={updateDevice} />);
    };

    return {
        firmware,
        modal,
        updateDevice,
        showEditLicenseKeyModal,
    };
};
