import { FC, useCallback, useMemo, useRef } from 'react';
import { Link } from 'react-router-dom';

import { useTooltip } from '@/Components/Hooks';
import { ButtonWrapper, Icon } from '@/Components/UI';

import { TableIcons } from './types';

const tableIcons: TableIcons = {
    cancel: {
        icon: 'close',
        text: 'Cancel',
    },
    delete: {
        icon: 'trash',
        text: 'Archive',
    },
    edit: {
        icon: 'edit',
        text: 'Edit',
    },
    open: {
        icon: 'chevrons-right',
        text: 'View',
    },
    lock: {
        icon: 'unlock',
        text: 'Block',
    },
    unlock: {
        icon: 'lock',
        text: 'Unlock',
    },
    restore: {
        icon: 'corner-up-left',
        text: 'Restore',
    },
    download: {
        icon: 'download',
        text: 'Download',
    },
    prices: {
        icon: 'tag',
        text: 'Prices',
    },
    destroy: {
        icon: 'destroy',
        text: 'Destroy',
    },
    analytics: {
        icon: 'analytics-chart',
        text: 'View analytics',
    },
};

export interface TableIconProps<T> {
    name: keyof T;
    onClick?: () => void;
    disabled?: boolean;
    link?: string;
    tooltip?: string;
}

export const TableIcon: FC<TableIconProps<typeof tableIcons>> = ({ name, onClick, disabled, link, tooltip: customText }) => {
    const [tooltip, setTooltip, setOpen] = useTooltip({});
    const ref = useRef<HTMLButtonElement>(null);

    const current = useMemo(() => tableIcons[name], [name]);

    const icon = useMemo(() => <Icon name={current.icon} disabled={disabled} />, [current, disabled]);

    const handleIn = useCallback(() => {
        const option = ref.current;

        if (!option) return;

        const rect = option.getBoundingClientRect();

        setTooltip({
            content: customText ?? current.text,
            coordinates: { x: rect.left + 10, y: rect.top },
            offsetX: rect.width,
            open: true,
        });
    }, [current, customText]);

    const handleOut = () => setOpen(false);

    return (
        <ButtonWrapper onClick={disabled ? undefined : onClick} onMouseEnter={handleIn} onMouseLeave={handleOut} ref={ref}>
            {link && !disabled ? <Link to={link}>{icon}</Link> : icon}
            {tooltip}
        </ButtonWrapper>
    );
};
