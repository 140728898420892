import useModal from '@fipnooone/react-modal';
import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { Api } from '@.api';
import { Layout } from '@.layout';
import { Table2 } from '@.partials';
import { LinkHelper, Tools } from '@.services';
import { Button, Checkbox, Filter3 } from '@.ui';
import { ModalList } from '@/Components/Modal/Dialogs';
import { ErrorsResponseType, SpecialOffersResponseType } from '@/Services/api/methods';
import { PermissionProps, Request } from '@/Types';

export * from './Action';
export * from './SpecialOffer';

export const SpecialOffers: React.FC<PermissionProps> = ({ permissions }) => {
    const navigator = useNavigate();
    const [modal, controls] = useModal();

    const get = useCallback((q?: Request.All) => Api.special_offers().getAll(q), []);

    const handleError = (error: ErrorsResponseType) => {
        controls.set(<ModalList message={error?.message ?? 'Error'} />);
        controls.open(true);
    };

    const handleDisable = useCallback((id: number) => Api.special_offers().disable(id).onErrors(handleError), []);
    const handleEnable = useCallback((id: number) => Api.special_offers().enable(id).onErrors(handleError), []);

    const getData = useCallback((d: SpecialOffersResponseType) => d.special_offers, []);

    const table = useMemo(() => {
        return (
            <Table2 get={get} getData={getData} export="special_offers">
                {{
                    filters: (handler) => (
                        <Filter3 onChange={handler}>
                            <Filter3.Search />
                            <Filter3.Companies related />
                            <Filter3.StoreGroups related />
                            <Filter3.Stores related />
                            <Filter3.SpecialOfferTypes />
                            <Filter3.Boolean name="is_enabled" placeholder="Enabled" />
                            <Filter3.Date />
                        </Filter3>
                    ),
                    items: [
                        (item) => ({
                            name: 'Name',
                            content: item.name,
                            sort: 'name',
                            max: 20,
                            link: LinkHelper.dataSpecialOfferId(item.id),
                        }),
                        (item) => ({
                            name: 'Company',
                            content: item.company.name,
                            max: 20,
                            sort: 'company',
                        }),
                        (item) => ({
                            name: 'Store group',
                            content: item.store_group.name,
                            max: 20,
                            sort: 'store_group',
                        }),
                        (item) => ({
                            name: 'Store',
                            content: item.store.name,
                            max: 20,
                            sort: 'store',
                        }),
                        (item) => ({
                            name: 'Products',
                            content: item.products.map((product) => product.name).join(', '),
                            max: 8,
                            sort: 'products',
                        }),
                        (item) => ({
                            name: 'Type',
                            content: item.types.map((type) => type.name).join(', '),
                            max: 8,
                            sort: 'types',
                        }),
                        (item) => ({
                            name: 'Start date',
                            content: Tools.date.format(item.start_date * 1000, 'DD.MM.YYYY hh:mm:ss'),
                            sort: 'start_date',
                        }),
                        (item) => ({
                            name: 'End date',
                            content: Tools.date.format(item.end_date * 1000, 'DD.MM.YYYY hh:mm:ss'),
                            sort: 'end_date',
                        }),
                        (item) => ({
                            name: 'Enabled',
                            content: (
                                <Checkbox
                                    value={item.is_enabled}
                                    isDisabled={false}
                                    onClick={(event: React.MouseEvent) => {
                                        event.preventDefault();
                                    }}
                                />
                            ),
                            sort: 'is_enabled',
                            onClick: item.is_enabled ? () => handleDisable(item.id) : () => handleEnable(item.id),
                            disabled: permissions?.admin,
                        }),
                    ],
                    actions: [
                        (item) => ({
                            type: 'edit',
                            link: LinkHelper.dataSpecialOfferEditId(item.id),
                            disabled: permissions?.admin,
                        }),
                        (item) => ({
                            type: 'open',
                            link: LinkHelper.dataSpecialOfferId(item.id),
                        }),
                    ],
                }}
            </Table2>
        );
    }, [permissions, get, getData]);

    return (
        <Layout name="data" breadcrumbs={[{ name: 'Special offers', current: true }]}>
            {modal}
            <h1>
                Special offers
                <Button color="brown" text="Create offer" onClick={() => navigator(LinkHelper.dataSpecialOffersAdd)} disabled={permissions?.admin} />
            </h1>
            {table}
        </Layout>
    );
};
