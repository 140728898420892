import { Base, Response } from '@.api/methods';

import { FilesUploadResponseType } from './types';

export class Files extends Base {
    public upload(data: { file: File }, handleProgress?: (progress: number) => void) {
        this.setPath('upload-file');
        this.setFormData(data);
        this.handleUploadProgress((event) => handleProgress && handleProgress((event.loaded / event.total) * 90));

        return this.postRequest() as Response<FilesUploadResponseType>;
    }
}
