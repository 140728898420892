import Cookies, { CookieAttributes } from 'js-cookie';

import { settings } from '../../settings';
import { CookieMethods, IClear, IGet, ISet } from './types';

const clear: IClear = () => Cookies.remove(settings.cookieName);

const get: IGet = () => {
    const cookie = Cookies.get(settings.cookieName)?.split(';');

    if (!cookie || cookie.length < 2) return null;

    const remember = cookie[2];

    return {
        access: cookie[0],
        refresh: cookie[1],
        remember: remember && remember === '1' ? remember : '0',
    };
};

const set: ISet = (tokens, remember) => {
    let cookieRemember: '0' | '1' | undefined = remember ? '1' : '0';

    if (remember === undefined) {
        cookieRemember = get()?.remember;
    }

    const setCookies = (options?: CookieAttributes) => Cookies.set(settings.cookieName, `${tokens.access};${tokens.refresh};${cookieRemember}`, options);

    if (remember) setCookies({ expires: settings.cookieExpire });
    else setCookies();
};

export const AuthCookies: CookieMethods = {
    clear,
    get,
    set,
};
