import { FC, useEffect, useRef } from 'react';

import { useTrustedState } from '@/Components/Hooks';
import { ButtonWrapper } from '@/Components/UI';

import { Switch, SwitcherProps } from './types';

const SwitchValues = Object.values(Switch);

type Item = HTMLDivElement | null;

export const DateSwitcher: FC<SwitcherProps> = ({ onChange, value, initialValue = Switch.Month }) => {
    const selectorRef = useRef<HTMLDivElement>(null),
        refs = useRef<[Item, Item, Item]>([null, null, null]);

    const [offset, setOffset, isTrusted] = useTrustedState<Switch>(initialValue);

    const handleSwitch = (value: Switch) => setOffset(value);

    useEffect(() => {
        if (onChange && (isTrusted || isTrusted === undefined)) {
            onChange(offset);
        }

        const selector = selectorRef.current;

        if (!selector) return;

        SwitchValues.forEach((offe, index) => {
            if (offe !== offset) return;
            const currentItem = refs.current[index];

            if (!currentItem) return;

            selector.style.left = `${currentItem.offsetLeft}px`;
            selector.style.width = `${currentItem.clientWidth}px`;
        });
    }, [offset]);

    useEffect(() => {
        setOffset(() => {
            if (!value || !Object.values(Switch).includes(value as Switch)) {
                return Switch.Month;
            }

            return value;
        }, false);
    }, [value]);

    return (
        <div className="switcher">
            <div className="switcher__selector" ref={selectorRef} />
            {Object.entries(Switch).map(([key, value], index) => {
                return (
                    <ButtonWrapper onClick={() => handleSwitch(value)} key={`switcher-item-${key}`} contents>
                        <div className="switcher__item" ref={(el) => (refs.current[index] = el)}>
                            {key}
                        </div>
                    </ButtonWrapper>
                );
            })}
        </div>
    );
};
