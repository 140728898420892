import cn from 'classnames';
import React, { FC, useEffect, useRef } from 'react';

import { ButtonWrapper } from '@.ui';
import { useTrustedState } from '@/Components/Hooks';

import S from './styles.module.scss';

type Item = HTMLDivElement | null;

export interface SwitcherProps {
    value?: number | string;
    initialValue?: number | string;
    onChange?: (value: number | string) => void;
    SwitcherValues: { [key: string]: number | string };
    errors?: string[];
}

export const Switcher: FC<SwitcherProps> = ({ onChange, initialValue, value, SwitcherValues, errors }) => {
    const selectorRef = useRef<HTMLDivElement>(null);
    const refs = useRef<[Item, Item, Item]>([null, null, null]);

    const getInitialValue = () => {
        if (initialValue) return initialValue;

        const values = Object.values(SwitcherValues);

        const middleIndex = Math.floor(values.length / 2);

        return values.length % 2 === 0 ? values[0] : values[middleIndex];
    };

    const [offset, setOffset, isTrusted] = useTrustedState<number | string>(initialValue || getInitialValue());
    const handleSwitch = (value: number | string) => setOffset(value);

    useEffect(() => {
        if (onChange && (isTrusted || isTrusted === undefined)) {
            onChange(offset);
        }

        const selector = selectorRef.current;

        if (!selector) return;

        Object.values(SwitcherValues).forEach((offe, index) => {
            if (offe.toString() !== offset.toString()) return;

            const currentItem = refs.current[index];

            if (!currentItem) return;

            selector.style.left = `${currentItem.offsetLeft}px`;
            selector.style.width = `${currentItem.clientWidth}px`;
        });
    }, [offset]);

    useEffect(() => {
        if (value) {
            setOffset(() => value, true);
        }
    }, [value]);

    return (
        <div>
            {errors && <span className={S.errors}>{errors[0]}</span>}
            <div className={cn(S.switcher, errors && S.switcher__errors)}>
                <div className={S.switcher__selector} ref={selectorRef} />
                {Object.entries(SwitcherValues).map(([key, value], index) => {
                    return (
                        <ButtonWrapper onClick={() => handleSwitch(value)} key={`switcher-item-${key}`} contents>
                            <div className={S.switcher__item} ref={(el) => (refs.current[index] = el)}>
                                {key}
                            </div>
                        </ButtonWrapper>
                    );
                })}
            </div>
        </div>
    );
};
