import React, { FC, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Api } from '@.api';
import { Layout } from '@.layout';
import { ProductProposal } from '@/Services/api/methods/ProductsProposals';

import { ProductProposalsDetailView } from './ProductProposalsDetailView';

const useProductProposalDetail = () => {
    const { id } = useParams();

    const [product, setProduct] = useState<ProductProposal>();

    const loadProduct = useCallback((id: string | number) => {
        Api.productsProposals()
            .getById(id)
            .onSuccess((response) => setProduct(response.data));
    }, []);

    const updateProduct = useCallback(() => {
        if (id) {
            loadProduct(id);
        }
    }, [id, loadProduct]);

    useEffect(() => {
        updateProduct();
    }, [updateProduct]);

    return {
        product,
        updateProduct,
    };
};

export const ProductProposalsDetail: FC = () => {
    const { product, updateProduct } = useProductProposalDetail();

    return <Layout name="ProductProposalsDetail">{product && <ProductProposalsDetailView updateProductProposal={updateProduct} product={product} />}</Layout>;
};
