import { requiredRule, ValidateOptions } from '@.hooks';

import { ManagementFilesData } from './types';

export const validateOptions: ValidateOptions<ManagementFilesData> = {
    name: {
        type: 'string',
        required: requiredRule,
    },
    type: {
        type: 'number',
        required: requiredRule,
    },
    version: {
        type: 'string',
        required: requiredRule,
    },
    description: {
        type: 'string',
    },
    file_id: {
        type: 'file',
        required: requiredRule,
    },
    date: {
        type: 'number[]',
    },
};
