import { isProductCompanyAdmin } from '@/Lib/isProductCompanyAdmin';
import { isProductManagerRole } from '@/Lib/isProductManagerRole';
import { User } from '@/Services/Auth/types';
import { ProductProposalsStatusEnum } from '@/Types/ProductProposals';

export class ProductProposalPermissionUtils {
    public static isCanEditProductProposal(productProposalStatus: ProductProposalsStatusEnum, user?: User): boolean {
        return this.isAllowToUpdateProductProposal(user) && productProposalStatus !== ProductProposalsStatusEnum.APPROVED;
    }

    public static isCanPendProductProposal(productProposalStatus: ProductProposalsStatusEnum, user?: User): boolean {
        return (
            this.isAllowToUpdateProductProposal(user) &&
            (productProposalStatus === ProductProposalsStatusEnum.DRAFT || productProposalStatus === ProductProposalsStatusEnum.REJECTED)
        );
    }

    public static isAllowToUpdateProductProposal(user?: User): boolean {
        const isProductManager = isProductManagerRole(user);
        const isCompanyAdmin = isProductCompanyAdmin(user);

        return isProductManager || isCompanyAdmin;
    }
}
