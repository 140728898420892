import { FC, useEffect, useMemo, useRef, useState } from 'react';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';

import { useDrag, useObjectEffect, useRefEffect } from '@/Components/Hooks';
import { Api } from '@/Services/api';
import { FileType } from '@/Types';

import { BaseProps, FileItem } from './types';

export const Base: FC<BaseProps> = ({
    accepts,
    max,
    single,
    onChange,
    onChangeFull,
    onFile,
    value,
    maxSize = 1048576,
    errors,
    className,
    itemRender,
    uploadRender,
    sizeRender,
    disabled,
    onClear,
}) => {
    const dropRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const [files, setFiles] = useState<File[]>();
    const [error, setError] = useState<string>();
    const [items, setItems] = useState<FileItem[]>([]);
    const [deleted, setDeleted] = useState<number[]>([]);

    useObjectEffect(() => {
        const uploaded = items.filter((item) => typeof item.id === 'number') as FileType[];

        if (single) {
            onChange && onChange(uploaded[0]?.id);
            onChangeFull && onChangeFull(uploaded[0]);
            return;
        }

        onChange && onChange(uploaded.map((item) => item.id));
        onChangeFull && onChangeFull(uploaded);
    }, [items]);

    useEffect(() => {
        if (!files) return;

        new Promise<File[]>((resolve, reject) => {
            setItems((prev) => {
                if (single) {
                    prev.splice(0, undefined);
                    resolve(files.splice(0, 1));
                    return prev;
                }

                if (max) {
                    if (prev.length >= max) return prev;
                    resolve(files.splice(max - prev.length, undefined));
                    return prev;
                }
                resolve(files);

                return prev;
            });
        }).then((resFiles) => {
            if (onFile) {
                onFile((single ? resFiles.at(0) : resFiles) as File & File[]);
            }

            resFiles.forEach((file) => {
                const uuid = uuidv4();

                if (onFile) {
                    setItems((prev) => [...prev, { uuid, progress: 100, name: file.name }]);

                    return;
                }

                setItems((prev) => [...prev, { uuid }]);

                Api.files()
                    .upload({ file }, (progress) => handleProgress(uuid, progress))
                    .onSuccess((res) => {
                        setItems((prev) => {
                            const found = prev.find((item) => item.uuid === uuid);

                            if (found) {
                                const { id, name, url } = res.data;

                                found.id = id;
                                found.name = name;
                                found.url = url;
                                found.progress = 100;

                                return [...prev];
                            }

                            return prev;
                        });
                    })
                    .onError(() => {
                        setItems((prev) => {
                            const index = prev.findIndex((item) => item.uuid === uuid);

                            if (index) return prev.slice(index, 1);

                            return prev;
                        });
                    });
            });
        });

        setFiles(undefined);
    }, [files]);

    useObjectEffect(() => {
        if (!value) return;

        setItems((prev) => {
            const exists = (id: number) => {
                return deleted.includes(id) || prev.find((item) => item.id === id);
            };

            if (Array.isArray(value)) return [...prev, ...value.filter((val) => !exists(val.id)).map((val) => ({ ...val, uuid: uuidv4() }))];
            else if (!exists(value.id)) return [...prev, { ...value, uuid: uuidv4() }];

            return prev;
        });
    }, [value, deleted]);

    useRefEffect(
        (input) => {
            input.value = '';
        },
        [files],
        inputRef
    );

    useEffect(() => {
        if (error) dropRef.current?.classList.add('error');

        const timeout = setTimeout(() => {
            dropRef.current?.classList.remove('error');
        }, 1000);

        return () => clearTimeout(timeout);
    }, [error]);

    const acceptTypes = useMemo(
        () =>
            accepts
                ?.map((accept) => {
                    return accept.type.map((type) => type.toLowerCase());
                })
                .flat(),
        [accepts]
    );

    // const removeAll = () => setFiles([]);

    const getSizeText = (size: number) => {
        const sizes = ['b', 'Kb', 'Mb', 'Gb'];
        let sizeText = '';
        let i = 0;

        while (size > 1) {
            sizeText = Math.round(size * 10) / 10 + sizes[i];
            size /= 1023;
            i++;
        }

        return sizeText;
    };

    const handleDrop = (files: File[]) => {
        setFiles(filterFiles(files));
    };

    const isDragging = useDrag(
        {
            areaRef: dropRef,
            onDrop: handleDrop,
        },
        [single, items.length, max]
    );

    const handleClick = () => inputRef.current?.click();

    const handleRemove = (uuid: string, id?: number) => {
        if (id) setDeleted((prev) => [...prev, id]);

        setItems((prev) => {
            const index = prev.findIndex((item) => item.uuid === uuid);

            if (index !== -1) prev.splice(index, 1);

            return prev;
        });
    };

    const handleProgress = (uuid: string, progress: number) => {
        setItems((prev) => {
            const found = prev?.find((item) => item.uuid === uuid);

            if (found) {
                found.progress = progress;
                return [...prev];
            }

            return prev;
        });
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.currentTarget.files;

        if (files) setFiles(filterFiles(Array.from(files)));
    };

    const filterFiles = (files: File[]) => {
        setError(undefined);

        if (acceptTypes) {
            const filtered: File[] = [];

            files.forEach((file) => {
                const fileExt = file.name.slice(file.name.lastIndexOf('.') + 1).toLowerCase();

                if (!acceptTypes.includes(fileExt)) {
                    setError('File type not permitted');
                } else if (maxSize !== 0 && file.size >= maxSize) {
                    setError('File exceeds size limit');
                } else {
                    filtered.push(file);
                }
            });

            return filtered;
        } else {
            return files;
        }
    };

    const renderedSize = useMemo(() => {
        if (maxSize === 0) return;

        if (sizeRender) {
            return sizeRender(getSizeText(maxSize));
        }

        return `*Max size ${getSizeText(maxSize)}`;
    }, [maxSize, sizeRender]);

    const uploadItem = useMemo(() => uploadRender({ ref: dropRef, handleClick, isDragging, error: !!error }), [uploadRender, isDragging, error]);

    const renderedItems = useMemo(
        () =>
            items.map((item, index) =>
                itemRender(item, index, {
                    handleRemove() {
                        onClear && onClear();
                        handleRemove(item.uuid, item.id);
                    },
                })
            ),
        [itemRender, items]
    );

    return (
        <div className={'uploader' + (className ? ` ${className}` : '') + (disabled ? ' disabled' : '')}>
            <div className="uploader__header">
                {/* {`*Max size ${getSizeText(maxSize)}, best ratio 3:4 `} */}
                <p>{renderedSize}</p>
                {!!accepts?.length && <p>{`Permitted formats: ${accepts.map((accept) => accept.type.join(', '))}`}</p>}
                {errors?.length || error ? <p className="uploader-error">{error ?? errors?.[0]}</p> : <></>}
            </div>
            <div className="uploader__items">
                <>
                    {!((single && items.length) || (max && items.length >= max)) && uploadItem}

                    {renderedItems}
                </>
            </div>
            <input type="file" className="hidden" ref={inputRef} onChange={handleInputChange} />
        </div>
    );
};
