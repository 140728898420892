import React, { useCallback } from 'react';

import { Api } from '@.api';
import { Layout } from '@.layout';
import { Table2 } from '@.partials';
import { Filter3 } from '@.ui';
import { LinkHelper, Tools } from '@/Services';
import { AuthLogsResponse } from '@/Services/api/methods/authLogs/types';
import { Request } from '@/Types';

export const AuthLogs: React.FC = () => {
    const get = useCallback((q?: Request.All) => Api.auth_logs().getAll(q), []);

    const getData = useCallback((d: AuthLogsResponse) => d.auth_logs, []);

    return (
        <Layout breadcrumbs={[{ name: 'Auth logs', current: true }]}>
            <h1>Auth logs</h1>
            <Table2 get={get} getData={getData} export="auth_logs">
                {{
                    filters: (handler) => (
                        <Filter3 onChange={handler}>
                            <Filter3.Search />
                            <Filter3.Boolean placeholder="Success" name="is_successfully" />
                            <Filter3.Date />
                        </Filter3>
                    ),
                    items: [
                        (item) => ({
                            name: 'ID',
                            max: 20,
                            content: item?.id,
                            sort: 'id',
                            link: LinkHelper.authLogId(item.id),
                        }),
                        (item) => ({
                            name: 'Time',
                            max: 20,
                            content: item?.created_at ? Tools.date.autoFormat(item.created_at * 1000) : '',
                            sort: 'created_at',
                        }),
                        (item) => ({
                            name: 'User',
                            max: 20,
                            content: item?.login,
                            sort: 'login',
                        }),
                        (item) => ({
                            name: 'Success',
                            content: item?.is_successfully,
                            sort: 'is_successfully',
                        }),
                    ],
                    actions: [
                        (item) => ({
                            type: 'open',
                            link: LinkHelper.authLogId(item.id),
                        }),
                    ],
                }}
            </Table2>
        </Layout>
    );
};
