import React, { useMemo } from 'react';
import { Route, Routes } from 'react-router-dom';

import { useForbidden } from '@/Components/Hooks';

import { PageListK, RoutePageK } from '..';
import { useAuthPages } from '../useAuthPages';
import { PageList } from './types';

export const useAuthRoutes = (pageList: PageList): React.ReactNode => {
    const pages = useAuthPages(pageList);
    const modal = useForbidden();

    const generatePage = (page: RoutePageK) => (
        <Route
            path={page.url}
            element={page.component && React.cloneElement(page.component, { permissions: page.userPermissions })}
            key={`Router_Page_${page.url}`}
        >
            {page.children && generatePages(page.children)}
        </Route>
    );

    const generatePages = (pages: PageListK) => pages.map((page) => generatePage(page));

    const routes: React.ReactNode = useMemo(() => generatePages(pages), [pages]);

    return (
        <>
            <Routes>{routes}</Routes>
            {modal}
        </>
    );
};
