import { Base, Response } from '@.api/methods';

import { ImportProductsData } from './types';

export class ImportBO extends Base {
    public products(data: ImportProductsData, handleProgress?: (progress: number) => void) {
        this.setPath('import/products');
        this.handleUploadProgress((event) => handleProgress && handleProgress((event.loaded / event.total) * 90));
        this.setFormData(data);

        return this.postRequest() as Response;
    }
}
