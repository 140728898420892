import React, { FC, useEffect, useState } from 'react';

import { useDictionaries, useStickyShadowHead } from '@.hooks';
import { Board } from '@.partials';
import { AnalyticsPageFilter } from '@/Components/Pages/AnalyticsPage/AnalyticsPageFilter';
import { AnalyticsPageTariffsButtons } from '@/Components/Pages/AnalyticsPage/AnalyticsPageTariffsButtons';
import { StatisticObjectEnum } from '@/Services/StatisticPermissionService';
import { analyticsStoreSelectors } from '@/Store';

import S from './styles.module.scss';

const useAnalyticsPageHeader = () => {
    const { statistic_object } = analyticsStoreSelectors.use.statisticRequest();

    const [dictionaries] = useDictionaries('Companies', 'StoreGroups', 'Stores');

    const [pageTitle, setPageTitle] = useState<string>('My Analytics');

    /**
     * Update page title when open analytics.
     */
    useEffect(() => {
        if (!statistic_object || !dictionaries) {
            return;
        }

        const { type, id } = statistic_object;

        const createPageTitle = (statisticObject: StatisticObjectEnum, objectName = ''): string => {
            const titlesMap: Record<string, string> = {
                [StatisticObjectEnum.STORE_GROUP]: 'Retail chain',
                [StatisticObjectEnum.STORE]: 'Retail store',
                [StatisticObjectEnum.COMPANY]: 'Company',
            };

            return `My Analytics: ${titlesMap[statisticObject]} | ${objectName}`;
        };

        if (type === StatisticObjectEnum.STORE_GROUP) {
            const storeGroup = dictionaries.store_groups?.find((storeGroup) => storeGroup.id === id);

            setPageTitle(createPageTitle(type, storeGroup?.name));
        } else if (type === StatisticObjectEnum.STORE) {
            const store = dictionaries.stores?.find((store) => store.id === id);

            setPageTitle(createPageTitle(type, store?.name));
        } else if (type === StatisticObjectEnum.COMPANY) {
            const store = dictionaries.companies?.find((company) => company.id === id);

            setPageTitle(createPageTitle(type, store?.name));
        }
    }, [statistic_object, dictionaries]);

    return {
        pageTitle,
    };
};

export const AnalyticsPageHeader: FC = () => {
    const { refComponent } = useStickyShadowHead();

    const { pageTitle } = useAnalyticsPageHeader();

    return (
        <Board.Item head ref={refComponent}>
            <div className={S.title}>
                <h1>{pageTitle}</h1>

                <AnalyticsPageTariffsButtons />
            </div>

            <AnalyticsPageFilter />
        </Board.Item>
    );
};
