import { requiredRule, ValidateOptions } from '@.hooks';

import { ProductData } from './types';

export const validateOptions = (isUDIRequired: boolean): ValidateOptions<ProductData> => ({
    name: {
        type: 'lingual',
        required: requiredRule,
    },
    description: {
        type: 'lingual',
    },
    udi: isUDIRequired
        ? {
              type: 'number',
              required: requiredRule,
              min: {
                  value: 0,
                  message: 'Field $field should not be smaller than $value',
              },
              /**
               * TODO: what is '4294967295'.
               */
              max: { value: 4294967295, message: 'Field $field should not be greater than $value' },
          }
        : undefined,
    brand_id: {
        type: 'number',
        required: requiredRule,
    },
    product_type_id: {
        type: 'number',
        required: requiredRule,
    },
    ages_ids: {
        type: 'number[]',
    },
    sexes_ids: {
        type: 'number[]',
        required: requiredRule,
    },
    strength_id: {
        type: 'number',
        required: requiredRule,
    },
    occasions_ids: {
        type: 'number[]',
        required: requiredRule,
    },
    ingredients_ids: {
        type: 'number[]',
        required: requiredRule,
    },
    characters_ids: {
        type: 'number[]',
        required: requiredRule,
    },
    tags_ids: {
        type: 'number[]',
    },
    images_ids: {
        type: 'number[]',
    },
});
