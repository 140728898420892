import { Base, Response } from '@.api/methods';
import { Request } from '@/Types';

import { CompaniesResponseType, CompanyResponseType, CreateCompaniesType } from './types';

export class Companies extends Base {
    public getAll(query?: Request.All) {
        this.setPath('companies');
        if (query) {
            this.setQuery(query);
        }

        return this.getRequest() as Response<CompaniesResponseType>;
    }

    public getById(id: number) {
        this.setPath('companies');
        this.setId(id);

        return this.getRequest() as Response<CompanyResponseType>;
    }

    public create(data: CreateCompaniesType) {
        this.setPath('companies');
        this.setData(data);

        return this.postRequest();
    }

    public update(data: CreateCompaniesType, id: number) {
        this.setPath('companies');
        this.setId(id);
        this.setData(data);

        return this.putRequest();
    }

    public block(id: number) {
        this.setPath('companies');
        this.setId(`${id}/block`);

        return this.postRequest();
    }

    public unblock(id: number) {
        this.setPath('companies');
        this.setId(`${id}/unblock`);

        return this.postRequest();
    }
}
