import { FC, useState } from 'react';
import { v4 as uuid } from 'uuid';

import { useObjectEffect, useTrustedState } from '@/Components/Hooks';
import { DatePicker, DateSwitcher } from '@/Components/UI';
import { Switch } from '@/Components/UI/DateSwitcher/types';
import { Tools } from '@/Services';
import { Maybe } from '@/Types/Maybe';

import { DateFilterProps } from './types';

/**
 * TODO: Extract to independent component.
 */
export const DateFilter: FC<DateFilterProps> = ({
    full,
    switcher,
    onChange,
    value,
    initialValue = [undefined, undefined],
    initialSwitchValue = Switch.Month,
}) => {
    const [dateRange, setDateRange, isTrusted] = useTrustedState<[number | undefined, number | undefined]>(initialValue);
    const [offset, setOffset] = useState<Maybe<Switch>>(initialSwitchValue);
    const [isOffsetChanged, setOffsetChanged] = useState<string>('');

    const handleDatePick = (range?: [number | undefined, number | undefined]) => {
        const [from, to] = range ?? [undefined, undefined];

        if (!from && !to) {
            setOffset(Switch.Month);
        }

        setDateRange([from, to]);
        setOffset(initialSwitchValue);
    };

    const handleOffsetPick = (offset: Switch) => {
        if (!offset) {
            setDateRange([undefined, undefined]);
            return;
        }

        setOffsetChanged(uuid());

        setDateRange(() => {
            switch (offset) {
                case Switch.Day:
                    return [Tools.date.create().dayStart().timestamp(), Tools.date.create().dayEnd().timestamp()];
                case Switch.Month: {
                    const currentDate = Tools.date.create();

                    return [
                        Tools.date.create(Date.UTC(currentDate.getUTCFullYear(), currentDate.getUTCMonth(), 1)).dayStart().timestamp(),
                        Tools.date.create(Date.UTC(currentDate.getUTCFullYear(), currentDate.getUTCMonth(), currentDate.lastDay())).dayEnd().timestamp(),
                    ];
                }
                case Switch.Year: {
                    const currentYear = new Date().getUTCFullYear();

                    return [
                        Tools.date.create(Date.UTC(currentYear, 0, 1)).dayStart().timestamp(),
                        Tools.date
                            .create(Date.UTC(currentYear, 11, Tools.date.create(Date.UTC(currentYear, 11, 1)).lastDay()))
                            .dayEnd()
                            .timestamp(),
                    ];
                }
            }
        });
    };

    useObjectEffect(() => {
        if (onChange && isTrusted) onChange(dateRange);
    }, [dateRange]);

    useObjectEffect(() => {
        if (!value) {
            return;
        }

        setDateRange(value, false);
    }, [value]);

    return (
        <>
            {switcher && <DateSwitcher initialValue={initialSwitchValue} value={offset} onChange={handleOffsetPick} />}
            <DatePicker value={dateRange} onChange={handleDatePick} full={full} key={isOffsetChanged} />
        </>
    );
};
