import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { Api } from '@.api';
import { Layout } from '@.layout';
import { Table2 } from '@.partials';
import { LinkHelper } from '@.services';
import { PermissionProps, Request } from '@.types';
import { Button, Filter3 } from '@.ui';
import { StoresResponseType } from '@/Services/api/methods';

import { StoresAction } from './Action';
import { Store } from './Store';
import { StoresTableProps } from './types';

export { Store, StoresAction };

export const Stores: React.FC<PermissionProps> = (props) => {
    const navigator = useNavigate();

    return (
        <Layout name="clients" breadcrumbs={[{ name: 'Stores', current: true }]}>
            <h1>
                Stores
                <Button color="brown" text="Add store" onClick={() => navigator(LinkHelper.clientsStoresAdd)} disabled={!props.permissions?.create} />
            </h1>
            <StoresTable permissions={props.permissions} />
        </Layout>
    );
};

export const StoresTable: React.FC<StoresTableProps> = ({ company_id, permissions, store_group_id, hideActions }) => {
    const get = useCallback((q?: Request.All) => Api.stores().getAll(q), []);
    const handleDelete = useCallback((id: number) => Api.stores().delete(id), []);
    const handleRestore = useCallback((id: number) => Api.stores().restore(id), []);

    const getData = useCallback((data: StoresResponseType) => data.stores, []);
    const item = useCallback((item: StoresResponseType['stores'][number]) => ({ className: item.deleted_at ? 'deleted' : '' }), []);

    const filters = useMemo(() => ({ company_id, store_group_id }), [company_id, store_group_id]);

    return (
        <Table2 filters={filters} get={get} getData={getData} export="stores" item={item}>
            {{
                filters: (handler) => (
                    <Filter3 onChange={handler}>
                        <Filter3.Search />
                        {!company_id && <Filter3.Companies related />}
                        {!store_group_id && <Filter3.StoreGroups related />}
                        <Filter3.Boolean name="is_archived" placeholder="Archived" />
                    </Filter3>
                ),
                items: [
                    (item) => ({
                        content: item.name,
                        link: LinkHelper.clientsStoreId(item.id),
                        name: 'Name',
                        max: 20,
                        sort: 'name',
                    }),
                    (item) => ({
                        content: item.company.name,
                        max: 30,
                        name: 'Company',
                        sort: 'company',
                    }),
                    (item) => ({
                        content: item.store_group.name,
                        max: 30,
                        name: 'Store groups',
                        sort: 'store_group',
                    }),
                ],
                actions: [
                    (item) => ({
                        type: 'edit',
                        link: LinkHelper.clientsStoresEditId(item.id),
                        disabled: !!item.deleted_at || !permissions?.update,
                        hide: hideActions?.includes('update'),
                    }),
                    (item) => ({
                        type: item.deleted_at ? 'restore' : 'delete',
                        onClick: item.deleted_at ? () => handleRestore(item.id) : () => handleDelete(item.id),
                        disabled: !permissions?.delete,
                        hide: hideActions?.includes('delete'),
                    }),
                    (item) => ({
                        type: 'open',
                        link: LinkHelper.clientsStoreId(item.id),
                    }),
                ],
            }}
        </Table2>
    );
};
