import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Api } from '@.api';
import { SpecialOfferResponseType } from '@.api/methods';
import { useId } from '@.hooks';
import { Layout } from '@.layout';
import { ImportTable, Tab, Table, TableColumn, TableRow, Tabs } from '@.partials';
import { Button } from '@.ui';
import { SpecialOfferStatistics } from '@/Components/Pages/Data/SpecialOffers/SpecialOffer/SpecialOfferStatistics';
import { LinkHelper, Tools, useLingualField } from '@/Services';
import { PermissionProps, Request } from '@/Types';

export const SpecialOffer: React.FC<PermissionProps> = ({ permissions }) => {
    const [offer, setOffer] = useState<SpecialOfferResponseType>();
    const navigator = useNavigate();
    const get = (id: number) => {
        Api.special_offers()
            .getById(id)
            .onSuccess((res) => setOffer(res.data));
    };

    const handleDisable = () => {
        if (!offer) return;

        Api.special_offers()
            .disable(offer.id)
            .onSuccess(() => get(offer.id));
    };

    const handleEnable = () => {
        if (!offer) return;

        Api.special_offers()
            .enable(offer.id)
            .onSuccess(() => get(offer.id));
    };

    const id = useId(get);

    const getTable = (query?: Request.All) => {
        if (!id) return;

        return Api.special_offers().getProducts(id, query);
    };

    const postTable = (ids: number[]) => {
        return Api.special_offers().updateProducts(id, ids);
    };

    const offerName = useLingualField(offer);

    return (
        <Layout
            name="data"
            breadcrumbs={[
                { name: 'Special offers', link: LinkHelper.dataSpecialOffers },
                { name: offerName, current: true },
            ]}
        >
            {offer && (
                <>
                    <h1>
                        {offerName}
                        <div>
                            <Button
                                color="transparent"
                                text={offer.is_enabled ? 'Disable' : 'Enable'}
                                onClick={offer.is_enabled ? handleDisable : handleEnable}
                                disabled={permissions?.admin}
                            />
                            <Button
                                color="brown"
                                text="Edit"
                                onClick={() => navigator(LinkHelper.dataSpecialOfferEditId(offer?.id))}
                                disabled={permissions?.admin}
                            />
                        </div>
                    </h1>
                    <Tabs>
                        <Tab name="General" path="general">
                            <Table>
                                <TableRow>
                                    <TableColumn bold>Company</TableColumn>
                                    <TableColumn>{offer.company?.name}</TableColumn>
                                </TableRow>
                                <TableRow>
                                    <TableColumn bold>Store group</TableColumn>
                                    <TableColumn>{offer.store_group?.name}</TableColumn>
                                </TableRow>
                                <TableRow>
                                    <TableColumn bold>Store</TableColumn>
                                    <TableColumn>{offer.store?.name}</TableColumn>
                                </TableRow>
                                <TableRow>
                                    <TableColumn bold>Types</TableColumn>
                                    <TableColumn>{offer.types.map((type) => type.name).join(', ')}</TableColumn>
                                </TableRow>
                                {offer.discount !== null && (
                                    <TableRow>
                                        <TableColumn bold>Discount</TableColumn>
                                        <TableColumn>{`${offer.discount}%`}</TableColumn>
                                    </TableRow>
                                )}
                                {offer.discount_on_second !== null && (
                                    <TableRow>
                                        <TableColumn bold>Discount on second</TableColumn>
                                        <TableColumn>{`${offer.discount_on_second}%`}</TableColumn>
                                    </TableRow>
                                )}
                                <TableRow>
                                    <TableColumn bold>Views</TableColumn>
                                    <TableColumn>{offer.views_count}</TableColumn>
                                </TableRow>
                                <TableRow>
                                    <TableColumn bold>Start date</TableColumn>
                                    <TableColumn>{Tools.date.format(offer.start_date * 1000, 'DD.MM.YYYY hh:mm:ss')}</TableColumn>
                                </TableRow>
                                <TableRow>
                                    <TableColumn bold>End date</TableColumn>
                                    <TableColumn>{Tools.date.format(offer.end_date * 1000, 'DD.MM.YYYY hh:mm:ss')}</TableColumn>
                                </TableRow>
                            </Table>
                        </Tab>
                        <Tab name="Products" path="products">
                            <ImportTable
                                headers={['UDI', 'Name', 'Brand'] as const}
                                get={getTable}
                                post={postTable}
                                getId={(item) => item.id}
                                itemRender={(column, item) => {
                                    switch (column) {
                                        case 'UDI':
                                            return item.udi;
                                        case 'Name':
                                            return item.name;
                                        case 'Brand':
                                            return item.brand.name;
                                    }
                                }}
                                getDefault={(item) => item.is_connected}
                                getData={(data) => data.products}
                                edit={!permissions?.admin}
                            />
                        </Tab>
                        <Tab name="Statistics" path="statistics">
                            <SpecialOfferStatistics id={offer.id} />
                        </Tab>
                    </Tabs>
                </>
            )}
        </Layout>
    );
};
