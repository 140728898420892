import { Station, Stations, StationsAction } from '@.pages';
import { StationLog } from '@/Components/Pages/Stations/Station/log';

import { Routes } from '../types';

export const StationPages: Routes = [
    {
        url: 'stations',
        component: <Stations />,
        permissions: {
            entity: 'Stations',
            right: 'read',
        },
        sidebar: {
            icon: 'irs-station',
            name: 'iRomaScents Station',
        },
        children: [
            {
                url: ':id',
                component: <Station />,
                children: [
                    {
                        url: 'edit',
                        component: <StationsAction />,
                        permissions: {
                            entity: 'Stations',
                            right: 'update',
                        },
                    },
                    {
                        url: 'logs/:log_id',
                        component: <StationLog />,
                    },
                ],
            },
        ],
    },
];
