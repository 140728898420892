import { Group } from '@visx/group';
import { hierarchy, Pack } from '@visx/hierarchy';
import { scaleQuantile } from '@visx/scale';
import { useEffect, useMemo, useRef, useState } from 'react';

import { Tools } from '@/Services';

import { WithTooltip } from '../Tools';
import { CirclesProps, Item } from './types';

let tooltipTimeout: number;

export const Circles: React.FC<CirclesProps> = (props = { data: [] }) => {
    const height = 600;
    const data = useMemo(() => props.data.sort((a, b) => b.value - a.value), [props.data]);
    const svgRef = useRef<SVGSVGElement | null>(null);
    const [width, setWidth] = useState<number>(0);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if (loaded) updateWidth();
    }, [loaded]);

    useEffect(() => {
        const handleResize = () => updateWidth();

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const updateWidth = () => setWidth(svgRef.current?.clientWidth ?? 0);

    const root = useMemo(
        () =>
            hierarchy({
                children: data,
                label: 'root',
                value: 0,
            }).sum((d) => d.value * d.value),
        [data]
    );

    const [colorScale] = useMemo(
        () => [
            scaleQuantile<string>({
                domain: [0, Tools.numbers.max(data.map((d) => d.value))],
                range: ['#66C0BE40', '#66C0BE80', '#66C0BE90', '#6ABEAAB2', '#BCCC8040', '#BCCC8080', '#BCCC8095', '#BCCC80'],
            }),
        ],
        [data]
    );

    return (
        <div className="chart_circles">
            <WithTooltip color="">
                {(show, hide) => {
                    const handleMouseLeave = () => {
                        tooltipTimeout = window.setTimeout(() => {
                            hide();
                        }, 300);
                    };

                    const handleMouseMove = (d: Item, x: number, y: number) => {
                        if (tooltipTimeout) clearTimeout(tooltipTimeout);

                        const coords = { tooltipTop: y - 20, tooltipLeft: x - 20 };

                        show({
                            tooltipData: { title: d.label, value: d.value },
                            ...coords,
                        });
                    };

                    return (
                        <svg
                            width={'100%'}
                            height={height}
                            ref={(ref) => {
                                setLoaded(true);
                                svgRef.current = ref;
                            }}
                        >
                            <rect width={width} height={height} rx={14} fill="#ffffff" />

                            <Pack root={root} size={[width, height]}>
                                {(packData) => {
                                    const circles = packData.descendants().slice(1); // skip outer hierarchies
                                    return (
                                        <Group
                                        // top={-height} left={-width / 2}
                                        >
                                            {circles.map((circle, i) => (
                                                <circle
                                                    key={`circle-${i}`}
                                                    r={circle.r}
                                                    cx={circle.x}
                                                    cy={circle.y}
                                                    fill={colorScale(circle.data.value)}
                                                    onMouseLeave={handleMouseLeave}
                                                    onMouseMove={() =>
                                                        handleMouseMove({ label: circle.data.label, value: circle.data.value }, circle.x, circle.y)
                                                    }
                                                />
                                            ))}
                                        </Group>
                                    );
                                }}
                            </Pack>
                        </svg>
                    );
                }}
            </WithTooltip>
        </div>
    );
};
