import { functions } from '@.api';

export const apiPromisify = <T>(executor: functions<T>): Promise<T> => {
    return new Promise((resolve, reject) => {
        executor
            .onSuccess((response) => {
                if (response && response.data) {
                    resolve(response.data);
                } else {
                    reject(response);
                }
            })
            .onValidationError(reject)
            .onForbidden(reject)
            .onError(reject)
            .onUnauthorized(reject)
            .onNotFound(reject)
            .catch(reject);
    });
};
