import React, { useEffect, useState } from 'react';

import { useTrustedState } from '@/Components/Hooks';

import { LogicProps } from './types';

export const Logic: React.FC<LogicProps> = ({ onChange, max, min, round, regex, ...props }) => {
    const [focused, setFocused] = useState(false);
    const [value, setValue, isTrusted] = useTrustedState<number>(props.value, !(props.value === undefined));
    const [temp, setTemp] = useState('');

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let val = event.target.value;

        if (val && regex && !regex.test(val)) {
            event.preventDefault();
            return;
        }

        if (val[0] === '0' && Number(val[1]) > 0) val = val.slice(1);

        // eslint-disable-next-line no-useless-escape
        if (!/(^[1-9]+$)|(^(?:[1-9]\d*|0)(?:[\.|\,]([\d]?|[\d]+))?$)|(^(?![\s\S]))/g.test(val)) return;

        setTemp(val);
    };

    const changed = () => {
        if (!temp) return setValue(undefined);

        let number = Number(temp);

        if (isNaN(number)) return;

        if (max !== undefined && number > max) number = max;
        else if (min !== undefined && number < min) number = min;

        setValue(number);
    };

    const format = (string?: string | number) => {
        let formatString = string;

        if (formatString === undefined || formatString === null) {
            return '';
        }

        if (typeof formatString !== 'string') {
            formatString = formatString.toString();
        }

        if (round && formatString) {
            formatString = round(Number(formatString)).toString();
        }

        switch (props.format) {
            case '2digits':
                return `0${formatString}`.slice(-2);
            default:
                return formatString;
        }
    };

    const handleKeySelect = (event: KeyboardEvent) => {
        event.key === 'Enter' && changed();
    };
    const handleFocus = () => setFocused(true);
    const handleBlur = () => {
        setFocused(false);
        changed();
    };

    useEffect(() => {
        if (onChange && isTrusted) {
            onChange(value);
        }

        setTemp(format(value));
    }, [value]);

    useEffect(() => {
        setValue(props.value, false);
    }, [props.value]);

    useEffect(() => {
        window.addEventListener('keydown', handleKeySelect);

        return () => {
            window.removeEventListener('keydown', handleKeySelect);
        };
    }, [focused, temp]);

    return <input {...props} value={temp} onChange={handleChange} onFocus={handleFocus} onBlur={handleBlur} />;
};
