import React, { CSSProperties, FC } from 'react';

import { Donut } from '@.partials';
import { LegendItem } from '@/Components/Partials/Charts/Donut/LegendItem';
import { DonutLegendType } from '@/Components/Partials/Charts/Donut/types';

import S from './styles.module.scss';

export const DonutLegend: FC<DonutLegendType> = ({ legendItems, columns }) => {
    const donutLegendCSSProperties: CSSProperties = {
        gridTemplateColumns: '1fr '.repeat(columns),
    };

    return (
        <div className={S.legend} style={donutLegendCSSProperties}>
            {legendItems.map((legend, index) => (
                <LegendItem key={index}>
                    <Donut.LegendCircle color={legend.color} />
                    {legend.label}
                </LegendItem>
            ))}
        </div>
    );
};
