import React, { useCallback, useImperativeHandle, useRef } from 'react';

import { useTooltip } from '@/Components/Hooks';

interface ToolputProps {
    tooltip?: boolean;
    readOnly?: boolean;
    value?: string;
    onClick?: () => void;
    onChange?: (value: string) => void;
}

export const Toolput = React.forwardRef<HTMLInputElement, ToolputProps>(({ tooltip: hasTooltip, onChange, ...props }, ref) => {
    const [tooltip, setTooltip, setOpen] = useTooltip({});

    const inputRef = useRef<HTMLInputElement>(null);

    useImperativeHandle(ref, () => inputRef.current as HTMLInputElement);

    const handleIn = useCallback(() => {
        if (!hasTooltip) {
            setOpen(false);
            return;
        }

        const option = inputRef.current;

        if (!option) return;

        const rect = option.getBoundingClientRect();

        setTooltip({
            content: props.value,
            coordinates: { x: rect.left + 10, y: rect.top },
            offsetX: rect.width,
            open: !!props.value,
        });
    }, [hasTooltip, props.value, setOpen, setTooltip]);

    const handleOut = () => setOpen(false);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange && onChange(event.currentTarget.value);
    };

    return (
        <>
            {tooltip}
            <input {...props} onChange={handleChange} ref={inputRef} onMouseEnter={handleIn} onMouseLeave={handleOut} />
        </>
    );
});
