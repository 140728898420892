import { ScenticonUser, ScenticonUsers, ScenticonUsersAction } from '@.pages';

import { RoutePage } from '../types';

export const ScenticonUsersPage: RoutePage = {
    url: 'irc-users',
    component: <ScenticonUsers />,
    permissions: 'admin',
    sidebar: {
        name: 'Scenticon Users',
    },
    children: [
        {
            url: ':id',
            component: <ScenticonUser />,
            children: [
                {
                    url: 'edit',
                    component: <ScenticonUsersAction edit />,
                    permissions: 'admin',
                },
            ],
        },
        {
            url: 'add',
            component: <ScenticonUsersAction />,
            permissions: 'admin',
        },
    ],
};
