import React, { ReactElement, ReactNode } from 'react';

import { OptionProps } from '../../option/types';

export interface BaseProps {
    errors?: string[];
    children?: ReactNode;
    placeholder?: string;
    absolute?: boolean;
    theme?: 'white' | 'default';
    hideList?: boolean;
    disabled?: boolean;
    autoClose?: boolean;
    required?: boolean;
    label?: string;
}

export interface BaseState {
    options?: Option[];
    visibleOptions?: Option[];
    isOpen?: boolean;
    before: React.ReactNode;
    after: React.ReactNode;
    selected?: unknown;
    inputValue?: string;
    search?: string;
    isTrusted?: boolean;
    hasTooltip?: boolean;
}

export type OptionElement = ReactElement<OptionProps>;

export interface Option<T = unknown> {
    id: number;
    content: React.ReactNode;
    selectedContent?: string;
    value: T;
}

export interface NonNullableRefObject<T> {
    readonly current: T;
}

export type OptionListRefs = NonNullableRefObject<{ [name: string]: HTMLDivElement | null }>;
export type SelectedOptionsListRef = NonNullableRefObject<{ [name: string]: HTMLDivElement | null }>;

export enum Class {
    Selected = 'selected',
    Absolute = 'absolute',
}

export type idsClassActions = (number | string | undefined | Option)[];
