import { LanguageIcon } from './types';

export const Usa: LanguageIcon = ({ id = 'clip0_970_19657' }) => (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath={`url(#${id})`}>
            <path
                d="M11.0002 21.9996C17.075 21.9996 22 17.0742 22 10.9998C22 4.92541 17.0754 0 11.0002 0C4.92495 0 0.000396729 4.92584 0.000396729 10.9998C0.000396729 17.0737 4.92538 21.9996 11.0002 21.9996Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.3743 5.25627H10.5054V2.38387H17.8383C18.8453 3.18552 19.7036 4.15775 20.3743 5.25627ZM21.9998 10.9998H10.5115V8.12825H21.6212C21.8733 9.06459 22.0006 10.0301 21.9998 10.9998ZM11 21.9996C13.4868 22.0053 15.9012 21.1626 17.8444 19.6106H4.15608C6.09889 21.1628 8.51325 22.0056 11 21.9996ZM20.3863 16.7446H1.61366C1.06613 15.8517 0.650349 14.8844 0.379196 13.8726H21.6208C21.3497 14.8844 20.934 15.8517 20.3863 16.7446Z"
                fill="#D80027"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.10713 1.71184H5.09725V1.71871L5.10713 1.71184ZM5.10713 1.71184H6.10184L5.16471 2.38428L5.5192 3.48426L4.58421 2.81181L3.65525 3.48426L3.96075 2.5351C3.13967 3.22231 2.42329 4.02569 1.83427 4.91982H2.15825L1.56529 5.3495L1.29631 5.82215L1.57732 6.69611L1.04581 6.3094L0.67929 7.16275L0.984792 8.12093H2.14493L1.22283 8.80025L1.57732 9.90023L0.642338 9.22778L0.0923486 9.63082C0.0309742 10.0846 0.000254113 10.5419 0.000397226 10.9998H11.0002V-5.99384e-06C8.91283 -0.00272076 6.86818 0.591219 5.10713 1.71184ZM5.51705 9.89465L5.52521 9.90109H5.5192L5.51705 9.89465ZM5.16428 8.80068L5.51705 9.89465L4.58379 9.22864L3.6488 9.90109L4.00329 8.80111L3.06831 8.12866H4.22844L4.58293 7.02869L4.93741 8.12866H6.09754L5.16428 8.80068ZM5.17073 5.59871L5.52521 6.69869L4.59023 6.02624L3.65525 6.69869L4.00973 5.59871L3.07475 4.92627H4.23489L4.58937 3.82629L4.94386 4.92627H6.10399L5.17073 5.59871ZM8.53769 9.22864L9.47267 9.90109L9.11819 8.80111L10.0549 8.12866H8.89476L8.54027 7.02869L8.18579 8.12866H7.02565L7.96063 8.80111L7.60615 9.90109L8.53769 9.22864ZM9.11819 5.59871L9.47267 6.69869L8.53769 6.02624L7.60271 6.69869L7.9572 5.59871L7.02221 4.92627H8.18235L8.53683 3.82629L8.89132 4.92627H10.0515L9.11819 5.59871ZM9.47267 3.49629L9.11819 2.39631L10.0549 1.72387H8.89476L8.54027 0.623888L8.18579 1.72387H7.02565L7.96063 2.39631L7.60615 3.49629L8.54113 2.82385L9.47267 3.49629Z"
                fill="#0052B4"
            />
        </g>
        <defs>
            <clipPath id={id}>
                <rect width="22" height="22" fill="white" />
            </clipPath>
        </defs>
    </svg>
);
