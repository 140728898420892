import { AnalyticsWidgetTabsEnum } from '@/Types/analytics/AnalyticsWidgetTabsEnum';
import { AnalyticWidgetsEnum } from '@/Types/analytics/AnalyticWidgetsEnum';

export const AnalyticsWidgetMap: Record<AnalyticsWidgetTabsEnum, AnalyticWidgetsEnum[]> = {
    [AnalyticsWidgetTabsEnum.ALL]: [
        AnalyticWidgetsEnum.AGE,
        AnalyticWidgetsEnum.GENDER,
        AnalyticWidgetsEnum.FRAGRANCE_TYPE,
        AnalyticWidgetsEnum.STRENGTH,
        AnalyticWidgetsEnum.WHIFF_COUNTS_PER_ITEM_BRAND,
        AnalyticWidgetsEnum.FAVORITE_HEART,
        AnalyticWidgetsEnum.HOW_MANY_USERS_HAVE_COMPLETED_WIZARD,
        AnalyticWidgetsEnum.CUSTOMERS_AND_WIZARD_STEPS,
    ],
    [AnalyticsWidgetTabsEnum.USERS]: [AnalyticWidgetsEnum.GENDER, AnalyticWidgetsEnum.AGE],
    [AnalyticsWidgetTabsEnum.PRODUCT]: [
        AnalyticWidgetsEnum.STRENGTH,
        AnalyticWidgetsEnum.FRAGRANCE_TYPE,
        AnalyticWidgetsEnum.FAVORITE_HEART,
        AnalyticWidgetsEnum.WHIFF_COUNTS_PER_ITEM_BRAND,
    ],
    [AnalyticsWidgetTabsEnum.BEST]: [],
    [AnalyticsWidgetTabsEnum.PRICE]: [],
    [AnalyticsWidgetTabsEnum.PROMOTIONS]: [],
};
