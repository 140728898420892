import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Icon } from '@.ui';

import { ItemProps, Sizes } from './types';

const getSizeClass = (size: Sizes) => {
    switch (size) {
        case '1/3':
            return ' third';
        case '2/3':
            return ' two-thirds';
        default:
            return '';
    }
};

export const Item = React.forwardRef<HTMLDivElement, ItemProps>(({ className, more, title, children, head, size = 'auto', additional }, ref) => {
    const navigator = useNavigate();

    return (
        <div
            className={'board_item' + (className ? ` ${className}` : '') + (head ? ` head` : '') + getSizeClass(size) + (additional ? ' additional' : '')}
            ref={ref}
        >
            {title && (
                <div className="board_item__header">
                    <h3>{title}</h3>
                    {more && (
                        <div className="board_item__header-more" onClick={() => navigator(more)}>
                            More <Icon name="chevron-right" />
                        </div>
                    )}
                </div>
            )}
            {children}
        </div>
    );
});
