import React, { FC } from 'react';
import { useSearchParams } from 'react-router-dom';

import { AnalyticsPageDateFilter } from '@/Components/Pages/AnalyticsPage/AnalyticsPageDateFilter';
import { TabButton } from '@/Components/UI/TabButton';
import { createPreventDefault } from '@/Lib';
import { AnalyticsWidgetTabsEnum } from '@/Types/analytics/AnalyticsWidgetTabsEnum';

import S from './styles.module.scss';

type WidgetFilter = {
    name: string;
    tab: AnalyticsWidgetTabsEnum;
    isDisabled?: boolean;
};

export const AnalyticsPageFilter: FC = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const widgetFilters: WidgetFilter[] = [
        {
            name: 'ALL',
            tab: AnalyticsWidgetTabsEnum.ALL,
        },
        {
            name: 'USER',
            tab: AnalyticsWidgetTabsEnum.USERS,
        },
        {
            name: 'PRODUCT',
            tab: AnalyticsWidgetTabsEnum.PRODUCT,
        },
        {
            name: 'BEST OF',
            tab: AnalyticsWidgetTabsEnum.BEST,
            isDisabled: true,
        },
        {
            name: 'PROMOTIONS',
            tab: AnalyticsWidgetTabsEnum.PROMOTIONS,
            isDisabled: true,
        },
        {
            name: 'PRICE',
            tab: AnalyticsWidgetTabsEnum.PRICE,
            isDisabled: true,
        },
    ];

    const onChangeTab = (tab: AnalyticsWidgetTabsEnum) => {
        searchParams.set('tab', tab);

        setSearchParams(searchParams);
    };

    const isActive = (tab: AnalyticsWidgetTabsEnum): boolean => {
        const searchParamsTabValue = searchParams.get('tab');

        if (!searchParamsTabValue) {
            return tab === AnalyticsWidgetTabsEnum.ALL;
        }

        return tab === searchParamsTabValue;
    };

    return (
        <div className={S.analytics_filters}>
            <AnalyticsPageDateFilter />

            <div className={S.analytics_filters_buttons}>
                {widgetFilters.map((filter) => (
                    <TabButton
                        key={filter.name}
                        onClick={createPreventDefault(() => onChangeTab(filter.tab))}
                        isActive={isActive(filter.tab)}
                        isDisabled={filter.isDisabled}
                    >
                        {filter.name}
                    </TabButton>
                ))}
            </div>
        </div>
    );
};
