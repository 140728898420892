export type NumberRange = [number | undefined, number | undefined];
export type RangeValues = number | 'now' | 'any';

export interface DatePickerProps {
    onChange?: (range?: NumberRange) => void;
    value?: NumberRange;
    range?: [RangeValues, RangeValues];
    placeholderFrom?: string;
    placeholderTo?: string;
    full?: boolean;
    single?: boolean;
    errors?: string[];
    required?: boolean;
}

export interface DatePickerData {
    from: number;
    to: number;
    hours: number;
    minutes: number;
    seconds: number;
}

export enum Class {
    Open = 'open',
    Selected = 'selected',
    InRange = 'inrange',
}

export interface DateData {
    day: number;
    month: number;
    year: number;
}

export interface SelectedState {
    from: DateData | undefined;
    to: DateData | undefined;
}

export interface TimeData {
    hours: number;
    minutes: number;
    seconds: number;
}

export interface TimeState {
    from: TimeData | undefined;
    to: TimeData | undefined;
}
