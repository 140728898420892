/**
 * TODO: need thinking... maybe need move to service or class utils.
 */
export const DEFAULT_ANALYTICS_CHART_COLORS = [
    '#D3DC6C',
    '#E95283',
    '#66C0BE',
    '#ED715D',
    '#BCCC80',
    '#A16464',
    '#A3CA74',
    '#97D2C4',
    '#73B962',
    '#F5CC61',
    '#D9D9D9',
    '#F6AA59',
    '#329795',
    '#C9FFDF',
    '#FFDCDC',
];
