import { Base, Response } from '@.api/methods';
import { IrcUsersProductsResponseType } from '@/Components/Pages/Settings/ScenticonUsers/ScenticonProductsTable/types';
import { Request } from '@/Types';

import { IrcUserResponseType, IrcUsersResponseType, IrcUsersType } from './types';

export class IrcUsers extends Base {
    public getAll(query?: { page?: number; per_page?: number; search?: string; is_archived?: boolean }) {
        this.setPath('irc-users');
        if (query) this.setQuery(query);

        return this.getRequest() as Response<IrcUsersResponseType>;
    }

    public getById(id: number) {
        this.setPath('irc-users');
        this.setId(id);
        return this.getRequest() as Response<IrcUserResponseType>;
    }

    public create(data: IrcUsersType) {
        this.setPath('irc-users');
        this.setData(data);

        return this.postRequest();
    }

    public updateById(data: IrcUsersType, id: number) {
        this.setPath('irc-users');
        this.setId(id);
        this.setData(data);

        return this.putRequest();
    }

    public blockById(id: number) {
        this.setPath('irc-users');
        this.setId(`${id}`);

        return this.deleteRequest();
    }

    public unblockById(id: number) {
        this.setPath('irc-users');
        this.setId(`${id}/restore`);

        return this.postRequest();
    }

    public destroyById(id: number) {
        this.setPath('irc-users');
        this.setId(`${id}/destroy`);

        return this.deleteRequest();
    }

    public deleteById(id: number) {
        this.setPath('irc-users');
        this.setId(id);

        return this.deleteRequest();
    }

    public restoreById(id: number) {
        this.setPath('irc-users');
        this.setId(`${id}/restore`);

        return this.postRequest();
    }

    public getProducts(id: number, query?: Request.All) {
        this.setPath('irc-users');
        this.setId(`${id}/products`);
        query && this.setQuery(query);

        return this.getRequest<IrcUsersProductsResponseType>();
    }

    public updateProducts(id: number, ids: number[]) {
        this.setPath('irc-users');
        this.setId(`${id}/products`);
        this.setData({
            products_ids: ids,
        });

        return this.postRequest();
    }
}
