import { requiredRule, ValidateOptions } from '@.hooks';
import { SpecialOfferData } from '@/Components/Pages/Data/SpecialOffers/Action/types';

export const validateOptions = (discount?: boolean, discountOnSecond?: boolean): ValidateOptions<SpecialOfferData> => ({
    id: {},
    name: {
        type: 'lingual',
        required: requiredRule,
    },
    store_group_id: {
        type: 'number',
        required: requiredRule,
    },
    types: {
        type: 'number[]',
        required: requiredRule,
    },
    is_enabled: {
        type: 'boolean',
        default: false,
        required: requiredRule,
    },
    discount: {
        type: 'number',
        required: discount && requiredRule,
    },
    date: {
        type: 'date',
        required: requiredRule,
    },
    discount_on_second: {
        type: 'number',
        required: discountOnSecond && requiredRule,
    },
    store_id: {
        type: 'number',
        required: requiredRule,
    },
});
