import { validatePhone } from 'react-international-phone';

import { FeedbacksType } from '@.api/methods';
import { requiredRule, ValidateOptions } from '@.hooks';

export const validateOptions = (phone: string | undefined): ValidateOptions<FeedbacksType> => ({
    name: {
        type: 'string',
        required: requiredRule,
    },
    phone: {
        type: 'string',
        required: requiredRule,
        regex: {
            value: phone && validatePhone(phone).lengthMatch ? /.*/ : /^$/,
            message: 'Field phone is invalid',
        },
    },
    message: {
        type: 'string',
        required: requiredRule,
    },
    file_id: {
        type: 'number',
    },
});
