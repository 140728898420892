import { Link } from 'react-router-dom';

import { LinkHelper } from '@.services';
import { Icon } from '@.ui';

export const PasswordRecoverySuccess: React.FC = () => {
    return (
        <div className="password-recovery-success">
            <div className="password-recovery-success__form">
                <div className="password-recovery-success__logo">logo</div>
                <h2>Password recovery</h2>
                <p>Further instruction has been sent in your Email</p>
                <Link to={LinkHelper.login}>
                    <Icon name="chevron-left" />
                    Back to sign in
                </Link>
            </div>
        </div>
    );
};
