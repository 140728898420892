import useModal from '@fipnooone/react-modal';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { Api } from '@.api';
import { Layout } from '@.layout';
import { Table2 } from '@.partials';
import { Auth, LinkHelper } from '@.services';
import { PermissionProps, Request } from '@.types';
import { Button, Filter3 } from '@.ui';
import { ModalConfirm } from '@/Components/Modal/Dialogs';
import { ManagementFilesResponseType } from '@/Services/api/methods';

export * from './FilesAction';

export const Files: React.FC<PermissionProps> = ({ permissions }) => {
    const [modal, modalControls] = useModal();
    const auth = Auth.Use();
    const navigator = useNavigate();

    const get = useCallback((q?: Request.All) => Api.managementFiles().getAll(q), []);

    const handleDelete = useCallback((id: number) => Api.managementFiles().delete(id), []);
    const handleRestore = useCallback((id: number) => Api.managementFiles().restore(id), []);
    const handleDestroy = useCallback((id: number) => Api.managementFiles().destroy(id), []);

    const handleDestroyConfirm = useCallback(
        (id: number) => {
            return new Promise<unknown>((resolve) => {
                const handleResolve = () => {
                    resolve(null);
                };

                const handleConfirm = () => {
                    handleDestroy(id).onSuccess(() => {
                        modalControls.close();
                        handleResolve();
                    });
                };

                modalControls.set(
                    <ModalConfirm message="Do you really want to permanently delete this file?" onConfirm={handleConfirm} onCancel={handleResolve} />
                );
            });
        },
        [handleDestroy]
    );

    const getData = useCallback((d: ManagementFilesResponseType) => d.management_files, []);
    const item = useCallback((item: ManagementFilesResponseType['management_files'][number]) => ({ className: item.deleted_at ? 'deleted' : '' }), []);

    return (
        <Layout name="data" breadcrumbs={[{ name: 'Files management', current: true }]}>
            {modal}
            <h1>
                Files management
                <Button
                    color="brown"
                    text="Add update"
                    onClick={() => navigator(LinkHelper.dataFilesAdd)}
                    disabled={!permissions?.create && !auth?.user?.is_super}
                />
            </h1>
            <Table2 get={get} getData={getData} item={item} export="management_files">
                {{
                    filters: (handler) => (
                        <Filter3 onChange={handler}>
                            <Filter3.Search />
                            <Filter3.ManagementFileTypes />
                            {auth?.user?.is_super && <Filter3.Boolean placeholder="Archived" name="is_archived" />}
                        </Filter3>
                    ),
                    items: [
                        (item) => ({
                            name: 'Name',
                            max: 20,
                            content: item.name,
                            sort: 'name',
                            link: LinkHelper.dataFilesEditId(item.id),
                            disabled: !!item.deleted_at || !permissions?.update || !auth?.user?.is_super,
                        }),
                        (item) => ({
                            name: 'Version',
                            max: 10,
                            content: item.version,
                            sort: 'version',
                        }),
                        (item) => ({
                            name: 'Type',
                            max: 20,
                            content: item.type.name,
                            sort: 'type',
                        }),
                        (item) => ({
                            name: 'Description',
                            max: 20,
                            content: item.description,
                        }),
                    ],
                    actions: [
                        ({ deleted_at, file }) => ({
                            type: 'download',
                            disabled: !!deleted_at,
                            onClick: () => {
                                const w = window.open(file.url, '_blank', 'noopener,noreferrer');
                                if (w) w.opener = null;
                            },
                        }),
                        ({ id, deleted_at }) => ({
                            type: 'edit',
                            link: LinkHelper.dataFilesEditId(id),
                            disabled: !!deleted_at || (!permissions?.update && !auth?.user?.is_super),
                        }),
                        ({ id, deleted_at }) => ({
                            type: deleted_at ? 'restore' : 'delete',
                            onClick: deleted_at ? () => handleRestore(id) : () => handleDelete(id),
                            disabled: !permissions?.delete && !auth?.user?.is_super,
                        }),
                        ({ id }) => ({
                            type: 'destroy',
                            onClick: () => handleDestroyConfirm(id),
                            hide: !permissions?.admin,
                        }),
                    ],
                }}
            </Table2>
        </Layout>
    );
};
