import React, { FC } from 'react';

import { LingualInput } from '@.ui';
import { AdminFields } from '@/Components/Partials/Product/CreateProductForm/components/AdminField';
import { FormItemHeader } from '@/Components/Partials/Product/CreateProductForm/components/FormItemHeader';
import { FormItemWrapper } from '@/Components/Partials/Product/CreateProductForm/components/FormItemWrapper';

import S from './styles.module.scss';

interface LingualInputFieldProps {
    headerText: string;
    isAllowed: boolean;
    required?: boolean;
}

export const LingualInputField: FC<LingualInputFieldProps> = ({ isAllowed, headerText, required, ...props }) => {
    return (
        <FormItemWrapper>
            <FormItemHeader required={required}>{headerText}</FormItemHeader>
            <div className={S.inputWrapper}>
                <AdminFields isAllowed={isAllowed}>
                    <LingualInput {...props} inputClassName={S.lingualInput} />
                </AdminFields>
            </div>
        </FormItemWrapper>
    );
};
