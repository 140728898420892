import cn from 'classnames';
import React, { FC, SyntheticEvent } from 'react';

import S from './styles.module.scss';

type TabButtonProps = {
    className?: string;
    isActive?: boolean;
    isDisabled?: boolean;
    children?: React.ReactNode;
    onClick?: (event: SyntheticEvent) => void;
};

/**
 * TODO: in future need add new reusable component or hook for smart controls of this tabs.
 */
export const TabButton: FC<TabButtonProps> = ({ className, isActive, isDisabled, onClick, children }) => {
    const classNames = cn(S.tab_button, className, isActive && S.active, isDisabled && S.disabled);

    return (
        <button className={classNames} onClick={onClick} disabled={isDisabled}>
            {children}
        </button>
    );
};
