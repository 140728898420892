import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Api } from '@.api';
import { IngredientResponseType } from '@.api/methods';
import { useForm, useId } from '@.hooks';
import { Layout } from '@.layout';
import { getLingualErrors, LinkHelper } from '@.services';
import { LingualInput, Upload } from '@.ui';
import { IngredientsActionProps, IngredientsData } from '@/Components/Pages/Data/Ingredients/Action/types';
import { validateOptions } from '@/Components/Pages/Data/Ingredients/Action/validate';
import { Buttons } from '@/Components/Partials';

export const IngredientsAction: React.FC<IngredientsActionProps> = ({ edit }) => {
    const [ingredient, setIngredient] = useState<IngredientResponseType>();
    const [field, form] = useForm<IngredientsData>();
    const navigator = useNavigate();

    const get = (id: number) => {
        if (!edit) return;

        return Api.ingredients()
            .getById(id)
            .onSuccess((res) => {
                setIngredient(res.data);
                const { id, name, icon } = res.data;
                form.set.values({ id, name, icon_id: icon?.id });
            });
    };

    const id = useId(get, edit);

    const handleCreate = () => {
        form.reset.errors();

        form.ifValid((data) => {
            Api.ingredients()
                .create(data)
                .onSuccess(() => navigator(LinkHelper.dataIngredients))
                .onValidationError((res) => {
                    form.set.errors({ ...res.errors, name: getLingualErrors(res.errors, 'name') });
                });
        }, validateOptions);
    };

    const handleUpdate = () => {
        form.reset.errors();

        form.ifValid((data) => {
            Api.ingredients()
                .update(data, id)
                .onSuccess(() => navigator(LinkHelper.dataIngredients))
                .onValidationError((res) => {
                    form.set.errors({ ...res.errors, name: getLingualErrors(res.errors, 'name') });
                });
        }, validateOptions);
    };

    return (
        <Layout
            name="data"
            breadcrumbs={
                edit
                    ? [
                          { name: 'Ingredients', link: LinkHelper.dataIngredients },
                          { name: ingredient?.name.en ?? '', link: LinkHelper.dataIngredientId(Number(id)) },
                          { name: 'Edit', current: true },
                      ]
                    : [
                          { name: 'Ingredients', link: LinkHelper.dataIngredients },
                          { name: 'Add', current: true },
                      ]
            }
            small
        >
            {edit ? <h1>Editing ingredient</h1> : <h1>Adding ingredient</h1>}
            <div className="ingredients-action__form">
                <LingualInput {...field('name').register()} text="Name" />
                <Upload.Image {...field('icon_id').register<number>({ noValue: true })} value={ingredient?.icon} single />
                <Buttons text={edit ? 'Save' : 'Add'} onClickSave={edit ? handleUpdate : handleCreate} />
            </div>
        </Layout>
    );
};
