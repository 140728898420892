import React, { FC } from 'react';

import { TabButton } from '@/Components/UI/TabButton';

import S from './styles.module.scss';

export const AnalyticsPageTariffsButtons: FC = () => {
    return (
        <div className={S.tariffs_buttons}>
            <TabButton isActive>BASIC</TabButton>
            <TabButton isDisabled>ADVANCED</TabButton>
            <TabButton isDisabled>PROFESSIONALS</TabButton>
        </div>
    );
};
