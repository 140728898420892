import createLinearScale from '@visx/scale/lib/scales/linear';
import { FC, useRef } from 'react';

import { Board } from '@.partials';
import { useRefEffect } from '@/Components/Hooks';
import { Tools } from '@/Services';

import { DeviceProps } from './types';

const deviceColors = ['#BCCC80', '#66C0BE', '#E95283'];

export const Device: FC<DeviceProps> = ({ device, index }) => {
    const productValues = device.products.map((p) => p.whiffs_count);
    const scale = createLinearScale({ range: [0, 100], domain: [0, Tools.numbers.max(productValues)] });
    const color = deviceColors[index];
    const productsRef = useRef<HTMLDivElement>(null);

    useRefEffect(
        (block) => {
            if (block.scrollHeight > block.clientHeight) block.classList.add('scroll');
            else block.classList.remove('scroll');
        },
        [device],
        productsRef
    );

    return (
        <Board.Item key={`device_${device.id}`} className="station_device">
            <h3>{device.type.name}</h3>
            <div ref={productsRef} className="station_device__products">
                {device.products.map((product) => (
                    <div key={`station-device-pr-${product.udi}`} className="station_device__product">
                        <span className="station_device__product-name">{product.udi}</span>
                        <div className="station_device__product-line">
                            <div style={{ width: `${scale(product.whiffs_count)}%`, backgroundColor: color }}></div>
                            <span>{product.whiffs_count}</span>
                        </div>
                    </div>
                ))}
            </div>
        </Board.Item>
    );
};
