import { useCallback } from 'react';

import { Board, Table2 } from '@/Components/Partials';
import { LinkHelper, Tools } from '@/Services';
import { Api } from '@/Services/api';
import { StationLogsResponse } from '@/Services/api/methods';
import { Request } from '@/Types';

import { StationTab } from '../types';

export const Logs: StationTab = ({ station, filters }) => {
    const get = useCallback((q?: Request.All) => {
        if (!station) return;

        return Api.stations().getLogs(station.id, q);
    }, []);

    const getData = useCallback((d: StationLogsResponse) => d.station_logs, []);

    return (
        <Board.Item>
            {station && (
                <Table2 filters={filters} get={get} getData={getData} export="station_logs">
                    {{
                        items: [
                            (item) => ({
                                name: 'ID',
                                max: 20,
                                content: item.id,
                            }),
                            (item) => ({
                                name: 'Time',
                                max: 20,
                                content: Tools.date.format(item.created_at, 'DD.MM.YYYY hh:mm:ss'),
                            }),
                            (item) => ({
                                name: 'Type',
                                max: 20,
                                content: item.type.name,
                            }),
                            (item) => ({
                                name: 'Description',
                                max: 20,
                                content: item.response_message,
                            }),
                        ],
                        actions: [
                            (item) => ({
                                type: 'open',
                                link: LinkHelper.stationLogId(station.id, item.id),
                            }),
                        ],
                    }}
                </Table2>
            )}
        </Board.Item>
    );
};
