import React, { FC } from 'react';

import { ProductRequest } from '@.api/methods';
import { Checkbox } from '@.ui';
import { AdminFields } from '@/Components/Partials/Product/CreateProductForm/components/AdminField';
import { FormItemHeader } from '@/Components/Partials/Product/CreateProductForm/components/FormItemHeader';
import { FormItemWrapper } from '@/Components/Partials/Product/CreateProductForm/components/FormItemWrapper';

import S from './styles.module.scss';

interface dictionariesItem {
    id: number;
    name: string;
}

interface CheckboxFieldProps {
    headerText: string;
    isAllowed: boolean;
    dictionariesValues: dictionariesItem[];
    activeValues?: number[];
    fieldName: keyof ProductRequest;
    onSet: (name: keyof ProductRequest, values: number[]) => void;
    required?: boolean;
    errors?: string[];
}

export const CheckboxField: FC<CheckboxFieldProps> = ({
    headerText,
    isAllowed,
    required,
    dictionariesValues,
    activeValues = [],
    fieldName,
    onSet,
    errors,
    ...props
}) => {
    const getCheckBoxValues = (isActive: boolean, activeValues: number[], newValue: number) => {
        if (isActive) {
            return activeValues.filter((item) => item !== newValue);
        } else {
            return activeValues.length ? [...activeValues, newValue] : [newValue];
        }
    };

    return (
        <FormItemWrapper>
            <FormItemHeader required={required}>{headerText}</FormItemHeader>
            {errors && <span className={S.errors}>{errors[0]}</span>}
            <AdminFields isAllowed={isAllowed}>
                <div className={S.checkboxWrapper}>
                    {dictionariesValues.map((item) => {
                        return (
                            <Checkbox
                                className={S.checkboxWrapper__item}
                                textClassName={S.checkboxWrapper__text}
                                text={item.name}
                                key={`ingredient_${item.id}`}
                                {...props}
                                value={activeValues.includes(item.id)}
                                onChange={(isActive: boolean) => {
                                    const newIds = getCheckBoxValues(!isActive, activeValues, item.id);

                                    onSet(fieldName, newIds);
                                }}
                            />
                        );
                    })}
                </div>
            </AdminFields>
        </FormItemWrapper>
    );
};
