import React, { FC } from 'react';

import { Input } from '@.ui';
import { AdminFields } from '@/Components/Partials/Product/CreateProductForm/components/AdminField';
import { FormItemHeader } from '@/Components/Partials/Product/CreateProductForm/components/FormItemHeader';
import { FormItemWrapper } from '@/Components/Partials/Product/CreateProductForm/components/FormItemWrapper';

import S from './styles.module.scss';

interface InputFieldProps {
    headerText: string;
    isAllowed: boolean;
    regex?: RegExp;
    required?: boolean;
}

export const InputField: FC<InputFieldProps> = ({ headerText, isAllowed, required, regex, ...props }) => {
    return (
        <FormItemWrapper>
            <FormItemHeader required={required}>{headerText}</FormItemHeader>
            <div className={S.inputWrapper}>
                <AdminFields isAllowed={isAllowed}>
                    <Input {...props} regex={regex} />
                </AdminFields>
            </div>
        </FormItemWrapper>
    );
};
