import React, { useCallback, useMemo, useRef } from 'react';
import { Link } from 'react-router-dom';

import { useObjectMemo, useTooltip } from '@/Components/Hooks';
import { ButtonWrapper, Icon } from '@/Components/UI';

import { TableActionProps, TableActionsProps, TableColumnProps, TableIconProps, TableIcons, TableProps, TableRowProps } from './types';

export const TableAction: React.FC<TableActionProps> = (props) => {
    return <div className="table__actions-item">{props.children}</div>;
};

export const TableActions: React.FC<TableActionsProps> = (props) => {
    return (
        <td>
            <div className={!Array.isArray(props.children) ? 'table__actions-single' : 'table__actions'}>{props.children}</div>
        </td>
    );
};

export const TableColumn: React.FC<TableColumnProps> = ({ children, bold, link, maxLength }) => {
    const props = useObjectMemo(() => {
        if (!children) children = '';

        const isOver = maxLength && children.toString().length > maxLength;
        const content = isOver ? children.toString().slice(0, maxLength).trim() + '...' : children;

        return {
            style: bold ? { fontWeight: '700' } : undefined,
            className: isOver ? 'tooltip' : undefined,
            'data-value': children,
            children: link ? <Link to={link}>{content}</Link> : content,
        };
    }, [bold, children, link, maxLength]);

    return <td {...props} />;
};

export const TableRow: React.FC<TableRowProps> = (props) => {
    return <tr className={props.className}>{props.children}</tr>;
};

export const Table: React.FC<TableProps> = ({ headers, children, total }) => {
    return (
        <div className="table">
            {total !== undefined && <p className="table__counter">Total count - {total}</p>}
            <table>
                <thead>
                    <tr>
                        {headers?.map((header) => (
                            <td key={`head_${header}`}>{header}</td>
                        ))}
                    </tr>
                </thead>
                <tbody>{children}</tbody>
            </table>
        </div>
    );
};

const tableIcons: TableIcons = {
    delete: {
        icon: 'trash',
        text: 'Archive',
    },
    edit: {
        icon: 'edit',
        text: 'Edit',
    },
    open: {
        icon: 'chevrons-right',
        text: 'View',
    },
    lock: {
        icon: 'lock',
        text: 'Block',
    },
    unlock: {
        icon: 'unlock',
        text: 'Unlock',
    },
    restore: {
        icon: 'corner-up-left',
        text: 'Restore',
    },
    download: {
        icon: 'download',
        text: 'Download',
    },
    prices: {
        icon: 'tag',
        text: 'Prices',
    },
};

export const TableIcon: React.FC<TableIconProps<typeof tableIcons>> = ({ name, onClick, disabled, link }) => {
    const [tooltip, setTooltip, setOpen] = useTooltip({});
    const ref = useRef<HTMLButtonElement>(null);

    const current = useMemo(() => tableIcons[name], [name]);

    const icon = useMemo(() => <Icon name={current.icon} disabled={disabled} />, [current, disabled]);

    const handleIn = useCallback(() => {
        const option = ref.current;

        if (!option) return;

        const rect = option.getBoundingClientRect();

        setTooltip({ content: current.text, coordinates: { x: rect.left + 10, y: rect.top }, offsetX: rect.width, open: true });
    }, [current]);

    const handleOut = () => setOpen(false);

    return (
        <ButtonWrapper onClick={disabled ? undefined : onClick} onMouseEnter={handleIn} onMouseLeave={handleOut} ref={ref}>
            {link && !disabled ? <Link to={link}>{icon}</Link> : icon}
            {tooltip}
        </ButtonWrapper>
    );
};
