import cn from 'classnames';
import React, { ButtonHTMLAttributes, useMemo } from 'react';

import { ButtonProps } from './types';

export type { ButtonProps };

export const Button: React.FC<ButtonProps> = ({ className, color, disabled, onClick, children, text, href, type = 'submit' }) => {
    const CurrentTag = useMemo<keyof JSX.IntrinsicElements>(() => {
        if (disabled || !href) return 'button';

        return 'a';
    }, [href, disabled]);

    return (
        <CurrentTag
            {...((CurrentTag === 'button' ? { type } : { href }) as typeof CurrentTag extends 'button'
                ? { type: ButtonHTMLAttributes<HTMLButtonElement>['type'] }
                : { href: string })}
            className={cn('button', className, { [color ?? '']: color }, { disabled })}
            onClick={!disabled ? onClick : undefined}
        >
            {children || text}
        </CurrentTag>
    );
};
