import React from 'react';

import { BaseInputActualProps } from '@/Components/UI/BaseInput/types';

export const BaseInput = React.forwardRef<HTMLDivElement, BaseInputActualProps>((props, ref) => {
    return (
        <div className={'input' + (props.className ? ` ${props.className}` : '')} ref={ref}>
            <div className="input__content">{props.children}</div>
        </div>
    );
});
