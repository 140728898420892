import React, { FC } from 'react';

type HeartFillProps = {
    width?: number;
    height?: number;
    fill?: string;
    className?: string;
};

/**
 * TODO: add icon creator.
 */
export const HeartFill: FC<HeartFillProps> = ({ height = 17, width = 18, fill = 'currentColor', className }) => {
    return (
        <svg className={className} width={width} height={height} viewBox={`0 0 ${width} ${height}`} fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M13.3137 0C11.3168 0 9.66797 1.80932 9 2.65915C8.33203 1.80589 6.6832 0 4.68633 0C2.10234 0 0 2.31647 0 5.16409C0 6.7164 0.632812 8.16591 1.74023 9.15624C1.75781 9.18365 1.77539 9.20764 1.8 9.23163C1.8 9.23163 8.90508 16.0851 9 16.0851C9.09492 16.0851 16.432 8.99518 16.432 8.99518L16.5059 8.92665C16.5656 8.87182 16.6219 8.81699 16.6887 8.74846C16.7168 8.72104 16.7379 8.69363 16.7555 8.66279C17.5605 7.7033 18 6.46625 18 5.17095C18 2.31647 15.8977 0 13.3137 0Z"
                fill={fill}
            />
        </svg>
    );
};
