import React, { FC, useMemo } from 'react';

import { AdminFields } from '@/Components/Partials/Product/CreateProductForm/components/AdminField';
import { FormItemHeader } from '@/Components/Partials/Product/CreateProductForm/components/FormItemHeader';
import { FormItemWrapper } from '@/Components/Partials/Product/CreateProductForm/components/FormItemWrapper';
import { Switcher } from '@/Components/UI';

import S from './styles.module.scss';

interface dictionariesItem {
    id: number;
    name: string;
}

interface SwitcherFieldProps {
    headerText: string;
    isAllowed: boolean;
    dictionariesValues: dictionariesItem[];
    initialValue?: number | string;
    required?: boolean;
}

export const SwitcherField: FC<SwitcherFieldProps> = ({ headerText, isAllowed, required, dictionariesValues, initialValue, ...props }) => {
    const switcherValues = useMemo(() => {
        const transformedObj = dictionariesValues.map((item) => [item.name, `${item.id}`]);

        return Object.fromEntries(transformedObj);
    }, [dictionariesValues]);

    return (
        <FormItemWrapper>
            <FormItemHeader required={required}>{headerText}</FormItemHeader>
            <div className={S.switcherWrapper}>
                <AdminFields isAllowed={isAllowed}>
                    <Switcher SwitcherValues={switcherValues} initialValue={initialValue} {...props} />
                </AdminFields>
            </div>
        </FormItemWrapper>
    );
};
