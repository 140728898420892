import cn from 'classnames';
import React, { useCallback } from 'react';

import { Api } from '@.api';
import { Layout } from '@.layout';
import { Table2 } from '@.partials';
import { Checkbox, Filter3 } from '@.ui';
import { Auth, LinkHelper, Tools } from '@/Services';
import { StationsResponseType } from '@/Services/api/methods';
import { PermissionProps, Request } from '@/Types';

export * from './Action';
export * from './Station';

export const Stations: React.FC<PermissionProps> = (props) => {
    const auth = Auth.Use();

    const get = useCallback((q?: Request.All) => Api.stations().getAll(q), []);

    const handleDelete = useCallback((id: number) => Api.stations().delete(id), []);
    const handleRestore = useCallback((id: number) => Api.stations().restore(id), []);

    const getData = useCallback((d: StationsResponseType) => d.stations, []);

    const item = useCallback((item: StationsResponseType['stations'][number]) => ({ className: cn({ deleted: item.deleted_at }) }), []);

    return (
        <Layout name="stations" breadcrumbs={[{ name: 'Stations', current: true }]}>
            <h1>iRomaScents Stations</h1>
            <Table2 get={get} getData={getData} export="stations" item={item}>
                {{
                    filters: (handleChange) => (
                        <Filter3 onChange={handleChange}>
                            <Filter3.Search />
                            <Filter3.Companies related />
                            <Filter3.StoreGroups related />
                            <Filter3.Stores related />
                            <Filter3.Boolean placeholder="Archived" name="is_archived" />
                            <Filter3.Boolean placeholder="Updated" name="is_updated" />
                        </Filter3>
                    ),
                    items: [
                        (item) => ({
                            name: 'ID',
                            max: 15,
                            content: item?.identifier,
                            sort: 'identifier',
                        }),
                        (item) => ({
                            name: 'Name',
                            max: 15,
                            content: item?.name,
                            sort: 'name',
                            link: LinkHelper.stationId(item.id),
                            disabled: !!item.deleted_at || !props.permissions?.update,
                        }),
                        (item) => ({
                            name: 'Last Online',
                            content: item.last_online_at ? Tools.date.autoFormat(item.last_online_at * 1000) : '',
                            sort: 'last_online_at',
                        }),
                        (item) => ({
                            name: 'Inactive time',
                            content: item.inactive_time ? Tools.time.format(item.inactive_time) : '',
                            sort: 'inactive_time',
                        }),
                        (item) => ({
                            name: 'Currency',
                            content: item?.currency?.name,
                            sort: 'currency',
                        }),
                        (item) => ({
                            name: 'Update',
                            content: <Checkbox isDisabled value={item.is_updated} />,
                            sort: 'is_updated',
                        }),
                    ],
                    actions: [
                        (item) => ({
                            type: 'edit',
                            link: LinkHelper.stationsEditId(item.id),
                            disabled: !auth?.hasRights('Stations', 'update'),
                        }),
                        (item) => ({
                            type: item.deleted_at ? 'restore' : 'delete',
                            onClick: item.deleted_at ? () => handleRestore(item.id) : () => handleDelete(item.id),
                            disabled: !props.permissions?.delete,
                        }),
                        (item) => ({
                            type: 'open',
                            link: LinkHelper.stationId(item.id),
                        }),
                    ],
                }}
            </Table2>
        </Layout>
    );
};
