import React, { FC, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

import { Layout } from '@.layout';
import { Auth, LinkHelper } from '@.services';
import { ButtonWrapper, Icon, Input, Select, Upload } from '@.ui';
import { requiredRule, useDictionaries, useForm, useId, useObjectEffect } from '@/Components/Hooks';
import { Buttons } from '@/Components/Partials';
import { Api } from '@/Services/api';
import { OrderProductResource, OrderProductResourceRequest } from '@/Services/api/methods';
import { BaseResource, FileType } from '@/Types';

import { ItemProps, NewProductsOrdersActionProps } from './types';

const ProductItem: FC<{ value: ItemProps; onRemove: (id: string | number) => void; onChange: (id: string | number, data: ItemProps) => void }> = ({
    value,
    onRemove,
    onChange,
}) => {
    const auth = Auth.Use();

    const [field, form] = useForm(value);
    const [file, setFile] = useState<FileType | undefined>(value.file);

    const handleRemove = useCallback(() => {
        onRemove(value.id);
    }, [value.id, onRemove]);

    useObjectEffect(() => {
        onChange(value.id, {
            id: value.id,
            name: form.state.name?.value ?? '',
            description: form.state.description?.value ?? '',
            file,
            errors: {
                name: form.state.name?.value ? value.errors.name : [],
                descriptione: form.state.description?.value ? value.errors.description : [],
                file: file ? value.errors.file : [],
            },
        });
    }, [form.state, file]);

    useObjectEffect(() => {
        form.set.errors(value.errors);
    }, [value.errors]);

    const handleChangeFull = useCallback(
        (file: FileType) => {
            if (auth?.user?.is_super) return;

            setFile(file);
        },
        [auth?.user?.is_super]
    );

    return (
        <div className="item">
            <div className="item__inputs">
                <Input {...field('name').register()} text="Name" disabled={auth?.user?.is_super} />
                <Input {...field('description').register()} text="Description" disabled={auth?.user?.is_super} />
                <Upload.File value={file} onChangeFull={handleChangeFull} single errors={value.errors.file} disabled={auth?.user?.is_super} />
                <ButtonWrapper onClick={handleRemove} disabled={auth?.user?.is_super}>
                    <Icon name="trash" disabled={auth?.user?.is_super} />
                </ButtonWrapper>
            </div>
        </div>
    );
};

export const NewOrdersAction: React.FC<NewProductsOrdersActionProps> = ({ edit = false }) => {
    const auth = Auth.Use();
    const navigate = useNavigate();
    const createDefault = useCallback(() => ({ id: uuid(), name: '', description: '', errors: {} }), []);
    const [data, setData] = useState<BaseResource>();
    const [field, form] = useForm<{ status: number }>();
    const [dictionaries] = useDictionaries('ProductOrderStatuses');

    const [list, setList] = useState<ItemProps[]>([createDefault()]);

    const id = useId((id) => {
        Api.orders()
            .show(id)
            .onSuccess(({ data }) => {
                field('status').value(data.status.id);

                setData({ id: data.id, name: `Order #${data.id}` });

                setList(() => {
                    if (data.products.length === 0) return [createDefault()];

                    return data.products.map((item) => ({ ...item, errors: {} }));
                });
            });
    }, edit);

    // const handleAdd = useCallback(() => {
    //     setList((prev) => [...prev, createDefault()]);
    // }, []);

    const handleRemove = useCallback((id: string | number) => {
        setList((prev) => {
            const filtered = [...prev.filter((item) => item.id !== id)];

            if (filtered.length === 0) return [createDefault()];

            return filtered;
        });
    }, []);

    const handleChange = useCallback((id: string | number, data: ItemProps) => {
        setList((prev) => {
            const found = prev.find((item) => item.id === id);

            if (!found) return prev;

            found.description = data.description;
            found.name = data.name;
            found.file = data.file;

            return [...prev];
        });
    }, []);

    const getList = useCallback(
        () =>
            new Promise<OrderProductResource[]>((resolve, reject) => {
                setList((prev) => {
                    const items: OrderProductResourceRequest[] = [];

                    prev.forEach((item) => {
                        const { file, name, description, id } = item;
                        const errors: Record<string, string[]> = {};

                        if (!file) {
                            errors.file = ['Field file is required'];
                        }
                        if (!name) {
                            errors.name = ['Field name is required'];
                        }
                        if (!description) {
                            errors.file = ['Field description is required'];
                        }

                        item.errors = errors;

                        if (Object.keys(errors).length !== 0) return;

                        const obj: OrderProductResourceRequest = { name, description, file_id: (file as FileType).id };

                        if (typeof id === 'number') obj.id = id;

                        items.push(obj);
                    });

                    if (items.length === prev.length) {
                        resolve(items);
                        return prev;
                    }

                    reject();
                    return [...prev];
                });
            }),
        []
    );

    const handleSave = useCallback(() => {
        if (edit) {
            if (auth?.user?.is_super) {
                form.ifValid(
                    ({ status }) => {
                        Api.orders()
                            .update(id, { status })
                            .onSuccess(() => {
                                navigate(LinkHelper.ordersNew);
                            });
                    },
                    {
                        status: {
                            type: 'number',
                            required: requiredRule,
                        },
                    }
                );
                return;
            }

            getList().then((list) => {
                Api.orders()
                    .update(id, { products: list })
                    .onSuccess(() => {
                        navigate(LinkHelper.ordersNew);
                    });
            });

            return;
        }

        getList()
            .then((list) => {
                Api.orders()
                    .create({ products: list })
                    .onSuccess(() => {
                        navigate(LinkHelper.ordersNew);
                    });
            })
            .catch(() => {
                setList((prev) => [...prev]);
            });
    }, [id, auth, form]);

    return (
        <Layout
            name="orders"
            breadcrumbs={
                edit
                    ? [
                          { name: 'New products orders', link: LinkHelper.ordersNew },
                          { name: data?.name ?? '', link: data && LinkHelper.ordersNewItemId(data.id) },
                          { name: 'Edit', current: true },
                      ]
                    : [
                          { name: 'New products orders', link: LinkHelper.ordersNew },
                          { name: 'Add', current: true },
                      ]
            }
        >
            {edit ? <h1>Editing order</h1> : <h1>New order</h1>}
            <div className="orders_action__form">
                <div className="orders_action__form__inputs">
                    {auth?.user?.is_super && (
                        <Select {...field('status').register<number>()}>
                            {dictionaries?.product_order_statuses?.map((item) => (
                                <Select.Option id={item.id} key={`orders-statuses-${item.id}`}>
                                    {item.name}
                                </Select.Option>
                            ))}
                        </Select>
                    )}
                </div>
                <div className="products">
                    <div className="products__header">
                        <h3>Products list</h3>
                        {/* <Button color="brown" text="Add product" onClick={handleAdd} /> */}
                    </div>
                    <div className="products__list">
                        {list.map((item) => (
                            <ProductItem value={item} onRemove={handleRemove} key={`order-action-${item.id}`} onChange={handleChange} />
                        ))}
                    </div>
                </div>
            </div>
            <Buttons linkCancel={LinkHelper.ordersNew} onClickSave={handleSave} text={edit ? 'Save' : 'Add'} />
        </Layout>
    );
};
