import { ReactNode, ReactPortal, useMemo, useRef, useState } from 'react';
import ReactDOM from 'react-dom';

import { StateValue } from '@/Types';

import { useRefEffect } from '../useRefEffect';
import { Coordinates, InitialState, State } from './types';

export const useTooltip = <T extends InitialState = InitialState>(
    initialState: T
): [ReactPortal | undefined, (options: Omit<State, keyof T> & InitialState & { open?: boolean }) => void, (value: StateValue<boolean>) => void] => {
    const [coords, setCoords] = useState<Coordinates | undefined>(initialState.coordinates);
    const [content, setContent] = useState<ReactNode | undefined>(initialState.content);
    const [offset, setOffset] = useState<Partial<Coordinates>>({ x: initialState.offsetX, y: initialState.offsetY });
    const [isOpen, setOpen] = useState(false);
    const tooltipRef = useRef<HTMLDivElement | null>(null);
    const [isRendered, setRendered] = useState(false);
    const [isReversedX, setReversedX] = useState(false);

    const handleIn = () => setOpen(true);
    const handleOut = () => setOpen(false);

    const tooltip = useMemo(() => {
        if (!coords || !content || !isOpen) return;
        // setRendered(false);

        let transform: string | undefined = undefined;

        let offsetX = offset.x ?? 0;
        if (isReversedX) {
            offsetX *= -1;
            transform = 'translateX(-100%)';
        }

        return ReactDOM.createPortal(
            <div
                className="tooltip_global"
                style={{
                    top: coords.y + (offset.y ?? 0) + window.scrollY ?? 0,
                    left: coords.x + offsetX + window.scrollX ?? 0,
                    position: 'absolute',
                    transform,
                }}
                onMouseEnter={handleIn}
                onMouseLeave={handleOut}
                ref={(element) => {
                    tooltipRef.current = element;
                    setRendered(true);
                }}
            >
                {content}
            </div>,
            document.getElementById('root') as HTMLDivElement
        );
    }, [coords, content, isOpen, isReversedX]);

    useRefEffect(
        (tooltip) => {
            const box = tooltip.getBoundingClientRect();
            const window = document.body.getBoundingClientRect();

            if (box.width + box.left >= window.width) setReversedX(true);
            else setReversedX(false);
        },
        [isRendered],
        tooltipRef
    );

    const set = (options: Omit<State, keyof T> & { open?: boolean } & InitialState) => {
        const args = options as InitialState & { open?: boolean };

        if (args.content) setContent(args.content);
        if (args.coordinates) setCoords(args.coordinates);
        if (args.open !== undefined) setOpen(args.open);
        if (args.offsetX !== undefined) setOffset((prev) => ({ ...prev, x: args.offsetX }));
        if (args.offsetY !== undefined) setOffset((prev) => ({ ...prev, y: args.offsetY }));
    };

    return [tooltip, set, setOpen];
};
