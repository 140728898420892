import React, { FC } from 'react';

import { Bars } from '@.partials';
import { CreateTooltipDataFn } from '@/Components/Partials/Charts/Bars/types';
import { CustomerWizardStepsDatasetValue } from '@/Types/analytics';

type CustomersWizardStepsChartProps = {
    data: CustomerWizardStepsDatasetValue[];
};

export const CustomersWizardStepsChart: FC<CustomersWizardStepsChartProps> = ({ data }) => {
    const createTooltipData: CreateTooltipDataFn<CustomerWizardStepsDatasetValue> = (data) => ({
        title: data.label,
        value: data.value,
        name: data.question,
    });

    return (
        <div>
            <h3>Customers and Wizard steps</h3>
            <Bars data={data} isHorizontal createTooltipData={createTooltipData} axisDataName="Steps" />
        </div>
    );
};
