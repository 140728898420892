import { Store, Stores, StoresAction } from '@.pages';

import { RoutePage } from '../types';

export const StoresPage: RoutePage = {
    url: 'stores',
    component: <Stores />,
    permissions: {
        entity: 'Stores',
        right: 'read',
    },
    sidebar: {
        name: 'Stores',
    },
    children: [
        {
            url: ':id',
            component: <Store />,
            children: [
                {
                    url: 'edit',
                    component: <StoresAction edit />,
                    permissions: {
                        entity: 'Stores',
                        right: 'update',
                    },
                },
            ],
        },
        {
            url: 'add',
            component: <StoresAction />,
            permissions: {
                entity: 'Stores',
                right: 'create',
            },
        },
    ],
};
