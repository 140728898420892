import { Navigate } from 'react-router-dom';

import { LinkHelper, PageList } from '@.services';
import { RedirectPage } from '@/Components/Pages/RedirectPage';

export const Redirect: PageList = [
    {
        url: '*',
        component: <Navigate to={LinkHelper.login} />,
    },
    {
        url: '*',
        component: <RedirectPage />,
        permissions: 'authorized',
    },
];
