import { useState } from 'react';

import { useObjectEffect } from '@/Components/Hooks';
import { Board, Curves } from '@/Components/Partials';
import { Api } from '@/Services/api';
import { DashboardNewProductsResponse } from '@/Services/api/methods/dashboard/types';

import { DashboardItem } from '../../types';

export const Products: DashboardItem = ({ filters }) => {
    const [newProducts, setNewProducts] = useState<DashboardNewProductsResponse>();

    useObjectEffect(() => {
        Api.dashboard()
            .newProducts({ ...filters })
            .onSuccess((res) => setNewProducts(res.data));
    }, [filters]);

    return <Board.Item title="New Products">{newProducts && <Curves data={newProducts.products} color={'#BCCC80'} />}</Board.Item>;
};
