import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { Language } from '@/Config';
import { StateValue } from '@/Types';

import { Use } from '../../context';
import { defaultLanguage } from '../../functions';

export const useLanguage = (): [Language.Language, (code?: StateValue<string>) => void] => {
    const lang = Use();

    const language = useMemo<Language.Language>(() => Language.languagesList.find((l) => l.code === lang.current) ?? defaultLanguage(), [lang]);

    const navigate = useNavigate();

    const setCurrent = (code?: StateValue<string>) => {
        lang.setCurrent(code);

        navigate(0);
    };

    return [language, setCurrent];
};
