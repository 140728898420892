import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { Api } from '@.api';
import { Layout } from '@.layout';
import { Table2 } from '@.partials';
import { LinkHelper } from '@.services';
import { Request } from '@.types';
import { Button, Filter3 } from '@.ui';
import { StoreGroupsTableProps } from '@/Components/Pages/Clients/StoreGroups/types';
import { StoreGroupsResponseType } from '@/Services/api/methods';
import { PermissionProps } from '@/Types';

import { StoreGroupsAction } from './Action';
import { StoreGroup } from './StoreGroup';

export { StoreGroup, StoreGroupsAction };

export const StoreGroups: React.FC<PermissionProps> = (props) => {
    const navigator = useNavigate();

    return (
        <Layout name="clients" breadcrumbs={[{ name: 'Store groups', current: true }]}>
            <h1>
                Store groups
                <Button
                    color="brown"
                    text="Add store group"
                    onClick={() => navigator(LinkHelper.clientsStoreGroupsAdd)}
                    disabled={!props.permissions?.create}
                />
            </h1>
            <StoreGroupsTable permissions={props.permissions} />
        </Layout>
    );
};

export const StoreGroupsTable: React.FC<StoreGroupsTableProps> = ({ company_id, hideActions, permissions }) => {
    const get = useCallback((query?: Request.All) => Api.store_groups().getAll(query), []);

    const handleBlock = useCallback((id: number) => Api.store_groups().block(id), []);
    const handleUnblock = useCallback((id: number) => Api.store_groups().unblock(id), []);
    const getData = useCallback((data: StoreGroupsResponseType) => data.store_groups, []);
    const item = useCallback((item: StoreGroupsResponseType['store_groups'][number]) => ({ highlight: item.status.name === 'Blocked' }), []);

    const filters = useMemo(() => ({ company_id }), [company_id]);

    return (
        <>
            <Table2 filters={filters} get={get} getData={getData} item={item} export="store_groups">
                {{
                    filters: (handler) => (
                        <Filter3 onChange={handler}>
                            <Filter3.Search />
                            <Filter3.Companies />
                            <Filter3.StoreGroupStatuses />
                        </Filter3>
                    ),
                    items: [
                        (item) => ({
                            content: item.name,
                            link: LinkHelper.clientsStoreGroupId(item.id),
                            max: 20,
                            name: 'Name',
                            sort: 'name',
                        }),
                        (item) => ({
                            content: item.company.name,
                            max: 20,
                            name: 'Company',
                            sort: 'company',
                        }),
                        (item) => ({
                            content: item.stores_count,
                            max: 4,
                            name: 'Number of stores',
                            sort: 'stores_count',
                        }),
                        (item) => ({
                            content: item.users_count,
                            max: 4,
                            name: 'Number of user',
                            sort: 'users_count',
                        }),
                    ],
                    actions: [
                        (item) => ({
                            type: 'edit',
                            link: LinkHelper.clientsStoreGroupsEditId(item.id),
                            disabled: !permissions?.update,
                            hide: hideActions,
                        }),
                        (item) => ({
                            type: item.status.name === 'Active' ? 'lock' : 'unlock',
                            onClick: item.status.name === 'Active' ? () => handleBlock(item.id) : () => handleUnblock(item.id),
                            disabled: !permissions?.update,
                            hide: hideActions,
                        }),
                        (item) => ({
                            type: 'open',
                            link: LinkHelper.clientsStoreGroupId(item.id),
                        }),
                    ],
                }}
            </Table2>
        </>
    );
};
