import cn from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Select } from '@.ui';
import { useForm, useObjectEffect, useObjectMemo } from '@/Components/Hooks';
import { Api, Code } from '@/Services/api';
import { DictionaryFilters } from '@/Services/api/methods';
import { Dictionary } from '@/Types';
import { DictionaryItem, DictionaryResponse } from '@/Types/api/Dictionaries';

import { DictionaryNames } from '..';
import { BooleanProps, DateProps, DictionaryProps, SearchProps } from '../types';
import { DateFilter } from './date';
import { FilterData, FiltersProps } from './types';

export const getQueryFilters = (query: URLSearchParams, dictionaries?: DictionaryResponse) =>
    Object.fromEntries(
        Array.from(query.entries()).map(([key, value]) => {
            const isInDictionary = (() => {
                if (!dictionaries) return true;

                const foundDictionaryKey = Object.entries(DictionaryNames).find(([, queryName]) => queryName === key)?.[0] as Dictionary;

                if (!foundDictionaryKey) return true;

                const dictionaryValues = dictionaries[foundDictionaryKey];

                if (!dictionaryValues) return true;

                return dictionaryValues.some((item) => item.id.toString() === value);
            })();

            if (isInDictionary) {
                if (key.endsWith('_id')) return [key, Number.parseInt(value)];

                return [key, value];
            }

            return [key, undefined];
        })
    );

/**
 * TODO: Try to eliminate the use of this component in the future.
 */
export const Filters: React.FC<FiltersProps> = ({ children, fromData, onChange, isSaveInQuery = true, className, resetOptionName = 'All' }) => {
    const [dictionaries, setDictionaries] = useState(fromData || {});
    const [query, setQuery] = useSearchParams();
    const [field, form] = useForm<FilterData>(isSaveInQuery ? getQueryFilters(query, dictionaries) : undefined);

    const handleSearch = (value?: string) => field('search').value(value);
    const handleBoolean = (name: string, value?: number) => field(name).value(value === 0 ? false : value === 1 ? true : undefined);

    const register = useCallback(
        <T,>(name: keyof FilterData, onChange?: (value?: T) => void) => {
            const reg = field(name).register<T>();

            return {
                ...reg,
                onChange(value?: T) {
                    if (onChange) {
                        onChange(value);
                    }

                    if (reg.onChange) {
                        reg.onChange(value);
                    }
                },
            };
        },
        [form.state]
    );

    useEffect(() => {
        if (isSaveInQuery) {
            form.set.values(getQueryFilters(query, dictionaries));
        }
    }, [query, dictionaries, isSaveInQuery]);

    const [filters, usedDictionaries, relations] = useObjectMemo(() => {
        const names: (keyof typeof Dictionary)[] = [];
        const relations: (keyof typeof Dictionary)[] = [];

        return [
            React.Children.map(children, (child) => {
                if (!React.isValidElement(child)) return null;
                if (!child || typeof child !== 'object' || !child.props) return child;

                const isSearchFilter = (child.props as SearchProps).isSearchFilter;

                if (isSearchFilter) {
                    const placeholder = child.props.placeholder || 'Search';

                    return <Select searchValue={query.get('search') ?? undefined} placeholder={placeholder} onSearch={handleSearch} search absolute />;
                }

                const isDateFilter = (child.props as DateProps).isDateFilter;

                if (isDateFilter) {
                    const props = child.props as DateProps;

                    return <DateFilter {...props} {...field('date').register<[number | undefined, number | undefined]>()} />;
                }

                const isBooleanFilter = (child.props as BooleanProps).isBooleanFilter;

                if (isBooleanFilter) {
                    const props = child.props as BooleanProps;

                    if (props.name === undefined) return;

                    const getValue = () => {
                        if (!props.name) return;
                        const value = form.state[props.name]?.value;

                        if (!value) return;

                        if (typeof value === 'boolean') {
                            return value ? 1 : 0;
                        }

                        if (typeof value === 'string') {
                            if (value === 'true') return 1;
                            if (value === 'false') return 0;
                        }
                    };

                    return (
                        <Select
                            placeholder={props.placeholder}
                            label={props.placeholder}
                            theme={props.theme ?? 'white'}
                            onChange={(value) => handleBoolean(props.name, value)}
                            value={getValue()}
                            absolute
                        >
                            {(props.noReset === undefined || false) && <Select.Option id={-1}>{'All'}</Select.Option>}
                            <Select.Option id={1} value={true}>
                                {props.true ?? 'Yes'}
                            </Select.Option>
                            <Select.Option id={0} value={false}>
                                {props.false ?? 'No'}
                            </Select.Option>
                        </Select>
                    );
                }

                const isFilter = (child.props as DictionaryProps).isFilter;

                if (isFilter) {
                    const props = child.props as DictionaryProps;
                    const dictionary = props.dictionary as keyof typeof Dictionary;
                    names.push(dictionary);

                    if (props.related) {
                        relations.push(dictionary);
                    }

                    const list = dictionaries?.[Dictionary[dictionary]] as DictionaryItem[];

                    return (
                        list && (
                            <Select
                                disabled={props.disabled}
                                theme={props.theme ?? 'white'}
                                {...(props.multiple
                                    ? {
                                          ...register<number[]>(props.name as string, props.onChange as (value?: number[]) => void),
                                          multiple: true,
                                      }
                                    : { ...register<number>(props.name as string, props.onChange as (value?: number) => void) })}
                                placeholder={props.visibleName}
                                /**
                                 * For compatibility for older code and version.
                                 */
                                label={props.visibleLabel ?? props.visibleName}
                                hideList
                                absolute
                                autoClose
                            >
                                {(props.noReset === undefined || false) && <Select.Option id={-1}>{resetOptionName}</Select.Option>}
                                {list.map((item) => (
                                    <Select.Option id={item.id} key={`filter-select-option-${item.id}`}>
                                        {/**
                                         * TODO: for product_proposal_statuses 'label' instead 'name'.
                                         */}
                                        {item.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        )
                    );
                }

                return child;
            }),
            names,
            relations,
        ];
    }, [children, dictionaries]);

    const [dictionaryNames, setDictionaryNames] = useState(usedDictionaries);

    useEffect(() => {
        if (!filters) return;

        if (fromData === undefined) {
            setDictionaryNames(usedDictionaries);
            return;
        }

        if (fromData) setDictionaries(fromData);
    }, [usedDictionaries, fromData]);

    useEffect(() => {
        if (fromData) {
            return;
        }

        const getDictionaries = async () => {
            const dictionaryFilters: DictionaryFilters = Object.fromEntries(
                relations
                    .map((item) => {
                        const dictionaryName = Dictionary[item];

                        const relatedFilters = Object.fromEntries(
                            relations
                                .map((relatedItem) => {
                                    const dictionaryName = Dictionary[relatedItem];
                                    const queryName = DictionaryNames[dictionaryName];
                                    const queryValue = form.state[queryName]?.value;

                                    if (item === relatedItem || queryValue === -1) {
                                        return [queryName, undefined];
                                    }

                                    return [queryName, queryValue];
                                })
                                .filter(([name, value]) => value !== undefined && name !== undefined)
                        );

                        return [dictionaryName, relatedFilters];
                    })
                    .filter(([_, value]) => Boolean(Object.values(value).length))
            );

            const response = await Api.dictionaries().byNames(dictionaryNames, Object.keys(dictionaryFilters).length ? dictionaryFilters : undefined);

            if (response.code !== Code.Success) {
                return;
            }

            setDictionaries(response.data);
        };

        getDictionaries();
    }, [JSON.stringify(relations), JSON.stringify(dictionaryNames), JSON.stringify(form.state)]);

    /**
     * TODO: ???
     */
    useObjectEffect(() => {
        const createValue = (value: any) => {
            switch (typeof value) {
                case 'boolean':
                case 'string':
                    return value;
                case 'number':
                    return value === -1 ? undefined : value;
                case 'object':
                    return value.includes(-1 as never) ? undefined : value; //TODO: fix types
                default:
                    return undefined;
            }
        };

        const state: { [name: string]: string | number | number[] } = Object.fromEntries(
            Object.entries(form.state)
                .map(([key, filter]) => {
                    if (typeof filter === 'string' || !filter) {
                        return [key, undefined];
                    }

                    return [key, createValue(filter?.value)];
                })
                .filter(([key, value]) => {
                    if (typeof key === 'string' && typeof value !== 'object') {
                        if (value === undefined) query.delete(key);
                        else query.set(key, value.toString());
                    }

                    return value !== undefined || value !== '';
                })
        );

        if (isSaveInQuery) {
            setQuery(query);
        }

        if (onChange) {
            const filtersQuery = Object.fromEntries(
                Object.entries(state)
                    .map(([key, filter]) => {
                        const getValue = (): any => {
                            if (key === 'search') {
                                return filter;
                            }

                            if (filter === undefined || typeof filter === 'string' || key === 'date') {
                                return null;
                            }

                            switch (typeof filter) {
                                case 'boolean':
                                case 'string':
                                    return `${filter}`;
                                case 'number':
                                    return `${filter}`;
                                case 'object':
                                    return filter.length ? `${filter.join(',')}` : null;
                                default:
                                    return null;
                            }
                        };

                        return [key, getValue()];
                    })
                    .filter((item) => item[1] !== null && item[1] !== '')
            );

            const [from, to] = form.state.date?.value ?? [undefined, undefined];

            if (from) {
                filtersQuery.start_date = Math.floor(from / 1000);
            }

            if (to) {
                filtersQuery.end_date = Math.floor(to / 1000);
            }

            onChange({ ...getQueryFilters(query, dictionaries), ...filtersQuery });
        }

        form.set.values(state);
    }, [form.state]);

    const classNames = cn('filters', className);

    return <div className={classNames}>{filters}</div>;
};
