import { validatePhone } from 'react-international-phone';

import { requiredRule, ValidateOptions } from '@.hooks';

import { CompaniesEntityScheme } from './types';

export const validateOptions = (phone: string | undefined, isCoordsRequired = false): ValidateOptions<CompaniesEntityScheme> => ({
    name: {
        type: 'string',
        required: requiredRule,
    },
    agent_email: {
        type: 'string',
        required: requiredRule,
        regex: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9-]+.+.[A-Z]{2,4}$/i,
            message: 'Field agent email is invalid',
        },
    },
    agent_name: {
        type: 'string',
        required: requiredRule,
    },
    agent_phone: {
        type: 'string',
        required: requiredRule,
        regex: {
            value: phone && validatePhone(phone).lengthMatch ? /.*/ : /^$/,
            message: 'Field agent phone is invalid',
        },
    },
    status: {
        type: 'number',
    },
    street_address_1: {
        type: 'string',
        required: requiredRule,
    },
    street_address_2: {
        type: 'string',
    },
    city: {
        type: 'string',
        required: requiredRule,
    },
    country: {
        type: 'string',
        required: requiredRule,
    },
    latitude: {
        type: 'number',
        required: isCoordsRequired ? requiredRule : undefined,
        min: {
            value: -90,
            message: 'Min latitude is -90',
        },
        max: {
            value: 90,
            message: 'Max latitude is 90',
        },
    },
    longitude: {
        type: 'number',
        required: isCoordsRequired ? requiredRule : undefined,
        min: {
            value: -180,
            message: 'Min longitude is -180',
        },
        max: {
            value: 180,
            message: 'Max longitude is 180',
        },
    },
    postal: {
        type: 'string',
        required: requiredRule,
    },
    region: {
        type: 'string',
        required: requiredRule,
    },
});
