import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Api } from '@.api';
import { CompanyResponseType } from '@.api/methods';
import { Layout } from '@.layout';
import { StoreGroupsTable, StoresTable, UsersTable } from '@.pages';
import { Tab, Table, TableColumn, TableRow, Tabs } from '@.partials';
import { LinkHelper } from '@.services';
import { Button } from '@.ui';
import { PermissionProps } from '@/Types';

export const Company: React.FC<PermissionProps> = (props) => {
    const [company, setCompany] = useState<CompanyResponseType>();
    const { id } = useParams();
    const navigator = useNavigate();

    const updateCompany = () => {
        Api.companies()
            .getById(Number(id))
            .onSuccess((res) => {
                setCompany(res.data);
            });
    };

    const handleBlock = () => {
        Api.companies()
            .block(Number(id))
            .onSuccess(() => updateCompany());
    };

    const handleUnblock = () => {
        Api.companies()
            .unblock(Number(id))
            .onSuccess(() => updateCompany());
    };

    useEffect(() => {
        if (!id) return;

        updateCompany();
    }, []);

    return (
        <Layout
            name="clients"
            breadcrumbs={[
                { name: 'Companies', link: LinkHelper.clientsCompanies },
                { name: company?.name ?? '', current: true },
            ]}
        >
            {company && (
                <>
                    <h1>
                        {company?.name}
                        <div>
                            <Button
                                color="transparent"
                                text={company?.status.name === 'Active' ? 'Block' : 'Unblock'}
                                onClick={company?.status.name === 'Active' ? handleBlock : handleUnblock}
                                disabled={!props.permissions?.delete}
                            />
                            {company?.id && (
                                <Button
                                    color="brown"
                                    text="Edit"
                                    onClick={() => navigator(LinkHelper.clientsCompaniesEditId(company?.id))}
                                    disabled={!props.permissions?.update}
                                />
                            )}
                        </div>
                    </h1>
                    <Tabs>
                        <Tab is_default name="General" path="general">
                            <Table>
                                <TableRow>
                                    <TableColumn bold>Number of store groups</TableColumn>
                                    <TableColumn>{company?.store_groups_count}</TableColumn>
                                </TableRow>
                                <TableRow>
                                    <TableColumn bold>Number of stores</TableColumn>
                                    <TableColumn>{company?.stores_count}</TableColumn>
                                </TableRow>
                                <TableRow>
                                    <TableColumn bold>Number of users</TableColumn>
                                    <TableColumn>{company?.users_count}</TableColumn>
                                </TableRow>
                                <TableRow>
                                    <TableColumn bold>Address of a company's headquarters</TableColumn>
                                    <TableColumn>{company?.street_address_1}</TableColumn>
                                </TableRow>
                                <TableRow>
                                    <TableColumn bold>Contact person</TableColumn>
                                    <TableColumn>{[company?.agent_name, company?.agent_phone, company?.agent_email].join(', ')}</TableColumn>
                                </TableRow>
                            </Table>
                        </Tab>
                        <Tab name="Store groups" path={`store-groups`}>
                            <StoreGroupsTable company_id={company?.id} filters={['StoreGroupStatuses']} hideActions={props.permissions?.companyAdmin} />
                        </Tab>
                        <Tab name="Stores" path="stores">
                            <StoresTable
                                company_id={company?.id}
                                filters={['StoreGroups', 'StoreStatuses']}
                                hideActions={props.permissions?.companyAdmin ? ['update', 'delete'] : undefined}
                            />
                        </Tab>
                        <Tab name="Users" path="users">
                            <UsersTable company_id={company?.id} filters={['StoreGroups', 'Roles', 'UserStatuses']} hideActions={['update', 'delete']} />
                        </Tab>
                    </Tabs>
                </>
            )}
        </Layout>
    );
};
