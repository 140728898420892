export interface BaseDictionaryType {
    id: number;
    name: string;
}

export enum Dictionary {
    Users = 'users',
    UserStatuses = 'user_statuses',
    Roles = 'roles',
    Companies = 'companies',
    CompanyStatuses = 'company_statuses',
    StoreGroups = 'store_groups',
    StoreGroupStatuses = 'store_group_statuses',
    Stores = 'stores',
    StoreStatuses = 'store_statuses',
    Brands = 'brands',
    ProductTypes = 'product_types',
    Ages = 'ages',
    Sexes = 'sexes',
    Strengths = 'strengths',
    Occasions = 'occasions',
    Ingredients = 'ingredients',
    Characters = 'characters',
    Tags = 'tags',
    Stations = 'stations',
    Currencies = 'currencies',
    DeviceStatuses = 'device_statuses',
    DeviceLogTypes = 'device_log_types',
    ManagementFileTypes = 'management_file_types',
    ProductOrderStatuses = 'product_order_statuses',
    DeviceOrderStatuses = 'device_order_statuses',
    OrderStatuses = 'order_statuses',
    SpecialOfferTypes = 'special_offer_types',
    SystemLogTypes = 'system_log_types',
    StationStatuses = 'station_statuses',
    StationLogTypes = 'station_log_types',
    WizardSteps = 'wizard_questions',
    ProductUdis = 'product_udis',
    WLMFirmwareManagementFiles = 'wlm_firmware_management_files',
    LMPFirmwareManagementFiles = 'lmp_firmware_management_files',
    /**
     * TODO: this thing is not needed here anyway...
     */
    FavoriteHeart = 'favorite_heart',
    ProductProposalStatuses = 'product_proposal_statuses',
}

export enum CustomDictionary {
    IsArchived = 'is_archived',
    HasImage = 'has_image',
    IsUpdated = 'is_updated',
    IsEnabled = 'is_enabled',
    IsSuccess = 'is_success',
}

export const AllDictionary = { ...CustomDictionary, ...Dictionary };

export type CustomDictionaries = (keyof typeof CustomDictionary)[];

export type DictionaryList = (keyof typeof Dictionary)[];

export type AllDictionaries = (keyof typeof AllDictionary)[];
