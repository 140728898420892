import { useObjectMemo } from '@fipnooone/hooks';
import Cookies from 'js-cookie';

import { base64 } from './base64';
import { Clear, CookieConfig, Get, Set, UseCookies } from './types';

export const useCookies: UseCookies = (cookieConfig?: Partial<CookieConfig>) => {
    const config: CookieConfig = useObjectMemo(() => ({ expire: cookieConfig?.expire ?? 30, name: cookieConfig?.name ?? '_c' }), [cookieConfig]);

    const clear = Cookie(config).clear;

    const get = Cookie(config).get;

    const set = Cookie(config).set;

    return { clear, set, get };
};

export const Cookie: UseCookies = <D>(cookieConfig?: Partial<CookieConfig>) => {
    const config: CookieConfig = { expire: cookieConfig?.expire ?? 30, name: cookieConfig?.name ?? '_c' };

    const clear: Clear = () => Cookies.remove(config.name);

    const get: Get<D> = <T = D>() => {
        const cookie = Cookies.get(config.name);

        if (!cookie) return;

        const parsed = base64.parse<T>(cookie);

        if (!parsed) return;

        return parsed;
    };

    const set: Set<D> = <T = D>(data: NonNullable<T>, remember = false) => {
        Cookies.set(config.name, base64.encode(data), {
            // secure: true,
            expires: remember === true ? config.expire : undefined,
        });
    };

    return { clear, set, get };
};
