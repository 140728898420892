import { Board } from '@/Components/Partials';

import { DashboardItem } from '../types';
import { Orders } from './orders';
import { Products } from './products';

export const New: DashboardItem = (props) => (
    <Board.Line>
        <Products {...props} />
        <Orders {...props} />
    </Board.Line>
);
