import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Api } from '@.api';
import { UserResponseType } from '@.api/methods';
import { Layout } from '@.layout';
import { Table, TableColumn, TableRow } from '@.partials';
import { Button, Checkbox } from '@.ui';
import { LinkHelper } from '@/Services';

export const User: React.FC = () => {
    const [user, setUser] = useState<UserResponseType>();
    const { id } = useParams();
    const navigator = useNavigate();

    const updateUser = () => {
        Api.users()
            .getById(Number(id))
            .onSuccess((res) => setUser(res.data));
    };

    const handleBlock = () => {
        Api.users()
            .blockById(Number(id))
            .onSuccess(() => updateUser());
    };

    const handleUnblock = () => {
        Api.users()
            .unblockById(Number(id))
            .onSuccess(() => updateUser());
    };

    useEffect(() => {
        updateUser();
    }, []);

    return (
        <Layout
            name="settings"
            breadcrumbs={[
                { name: 'Users', link: LinkHelper.settingsUsers },
                { name: user?.name ?? '', current: true },
            ]}
        >
            {user && (
                <>
                    <h1>
                        {user?.name}
                        <div>
                            <Button
                                color="transparent"
                                text={user?.status.name === 'Active' ? 'Block' : 'Unblock'}
                                onClick={user?.status.name === 'Active' ? handleBlock : handleUnblock}
                            />
                            {user?.id && <Button color="brown" text="Edit" onClick={() => navigator(LinkHelper.settingsUsersEditId(user?.id))} />}
                        </div>
                    </h1>
                    <Table>
                        <TableRow>
                            <TableColumn bold>Name</TableColumn>
                            <TableColumn>{user?.name}</TableColumn>
                        </TableRow>
                        <TableRow>
                            <TableColumn bold>Username</TableColumn>
                            <TableColumn>{user?.username}</TableColumn>
                        </TableRow>
                        <TableRow>
                            <TableColumn bold>Email</TableColumn>
                            <TableColumn>{user?.email}</TableColumn>
                        </TableRow>
                        <TableRow>
                            <TableColumn bold>Role</TableColumn>
                            <TableColumn>{user?.role?.name}</TableColumn>
                        </TableRow>
                        <TableRow>
                            <TableColumn bold>Company</TableColumn>
                            <TableColumn>{user?.company?.name}</TableColumn>
                        </TableRow>
                        <TableRow>
                            <TableColumn bold>Store groups</TableColumn>
                            <TableColumn>{user?.store_groups?.map((group) => group.name).join(', ')}</TableColumn>
                        </TableRow>
                        <TableRow>
                            <TableColumn bold>Status</TableColumn>
                            <TableColumn>{user?.status?.name}</TableColumn>
                        </TableRow>
                        <TableRow>
                            <TableColumn bold>Is company admin</TableColumn>
                            <TableColumn>
                                <Checkbox value={user?.is_company_admin} isDisabled />
                            </TableColumn>
                        </TableRow>
                    </Table>
                </>
            )}
        </Layout>
    );
};
