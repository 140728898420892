import { useContext } from '@fipnooone/react-modal';

import { Button } from '@/Components/UI/Button';
import { Icon } from '@/Components/UI/Icon';

interface ForbiddenProps {
    message?: string;
}

export const ModalForbidden: React.FC<ForbiddenProps> = ({ message = 'You do not have permission to do this' }) => {
    const modal = useContext();

    return (
        <div className="unsaved">
            <Icon name="close" size={20} onClick={() => modal.close()} />
            <h1>{message}</h1>
            {/* {message && <span>{message}</span>} */}
            <Button color="brown" text="Ok" onClick={() => modal.close()} />
        </div>
    );
};
