import { curveLinear } from '@visx/curve';
import { localPoint } from '@visx/event';
import { Group } from '@visx/group';
import { Circle, LinePath } from '@visx/shape';
import { bisector } from 'd3-array';
import React, { useState } from 'react';

import { Tools } from '@/Services';

import { CurveProps, Item } from './types';

// let tooltipTimeout: number;

const bisectDate = bisector<Item, Date>((d) => new Date(d.date)).left;

export const Curve: React.FC<CurveProps> = ({ data, hideTooltip, scaleX, scaleY, showTooltip, color }) => {
    const [markerCoords, setMarkerCoords] = useState<{ x: number; y: number }>();

    const handleMouseLeave = () => {
        // tooltipTimeout = window.setTimeout(() => {

        // }, 100);

        hideTooltip();

        setMarkerCoords(undefined);
    };

    const handleMouseMove = (event: React.MouseEvent<SVGPathElement> | React.TouchEvent<SVGPathElement>) => {
        // if (tooltipTimeout) clearTimeout(tooltipTimeout);

        const { x } = localPoint(event) ?? { x: 0 };

        const x0 = scaleX.invert(x);
        const index = bisectDate(data, new Date(x0), 0);
        const d = data[index - 1];
        // const [d0, d1] = [data[index - 1], data[index]];

        // let d = d0;
        // if (d1 && d1.date) {
        //     d = x0.valueOf() - d1.date.valueOf() > d1.value.valueOf() - x0.valueOf() ? d1 : d0;
        // }

        if (!d) {
            handleMouseLeave();
            return;
        }

        const coords = {
            x: scaleX(d.date) ?? 0,
            y: scaleY(d.value) ?? 0,
        };

        setMarkerCoords(coords);

        showTooltip({
            tooltipData: { title: `Count ${d.value}`, value: Tools.date.format(d.date, 'DD.MM.YY') },
            tooltipLeft: coords.x,
            tooltipTop: coords.y,
        });
    };
    return (
        <Group>
            <Group>
                <LinePath
                    className="chart_bars__path"
                    curve={curveLinear}
                    data={data}
                    x={(d) => scaleX(d.date) ?? 0}
                    y={(d) => scaleY(d.value) ?? 0}
                    stroke={color}
                    strokeWidth={2}
                    strokeOpacity={1}
                    cursor={'pointer'}
                    style={{ filter: `drop-shadow(0px 5px 10px ${color})` }}
                ></LinePath>

                <Circle fill={markerCoords ? color : 'transparent'} cx={markerCoords?.x} cy={markerCoords?.y} r={5} />

                <rect
                    width="100%"
                    height="100%"
                    fill="transparent"
                    onMouseMove={handleMouseMove}
                    onTouchMove={handleMouseMove}
                    onTouchStart={handleMouseMove}
                    onMouseLeave={handleMouseLeave}
                />
            </Group>
        </Group>
    );
};
