import { LanguageIcon } from './types';

export const Japan: LanguageIcon = ({ id = 'clip0_970_19660' }) => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath={`url(#${id})`}>
            <path d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z" fill="white" />
            <path
                d="M10 14.3478C12.4012 14.3478 14.3478 12.4013 14.3478 10.0001C14.3478 7.59884 12.4012 5.65228 10 5.65228C7.59878 5.65228 5.65222 7.59884 5.65222 10.0001C5.65222 12.4013 7.59878 14.3478 10 14.3478Z"
                fill="#D80027"
            />
        </g>
        <defs>
            <clipPath id={id}>
                <rect width="20" height="20" fill="white" />
            </clipPath>
        </defs>
    </svg>
);
