import { FC, FormEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useDictionaries, useForm, useId } from '@/Components/Hooks';
import { Layout } from '@/Components/Layouts';
import { Buttons } from '@/Components/Partials';
import { DatePicker, Input, Select, Textarea } from '@/Components/UI';
import { LinkHelper } from '@/Services';
import { Api } from '@/Services/api';
import { BaseResource, PageActionProps, UpdatePackageEditableFields } from '@/Types';

import { useBreadcrumbs } from '../useBreadcrumbs';
import S from './styles.module.scss';
import { UpdatePackageData } from './types';
import { validateOptions } from './validate';

export const PackagesUpdateAction: FC<PageActionProps> = ({ edit }) => {
    const navigate = useNavigate();

    const [dictionaries] = useDictionaries('WLMFirmwareManagementFiles', 'LMPFirmwareManagementFiles');
    const [data, setData] = useState<BaseResource>();

    const breadcrumbs = useBreadcrumbs({
        type: edit ? 'edit' : 'add',
        id: data?.id,
        name: data?.name,
    });

    const [field, form] = useForm<UpdatePackageData>();
    const id = useId((id) => {
        Api.updatePackages()
            .getById(id)
            .onSuccess(({ data }) => {
                form.set.values({
                    ...data,
                    date: [data.date && data.date * 1000],
                    wlm_firmware_management_file_id: data.wlm_firmware_management_file?.id,
                    lmp_firmware_management_file_id: data.lmp_firmware_management_file?.id,
                });

                setData({
                    id: data.id,
                    name: data.name,
                });
            });
    }, edit);

    const handleUpdate = (data: UpdatePackageEditableFields) => {
        return Api.updatePackages().updateByid(id, data);
    };

    const handleCreate = (data: UpdatePackageEditableFields) => {
        return Api.updatePackages().create(data);
    };

    const handleSave = (event?: FormEvent) => {
        event?.preventDefault();

        form.ifValid((formData) => {
            const data = {
                ...formData,
                date: (() => {
                    const dateValue = formData.date?.at(0);
                    if (!dateValue) return 0;

                    return Math.round(dateValue / 1000);
                })(),
            };

            const currentRequest = (() => {
                if (edit) {
                    return handleUpdate(data);
                }

                return handleCreate(data);
            })();

            currentRequest
                .onSuccess(() => {
                    navigate(LinkHelper.updatePackages);
                })
                .onValidationError(({ errors }) => {
                    form.set.errors(errors);
                });
        }, validateOptions);
    };

    return (
        <Layout breadcrumbs={breadcrumbs} small>
            <h1>{edit ? 'Editing package update' : 'Adding package update'}</h1>
            <form className={S.form} onSubmit={handleSave}>
                <Input {...field('name').register()} text="Name" required />
                <Textarea {...field('changelog').register()} text="Changelog" required />
                <Input {...field('version').register()} text="Version" required />
                <DatePicker {...field('date').register()} placeholderFrom="Date" single required />
                <Select {...field('wlm_firmware_management_file_id').register<number>()} placeholder="WLM firmware" search>
                    {dictionaries?.wlm_firmware_management_files?.map(({ id, name }) => (
                        <Select.Option id={id} key={id}>
                            {name}
                        </Select.Option>
                    ))}
                </Select>
                <Select {...field('lmp_firmware_management_file_id').register<number>()} placeholder="LMP firmware" search>
                    {dictionaries?.lmp_firmware_management_files?.map(({ id, name }) => (
                        <Select.Option id={id} key={id}>
                            {name}
                        </Select.Option>
                    ))}
                </Select>
                <Buttons linkCancel={LinkHelper.updatePackages} text={edit ? 'Save' : 'Add'} onClickSave={handleSave} />
            </form>
        </Layout>
    );
};
