import { useNavigate } from 'react-router-dom';

import { Icon } from '@.ui';

export const NotFound: React.FC = () => {
    const navigator = useNavigate();

    return (
        <div className="not-found">
            <div className="not-found__content">
                <h1>404</h1>
                <p>Something went wrong...</p>
                <span onClick={() => navigator(-1)}>
                    <Icon name="chevron-left" />
                    Back to previous page
                </span>
            </div>
        </div>
    );
};
