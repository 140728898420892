import { Profile } from '@.pages';
import { Routes } from '@/Routes/types';

export const MyPageRoutes: Routes = [
    {
        url: 'my-page',
        permissions: 'authorized',
        component: <Profile />,
    },
];
