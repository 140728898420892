import { User } from '@/Services/Auth/types';
import { UserRoleEnum } from '@/Types/api/User';

export const isProductCompanyAdmin = (user?: User): boolean => {
    if (!user) {
        return false;
    }

    return user.role?.key === UserRoleEnum.COMPANY_ADMIN;
};
