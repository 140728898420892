import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Api } from '@.api';
import { IngredientResponseType } from '@.api/methods';
import { Layout } from '@.layout';
import { LinkHelper, useLingualField } from '@.services';
import { Button } from '@.ui';
import { useId } from '@/Components/Hooks';
import { PermissionProps } from '@/Types';

export const Ingredient: React.FC<PermissionProps> = (props) => {
    const [ingredient, setIngredient] = useState<IngredientResponseType>();
    const navigator = useNavigate();

    const updateIngredient = (id: number) => {
        return Api.ingredients()
            .getById(id)
            .onSuccess((res) => setIngredient(res.data));
    };

    const id = useId(updateIngredient, true);

    const handleDelete = () => {
        Api.ingredients()
            .delete(id)
            .onSuccess(() => updateIngredient(id))
            .onError((res) => alert(res.message));
    };

    const handleRestore = () => {
        Api.ingredients()
            .restore(id)
            .onSuccess(() => updateIngredient(id));
    };

    const ingredientName = useLingualField(ingredient);

    return (
        <Layout
            name="data"
            breadcrumbs={[
                { name: 'Ingredients', link: LinkHelper.dataIngredients },
                { name: ingredientName, current: true },
            ]}
        >
            {ingredient && (
                <>
                    <h1>
                        {ingredientName}
                        <div>
                            <Button
                                color="transparent"
                                text={ingredient.deleted_at ? 'Restore' : 'Archive'}
                                onClick={ingredient.deleted_at ? handleRestore : handleDelete}
                                disabled={!props.permissions?.delete}
                            />
                            <Button
                                color="brown"
                                text="Edit"
                                onClick={() => navigator(LinkHelper.dataIngredientsEditId(id))}
                                disabled={!!ingredient.deleted_at || !props.permissions?.update}
                            />
                        </div>
                    </h1>
                    <div className="entity-page">
                        <div className="entity-page__container">
                            <div>
                                <div className="entity-page__item">
                                    <div className="bold">Name</div>
                                    <div>{ingredientName}</div>
                                </div>
                            </div>
                            <img src={ingredient?.icon?.url} alt={ingredient?.icon?.name} />
                        </div>
                    </div>
                </>
            )}
        </Layout>
    );
};
