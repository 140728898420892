import { Base, ManagementFileResponseType, Response } from '@.api/methods';

import { ManagementFilesResponseType, ManagementFilesType } from './types';

export class ManagementFiles extends Base {
    public getAll(query?: { page?: number; per_page?: number; search?: string; type?: number; is_archived?: boolean }) {
        this.setPath('management-files');
        if (query) this.setQuery(query);

        return this.getRequest() as Response<ManagementFilesResponseType>;
    }

    public getById(id: number) {
        this.setPath('management-files');
        this.setId(id);

        return this.getRequest() as Response<ManagementFileResponseType>;
    }

    public create(data: ManagementFilesType) {
        this.setPath('management-files');
        this.setData(data);

        return this.postRequest();
    }

    public update(data: ManagementFilesType, id: number) {
        this.setPath('management-files');
        this.setId(id);
        this.setData(data);

        return this.putRequest();
    }

    public delete(id: number) {
        this.setPath('management-files');
        this.setId(id);

        return this.deleteRequest();
    }

    public restore(id: number) {
        this.setPath('management-files');
        this.setId(`${id}/restore`);

        return this.postRequest();
    }

    public destroy(id: number) {
        this.setPath('management-files');
        this.setId(`${id}/destroy`);

        return this.deleteRequest();
    }
}
