import { requiredRule, ValidateOptions } from '@.hooks';

import { FormType } from './types';

export const validateOptions: ValidateOptions<FormType> = {
    email: {
        type: 'string',
        required: requiredRule,
        regex: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9-]+.+.[A-Z]{2,4}$/i,
            message: 'Field email is invalid',
        },
    },
};
