import React from 'react';

import { Icon } from '@.ui';
import { PaginationProps } from '@/Components/UI/Pagination/types';

export const Pagination: React.FC<PaginationProps> = (props) => {
    const handlePageChange = (page: number) => {
        if (props.changePage) {
            props.changePage(page);
        }
    };

    const dots = (index: number) => {
        return <span key={`Dots_${index}`}>...</span>;
    };

    const page = (page: number, key: string) => {
        return (
            <span key={`page_${page}`} onClick={() => handlePageChange(page)}>
                {page}
            </span>
        );
    };

    const getBefore = () => {
        const arr = [];

        for (let i = 1; i <= 4; i++) {
            const calc = props.current - i;

            if (calc !== 0 && calc > 0) {
                const first = page(1, `Before_${i}`);
                let element;

                switch (i) {
                    case 3:
                        if (calc === 1) {
                            element = first;
                        } else {
                            element = dots(i);
                        }

                        break;
                    case 4:
                        element = first;
                        break;
                    default:
                        element = page(calc, `Before_${i}`);
                        break;
                }

                arr.unshift(element);
            }
        }

        return arr;
    };

    const getAfter = () => {
        if (!props.last) return;

        const arr = [];

        for (let i = 1; i <= 4; i++) {
            const calc = props.current + i;

            if (calc !== props.last + 1 && calc <= props.last) {
                const last = page(props.last, `After_${i}`);
                let element;

                switch (i) {
                    case 3:
                        if (calc === props.last) {
                            element = last;
                        } else {
                            element = dots(i);
                        }
                        break;
                    case 4:
                        element = last;
                        break;
                    default:
                        element = page(calc, `After_${i}`);
                        break;
                }

                arr.push(element);
            }
        }

        return arr;
    };

    return (
        <div className={'pagination' + (props.className ? ` ${props.className}` : '')}>
            {!!props.last && (
                <>
                    {props.current !== 1 && (
                        <Icon
                            name="chevron-left"
                            size={24}
                            // onClick={(event: React.MouseEvent) => {
                            //     event.preventDefault();
                            //     handlePageChange(props.current - 1);
                            // }}
                            onClick={props.prevPage}
                        />
                    )}
                    {getBefore()}
                    <span className="active">{props.current}</span>
                    {getAfter()}
                    {props.current !== props.last && (
                        <Icon
                            name="chevron-right"
                            size={24}
                            // onClick={(event: React.MouseEvent) => {
                            //     event.preventDefault();
                            //     handlePageChange(props.current + 1);
                            // }}
                            onClick={props.nextPage}
                        />
                    )}
                </>
            )}
        </div>
    );
};
