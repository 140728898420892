import * as Icon from '@/Assets/Languages';

import { Language } from './types';

export const ENGLISH_LANGUAGE: Language = { icon: Icon.Usa, name: 'English', short: 'En', code: 'en', default: true };

export const DEFAULT_LANGUAGE: Language = ENGLISH_LANGUAGE;

export const languagesList: Language[] = [
    ENGLISH_LANGUAGE,
    { icon: Icon.Spain, name: 'Española', short: 'Es', code: 'es_ES' },
    { icon: Icon.Russia, name: 'Русский', short: 'Ru', code: 'ru_RU' },
    { icon: Icon.Israel, name: 'שפהעברית', short: 'Il', code: 'he_IL' },
    { icon: Icon.Uae, name: 'اللغة العربية', short: 'Ae', code: 'ar_AE' },
    { icon: Icon.Japan, name: '日本', short: 'Jp', code: 'ja_JP' },
    { icon: Icon.Portugal, name: 'Português', short: 'Pt', code: 'pt_PT' },
    { icon: Icon.German, name: 'Deutsch', short: 'De', code: 'de_DE' },
    { icon: Icon.Czech, name: 'Česky', short: 'Cz', code: 'cs_CZ' },
    { icon: Icon.France, name: 'Français', short: 'Fr', code: 'fr_FR' },
    { icon: Icon.Italy, name: 'Italiano', short: 'It', code: 'it_IT' },
    { icon: Icon.Romania, name: 'Română', short: 'Ro', code: 'ro_RO' },
    {
        icon: Icon.Chinese,
        name: '中文简体',
        short: 'Cn',
        code: 'zh_CN',
    },
];
