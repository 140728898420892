import cn from 'classnames';
import { FC, useEffect, useMemo, useRef, useState } from 'react';

import { BaseInput, ButtonWrapper, Icon, NumberInput } from '@.ui';
import { useForm, useObjectEffect, useOutsideAlerter, useRefEffect, useTrustedState } from '@/Components/Hooks';
import { Tools } from '@/Services';

import { Input } from './Input';
import { Class, DateData, DatePickerData, DatePickerProps, NumberRange, RangeValues, SelectedState, TimeData, TimeState } from './types';

const getRealRangeValue = (value: RangeValues) => {
    if (typeof value === 'string') {
        switch (value) {
            case 'any':
                return undefined;
            case 'now':
                return Tools.date.create().timestamp();
        }
    }

    return value;
};

const getRealRange = (range: [RangeValues, RangeValues]) => range.map((item) => getRealRangeValue(item)) as [number | undefined, number | undefined];
const refName = (date: DateData) => `${date.day}/${date.month}/${date.year}`;
const dateObj = (date: DateData) => Tools.date.create(new Date(date.year, date.month, date.day));

const weekdays = ['S', 'M', 'T', 'W', 'TH', 'F', 'S'];

export const DatePicker: FC<DatePickerProps> = ({ onChange, placeholderFrom, single, placeholderTo, range, value, full, errors, required }) => {
    const createCurrentMonth = () => {
        const [from, to] = getRealRange(range ?? ['any', 'any']);

        let currMonth = Tools.date.create();
        const currentMonthTimestamp = currMonth.timestamp();

        if (from && to && (currentMonthTimestamp > to || currentMonthTimestamp < from)) {
            if (typeof to === 'string') currMonth = Tools.date.create();
            else currMonth = Tools.date.create(to);
        }

        return currMonth;
    };

    const [field, form] = useForm<DatePickerData>();

    const getRangeFromValue = (value: NumberRange = [undefined, undefined]) => {
        const [from, to] = value;

        if (form.state.from?.value && form.state.to?.value) return;

        const range: SelectedState = { from: undefined, to: undefined };
        const time: TimeState = { from: undefined, to: undefined };

        if (from) {
            const date = Tools.date.create(from);

            range.from = { day: date.getDate(), month: date.getMonth(), year: date.getFullYear() };
            time.from = { hours: date.getHours(), minutes: date.getMinutes(), seconds: date.getSeconds() };
        }
        if (to) {
            const date = full ? Tools.date.create(to) : Tools.date.create(to).dayEnd();

            range.to = { day: date.getDate(), month: date.getMonth(), year: date.getFullYear() };
            time.to = { hours: date.getHours(), minutes: date.getMinutes(), seconds: date.getSeconds() };
        }

        return { range, time };
    };

    const fromRef = useRef<HTMLDivElement>(null),
        toRef = useRef<HTMLDivElement>(null),
        blockRef = useRef<HTMLDivElement>(null),
        daysRef = useRef<{ [name: string]: HTMLSpanElement | null }>({});

    const [isOpen, setOpen] = useState(false),
        [selectedRange, setSelectedRange, isTrusted] = useTrustedState<SelectedState>(
            getRangeFromValue(value)?.range ?? {
                from: undefined,
                to: undefined,
            }
        ),
        [currentMonth, setCurrentMonth] = useState(createCurrentMonth()),
        [selectedTime, setSelectedTime] = useState<TimeState>(
            getRangeFromValue(value)?.time ?? {
                from: undefined,
                to: undefined,
            }
        ),
        [inputTime, setInputTime] = useState<[number, number, number]>([0, 0, 0]),
        [currentSelect, setCurrentSelect] = useState<'from' | 'to'>('from');

    useRefEffect(
        (block) => {
            if (isOpen) block.classList.add(Class.Open);
            else block.classList.remove(Class.Open);
        },
        [isOpen],
        blockRef
    );

    useObjectEffect(() => {
        const { from: fromField, to: toField } = form.state;

        const from = fromField?.value;
        const to = toField?.value;

        const add = (d: number | undefined, time: TimeData | undefined, end = false) => {
            if (!isTrusted) return;

            let date = Tools.date.create(d);

            const { hours, minutes, seconds } = time ?? { hours: 0, minutes: 0, seconds: 0 };

            date = date.offset(seconds * 1000 + minutes * 1000 * 60 + hours * 1000 * 60 * 60);

            if (end && !hours && !minutes && !seconds) return date.dayEnd().timestamp();

            return end ? date.dayEnd().timestamp() : date.timestamp();
        };

        if (onChange && isTrusted) {
            onChange([from && add(from, selectedTime.from), to && add(to, selectedTime.to, !full)]);

            if (single && from) {
                handleClose();
            }
        }
    }, [form.state, `${isTrusted}`, selectedTime]);

    useObjectEffect(() => {
        const val = getRangeFromValue(value)?.range;

        if (!val) return;

        setSelectedRange(val, false);
    }, [value]);

    useObjectEffect(() => {
        const val = getRangeFromValue(value)?.time;

        if (!val) return;

        setSelectedTime(val);
    }, [value]);

    useObjectEffect(() => {
        const { from, to } = selectedRange;

        setCurrentSelect(() => {
            if (!from || !to) return 'from';
            else return 'to';
        });
    }, [selectedRange]);

    useObjectEffect(() => {
        setInputTime(() => {
            const { hours, minutes, seconds } = selectedTime[currentSelect] ?? { hours: 0, minutes: 0, seconds: 0 };

            return [hours, minutes, seconds];
        });
    }, [currentSelect, selectedTime]);

    useEffect(() => {
        clearSelected();

        const { from, to } = selectedRange;

        form.set.values({
            from: from && dateObj(from).timestamp(),
            to: to && dateObj(to).timestamp(),
        });

        if (from) daysRef.current[refName(from)]?.classList.add(Class.Selected);
        if (to) daysRef.current[refName(to)]?.classList.add(Class.Selected);

        if (!from || !to) return;

        Object.entries(daysRef.current).forEach(([key, el]) => {
            const [day, month, year]: number[] = key.split('/').map((k) => parseInt(k));
            const date = dateObj({ year, month, day });

            if (date < dateObj(from) || date > dateObj(to)) return;

            el?.classList.add(Class.InRange);
        });
    }, [selectedRange, currentMonth]);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    useOutsideAlerter([fromRef, toRef, blockRef], handleClose);

    const clearSelected = () => Object.values(daysRef.current).forEach((day) => day?.classList.remove(Class.InRange, Class.Selected));

    const isSame = (date0: DateData, date: DateData) => date0.day === date.day && date0.month === date.month && date0.year === date.year;

    const handleTime = (state: Partial<TimeData>) => {
        new Promise<'from' | 'to'>((resolve) => {
            setCurrentSelect((prev) => {
                resolve(prev);
                return prev;
            });
        }).then((select) => {
            setSelectedTime((prev) => {
                prev[select] = {
                    ...prev[select],
                    ...(Object.fromEntries(Object.entries(state).map(([key, value]) => [key, value ?? 0])) as unknown as TimeData),
                };

                return { ...prev };
            });
            setSelectedRange((prev) => prev, true);
        });
    };

    const handleHours = (value?: number) => handleTime({ hours: value });
    const handleMinutes = (value?: number) => handleTime({ minutes: value });
    const handleSeconds = (value?: number) => handleTime({ seconds: value });

    const handleDaySelect = (date: DateData) => {
        setSelectedRange((prev) => {
            if (single || !prev.from) return { from: date, to: undefined };
            if (!prev.to) {
                if (new Date(date.year, date.month, date.day) < new Date(prev.from.year, prev.from.month, prev.from.day))
                    return {
                        from: date,
                        to: prev.from,
                    };
                return { ...prev, to: date };
            }

            if (prev.from && prev.to && (isSame(prev.from, date) || isSame(prev.to, date)))
                return {
                    from: undefined,
                    to: undefined,
                };
            if (prev.from && prev.to) return { from: date, to: undefined };

            return prev;
        });
    };

    const handleMinus = () =>
        setCurrentMonth((prev) => {
            prev.setMonth(prev.getMonth() - 1);
            return Tools.date.create(prev.timestamp());
        });
    const handlePlus = () =>
        setCurrentMonth((prev) => {
            prev.setMonth(prev.getMonth() + 1);
            return Tools.date.create(prev.timestamp());
        });

    const content = useMemo(() => {
        const prevMonth = Tools.date.create(currentMonth.timestamp());
        prevMonth.setMonth(prevMonth.getMonth() - 1);

        const nextMonth = Tools.date.create(currentMonth.timestamp());
        nextMonth.setMonth(nextMonth.getMonth() + 1);

        const prev = Array.from({ length: currentMonth.firstWeekDay() }, (x, index) => prevMonth.lastDay() - index).reverse();
        const days = Array.from({ length: currentMonth.lastDay() }, (x, index) => index + 1);
        const next = Array.from({ length: 42 - prev.length - days.length }, (x, index) => index + 1);

        const renderDays = (days: number[], key: string, dateObj: Tools.date.CustomDate, isOffset?: boolean) =>
            days.map((day) => {
                const month = dateObj.getMonth(),
                    year = dateObj.getFullYear();

                const date = { day, month, year };

                return (
                    <span
                        className={isOffset ? 'offset' : undefined}
                        key={`${day}-${month}-${year}`}
                        ref={(el) => (daysRef.current[refName(date)] = el)}
                        onClick={() => handleDaySelect(date)}
                    >
                        {day}
                    </span>
                );
            });

        return (
            <div className="datepicker_content">
                {full && (
                    <div className="datepicker_content__inputs">
                        <NumberInput.Logic onChange={handleHours} value={inputTime[0]} placeholder="Hour" format="2digits" min={0} max={24} />
                        <NumberInput.Logic onChange={handleMinutes} value={inputTime[1]} placeholder="Minutes" format="2digits" min={0} max={60} />
                        <NumberInput.Logic onChange={handleSeconds} value={inputTime[2]} placeholder="Seconds" format="2digits" min={0} max={60} />
                    </div>
                )}
                <div className="datepicker_content__header">
                    <p>Period</p>
                    <div className="datepicker_content__header-date">
                        <ButtonWrapper onClick={handleMinus}>
                            <Icon name="chevron-right" className="datepicker_content__header-icon_left" />
                        </ButtonWrapper>
                        <p>{currentMonth.format('MON YYYY')}</p>
                        <ButtonWrapper onClick={handlePlus}>
                            <Icon name="chevron-right" className="datepicker_content__header-icon_right" />
                        </ButtonWrapper>
                    </div>
                </div>
                <div className="datepicker_content__calendar">
                    <div className="datepicker_content__calendar-weekdays">
                        {weekdays.map((day, i) => (
                            <span key={`week-days-${day}-${i}`}>{day}</span>
                        ))}
                    </div>
                    <div className="datepicker_content__calendar-days">
                        {renderDays(prev, 'prev', prevMonth, true)}
                        {renderDays(days, 'month', currentMonth)}
                        {renderDays(next, 'next', nextMonth, true)}
                    </div>
                </div>
            </div>
        );
    }, [currentMonth, inputTime]);

    return (
        <BaseInput className={cn('datepicker', { datepicker_single: single, datepicker_required: required })}>
            {errors?.length ? (
                <div className="datepicker-errors">
                    {errors.map((error, index) => (
                        <p key={`${error}-${index}`}>{error}</p>
                    ))}
                </div>
            ) : (
                <></>
            )}
            <div className="datepicker__inputs">
                <Input
                    {...field('from').register()}
                    ref={fromRef}
                    onClick={handleOpen}
                    placeholder={placeholderFrom ?? (full ? 'Start date' : 'From')}
                    current={currentSelect === 'from' && isOpen}
                />
                {!single && (
                    <Input
                        {...field('to').register()}
                        ref={toRef}
                        onClick={handleOpen}
                        placeholder={placeholderTo ?? (full ? 'End date' : 'To')}
                        current={currentSelect === 'to' && isOpen}
                    />
                )}
            </div>

            <div className="datepicker__block" ref={blockRef}>
                {content}
            </div>
        </BaseInput>
    );
};
