import { Base, FeedbacksType } from '@.api/methods';

export class Feedbacks extends Base {
    public create(data: FeedbacksType) {
        this.setPath('feedbacks');
        this.setData(data);

        return this.postRequest();
    }
}
