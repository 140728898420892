import React, { FC } from 'react';

import { LingualInput } from '@.ui';
import { AdminFields } from '@/Components/Partials/Product/CreateProductForm/components/AdminField';
import { FormItemHeader } from '@/Components/Partials/Product/CreateProductForm/components/FormItemHeader';
import { FormItemWrapper } from '@/Components/Partials/Product/CreateProductForm/components/FormItemWrapper';

interface LingualTextAreaFieldProps {
    headerText: string;
    isAllowed: boolean;
    required?: boolean;
}

export const LingualTextAreaField: FC<LingualTextAreaFieldProps> = ({ headerText, isAllowed, required, ...props }) => {
    return (
        <FormItemWrapper>
            <FormItemHeader required={required}>{headerText}</FormItemHeader>
            <AdminFields isAllowed={isAllowed}>
                <LingualInput {...props} textarea textAreaLangPosition={'top'} />
            </AdminFields>
        </FormItemWrapper>
    );
};
