import { StoreApi, UseBoundStore } from 'zustand';

type WithSelectors<S> = S extends { getState: () => infer T } ? S & { use: { [K in keyof T]: () => T[K] } } : never;

export const createSelectors = <S extends UseBoundStore<StoreApi<object>>>(_store: S) => {
    const store = _store as WithSelectors<typeof _store>;

    store.use = {};

    for (const keyStore of Object.keys(store.getState())) {
        /**
         * Function in value because need get actual value from state.
         */
        (store.use as any)[keyStore] = () => store((state) => state[keyStore as keyof typeof state]);
    }

    return store;
};
