import React, { FC } from 'react';

import { Select } from '@.ui';
import { AdminFields } from '@/Components/Partials/Product/CreateProductForm/components/AdminField';
import { FormItemHeader } from '@/Components/Partials/Product/CreateProductForm/components/FormItemHeader';
import { FormItemWrapper } from '@/Components/Partials/Product/CreateProductForm/components/FormItemWrapper';

import S from './styles.module.scss';

interface dictionariesItem {
    id: number;
    name: string;
}

interface SelectFieldProps {
    headerText: string;
    isAllowed: boolean;
    dictionariesValues: dictionariesItem[];
    multiple?: boolean;
    required?: boolean;
    placeholder?: string;
}

export const SelectField: FC<SelectFieldProps> = ({ dictionariesValues, headerText, isAllowed, required, multiple, placeholder, ...props }) => {
    return (
        <FormItemWrapper>
            <FormItemHeader required={required}>{headerText}</FormItemHeader>
            <div className={S.selectWrapper}>
                <AdminFields isAllowed={isAllowed}>
                    {multiple ? (
                        <Select {...props} placeholder={placeholder} absolute multiple>
                            {dictionariesValues.map((item) => (
                                <Select.Option key={item.id} id={item.id}>
                                    {item.name}
                                </Select.Option>
                            ))}
                        </Select>
                    ) : (
                        <Select {...props} placeholder={placeholder} absolute>
                            {dictionariesValues.map((item) => (
                                <Select.Option key={item.id} id={item.id}>
                                    {item.name}
                                </Select.Option>
                            ))}
                        </Select>
                    )}
                </AdminFields>
            </div>
        </FormItemWrapper>
    );
};
