import { useFirmware } from '@/Components/Pages/Stations/Station/tabs/firmware/useFirmware';

import { StationTab } from '../types';
import { DeviceCard } from './DeviceCard';

export const Firmware: StationTab = ({ station }) => {
    const { modal, firmware, showEditLicenseKeyModal } = useFirmware(station);

    return (
        <div className="station-firmware">
            {firmware?.devices
                .sort((a, b) => a.type.id - b.type.id)
                .map((device) => (
                    <DeviceCard device={device} firmwareData={firmware} key={device.id} onClickEditLicenseKey={showEditLicenseKeyModal} />
                ))}

            {modal}
        </div>
    );
};
