import { autoFormat, format } from './format';
import { groups } from './groups';

export type Offset = number | 'month' | '-month';

const getOffsetNumber = (offset?: Offset) => {
    if (!offset) return 0;

    if (typeof offset === 'number') return offset;

    switch (offset) {
        case 'month':
            return groups.month;
        case '-month':
            return -groups.month;
    }
};

export class CustomDate extends Date {
    public constructor(date?: number | 'now' | undefined | Date, offset?: Offset) {
        let created: number;

        if (date === 'now' || date === undefined) created = Date.now();
        else created = new Date(date).getTime();

        created += getOffsetNumber(offset);

        super(created);
    }

    public format = (toformat: string) => format(this.getTime(), toformat);
    public autoFormat = () => autoFormat(this.getTime());
    // public toJs = () => toJs(this.getTime());
    public fromJs = () => Math.floor(this.getTime() / 1000);
    public firstWeekDay = () => new Date(this.getFullYear(), this.getMonth(), 1).getDay();
    public lastDay = () => new Date(this.getFullYear(), this.getMonth() + 1, 0).getDate();
    public offset = (offset: Offset) => new CustomDate(this.getTime(), offset);
    public timestamp = () => this.getTime();
    public dayStart = () => {
        if (this.getUTCHours() === 0 && this.getUTCMinutes() === 0 && this.getUTCSeconds() === 0) return this;

        return new CustomDate(new Date(Date.UTC(this.getFullYear(), this.getMonth(), this.getDate())).getTime());
    };
    public dayEnd = () => {
        if (this.getUTCHours() === 23 && this.getUTCMinutes() === 59 && this.getUTCSeconds() === 59) return this;

        return new CustomDate(new Date(Date.UTC(this.getFullYear(), this.getMonth(), this.getDate())).getTime() + 24 * 60 * 60 * 1000 - 1000);
    };
}
