import { Base, Response, UsersQueryType } from '@.api/methods';

import { UserRequestType, UserResponseType, UsersResponseType } from './types';

export class Users extends Base {
    basePath = 'users';

    public getAll(query?: UsersQueryType) {
        this.setPath();
        if (query) this.setQuery(query);

        return this.getRequest() as Response<UsersResponseType>;
    }

    public getById(id: number) {
        this.setPath(id);

        return this.getRequest() as Response<UserResponseType>;
    }

    public create(data: UserRequestType) {
        this.setPath();
        this.setData(data);

        return this.postRequest() as Response;
    }

    public updateById(data: UserRequestType, id: number) {
        this.setPath(id);
        this.setData(data);

        return this.putRequest();
    }

    public blockById(id: number) {
        this.setPath(id, 'block');

        return this.postRequest();
    }

    public unblockById(id: number) {
        this.setPath(id, 'unblock');

        return this.postRequest();
    }

    public destroyById(id: number) {
        this.setPath(id, 'destroy');

        return this.deleteRequest();
    }

    public deleteById(id: number) {
        this.setPath(id);

        return this.deleteRequest();
    }

    public restoreById(id: number) {
        this.setPath(id, 'restore');

        return this.postRequest();
    }
}
