import { ENGLISH_LANGUAGE, Language } from '@/Config/languages';

const to2 = (string: string | number) => `0${string}`.slice(-2);

export const format = (date: string | number | Date, format: string, language: Language = ENGLISH_LANGUAGE) => {
    const newDate = typeof date === 'object' ? date : new Date(date);

    const localeCode = language.code;

    return format
        .replace('hh', to2(newDate.getHours()))
        .replace('mm', to2(newDate.getMinutes()))
        .replace('ss', to2(newDate.getSeconds()))

        .replace('h', newDate.getHours().toString())
        .replace('m', newDate.getMinutes().toString())
        .replace('s', newDate.getSeconds().toString())

        .replace('DD', to2(newDate.getDate()))
        .replace('MM', to2(newDate.getMonth() + 1))

        .replace('MON', newDate.toLocaleString(localeCode, { month: 'short' }))
        .replace('MONTH', newDate.toLocaleString(localeCode, { month: 'long' }))

        .replace('YYYY', newDate.getFullYear().toString())
        .replace('YY', newDate.getFullYear().toString().substring(2));
};

export const autoFormat = (date: string | number | Date, language: Language = ENGLISH_LANGUAGE) =>
    new Date(date).toLocaleString(language.code, {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
    });
