import useModal from '@fipnooone/react-modal';
import { FC, useCallback, useMemo, useReducer, useState } from 'react';

import { useForm } from '@/Components/Hooks';
import { Layout } from '@/Components/Layouts';
import { ModalMessage } from '@/Components/Modal/Dialogs/Message';
import { Button, Upload } from '@/Components/UI';
import { Api } from '@/Services/api';
import { ImportProductsData } from '@/Services/api/methods';

export const BOImport: FC = () => {
    const [keyBrand, refreshBrand] = useReducer((p) => (p === 1000 ? 0 : p + 1), 0);
    const [keyProduct, refreshProduct] = useReducer((p) => (p === 1000 ? 0 : p + 1), 0);
    const [field, form] = useForm<ImportProductsData>();
    const [modal, controls] = useModal();
    const [percent, setPercent] = useState(100);

    const handleBrand = useCallback((file: File | undefined) => {
        field('brands_file').value(file);
    }, []);

    const handleProduct = useCallback((file: File | undefined) => {
        field('products_file').value(file);
    }, []);

    const handleClearBrand = useCallback(() => {
        field('brands_file').value(undefined);
        refreshBrand();
    }, []);

    const handleClearProduct = useCallback(() => {
        field('products_file').value(undefined);
        refreshProduct();
    }, []);

    const handleProgress = useCallback((percent: number) => setPercent(percent), []);

    const handleUpload = useCallback(() => {
        form.ifValid((data) => {
            handleProgress(0);

            Api.import_bo()
                .products(data, handleProgress)
                .onSuccess(({ message }) => {
                    controls.set(<ModalMessage message={message} />);

                    handleClearBrand();
                    handleClearProduct();
                })
                .onValidationError(({ errors }) => {
                    form.set.errors(errors);
                })
                .onError(({ message }) => {
                    message && controls.set(<ModalMessage message={message} />);
                })
                .then(() => {
                    handleProgress(100);
                });
        });
    }, [form.state, form.set, handleProgress]);

    const offset = useMemo(() => {
        const diameter = Math.PI * 12;

        return ((100 - percent) / 100) * diameter;
    }, [percent]);

    return (
        <Layout name="settings" breadcrumbs={[{ name: 'Import from BO', current: true }]} medium>
            {modal}
            <h1>Import from BO</h1>
            <div className="import__column">
                <div className="import__row">
                    <div className="import__input">
                        <label>Brands</label>
                        <Upload.File
                            {...field('brands_file').register()}
                            onChange={undefined}
                            value={undefined}
                            onFile={handleBrand}
                            onClear={handleClearBrand}
                            single
                            key={`import-brand-${keyBrand}`}
                        />
                    </div>
                    <div className="import__input">
                        <label>Products</label>
                        <Upload.File
                            {...field('products_file').register()}
                            onChange={undefined}
                            value={undefined}
                            onFile={handleProduct}
                            onClear={handleClearProduct}
                            single
                            key={`import-product-${keyProduct}`}
                        />
                    </div>
                </div>
                <Button color="brown" onClick={handleUpload} disabled={percent !== 100} className="import_button">
                    {percent !== 100 && (
                        <svg className="circle_loader" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle
                                id="bar"
                                r="6"
                                cx="7"
                                cy="7"
                                fill="transparent"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeMiterlimit="0"
                                strokeDasharray="37.7"
                                strokeDashoffset={offset}
                            />
                        </svg>
                    )}
                    {'Upload'}
                </Button>
            </div>
        </Layout>
    );
};
