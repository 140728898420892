import { Base, Response } from '@.api/methods';

import { AllProductProposalsRequest, ProductProposalsRequest } from './/ProductProposalsRequests';
import {
    AllProductProposalsResponse,
    ProductProposalsApproveResponse,
    ProductProposalsByIdResponse,
    ProductProposalsCreateResponse,
    ProductProposalsPendResponse,
    ProductProposalsRejectResponse,
} from './ProductProposalsResponses';

export class ProductProposalApiService extends Base {
    constructor() {
        super();

        this.setPath('product-proposals');
    }

    public fetchAll(query?: AllProductProposalsRequest): Response<AllProductProposalsResponse> {
        if (query) {
            this.setQuery(query);
        }

        return this.getRequest<AllProductProposalsResponse>();
    }

    public create(data: ProductProposalsRequest): Response<ProductProposalsCreateResponse> {
        this.setData(data);

        return this.postRequest<ProductProposalsCreateResponse>();
    }

    public update(data: ProductProposalsRequest, id: number | string): Response<ProductProposalsCreateResponse> {
        this.setId(id);
        this.setData(data);

        return this.putRequest<ProductProposalsCreateResponse>();
    }

    public getById(id: number | string): Response<ProductProposalsByIdResponse> {
        this.setId(id);

        return this.getRequest<ProductProposalsByIdResponse>();
    }

    public pend(productProposalId: number | string): Response<ProductProposalsPendResponse> {
        this.setId(`${productProposalId}/pend`);

        return this.postRequest<ProductProposalsPendResponse>();
    }

    public approve(productProposalId: number | string, udi: number): Response<ProductProposalsApproveResponse> {
        this.setId(`${productProposalId}/approve`);

        this.setData({ udi });

        return this.postRequest<ProductProposalsApproveResponse>();
    }

    public reject(productProposalId: number | string, rejectReason: string) {
        this.setId(`${productProposalId}/reject`);
        this.setData({ reject_reason: rejectReason });

        return this.postRequest<ProductProposalsRejectResponse>();
    }

    public delete(productProposalId: number | string): Response {
        this.setId(productProposalId);

        return this.deleteRequest();
    }
}
