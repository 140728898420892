import { _RuleMessageObject, Form, FormErrors, RuleMessage, ValidateFieldOptionObj, ValidateOptions } from '@.hooks';

import { replacer, ruleValue } from '../functions';

type ValidOptions<T extends object> = { [name in keyof ValidateOptions<T>]: T[name] };

export const validator = <T extends object>(
    state: Form<T>,
    options?: ValidateOptions<T>
): {
    data: ValidOptions<T> | false;
    errors: FormErrors<T>;
} => {
    if (!options) {
        return { data: {} as ValidOptions<T>, errors: {} };
    }

    const data: ValidOptions<T> | Partial<T> = {};

    const errors: FormErrors<T> = {};

    let isValidated = true;

    const setLocalError = <S>(fieldName: keyof T, fieldOption: ValidateFieldOptionObj<T>, rule?: RuleMessage<S>) => {
        isValidated = false;

        const set = (message: string) => (errors[fieldName] = [replacer(message, fieldName, rule)]);

        if (rule) {
            rule = rule as _RuleMessageObject<S>;

            if (rule.message && rule.value !== undefined && rule.value !== null && rule.value !== false) {
                set(rule.message);
                return;
            }
        }

        if (fieldOption && fieldOption.message) {
            set(fieldOption.message);
            return;
        }
    };

    Object.entries(options).forEach(([key, value]) => {
        const name = key as keyof T;
        const option = value as ValidateFieldOptionObj<T>;
        const field = state[name];
        let fieldValue = field?.value as T[keyof T] | undefined;

        if (!option) return;

        const ifRule = <V>(rule: RuleMessage<V>, fn: (ruleValue: NonNullable<V>) => void | boolean) => {
            if (rule === undefined) {
                return;
            }

            const value = ruleValue(rule);

            if (value === undefined || value === null) {
                return;
            }

            if (fn(value) === false) {
                setLocalError(name, option, rule);
            }
        };

        ifRule(option.default, (rule) => {
            if (rule !== undefined && !fieldValue) data[name] = fieldValue = rule as T[keyof T];
        });

        ifRule(option.required, (rule) => {
            if (rule && fieldValue === undefined) return false;
        });

        ifRule(option.requiredIfField, (rule) => {
            if (rule && state[rule]?.value && !fieldValue) return false;
        });

        if (option.type === 'string' && fieldValue) {
            ifRule(option.regex, (rule) => {
                if (typeof fieldValue !== 'string' || !rule.test(fieldValue)) return false;
            });

            const __val = fieldValue;

            if (typeof __val === 'string') {
                ifRule(option.min, (rule) => (__val as string).length > rule);

                ifRule(option.max, (rule) => (__val as string).length < rule);
            }
        }

        if (option.type === 'number' && fieldValue !== undefined) {
            const __val = fieldValue;

            if (typeof __val === 'number' || typeof __val === 'string') {
                ifRule(option.min, (rule) => Number(__val) > rule);
                ifRule(option.max, (rule) => Number(__val) < rule);
            }
        }

        if (option.type === 'date') {
            ifRule(option.required, (rule) => {
                if (
                    (rule && (fieldValue === undefined || fieldValue === null)) ||
                    (fieldValue as [number, number])[0] === undefined ||
                    (fieldValue as [number, number])[1] === undefined
                )
                    return false;
            });
        }

        if (option.type === 'date_from') {
            ifRule(option.required, (rule) => {
                if ((rule && (fieldValue === undefined || fieldValue === null)) || (fieldValue as [number, number])[0] === undefined) return false;
            });
        }

        if (option.type === 'number[]' || option.type === 'string[]') {
            ifRule(option.required, (rule) => {
                if (rule && (fieldValue === undefined || fieldValue === null || (fieldValue as unknown[]).length === 0)) return false;
            });
        }

        if (option.type === 'lingual') {
            if (fieldValue !== undefined && fieldValue !== null && typeof fieldValue === 'object') {
                Object.assign(data, Object.fromEntries(Object.entries(fieldValue as object).map(([key, value]) => [`${name.toString()}_${key}`, value])));
            }

            const __val = fieldValue;

            if (typeof __val === 'object' && fieldValue !== null) {
                const __vals = Object.values(__val as object);

                ifRule(option.max, (rule) => __vals.every((item) => !item || item.length < rule));
            }
        }

        ifRule(option.sameAs, (rule) => fieldValue === state[rule]?.value);
    });

    return {
        data: (isValidated ? data : false) as ValidOptions<T> | false,
        errors,
    };
};
