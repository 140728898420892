import { useRef, useState } from 'react';

import { useObjectEffect, useRefEffect } from '@/Components/Hooks';
import { Board } from '@/Components/Partials';
import { Select } from '@/Components/UI';
import { LinkHelper } from '@/Services';
import { Api } from '@/Services/api';
import { Dashboard } from '@/Types';

import { DashboardItem } from '../../types';

export const Wisard: DashboardItem = ({ filters }) => {
    const [wizard, setWizard] = useState<Dashboard.Wisard.Response['questions']>([]);
    const [selected, setSelected] = useState<number | undefined>(wizard[0]?.id);
    const listRef = useRef<HTMLDivElement>(null);

    useObjectEffect(() => {
        if (!wizard) return;

        setSelected((prev) => {
            if (!prev) return wizard[0]?.id;
            return prev;
        });
    }, [wizard]);

    useRefEffect(
        (block) => {
            if (block.scrollHeight > block.clientHeight) block.classList.add('scroll');
            else block.classList.remove('scroll');
        },
        [selected, wizard],
        listRef
    );

    useObjectEffect(() => {
        Api.dashboard()
            .wizard(filters)
            .onSuccess((res) => setWizard(res.data.questions));
    }, [filters]);

    const handleSelect = (id?: number) => setSelected(id ?? wizard[0]?.id);

    return (
        <Board.Item size="2/3" title="Popular wizard answers" className="dashboard__wizard-answers" more={LinkHelper.dashboardWizardAnswers}>
            <Select placeholder="Question" onChange={handleSelect} value={selected} theme="white" absolute autoClose>
                {wizard.map((question) => (
                    <Select.Option key={`wizard-option-${question.id}`} id={question.id}>
                        {question.name}
                    </Select.Option>
                ))}
            </Select>
            <div ref={listRef} className="dashboard__wizard-answers__list">
                {selected !== undefined &&
                    wizard
                        .find((d) => d.id === selected)
                        ?.answers.map((answer) => (
                            <div className="dashboard__wizard-answers__item" key={`answer+${answer.id}`}>
                                <p>{answer.name}</p>
                                <p>{answer.count}</p>
                            </div>
                        ))}
            </div>
        </Board.Item>
    );
};
