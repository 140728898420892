import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Layout } from '@.layout';
import { Auth, LinkHelper } from '@.services';
import { Input, Select, Textarea } from '@.ui';
import { requiredRule, useDictionaries, useForm, useId } from '@/Components/Hooks';
import { Buttons } from '@/Components/Partials';
import { Api } from '@/Services/api';
import { OrderResource, OrdersUpdateRequest } from '@/Services/api/methods/deviceOrders/types';
import { BaseResource } from '@/Types';

import { NewProductsOrdersActionProps } from './types';

export const DevicesAction: React.FC<NewProductsOrdersActionProps> = ({ edit = false }) => {
    const auth = Auth.Use();
    const navigate = useNavigate();
    const [data, setData] = useState<OrderResource & BaseResource>();
    const [field, form] = useForm<OrdersUpdateRequest>();
    const [dictionaries] = useDictionaries('DeviceOrderStatuses', 'Stores');

    const id = useId((id) => {
        Api.deviceOrders()
            .show(id)
            .onSuccess(({ data }) => {
                form.set.values({
                    address: data.address ?? '',
                    comment: data.comment ?? '',
                    devices_count: data.devices_count,
                    status: data.status.id,
                    store_id: data.store.id,
                });

                setData({ name: `Order #${data.id}`, ...data });
            });
    }, edit);

    const handleSave = useCallback(() => {
        if (edit) {
            if (auth?.user?.is_super) {
                form.ifValid(
                    ({ status }) => {
                        Api.deviceOrders()
                            .update(id, {
                                address: data?.address,
                                comment: data?.comment,
                                devices_count: data?.devices_count,
                                store_id: data?.store.id,
                                status,
                            })
                            .onSuccess(() => {
                                navigate(LinkHelper.ordersDevices);
                            })
                            .onValidationError(({ errors }) => {
                                form.set.errors(errors);
                            });
                    },
                    {
                        status: {
                            type: 'number',
                            required: requiredRule,
                        },
                        address: {
                            type: 'string',
                        },
                        comment: {
                            type: 'string',
                        },
                        devices_count: {
                            type: 'number',
                        },
                        store_id: {
                            type: 'number',
                        },
                    }
                );
                return;
            }

            form.ifValid(
                ({ comment = '', ...validated }) => {
                    if (!data) return;

                    Api.deviceOrders()
                        .update(id, { ...validated, comment, status: data.status.id })
                        .onSuccess(() => {
                            navigate(LinkHelper.ordersDevices);
                        })
                        .onValidationError(({ errors }) => {
                            form.set.errors(errors);
                        });
                },
                {
                    status: {
                        type: 'number',
                    },
                    address: {
                        type: 'string',
                        required: requiredRule,
                    },
                    comment: {
                        type: 'string',
                    },
                    devices_count: {
                        type: 'number',
                        required: requiredRule,
                        min: {
                            value: 0,
                            message: 'Number of devices must be at least 1',
                        },
                    },
                    store_id: {
                        type: 'number',
                        required: requiredRule,
                    },
                }
            );

            return;
        }

        form.ifValid(
            ({ comment = '', ...validated }) => {
                Api.deviceOrders()
                    .create({ ...validated, comment })
                    .onSuccess(() => {
                        navigate(LinkHelper.ordersDevices);
                    })
                    .onValidationError(({ errors }) => {
                        form.set.errors(errors);
                    });
            },
            {
                status: {
                    type: 'number',
                },
                address: {
                    type: 'string',
                    required: requiredRule,
                },
                comment: {
                    type: 'string',
                },
                devices_count: {
                    type: 'number',
                    required: requiredRule,
                    min: {
                        value: 0,
                        message: 'Number of devices must be at least 1',
                    },
                },
                store_id: {
                    type: 'number',
                    required: requiredRule,
                },
            }
        );
    }, [id, auth, form, data]);

    return (
        <Layout
            name="orders"
            breadcrumbs={
                edit
                    ? [
                          { name: 'Ordering devices', link: LinkHelper.ordersDevices },
                          { name: data?.name ?? '', link: data && LinkHelper.ordersDevicesItemId(data.id) },
                          { name: 'Edit', current: true },
                      ]
                    : [
                          { name: 'Ordering devices', link: LinkHelper.ordersDevices },
                          { name: 'Add', current: true },
                      ]
            }
        >
            {edit ? <h1>Editing order</h1> : <h1>New order</h1>}
            <div className="orders_action__form">
                <div className="orders_action__form__inputs">
                    {auth?.user?.is_super && (
                        <Select {...field('status').register<number>()} placeholder="Status" theme="white" required>
                            {dictionaries?.device_order_statuses?.map((item) => (
                                <Select.Option id={item.id} key={`orders-statuses-${item.id}`}>
                                    {item.name}
                                </Select.Option>
                            ))}
                        </Select>
                    )}
                    <Select {...field('store_id').register<number>()} placeholder="Store" theme="white" disabled={auth?.user?.is_super} required>
                        {dictionaries?.stores?.map((item) => (
                            <Select.Option id={item.id} key={`orders-stores-${item.id}`}>
                                {item.name}
                            </Select.Option>
                        ))}
                    </Select>
                    <Input text="Address" {...field('address').register()} placeholder="Shipping address" readOnly={auth?.user?.is_super} required />
                    <Input
                        text="Number of devices"
                        {...field('devices_count').register()}
                        placeholder="Number of devices"
                        regex={/^\d+$/}
                        readOnly={auth?.user?.is_super}
                        required
                    />
                    <Textarea text="Comment" {...field('comment').register()} placeholder="Comment" readOnly={auth?.user?.is_super}></Textarea>
                </div>
            </div>
            <Buttons linkCancel={LinkHelper.ordersDevices} onClickSave={handleSave} text={edit ? 'Save' : 'Add'} />
        </Layout>
    );
};
