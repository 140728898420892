import { Breadcrumb } from '@/Components/UI';
import { LinkHelper } from '@/Services';

import { UseBreadCrumbsParameters } from './types';

export const useBreadcrumbs = ({ type, id, name = '' }: UseBreadCrumbsParameters): Breadcrumb[] => {
    const baseBreadcrumb: Breadcrumb = {
        name: 'Update packages',
        link: LinkHelper.updatePackages,
    };

    const itemBreadcrumb: Breadcrumb | undefined =
        id && name
            ? {
                  name,
                  link: LinkHelper.updatePackagesItemId(id),
              }
            : undefined;

    const breadcrumbs = (() => {
        switch (type) {
            case 'list':
                return [baseBreadcrumb];
            case 'add':
                return [
                    baseBreadcrumb,
                    {
                        name: 'Add',
                    },
                ];
            case 'view':
                return [baseBreadcrumb, itemBreadcrumb];
            case 'edit':
                return [
                    baseBreadcrumb,
                    itemBreadcrumb,
                    {
                        name: 'Edit',
                    },
                ];
        }
    })();

    const lastBreadcrumb = breadcrumbs?.at(-1);
    if (lastBreadcrumb) lastBreadcrumb.current = true;

    return breadcrumbs?.filter((item) => item !== undefined) as Breadcrumb[];
};
