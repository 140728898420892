import React, { useCallback } from 'react';

import { Api } from '@.api';
import { Layout } from '@.layout';
import { Table2 } from '@.partials';
import { Filter3 } from '@.ui';
import { LinkHelper } from '@/Services';
import { DashboardStoresDetailedResponse } from '@/Services/api/methods/dashboard/types';
import { Request } from '@/Types';

export const PopularStores: React.FC = () => {
    const get = useCallback((q?: Request.All) => Api.dashboard().storesDetailed(q), []);
    const getData = useCallback((data: DashboardStoresDetailedResponse) => data.stores, []);

    return (
        <Layout
            breadcrumbs={[
                { name: 'Dashboard', link: LinkHelper.dashboard },
                { name: 'Popular stores', current: true },
            ]}
        >
            <h1>Popular stores</h1>
            <Table2 get={get} getData={getData} export="dashboard_stores">
                {{
                    filters: (handler) => (
                        <Filter3 onChange={handler}>
                            <Filter3.Search />
                            <Filter3.Companies related />
                            <Filter3.StoreGroups related />
                        </Filter3>
                    ),
                    items: [
                        (item) => ({
                            link: LinkHelper.clientsStoreId(item.id),
                            name: 'Name',
                            content: item.name,
                        }),
                        (item) => ({
                            name: 'Company',
                            content: item.company.name,
                        }),
                        (item) => ({
                            name: 'Store group',
                            content: item.store_groups.name,
                        }),
                        (item) => ({
                            name: 'Sales',
                            content: item.sales_count,
                        }),
                        (item) => ({
                            name: 'Whiffs',
                            content: item.whiffs_count,
                        }),
                    ],
                    actions: [
                        (item) => ({
                            type: 'open',
                            link: LinkHelper.clientsStoreId(item.id),
                        }),
                    ],
                }}
            </Table2>
        </Layout>
    );
};

/*
<Filters filters={['Companies', 'StoreGroups']} onChange={setFilters} />
            <Table headers={['Name', 'Company', 'Store group', 'Sales', 'Whiffs']} total={total}>
                {stores?.stores.map((store) => (
                    <TableRow key={`row_${store.id}`}>
                        <TableColumn link={LinkHelper.clientsStoreId(store.id)}>{store?.name}</TableColumn>
                        <TableColumn>{store?.company?.name}</TableColumn>
                        <TableColumn>{store?.store_groups?.name}</TableColumn>
                        <TableColumn>{store?.sales_count}</TableColumn>
                        <TableColumn>{store?.whiffs_count}</TableColumn>
                        <TableActions>
                            <TableAction>
                                <TableIcon name="open" link={LinkHelper.clientsStoreId(store.id)} />
                            </TableAction>
                        </TableActions>
                    </TableRow>
                ))}
            </Table>
            <div className="page-table__footer">
                <Pagination
                    className={total && total <= perPage ? 'hide' : ''}
                    current={currentPage}
                    last={lastPage ?? 1}
                    prevPage={prevPage}
                    nextPage={nextPage}
                    changePage={changePage}
                />
                <ExportBlock model="dashboard_stores" filters={filters} />
            </div>

const [stores, setStores] = useState<DashboardStoresDetailedResponse>();
    const [filters, setFilters] = useState<{ search?: string; company_id?: number; store_group_id?: number; store_id?: number }>();
    const { currentPage, lastPage, perPage, prevPage, nextPage, changePage, total, process } = usePagination();

    const updateStores = () => {
        Api.dashboard()
            .storesDetailed({ page: currentPage, per_page: perPage, ...filters })
            .onSuccess((res) => {
                setStores(res.data);
                process(res.data.pagination);
            });
    };

    useEffect(() => {
        updateStores();
    }, [currentPage, filters]);


    */
