import { useContext } from '@fipnooone/react-modal';
import { useState } from 'react';

import { Api } from '@.api';
import { useForm } from '@.hooks';
import { StationFirmwareDevice } from '@/Types/stations';

import { EditLicenseKeyFormState } from './EditLicenseKeyFormState';
import { EditLicenseKeyValidateOptions } from './EditLicenseKeyValidateOptions';

type UseDeviceCardParams = {
    deviceId: number;
    licenseKeyInitial: string;
    updateDevice: (device: StationFirmwareDevice) => void;
};

export const useEditLicenseKey = ({ updateDevice, licenseKeyInitial, deviceId }: UseDeviceCardParams) => {
    const [isLoading, setLoading] = useState<boolean>(false);

    const [field, form] = useForm<EditLicenseKeyFormState>({ licenseKey: licenseKeyInitial });

    const { close } = useContext();

    const requestSaveLicenseKey = async () => {
        form.ifValid((data) => {
            setLoading(true);

            Api.devices()
                .updateLicenseKey(deviceId, data.licenseKey)
                .onSuccess((response) => {
                    if (response.data) {
                        updateDevice(response.data);
                    }

                    close();
                })
                .onValidationError((response) => {
                    form.set.fieldError('licenseKey', [response.message]);
                })
                .then(() => setLoading(false));
        }, EditLicenseKeyValidateOptions);
    };

    return {
        isLoading,
        field,
        close,
        requestSaveLicenseKey,
    };
};
