import cn from 'classnames';
import React, { useEffect, useState } from 'react';

import { BaseInput } from '@.ui';
import { TextareaProps } from '@/Components/UI/Textarea/types';

export const Textarea: React.FC<TextareaProps> = ({ value = '', onChange, required, readOnly, ...props }) => {
    const [inputText, setInputText] = useState<string>(value.toString());

    const handleChange = (value: string) => {
        setInputText(value);
        onChange?.(value);
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement & HTMLTextAreaElement>) => {
        handleChange(event.target.value);
    };

    useEffect(() => {
        setInputText(value.toString());
    }, [value]);

    return (
        <BaseInput className={cn('textarea', { error: !!props.errors })} {...props}>
            <label className="input-block__label">
                {props.errors?.[0] ?? props.text ?? ''}
                {required && <span>*</span>}
            </label>
            <textarea value={inputText} placeholder={props.placeholder} onChange={handleInputChange} readOnly={readOnly} />
        </BaseInput>
    );
};
