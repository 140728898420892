import {
    Base,
    PricesRequest,
    PricesResponse,
    ProductRequest,
    ProductsImportGetResponse,
    ProductsImportResponse,
    ProductsQuery,
    ProductsResponseType,
    ProductsSearchResponse,
    ProductType,
    Response,
} from '@.api/methods';
import { Request } from '@/Types';

export class Products extends Base {
    public getAll(query?: ProductsQuery) {
        this.setPath('products');
        if (query) this.setQuery(query);

        return this.getRequest() as Response<ProductsResponseType>;
    }

    public getById(id: number) {
        this.setPath('products');
        this.setId(id);

        return this.getRequest() as Response<ProductType>;
    }

    public create(data: ProductRequest): Response<ProductType> {
        this.setPath('products');
        this.setData(data);

        return this.postRequest<ProductType>();
    }

    public update(data: ProductRequest, id: number): Response<ProductType> {
        this.setPath('products');
        this.setId(id);
        this.setData(data);

        return this.putRequest<ProductType>();
    }

    public description(id: number | string, data: { [name: string]: string | null }) {
        this.setPath(`products/${id}/descriptions`);
        this.setData(data);

        return this.putRequest();
    }

    public delete(id: number) {
        this.setPath('products');
        this.setId(id);

        return this.deleteRequest<ProductType>();
    }

    public restore(id: number) {
        this.setPath('products');
        this.setId(`${id}/restore`);

        return this.postRequest<ProductType>();
    }

    public getPrices(id: number) {
        this.setPath('products');
        this.setId(`${id}/prices`);

        return this.getRequest() as Response<PricesResponse>;
    }

    public postPrices(data: PricesRequest, id: number) {
        this.setPath('products');
        this.setId(`${id}/prices`);
        this.setData(data);

        return this.postRequest<PricesResponse>();
    }

    public import(data: { products_ids: number[] }) {
        this.setPath('products/import');
        this.setData(data);

        return this.postRequest() as Response<ProductsImportResponse>;
    }

    public importGet(query?: Request.All) {
        this.setPath('products/import');
        query && this.setQuery(query);

        return this.getRequest<ProductsImportGetResponse>();
    }

    public importRemove(data: { product_id: number }) {
        this.setPath('products/import/remove');
        this.setData(data);

        return this.postRequest();
    }

    public search(query: { search: string }) {
        this.setPath('products/search');
        this.setQuery(query);

        return this.getRequest() as Response<ProductsSearchResponse>;
    }
}
