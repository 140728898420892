import { useState } from 'react';

import { useObjectEffect } from '@/Components/Hooks';
import { Board, Curves } from '@/Components/Partials';
import { Api } from '@/Services/api';
import { DashboardNewOrdersResponse } from '@/Services/api/methods/dashboard/types';

import { DashboardItem } from '../../types';

export const Orders: DashboardItem = ({ filters }) => {
    const [orders, setOrders] = useState<DashboardNewOrdersResponse>();

    useObjectEffect(() => {
        Api.dashboard()
            .newOrders({ ...filters })
            .onSuccess((res) => setOrders(res.data));
    }, [filters]);

    return <Board.Item title="New Orders">{orders && <Curves data={orders.orders} color={'#66C0BE'} />}</Board.Item>;
};
