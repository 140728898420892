import './Styles/index.scss';

import React from 'react';
import ReactDOM from 'react-dom/client';

import { Auth } from '@.services';

import { Router } from './Components/Router';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

/**
 * TODO: in future add <React.StrictMode/>.
 */
root.render(
    <Auth.Provider>
        <Router />
    </Auth.Provider>
);
