import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Api } from '@.api';
import { BrandResponseType } from '@.api/methods';
import { Layout } from '@.layout';
import { Tab, Tabs } from '@.partials';
import { LinkHelper, Tools, useLingualField } from '@.services';
import { Button } from '@.ui';
import { ProductsTable } from '@/Components/Pages/Data/Products';
import { PermissionProps } from '@/Types';

export const Brand: React.FC<PermissionProps> = (props) => {
    const [brand, setBrand] = useState<BrandResponseType>();
    const navigator = useNavigate();
    const { id } = useParams();

    const updateBrand = () => {
        Api.brands()
            .getById(Number(id))
            .onSuccess((res) => setBrand(res.data));
    };

    const handleDelete = () => {
        Api.brands()
            .delete(Number(id))
            .onSuccess(() => updateBrand())
            .onError((res) => alert(res.message));
    };

    const handleRestore = () => {
        Api.brands()
            .restore(Number(id))
            .onSuccess(() => updateBrand());
    };

    useEffect(() => {
        updateBrand();
    }, []);

    const brandName = useLingualField(brand);

    return (
        <Layout
            name="data"
            breadcrumbs={[
                { name: 'Brands', link: LinkHelper.dataBrands },
                { name: brandName, current: true },
            ]}
        >
            {brand && (
                <>
                    <h1>
                        {brandName}
                        <div>
                            <Button
                                color="transparent"
                                text={brand.deleted_at ? 'Restore' : 'Archive'}
                                onClick={brand.deleted_at ? handleRestore : handleDelete}
                                disabled={!props.permissions?.delete}
                            />
                            <Button
                                color="brown"
                                text="Edit"
                                onClick={() => navigator(LinkHelper.dataBrandsEditId(Number(id)))}
                                disabled={!!brand.deleted_at || !props.permissions?.update}
                            />
                        </div>
                    </h1>
                    <Tabs>
                        <Tab name="General" path="general">
                            <div className="entity-page">
                                <div className="entity-page__container">
                                    <div>
                                        <div className="entity-page__item">
                                            <div className="bold">Name</div>
                                            <div>{brandName}</div>
                                        </div>
                                        <div className="entity-page__item">
                                            <div className="bold">Adding date</div>
                                            <div>{Tools.date.autoFormat(brand?.created_at * 1000)}</div>
                                        </div>
                                        <div className="entity-page__item">
                                            <div className="bold">Number of sales</div>
                                            <div>{brand?.sales_count}</div>
                                        </div>
                                    </div>
                                    <img src={brand?.image?.url} alt={brand?.image?.name} />
                                </div>
                                <div className="entity-page__item">
                                    <div className="bold">Whiffs</div>
                                    <div>{brand?.whiffs_count}</div>
                                </div>
                            </div>
                        </Tab>
                        <Tab name="Products" path="products">
                            <ProductsTable
                                filters={['ProductTypes', 'Ages', 'Sexes', 'Strengths', 'Occasions', 'Ingredients', 'IsArchived']}
                                brand_id={brand?.id}
                                hideActions={true}
                            />
                        </Tab>
                    </Tabs>
                </>
            )}
        </Layout>
    );
};
