import { LinkHelper } from '@/Services';
import { UserRoleEnum } from '@/Types/api/User';

export const createUserDefaultUrlPage = (role?: UserRoleEnum): string => {
    let navigatePath = LinkHelper.dashboard;

    if (role === UserRoleEnum.PRODUCT_MANAGER) {
        navigatePath = LinkHelper.productProposals;
    } else if (role === UserRoleEnum.SERVICE_ENGINEER) {
        navigatePath = LinkHelper.stations;
    }

    return navigatePath;
};

export const navigateUserToDefaultPage = (role?: UserRoleEnum): void => {
    window.location.href = createUserDefaultUrlPage(role);
};
