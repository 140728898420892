import { useState } from 'react';

import { useObjectEffect } from '@/Components/Hooks';
import { Board } from '@/Components/Partials';
import { Icon } from '@/Components/UI';
import { Api } from '@/Services/api';
import { DashboardSearchesResponse } from '@/Services/api/methods/dashboard/types';

import { DashboardItem } from '../../types';

export const Searches: DashboardItem = ({ dictionaries, filters }) => {
    const [searches, setSearches] = useState<DashboardSearchesResponse>();

    useObjectEffect(() => {
        Api.dashboard()
            .searches(filters)
            .onSuccess((res) => setSearches(res.data));
    }, [filters]);

    return (
        <Board.Item title="Popular users searches">
            {searches?.searches.map((search) => (
                <div key={`search_${search.label}`} className="dashboard__search">
                    <div className="dashboard__search-label">
                        <Icon name="search" color={'#C17E7E'} className="opacity" size={18} />
                        <p>{search.label}</p>
                    </div>
                    <p>{search.value}</p>
                </div>
            ))}
        </Board.Item>
    );
};
