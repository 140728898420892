import { defaultStyles, Tooltip as VTooltip } from '@visx/tooltip';
import { FC } from 'react';

import { TooltipProps } from './types';

/**
 * TODO: refactor.
 */
export const Tooltip: FC<TooltipProps> = ({ name, title, value, left, top, color }) => (
    <VTooltip className="chart_tooltip" style={defaultStyles} left={left} top={top}>
        <div className="chart_tooltip__title">
            <div className="chart_tooltip__title-circle" style={{ backgroundColor: color }} />
            <p>{title}</p>
        </div>
        <div className="chart_tooltip__content">
            <p>{name}</p>
            <p>{value}</p>
        </div>
    </VTooltip>
);
