import React, { useEffect, useState } from 'react';

import { Language as LanguageConfig } from '@/Config';
import { useCookies } from '@/Services/Cookie';
import { Language } from '@/Types';

import { currentLanguage, defaultLanguage } from '../functions';
import { ISetCurrentLanguage, LanguageContextValue, LanguageProviderProps } from './types';

const initialValue = { current: currentLanguage().code };

export const Context = React.createContext<LanguageContextValue | null>(null);

export const Provider: React.FC<LanguageProviderProps> = ({ children }) => {
    const { get, set } = useCookies<Language.Store>({ expire: 360, name: '_lang' });
    const [current, setCurrent] = useState<string>(get()?.code ?? initialValue.current);

    useEffect(() => {
        set({ code: current });
    }, [current]);

    const setCurrentLanguage: ISetCurrentLanguage = (languageCode) => {
        setCurrent((prev) => {
            let code: string | undefined;

            if (typeof languageCode === 'function') code = languageCode(prev);
            else if (languageCode) code = languageCode;

            if (!code || !LanguageConfig.languagesList.find((lang) => lang.code === code)) {
                return defaultLanguage().code;
            }

            return code;
        });
    };

    return <Context.Provider value={{ current, setCurrent: setCurrentLanguage }}>{children}</Context.Provider>;
};

export const Use = () => {
    const lang = React.useContext(Context);

    if (!lang) throw Error('Wrong language context usage');

    return lang;
};
