import { Base, Response } from '@.api/methods';
import { Request } from '@/Types';

import { OrderResource, OrdersCreateRequest, OrdersReponseType, OrdersUpdateRequest } from './types';

export class DeviceOrders extends Base {
    public list(query?: Request.All) {
        this.setPath('device-orders');
        if (query) this.setQuery(query);

        return this.getRequest() as Response<OrdersReponseType>;
    }

    public show(id: number | string) {
        this.setPath(`device-orders/${id}`);

        return this.getRequest() as Response<OrderResource>;
    }

    public create(data: OrdersCreateRequest) {
        this.setPath('device-orders');
        this.setData(data);

        return this.postRequest() as Response<OrderResource>;
    }

    public update(id: number | string, data: Partial<OrdersUpdateRequest>) {
        this.setPath(`device-orders/${id}`);
        this.setData(data);

        return this.putRequest() as Response<OrderResource>;
    }

    public cancel(id: string | number) {
        this.setPath(`device-orders/${id}/cancel`);

        return this.postRequest() as Response<OrderResource>;
    }
}
