import { Routes } from '../types';
import { CompaniesPage } from './companies';
import { StoreGroupsPage } from './storeGroups';
import { StoresPage } from './stores';

export const ClientsPages: Routes = [
    {
        url: 'clients',
        permissions: 'readOnly',
        sidebar: {
            icon: 'clients',
            name: 'Clients',
        },
        children: [CompaniesPage, StoreGroupsPage, StoresPage],
    },
];
