import { useContext } from '@fipnooone/react-modal';
import React from 'react';

import { Api } from '@.api';
import { FeedbacksType } from '@.api/methods';
import { useForm } from '@.hooks';
import { Form } from '@.partials';
import { Button, Icon, Input, PhoneInput, Textarea, Upload } from '@.ui';
import { validateOptions } from '@/Components/Modal/Dialogs/Feedback/validate';

export const ModalFeedback: React.FC = () => {
    const [field, form] = useForm<FeedbacksType>();
    const controls = useContext();

    const handleSubmit = () => {
        form.reset.errors();

        form.ifValid((data) => {
            Api.feedbacks()
                .create(data)
                .onSuccess(() => controls.close());
        }, validateOptions(form.state.agent_phone?.value));
    };

    return (
        <div className="feedback">
            <Icon name="close" size={20} onClick={() => controls.close()} />
            <h1>Feedback</h1>
            <Form className="feedback__form" onSubmit={handleSubmit}>
                <Input {...field('name').register()} text="Name" />
                <PhoneInput {...field('phone').register()} label="Phone" />
                <Textarea {...field('message').register()} text="Message" />
                <Upload.File {...field('file_id').register<number>()} value={undefined} errors={form.state.file_id?.errors} single />
                <Button color="brown" text="Send" type="submit" />
            </Form>
        </div>
    );
};
