import React, { FC } from 'react';

import { Button, Input } from '@.ui';
import { createPreventDefault } from '@/Lib';
import { useLingualField } from '@/Services';
import { ProductProposal } from '@/Services/api/methods/ProductsProposals';

import S from './styles.module.scss';
import { useApproveProduct } from './useApproveProduct';

interface ApproveProductProposalModalProps {
    productProposal: ProductProposal;
    close: () => void;
    updateProductProposal: () => void;
}

export const ApproveProductProposalModal: FC<ApproveProductProposalModalProps> = ({ productProposal, updateProductProposal, close }) => {
    const { approve, isShowUpdatedProductProposalMessage, field } = useApproveProduct({
        productProposal,
        updateProductProposal,
        close,
    });

    const viewActualStateProductProposal = createPreventDefault(() => {
        updateProductProposal();
        close();
    });

    const productName = useLingualField(productProposal);

    return (
        <div className={S.modal}>
            <h1>Approve product {productName}</h1>

            {isShowUpdatedProductProposalMessage && (
                <div>
                    <p className={S.modal_error}>While you were checking and set UDI, the product application was updated</p>

                    <div className={S.modal__actions}>
                        <Button color="brown" onClick={viewActualStateProductProposal}>
                            View actual product proposal
                        </Button>
                    </div>
                </div>
            )}

            {!isShowUpdatedProductProposalMessage && (
                <>
                    <p className={S.modal_description}>For approve need set UDI for new product</p>

                    <Input {...field('udi').register()} type="number" text="UDI" required />

                    <div className={S.modal__actions}>
                        <Button color="brown" onClick={createPreventDefault(approve)}>
                            Save and approve
                        </Button>
                        <Button onClick={close}>Cancel</Button>
                    </div>
                </>
            )}
        </div>
    );
};
