import { Text } from '@visx/text';
import React, { FC } from 'react';

type DefaultCircleDivisionsCenterContentProps = {
    total: number;
    text: string;
};

export const DefaultCircleDivisionsCenterContent: FC<DefaultCircleDivisionsCenterContentProps> = ({ total, text }) => {
    return (
        <>
            <Text fontWeight={700} fontSize={24} lineHeight={31.25} fill="#000" dy={-5} textAnchor="middle" verticalAnchor="middle">
                {total}
            </Text>
            <Text fontWeight={500} fontSize={14} lineHeight={18.23} fill="#000" dy={15} textAnchor="middle" verticalAnchor="middle">
                {text}
            </Text>
        </>
    );
};
