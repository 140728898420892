import { LinearGradient } from '@visx/gradient';
import { Group } from '@visx/group';
import { Pie } from '@visx/shape';
import { Text } from '@visx/text';
import { useMemo } from 'react';

import { WhiffsCircleProps } from '@/Components/Partials/Charts/WhiffsCircle/types';

enum Colors {
    Active = 'active',
    Inactive = 'inactive',
}

export const WhiffsCircle: React.FC<WhiffsCircleProps> = ({ total = 0, active = 0, inactive = 0, errors, failovers, warnings, noStats = false }) => {
    const data = useMemo(() => {
        if (active && inactive)
            return [
                { value: active, color: Colors.Active },
                { value: inactive, color: Colors.Inactive },
            ];

        return [{ value: total ?? 1, color: Colors.Active }];
    }, [active, inactive, active]);

    const width = 189,
        height = 189,
        margin = { top: 20, right: 20, bottom: 20, left: 20 };

    const innerWidth = width - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;
    const radius = width / 2 - 12;
    const centerY = innerHeight / 2;
    const centerX = innerWidth / 2;
    const top = centerY + margin.top;
    const left = centerX + margin.left;
    const pieSortValues = (a: number, b: number) => a - b;

    const defs = (
        <>
            <LinearGradient id={Colors.Active} from="#A3CA74" to="#D3DC6C" />
            <LinearGradient id={Colors.Inactive} from="#F5CC61" to="#F5CC61" />
        </>
    );

    return (
        <div className="whiffs_circle">
            <div className="whiffs_circle__graph">
                <svg style={{ top, left, overflow: 'visible', position: 'relative', width: 189, height: 189 }}>
                    {defs}
                    <Group>
                        <Pie
                            data={data}
                            pieSortValues={pieSortValues}
                            outerRadius={radius}
                            innerRadius={radius + 12}
                            pieValue={(d) => d.value}
                            padAngle={0.05}
                            cornerRadius={30}
                            startAngle={Math.PI}
                            endAngle={-Math.PI}
                        >
                            {(pie) =>
                                pie.arcs.map((arc) => {
                                    const { color } = arc.data;
                                    const arcPath = pie.path(arc);

                                    return (
                                        <g key={`arc-${color}`}>
                                            <path d={arcPath ?? undefined} fill={`url('#${color}')`} />
                                        </g>
                                    );
                                })
                            }
                        </Pie>
                        <Text fontWeight={700} fontSize={24} lineHeight={31.25} fill="#000" dy={-5} textAnchor="middle" verticalAnchor="middle">
                            {total}
                        </Text>
                        <Text fontWeight={500} fontSize={14} lineHeight={18.23} fill="#000" dy={15} textAnchor="middle" verticalAnchor="middle">
                            whiffs
                        </Text>
                    </Group>
                </svg>
            </div>
        </div>
    );
};
