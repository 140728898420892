import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { Api } from '@.api';
import { Layout } from '@.layout';
import { Table2 } from '@.partials';
import { Auth, LinkHelper } from '@.services';
import { Button, Checkbox, Filter3 } from '@.ui';
import { BrandsResponseType } from '@/Services/api/methods';
import { PermissionProps, Request } from '@/Types';

export * from './Action';
export * from './Brand';

export const Brands: React.FC<PermissionProps> = (props) => {
    const auth = Auth.Use();
    const navigate = useNavigate();

    const get = useCallback((q?: Request.All) => Api.brands().getAll(q), []);
    const handleRestore = useCallback((id: number) => Api.brands().restore(id), []);
    const handleDelete = useCallback(
        (id: number) =>
            Api.brands()
                .delete(id)
                .onError((res) => alert(res.message)),
        []
    );

    const getData = useCallback((data: BrandsResponseType) => data.brands, []);
    const items = useCallback((item: BrandsResponseType['brands'][number]) => ({ className: item.deleted_at ? 'deleted' : '' }), []);

    return (
        <Layout name="data" breadcrumbs={[{ name: 'Brands', current: true }]}>
            <h1>
                Brands
                <Button color="brown" text="Add brand" onClick={() => navigate(LinkHelper.dataBrandsAdd)} disabled={!props.permissions?.create} />
            </h1>
            <Table2 get={get} getData={getData} export="brands" item={items}>
                {{
                    filters: (handler) => (
                        <Filter3 onChange={handler}>
                            <Filter3.Search />
                            <Filter3.Boolean placeholder="Has image" name="has_image" />
                            {auth?.user?.is_super && <Filter3.Boolean placeholder="Archived" name="is_archived" />}
                        </Filter3>
                    ),
                    items: [
                        (item) => ({
                            link: LinkHelper.dataBrandId(item.id),
                            name: 'Name',
                            content: item.name,
                            sort: 'name',
                        }),
                        (item) => ({
                            content: <Checkbox value={item.has_image} isDisabled={true} />,
                            name: 'Has image',
                            sort: 'has_image',
                        }),
                    ],
                    actions: [
                        (item) => ({
                            type: 'edit',
                            link: LinkHelper.dataBrandsEditId(item.id),
                            disabled: !!item.deleted_at || !props.permissions?.update,
                        }),
                        (item) => ({
                            type: item.deleted_at ? 'restore' : 'delete',
                            onClick: item.deleted_at ? () => handleRestore(item.id) : () => handleDelete(item.id),
                            disabled: !props.permissions?.delete,
                        }),
                        (item) => ({
                            type: 'open',
                            link: LinkHelper.dataBrandId(item.id),
                        }),
                    ],
                }}
            </Table2>
        </Layout>
    );
};
