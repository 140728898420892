import { useEffect, useState } from 'react';

import { Api } from '@.api';
import { SpecialOfferStatisticsResponse } from '@.api/methods';
import { Curves } from '@.partials';

export const SpecialOfferStatistics: React.FC<{ id: number }> = (props) => {
    const [statistics, setStatistics] = useState<SpecialOfferStatisticsResponse>();

    useEffect(() => {
        Api.special_offers()
            .getStatistics(props.id)
            .onSuccess((res) => setStatistics(res.data));
    }, []);

    return statistics?.statistics ? (
        <Curves
            data={statistics.statistics.map(({ date, views_count }) => {
                return { date: date, value: views_count };
            })}
            color={'#BCCC80'}
        />
    ) : (
        <></>
    );
};
