/**
 *
 * seconds to milliseconds
 *
 * @param timestamp seconds
 * @returns
 */
export const toJs = (timestamp: number) => timestamp * 1000;
/**
 *
 * milliseconds to seconds
 *
 * @param timestamp milliseconds
 * @returns
 */
export const fromJS = (timestamp: number) => timestamp / 1000;
