import React, { FC, useMemo } from 'react';

import { CircleDivisionData, CircleDivisions } from '@.partials';
import { DEFAULT_ANALYTICS_CHART_COLORS } from '@/Components/Partials/Analytics/constants';
import { ChartDatasetValues } from '@/Types/analytics/chartsTypes';

import S from './styles.module.scss';

type StrengthChartProps = {
    data: ChartDatasetValues;
};

export const StrengthChart: FC<StrengthChartProps> = ({ data }) => {
    const circleDivisionsData = useMemo<CircleDivisionData[]>(
        () =>
            data.map((dataItem, index) => ({
                value: dataItem.value,
                color: DEFAULT_ANALYTICS_CHART_COLORS[index],
                label: dataItem.label,
            })),
        [data]
    );

    return (
        <div>
            <h3>Strength</h3>
            <CircleDivisions data={circleDivisionsData} className={S.strength_chart} />
        </div>
    );
};
