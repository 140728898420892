import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Api } from '@.api';
import { UserRequestType } from '@.api/methods';
import { useDictionaries, useForm, useObjectEffect } from '@.hooks';
import { Group, UsersData } from '@/Components/Pages/Settings/Users/Action/types';
import { validateOptions } from '@/Components/Pages/Settings/Users/Action/validate';
import { LinkHelper } from '@/Services';
import { StatisticObjectEnum } from '@/Services/StatisticPermissionService';
import { DictionaryList } from '@/Types';
import { DictionaryResponseKnown } from '@/Types/api/Dictionaries';

export const useUserFormAction = (isEdit: boolean) => {
    const [dictionaries, loadDictionaries] = useDictionaries();

    const [groups, setGroups] = useState<Group[]>();

    const [field, form] = useForm<UsersData>();

    const navigate = useNavigate();

    const { id } = useParams();

    const loadFormData = useCallback((id: number, dictionaries: Partial<DictionaryResponseKnown>) => {
        Api.users()
            .getById(id)
            .onSuccess((res) => {
                const { name, role, status, company, store_groups, email, username, statistic_object } = res.data;

                const statisticObjectType = statistic_object?.type;
                const statisticObjectTypeId = statistic_object?.id;

                const formUserData: Partial<UsersData> = {
                    name,
                    email,
                    username,
                    company_id: company?.id,
                    role_id: role?.id,
                    store_groups_ids: store_groups.map((group) => group.id),
                    status: status?.id,
                    statistic_object_type: statisticObjectType,
                    statistic_object_type_id: statisticObjectTypeId,
                };

                if (statisticObjectType === StatisticObjectEnum.STORE_GROUP) {
                    formUserData.statistic_object_store_group_id = statisticObjectTypeId;
                } else if (statisticObjectType === StatisticObjectEnum.STORE) {
                    const selectedStore = dictionaries?.stores?.find((store) => store.id === statisticObjectTypeId);

                    formUserData.statistic_object_store_group_id = selectedStore?.store_group_id;
                    formUserData.statistic_object_store_id = statisticObjectTypeId;
                }

                form.set.values(formUserData);
            });
    }, []);

    const fetchFormAction = () => {
        form.reset.errors();

        form.ifValid((data) => {
            const requestUserData: UserRequestType = {
                ...data,
                store_groups_ids: data.is_company_admin ? [] : data.store_groups_ids ?? [],
            };

            const statisticObjectType = data.statistic_object_type;
            let statisticObjectValue: number | undefined;

            if (statisticObjectType === StatisticObjectEnum.COMPANY) {
                statisticObjectValue = data.company_id;
            } else if (statisticObjectType === StatisticObjectEnum.STORE_GROUP) {
                statisticObjectValue = data.statistic_object_store_group_id;
            } else if (statisticObjectType === StatisticObjectEnum.STORE) {
                statisticObjectValue = data.statistic_object_store_id;
            }

            if (statisticObjectValue) {
                requestUserData.statistic_object = {
                    type: statisticObjectType as StatisticObjectEnum,
                    id: statisticObjectValue,
                };
            }

            if (isEdit) {
                Api.users()
                    .updateById(requestUserData, Number(id))
                    .onSuccess(() => navigate(LinkHelper.settingsUsers))
                    .onValidationError((res) => form.set.errors(res.errors));
            } else {
                Api.users()
                    .create(requestUserData)
                    .onSuccess(() => {
                        navigate(LinkHelper.settingsUsers);
                    })
                    .onValidationError((res) => form.set.errors(res.errors));
            }
        }, validateOptions(isEdit));
    };

    const breadcrumbs = isEdit
        ? [
              { name: 'Users', link: LinkHelper.settingsUsers },
              { name: form.state.name?.value ?? '', link: LinkHelper.settingsUserId(Number(id)) },
              { name: 'Edit', current: true },
          ]
        : [
              { name: 'Users', link: LinkHelper.settingsUsers },
              { name: 'Add', current: true },
          ];

    useEffect(() => {
        const dictionaries: DictionaryList = ['Companies', 'Roles', 'UserStatuses', 'StoreGroups', 'Stores'];

        if (isEdit) {
            dictionaries.push('StoreGroups');
        }

        loadDictionaries(dictionaries);
    }, [isEdit, loadDictionaries]);

    useObjectEffect(() => {
        if (form.state.company_id?.value) {
            Api.store_groups()
                .getAll({ company_id: form.state.company_id.value })
                .onSuccess((res) => {
                    const { store_groups } = res.data;

                    const ids = store_groups.map((group) => group.id);

                    setGroups(store_groups);

                    const storeGroupIds = form.state.store_groups_ids?.value;

                    if (storeGroupIds && !storeGroupIds.every((id) => ids.includes(id))) {
                        field('store_groups_ids').value([]);
                    }
                });
        }
    }, [form.state.company_id]);

    useEffect(() => {
        if (id && dictionaries && isEdit) {
            loadFormData(Number(id), dictionaries);
        }
    }, [dictionaries, loadFormData, id, isEdit]);

    return {
        breadcrumbs,
        form,
        dictionaries,
        groups,
        field,
        fetchFormAction,
    };
};
