import React, { FC, useState } from 'react';

import { Api } from '@.api';
import { Button } from '@.ui';
import { createPreventDefault } from '@/Lib';
import { ProductProposal } from '@/Services/api/methods/ProductsProposals';
import { ProductProposalService } from '@/Services/ProductProposal';

import S from './styles.module.scss';

interface PendProductProposalModalProps {
    productProposal: ProductProposal;
    updateProductProposal: () => void;
    close: () => void;
}

export const PendProductProposalModal: FC<PendProductProposalModalProps> = ({ productProposal, updateProductProposal, close }) => {
    const [isProductWillChanged, setIsProductWillChanged] = useState<boolean>(false);

    const pend = createPreventDefault(async () => {
        const productProposalService = new ProductProposalService(Api.productsProposals());

        try {
            await productProposalService.pend(productProposal);

            updateProductProposal();
            close();
        } catch (error) {
            setIsProductWillChanged(true);
        }
    });

    const viewActualProductProposal = createPreventDefault(() => {
        updateProductProposal();
        close();
    });

    return (
        <div className={S.modal}>
            <h1>Pend product proposal?</h1>

            {isProductWillChanged && (
                <div>
                    <p className={S.modal_error}>The product has been changed, see the latest data?</p>

                    <div className={S.modal__actions}>
                        <Button color={'brown'} onClick={viewActualProductProposal}>
                            View actual product proposal
                        </Button>
                    </div>
                </div>
            )}

            {!isProductWillChanged && (
                <>
                    <p>Are you sure you want to submit your request for review?</p>

                    <div className={S.modal__actions}>
                        <Button color={'brown'} onClick={pend}>
                            Pend
                        </Button>
                        <Button onClick={close}>Cancel</Button>
                    </div>
                </>
            )}
        </div>
    );
};
