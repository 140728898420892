import React, { FC } from 'react';

import { Bars } from '@.partials';
import { Filter3, FilterData } from '@.ui';
import { HeartFill } from '@/Components/Icons';
import { AnalyticChartCardInfo } from '@/Components/Partials/Analytics/AnalyticChartCardInfo';
import { AnalyticChartFilterInfo } from '@/Components/Partials/Analytics/AnalyticChartFilterInfo';
import { BAR_FACTOR, TOP_STEP_VALUE } from '@/Components/Partials/Analytics/Charts/FavoriteHeartChart/constants';
import { isNumber, loop } from '@/Lib';
import { analyticsStoreSelectors } from '@/Store';
import { Dictionary } from '@/Types';
import { ChartDatasetValues, StatisticBestProduct } from '@/Types/analytics';
import { DictionaryItem } from '@/Types/api/Dictionaries';

type FavoriteHeartChartProps = {
    data: ChartDatasetValues;
    bestProduct?: StatisticBestProduct;
    totalProducts: number;
    totalHearts: number;
    limit: number;
};

export const FavoriteHeartChart: FC<FavoriteHeartChartProps> = ({ data, bestProduct, totalHearts, totalProducts, limit }) => {
    const filterFavoriteHeartByLimit = analyticsStoreSelectors.use.filterFavoriteHeartByLimit();

    const onChangeFavoriteHeartTopFilter = (item: FilterData) => {
        const limit = Number(item.limit);

        if (isNumber(limit) && !isNaN(limit)) {
            filterFavoriteHeartByLimit(limit);
        }
    };

    const filterFormData = loop(1, Math.trunc(totalProducts / TOP_STEP_VALUE), (value: number): DictionaryItem => {
        return {
            name: `Top ${value * TOP_STEP_VALUE}`,
            id: value * TOP_STEP_VALUE,
        };
    });

    const filters = [
        <Filter3
            key={totalProducts}
            fromData={{
                [Dictionary.FavoriteHeart]: filterFormData,
            }}
            isSaveInQuery={false}
            onChange={onChangeFavoriteHeartTopFilter}
        >
            <Filter3.FavoriteHeart noReset visibleLabel={''} visibleName={`Top ${limit}`} />
        </Filter3>,
    ];

    const heartIcon = <HeartFill />;

    const createCardsInfo = () => {
        const totalHeartsData = {
            key: totalHearts,
            title: totalHearts.toString(),
            description: 'total hearts per period',
        };

        if (bestProduct) {
            const bestProductDescription = bestProduct ? (
                <>
                    <p>{bestProduct.name}</p>
                    <p>best product</p>
                </>
            ) : null;

            const bestProductData = {
                key: bestProduct.id,
                title: bestProduct.value.toString(),
                description: bestProductDescription,
            };

            return [bestProductData, totalHeartsData];
        }

        return [totalHeartsData];
    };

    const cardsInfoData = createCardsInfo();

    const cardsInfo = cardsInfoData.map((card, index) => (
        <AnalyticChartCardInfo key={card.key + index} title={card.title} description={card.description} icon={heartIcon} />
    ));

    const height = data.length * TOP_STEP_VALUE * BAR_FACTOR;

    return (
        <div>
            <h3>Favorite Heart counts per item (per brand)</h3>

            <AnalyticChartFilterInfo cardsInfo={cardsInfo} filters={filters} />

            <Bars data={data} isHorizontal height={height} axisDataName="Product name" />
        </div>
    );
};
