import { useMemo } from 'react';

import { useLanguage } from '@/Services';
import { LingualName } from '@/Types/api/language';

export const useLingualField = <
    T extends object & {
        name: object | undefined | null;
    }
>(
    data: T | undefined,
    field: keyof T = 'name'
) => {
    const [current] = useLanguage();

    const value = useMemo(() => {
        if (!data) return;

        const obj = data[field] as { [name: string]: string | null } | undefined;

        if (!obj) return;

        if (!current || !obj[current.code]) return obj['en'];

        return obj[current.code];
    }, [data, field, current]);

    return value ?? '';
};

export const useLingualData = (data?: LingualName) => {
    const [current] = useLanguage();

    const value = useMemo(() => {
        if (!data) return;

        if (!current || !data[current.code]) return data['en'];

        return data[current.code];
    }, [data, current]);

    return value ?? '';
};
