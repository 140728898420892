import { Link } from 'react-router-dom';

import { Breadcrumb, Props } from './types';

export type { Breadcrumb };

export const Breadcrumbs: React.FC<Props> = (props) => {
    return (
        <div className="breadcrumbs">
            {props.path.map((crumb, i) => {
                const className = 'breadcrumbs-item text-small' + (crumb.current ? ' current' : '');
                const key = `brc-itm-${i}`;

                return crumb.link ? (
                    <Link to={crumb.link} className={className + ' link'} key={key}>
                        {crumb.name}
                    </Link>
                ) : (
                    <span className={className} key={key}>
                        {crumb.name}
                    </span>
                );
            })}
        </div>
    );
};
