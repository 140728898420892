import { Response } from '@.api/methods';

import { Base } from '../base';
import { LanguageResponse } from './types';

export class Languages extends Base {
    public getAll() {
        this.setPath('languages');

        return this.getRequest() as Response<LanguageResponse>;
    }
}
