export type UserRolePermission = {
    id: number;
    name: string;
    key: UserRoleEnum;
    deleted_at: number | null;
};

export enum UserRoleEnum {
    SUPER_ADMIN = 'super_admin',
    PRODUCT_MANAGER = 'product_manager',
    COMPANY_ADMIN = 'company_admin',
    COMPANY_USER = 'company_user',
    SERVICE_ENGINEER = 'service_engineer',
    DEFAULT_USER = 'default_user',
}
