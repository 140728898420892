import { HTMLProps, ReactElement, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import React from 'react';
import { Link, useSearchParams } from 'react-router-dom';

import { useObjectMemo, usePagination } from '@/Components/Hooks';
import { FilterData, Icon, Pagination } from '@/Components/UI';
import { getQueryFilters } from '@/Components/UI/Filters.v3/modules';
import { functions } from '@/Services/api';

import { ExportBlock } from '../ExportBlock';
import { TableIcon } from './icons';
import { DataExample, TableActionOptions, TableChildAction, TableChildItem, TableColumnsOptions, TableItemOptions, TableProps, TableRowOptions } from './types';
import { useSort } from './useSort';

interface TableRow {
    options?: TableRowOptions;
    items: (TableItemOptions | string)[];
}

const memo: <T>(component: T) => T = React.memo;

const Component = <T extends DataExample, Items extends T[keyof Omit<T, 'pagination'>] & object[]>({
    children,
    getData,
    item,
    export: model,
    data: propData,
    ...props
}: TableProps<T, Items>): ReactElement<TableProps<T, Items>> => {
    type Data = T[Exclude<keyof T, 'pagination'>] & object[];

    const [query] = useSearchParams();

    const [filters, setFilters] = useState<FilterData>(getQueryFilters(query));
    const [data, setData] = useState<Data>();

    const [sort, toggleSort, currentColumn] = useSort();
    const { changePage, currentPage, lastPage, nextPage, perPage, prevPage, process, total } = usePagination();

    const processData = useCallback(
        (data: T | undefined) => {
            if (!data) {
                return;
            }

            const getDataObject = () => {
                if (getData) {
                    return getData(data);
                }

                return data;
            };

            const res = getDataObject();

            if (!Array.isArray(res)) {
                return;
            }

            return res as Data;
        },
        [getData]
    );

    const update = useCallback(() => {
        if (!props.get) {
            return;
        }

        props
            .get({
                page: currentPage,
                per_page: perPage,
                ...filters,
                ...Object.fromEntries(Object.entries(props.filters ?? {}).filter(([, value]) => value !== undefined)),
                ...sort,
            })
            ?.onSuccess((res) => {
                if ('pagination' in res.data) {
                    process(res.data.pagination);
                }

                setData(() => {
                    return processData(res.data);
                });
            });
    }, [props.get, currentPage, perPage, filters, props.filters, sort, processData, process]);

    const handleActionClick = useCallback(
        (fn?: (() => void) | (() => functions<unknown> | Promise<unknown>) | undefined) => {
            if (!fn) return;

            const res = fn();

            if (typeof res !== 'object') return;

            res.then(() => {
                update();
            });
        },
        [update]
    );

    const handleColumnClick = (column: TableColumnsOptions) => column.sort && toggleSort(column.sort);

    const handleFilters = useCallback((data: FilterData) => setFilters(data), []);

    useEffect(() => {
        update();
    }, [currentPage, perPage, sort.sort, sort.order_by, JSON.stringify(filters), JSON.stringify(props.filters)]);

    useEffect(() => {
        setData(processData(propData));
    }, [propData, processData]);

    const getRows = useCallback(
        (rows: TableChildItem<T>[]): TableRow[] => {
            if (!data) {
                return [];
            }

            return data.map((dataItem) => ({
                options: item && item(dataItem as T),
                items: rows.map((item) => item(dataItem as T)),
            }));
        },
        [data, item]
    );

    const getColumns = useCallback((rows: TableRow[] | undefined): TableColumnsOptions[] => {
        if (!rows || !rows.length) {
            return [];
        }

        return rows[0].items.map((item) => {
            if (typeof item === 'string') return { name: '' };

            return { name: item.name, sort: item.sort };
        });
    }, []);

    const getActions = useCallback(
        (rows: TableChildAction<T>[]): TableActionOptions[][] => {
            if (!data) {
                return [];
            }

            return data.map((dataItem) => rows.map((item) => item(dataItem as T)).filter((item) => typeof item === 'object') as TableActionOptions[]);
        },
        [data]
    );

    const [filter, rows, actions, columns] = useMemo(() => {
        let _filter: ReactNode = undefined;
        let _rows: TableRow[] = [];
        let _actions: TableActionOptions[][] = [];
        let _columns: TableColumnsOptions[] = [];

        if (!children) return [undefined, [], [], []];

        if (Array.isArray(children)) {
            _rows = getRows(children);
        } else {
            if (children.filters) _filter = children.filters(handleFilters);
            if (children.items) _rows = getRows(children.items);
            if (children.actions) _actions = getActions(children.actions);
        }

        _columns = getColumns(_rows);

        return [_filter, _rows, _actions, _columns];
    }, [children, getActions, getColumns, getRows, handleFilters]);

    const head = useObjectMemo(
        () => (
            <tr>
                {columns.map((column) => {
                    const isSorted = currentColumn && currentColumn.name === column.sort;

                    return (
                        <td
                            className={'header_cell' + (column.sort ? ' sortable' : '') + (isSorted ? ` active ${currentColumn.sort}` : '')}
                            key={`table-column-${column.name}`}
                            onClick={() => handleColumnClick(column)}
                        >
                            <div>
                                {column.name}
                                {isSorted && <Icon name="chevron-down" size={10} />}
                            </div>
                        </td>
                    );
                })}
            </tr>
        ),
        [columns, currentColumn]
    );

    const body = useObjectMemo(
        () =>
            rows.map((row, index) => {
                const rowKey = index;

                const className = [row.options?.className ? ` ${row.options?.className}` : '', row.options?.highlight ? 'blocked' : '']
                    .filter((s) => s !== undefined)
                    .join(' ');

                return (
                    <tr className={className} key={`table-row-${rowKey}`}>
                        {row.items.map((item, index) => {
                            let content: ReactNode;
                            let tooltip: ReactNode;
                            const props: Omit<HTMLProps<Element>, 'onClick'> & {
                                ['contentv']?: ReactNode;
                                onClick?: () => void | functions;
                            } = {};

                            if (typeof item === 'string') {
                                content = item;
                            } else {
                                if (item.max && item.content && item.content.toString().length > item.max) {
                                    props.className = 'tooltip';
                                    tooltip = item.content;
                                    content = item.content.toString().slice(0, item.max).trim() + '...';
                                } else {
                                    content = item.content;
                                }

                                if (item.link && item.disabled !== true) {
                                    content = <Link to={item.link}>{content}</Link>;
                                }

                                if (item.onClick && item.disabled !== true) {
                                    props.onClick = item.onClick;
                                }
                            }

                            return (
                                <td
                                    className={props.className}
                                    // data-value={props.contentv}
                                    style={props.style}
                                    key={`table-item-r-${rowKey}-${index}`}
                                >
                                    <div onClick={() => handleActionClick(props.onClick)}>{content}</div>
                                    {tooltip && <div className="tooltip__content">{tooltip}</div>}
                                </td>
                            );
                        })}
                        {Boolean(actions.length) && (
                            <td>
                                <div className={actions[index].length === 1 ? 'table__actions-single' : 'table__actions'}>
                                    {actions[index]?.map(
                                        ({ type, onClick, hide, ...action }) =>
                                            !hide && (
                                                <div className="table__actions-item" key={`table-action-${action.link}-${onClick}`}>
                                                    <TableIcon onClick={() => handleActionClick(onClick)} {...action} name={type} />
                                                </div>
                                            )
                                    )}
                                </div>
                            </td>
                        )}
                    </tr>
                );
            }),
        [rows]
    );

    const content = useMemo(
        () => (
            <>
                {filter}
                <div className="table">
                    {Boolean(total) && <p className="table__counter">Total count - {total}</p>}
                    <table>
                        <thead>{head}</thead>
                        <tbody>{body}</tbody>
                    </table>
                </div>
                <div className="page-table__footer">
                    <Pagination current={currentPage} changePage={changePage} last={lastPage} nextPage={nextPage} prevPage={prevPage} />
                    {model && <ExportBlock model={model} filters={{ ...filters, ...props.filters }} />}
                </div>
            </>
        ),
        [filter, total, head, body, currentPage, changePage, lastPage, nextPage, prevPage, model, filters, props.filters]
    );

    return useMemo(() => (data ? content : <></>), [data, content]);
};

export const Table = memo(Component);
