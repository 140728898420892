import { requiredRule, ValidateOptions } from '@.hooks';

export type RejectProposalModalForm = {
    reason: string;
};

export const validateOptions: ValidateOptions<RejectProposalModalForm> = {
    reason: {
        type: 'string',
        required: requiredRule,
    },
};
