import { Base, DictionaryFilters } from '@.api/methods';
import { Api, Dictionary } from '@.types';
export class Dictionaries extends Base {
    public get(query?: { by_name?: string | string[] }) {
        this.setPath('dictionaries');
        if (query) this.setQuery(query);

        return this.getRequest<Api.Dictionaries.DictionaryResponseKnown>();
    }

    public byNames(names: (keyof typeof Dictionary)[], filters?: DictionaryFilters) {
        this.setPath('dictionaries');
        this.setQuery({ by_name: names.map((name) => Dictionary[name]).join(',') });

        if (filters) {
            this.setData({
                filters,
            });
            return this.postRequest<Api.Dictionaries.DictionaryResponseKnown>();
        }

        return this.getRequest<Api.Dictionaries.DictionaryResponseKnown>();
    }
}
