import { Base, Response } from '@.api/methods';
import { AuthLogResponse, AuthLogsResponse } from '@/Services/api/methods/authLogs/types';

export class AuthLogs extends Base {
    public getAll(query?: { page?: number; per_page?: number; search?: string; start_date?: number; end_date?: number; is_success?: boolean }) {
        this.setPath('auth-logs');
        if (query) this.setQuery(query);

        return this.getRequest<AuthLogsResponse>();
    }

    public getById(id: number) {
        this.setPath('auth-logs');
        this.setId(id);

        return this.getRequest<AuthLogResponse>();
    }
}
