import { useEffect, useState } from 'react';
import { useRef } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import { useEventListener, useObjectEffect, useRefEffect } from '@.hooks';
import { Icon } from '@.ui';

interface ItemProps {
    url: string;
    name?: string;
    icon?: string;
    key: string;
}

interface NestedItemProps extends ItemProps {
    items?: ItemProps[];
    key: string;
}

const useLocationActive = (fn: (pathname: string) => boolean) => {
    const location = useLocation();
    const [isActive, setActive] = useState(fn(location.pathname));

    useObjectEffect(() => {
        if (fn(location.pathname)) setActive(true);
        else setActive(false);
    }, [location]);

    return isActive;
};

const fireEvent = (url: string) => window.dispatchEvent(new CustomEvent('sidebar-clicked', { detail: { url } }));

export const Item: React.FC<ItemProps> = ({ url, icon, name }) => (
    <NavLink to={url}>
        <p>
            {icon && <Icon name={icon} size={19} />}
            {name}
        </p>
    </NavLink>
);

const SmallItem: React.FC<ItemProps> = ({ url, icon, name }) => (
    <NavLink to={url}>
        <Icon name="arrow-right" size={19} />
        {name}
    </NavLink>
);

export const NestedItem: React.FC<NestedItemProps> = ({ name, items, icon, url }) => {
    const ref = useRef<HTMLDivElement>(null);
    const locationActive = useLocationActive((pathname) => pathname.startsWith(url));
    const [isActive, setActive] = useState(locationActive);

    useEventListener('sidebar-clicked', (event) => {
        if (event.detail.url !== url) setActive(false);
    });

    useEffect(() => setActive(locationActive), [locationActive]);

    useRefEffect(
        (nested) => {
            if (isActive) nested.classList.add('active');
            else nested.classList.remove('active');
        },
        [isActive],
        ref
    );

    const handleClick = () => {
        setActive((prev) => !prev);
        fireEvent(url);
    };

    return (
        <div className="sidebar__links-nested" onClick={handleClick} ref={ref}>
            <div>
                <p>
                    {icon && <Icon name={icon} size={19} />}
                    {name}
                </p>
                <Icon name="chevron-down" size={10} />
            </div>
            <nav>
                {items?.map((item) => (
                    <SmallItem url={item.url} name={item.name} key={`Sidebar_${item.key}`} />
                ))}
            </nav>
        </div>
    );
};
