import cn from 'classnames';
import React, { FC, ReactNode } from 'react';

import S from './styles.module.scss';

interface FormItemHeaderProps {
    children: ReactNode;
    className?: string;
    required?: boolean;
}

export const FormItemHeader: FC<FormItemHeaderProps> = ({ children, className, required }) => {
    return (
        <h2 className={cn(S.text, className)}>
            {children}
            {required && <span className={S.required}>*</span>}
        </h2>
    );
};
