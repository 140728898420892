import { StoreGroup, StoreGroups, StoreGroupsAction } from '@.pages';

import { RoutePage } from '../types';

export const StoreGroupsPage: RoutePage = {
    url: 'store-groups',
    component: <StoreGroups />,
    permissions: {
        entity: 'Store_groups',
        right: 'read',
    },
    sidebar: {
        name: 'Store groups',
    },
    children: [
        {
            url: 'add',
            component: <StoreGroupsAction />,
            permissions: {
                entity: 'Store_groups',
                right: 'create',
            },
        },
        {
            url: ':id',
            component: <StoreGroup />,
            children: [
                {
                    url: 'edit',
                    component: <StoreGroupsAction edit />,
                    permissions: {
                        entity: 'Store_groups',
                        right: 'update',
                    },
                },
            ],
        },
    ],
};
