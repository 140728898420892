import { Product, Products } from '@.pages';
import { ProductsAction } from '@/Components/Pages/Data/Products/Action';
import { ProductsImport } from '@/Components/Pages/Data/Products/Import';
import { ProductPrices } from '@/Components/Pages/Data/Products/Prices';

import { RoutePage } from '../types';

export const ProductsPage: RoutePage = {
    url: 'products',
    component: <Products />,
    permissions: {
        entity: 'Products',
        right: 'read',
    },
    sidebar: {
        name: 'Products',
    },
    children: [
        {
            url: 'add',
            component: <ProductsAction />,
            permissions: {
                entity: 'Products',
                right: 'create',
            },
        },
        {
            url: 'import',
            component: <ProductsImport />,
            permissions: {
                entity: 'ProductImports',
                right: 'read',
            },
        },
        {
            url: ':id',
            component: <Product />,
            children: [
                {
                    url: 'edit',
                    component: <ProductsAction edit />,
                    permissions: {
                        entity: 'Products',
                        right: 'read',
                    },
                },
                {
                    url: 'prices',
                    component: <ProductPrices />,
                    permissions: {
                        entity: 'ProductPrices',
                        right: 'read',
                        admin: false,
                    },
                },
            ],
        },
    ],
};
