import { StatisticObjectEnum } from '@/Services/StatisticPermissionService/StatisticObjectEnum';

export class StatisticPermissionService {
    private readonly permission: StatisticObjectEnum | null;
    private readonly isSuperAdmin: boolean = false;

    constructor(permission: StatisticObjectEnum | null, isSuperAdmin = false) {
        this.permission = permission;
        this.isSuperAdmin = isSuperAdmin;
    }

    /**
     * Check permission by cascade model.
     */
    public isAllowStatisticObjectPermission(targetObject?: StatisticObjectEnum): boolean {
        if (this.isSuperAdmin) {
            return true;
        }

        if (!targetObject) {
            return true;
        }

        if (!this.permission) {
            return false;
        }

        const validatePermissionObject = (objects: StatisticObjectEnum[]): boolean => objects.includes(this.permission as StatisticObjectEnum);

        switch (targetObject) {
            case StatisticObjectEnum.COMPANY:
                return validatePermissionObject([StatisticObjectEnum.COMPANY]);
            case StatisticObjectEnum.STORE_GROUP:
                return validatePermissionObject([StatisticObjectEnum.COMPANY, StatisticObjectEnum.STORE_GROUP]);
            case StatisticObjectEnum.STORE:
                return validatePermissionObject([StatisticObjectEnum.COMPANY, StatisticObjectEnum.STORE_GROUP, StatisticObjectEnum.STORE]);
            default:
                return false;
        }
    }
}
