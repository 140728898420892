import React, { FC, useMemo } from 'react';

import { AdminFields } from '@/Components/Partials/Product/CreateProductForm/components/AdminField';
import { FormItemHeader } from '@/Components/Partials/Product/CreateProductForm/components/FormItemHeader';
import { FormItemWrapper } from '@/Components/Partials/Product/CreateProductForm/components/FormItemWrapper';
import { CheckboxPicker } from '@/Components/UI';

import S from './styles.module.scss';

interface dictionariesItem {
    id: number;
    name: string;
}

interface CheckboxPickerFieldProps {
    headerText: string;
    isAllowed: boolean;
    dictionariesValues: dictionariesItem[];
    required?: boolean;
}

export const CheckboxPickerField: FC<CheckboxPickerFieldProps> = ({ dictionariesValues, headerText, isAllowed, required, ...props }) => {
    const checkboxValues = useMemo(() => {
        const transformedObj = dictionariesValues.map((item) => [item.name, item.id]);

        return Object.fromEntries(transformedObj);
    }, [dictionariesValues]);

    return (
        <FormItemWrapper>
            <FormItemHeader required={required}>{headerText}</FormItemHeader>
            <div className={S.checkboxPickerWrapper}>
                <AdminFields isAllowed={isAllowed}>
                    <CheckboxPicker checkboxValues={checkboxValues} {...props} />
                </AdminFields>
            </div>
        </FormItemWrapper>
    );
};
