import cn from 'classnames';
import React from 'react';

import { Api } from '@.api';
import { Tools } from '@.services';
import { CustomDictionary } from '@.types';
import { ExportBlockProps } from '@/Components/Partials/ExportBlock/types';

export const ExportBlock: React.FC<ExportBlockProps> = ({ className, model, filters }) => {
    const handleExport = () => {
        Api.export()
            .excel({
                model: model,
                filters: Object.fromEntries(
                    Object.entries(filters ?? {}).map(([key, value]) => {
                        const name = key as CustomDictionary;

                        if (Object.values(CustomDictionary).includes(name)) {
                            return [key, Boolean(value)];
                        }

                        return [key, value];
                    })
                ),
            })
            .onSuccess((res) => {
                Tools.file.download(res.data, `${model}.xlsx`);
            });
    };

    const exportBlockClassNames = cn('export-block', className);

    return (
        <div className={exportBlockClassNames}>
            <span onClick={handleExport}>Export XLS</span>
        </div>
    );
};
