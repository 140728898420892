import { useMemo, useState } from 'react';

import { Layout } from '@.layout';
import { Board } from '@.partials';
import { useDictionaries, useStickyShadowHead } from '@/Components/Hooks';
import { Filter3, FilterData } from '@/Components/UI';
import { DictionaryResponse } from '@/Types/api/Dictionaries';

import { IndexWizard, New, Popular } from './items';

export const Dashboard: React.FC = () => {
    const { refComponent } = useStickyShadowHead();

    const [filters, setFilters] = useState<FilterData>({});
    const [dictionaries] = useDictionaries(
        'Companies',
        'StoreGroups',
        'Stores',
        'Brands',
        'ProductTypes',
        'Ages',
        'Stations',
        'Sexes',
        'Strengths',
        'Occasions',
        'Ingredients',
        'Characters',
        'IsArchived',
        'Tags',
        'OrderStatuses',
        'ProductUdis'
    );

    const filteredDictionaries = useMemo((): DictionaryResponse | undefined => {
        const { company_id: companyId, store_group_id: storeGroupId, store_id: storeId } = filters;

        if (!dictionaries) {
            return dictionaries;
        }

        const storeGroups = (() => {
            let { store_groups: processingStoreGroups } = dictionaries;

            if (!processingStoreGroups) return;

            if (companyId) {
                processingStoreGroups = processingStoreGroups.filter((item) => item.company_id.toString() === companyId.toString());
            }

            if (storeId) {
                const foundStore = dictionaries.stores?.find(({ id }) => id.toString() === storeId.toString());
                if (!foundStore) return dictionaries.store_groups;

                processingStoreGroups = processingStoreGroups.filter((item) => foundStore.store_group_id === item.id);
            }

            return processingStoreGroups;
        })();

        const companies = (() => {
            if (!dictionaries.companies) return;

            if (storeGroupId) {
                const foundStoreGroup = dictionaries.store_groups?.find(({ id }) => id.toString() === storeGroupId.toString());
                if (!foundStoreGroup) return dictionaries.companies;

                return dictionaries.companies.filter((item) => item.id === foundStoreGroup.company_id);
            }

            if (storeId) {
                const foundStore = dictionaries.stores?.find(({ id }) => id.toString() === storeId.toString());
                if (!foundStore || !storeGroups) return dictionaries.companies;

                return dictionaries.companies.filter((item) => storeGroups.some(({ company_id }) => company_id === item.id));
            }

            return dictionaries.companies;
        })();

        const stores = (() => {
            if (!dictionaries.stores) return;

            if (storeGroupId) {
                const foundStoreGroup = dictionaries.store_groups?.find(({ id }) => id.toString() === storeGroupId.toString());
                if (!foundStoreGroup) return dictionaries.stores;

                return dictionaries.stores.filter((item) => item.store_group_id === foundStoreGroup.id);
            }

            if (companyId) {
                if (!storeGroups) return dictionaries.stores;

                return dictionaries.stores.filter((item) => storeGroups.some(({ id }) => item.store_group_id === id));
            }

            return dictionaries.stores;
        })();

        return {
            ...dictionaries,
            companies,
            store_groups: storeGroups,
            stores,
        };
    }, [dictionaries, filters.company_id, filters.store_group_id, filters.store_id]);

    const handleFilters = (data: FilterData) => setFilters(data);

    return (
        <Layout nobg name="dashboard" breadcrumbs={[{ name: 'Dashboard', current: true }]}>
            <Board>
                <Board.Item head ref={refComponent}>
                    <h1>Dashboard</h1>
                    <Filter3 fromData={filteredDictionaries ?? null} onChange={handleFilters}>
                        <Filter3.Companies />
                        <Filter3.StoreGroups />
                        <Filter3.Stores />
                        <Filter3.Stations />
                        <Filter3.Date />
                    </Filter3>
                </Board.Item>
                <IndexWizard filters={filters} />
                <Popular dictionaries={dictionaries} filters={filters} />
                <New dictionaries={dictionaries} filters={filters} />
            </Board>
        </Layout>
    );
};
