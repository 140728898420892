import { requiredRule, ValidateOptions } from '@.hooks';

import { FormType } from './types';

export const validateOptions: ValidateOptions<FormType> = {
    password: {
        type: 'string',
        required: requiredRule,
    },
    confirm_password: {
        type: 'string',
        sameAs: 'password',
    },
};
