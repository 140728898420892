import {
    Base,
    Response,
    StationFirmwareResponse,
    StationLogResponse,
    StationLogsResponse,
    StationProductsResponse,
    StationStatisticsResponse,
    StationType,
} from '@.api/methods';
import { Request } from '@/Types';

import { StationResponseType, StationsResponseType } from './types';

export class Stations extends Base {
    public getAll(query?: { page?: number; per_page?: number; status?: number; is_updated?: boolean; is_archived?: boolean }) {
        this.setPath('stations');
        if (query) this.setQuery(query);

        return this.getRequest() as Response<StationsResponseType>;
    }

    public getById(id: number) {
        this.setPath('stations');
        this.setId(id);

        return this.getRequest() as Response<StationResponseType>;
    }

    public update(data: StationType, id: number) {
        this.setPath('stations');
        this.setId(id);
        this.setData(data);

        return this.putRequest();
    }

    public destroy(id: number) {
        this.setPath(`stations/${id}/destroy`);

        return this.deleteRequest();
    }

    public delete(id: number) {
        this.setPath('stations');
        this.setId(id);

        return this.deleteRequest();
    }

    public restore(id: number) {
        this.setPath('stations');
        this.setId(`${id}/restore`);

        return this.postRequest();
    }

    public getFirmware(id: number) {
        this.setPath('stations');
        this.setId(`${id}/firmware`);

        return this.getRequest() as Response<StationFirmwareResponse>;
    }

    public getStatistics(id: number, query?: Request.All) {
        this.setPath('stations');
        this.setId(`${id}/statistics`);
        query && this.setQuery(query);

        return this.getRequest() as Response<StationStatisticsResponse>;
    }

    public getProducts(id: number, query?: Request.All) {
        this.setPath('stations');
        this.setId(`${id}/products`);
        query && this.setQuery(query);

        return this.getRequest<StationProductsResponse>();
    }

    public updateProducts(id: number, ids: number[]) {
        this.setPath('stations');
        this.setId(`${id}/products`);
        this.setData({
            products_ids: ids,
        });

        return this.postRequest();
    }

    public deleteProduct(id: number, product_id: number) {
        this.setPath(`stations/${id}/products/${product_id}`);

        return this.deleteRequest<StationProductsResponse>();
    }

    public getLogs(id: number, query?: Request.All) {
        this.setPath('stations');
        this.setId(`${id}/logs`);
        query && this.setQuery(query);

        return this.getRequest<StationLogsResponse>();
    }

    public getLogById(id: bigint, log_id: bigint) {
        this.setPath(`stations/${id}/logs/${log_id}`);

        return this.getRequest<StationLogResponse>();
    }
}
