import { StatisticObjectEnum } from '@/Services/StatisticPermissionService';

/**
 * A crutch due to the work of the selector.
 */
export const StatisticObjectNumberMap: Record<StatisticObjectEnum, number> = {
    [StatisticObjectEnum.COMPANY]: 1,
    [StatisticObjectEnum.STORE_GROUP]: 2,
    [StatisticObjectEnum.STORE]: 3,
};
