// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { SearchExtend } from './extend';
import { SearchLocalProps, SearchLocalState, SearchMultipleProps, SearchMultipleState } from './types';

type LocalProps<Multiple = false> = Multiple extends true ? SearchMultipleProps : SearchSingleProps;
type LocalState<Multiple = false> = Multiple extends true ? SearchMultipleState : SearchSingleState;

interface ISearchExtend {
    <Props extends SearchSingleProps = SearchSingleProps, State extends SearchSingleState = SearchSingleState>(
        multiple?: undefined | false
    ): typeof React.Component<Props, State>;
    <Props extends SearchMultipleProps = SearchMultipleProps, State extends SearchMultipleState = SearchMultipleState>(multiple: true): typeof React.Component<
        Props,
        State
    >;
}

export const SearchLocalExtend: ISearchExtend = (multiple = false) => {
    return class extends SearchExtend<LocalProps<typeof multiple>, LocalState<typeof multiple>>(multiple) {
        state: Readonly<SearchLocalState> = {
            ...this.state,
        };

        componentDidUpdate(prevProps: Readonly<SearchLocalProps>, prevState: Readonly<SearchLocalState>): void {
            super.componentDidUpdate(prevProps, prevState);

            if (prevState.search === '' && this.props.searchValue) {
                this.setState((prev) => ({ ...prev, search: this.props.searchValue }));
            }

            if (prevState.search !== this.state.search) {
                const search = this.state.search.toLocaleLowerCase();

                const visibleOptions = search
                    ? this.state.options?.filter((option) => option.content?.toString().toLocaleLowerCase().includes(search))
                    : this.state.options;

                this.setState((prev) => ({ ...prev, visibleOptions: visibleOptions ? [...visibleOptions] : undefined }));
            }
        }
    };
};
