import React, { FC, ReactNode } from 'react';

import S from './styles.module.scss';

type AnalyticChartCardInfoProps = {
    icon?: JSX.Element;
    title: string;
    description: ReactNode;
};

export const AnalyticChartCardInfo: FC<AnalyticChartCardInfoProps> = ({ description, title, icon }) => {
    return (
        <div className={S.analytic_card_info}>
            <div className={S.title}>
                {icon}
                {title}
            </div>
            <div>{description}</div>
        </div>
    );
};
