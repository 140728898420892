import { omit } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Api } from '@.api';
import { CreateCompaniesType, DictionariesResponseType } from '@.api/methods';
import { useForm } from '@.hooks';
import { Layout } from '@.layout';
import { LinkHelper } from '@.services';
import { Input, PhoneInput, Select } from '@.ui';
import { Buttons } from '@/Components/Partials';

import { CompaniesActionProps, CompaniesEntityScheme } from './types';
import { validateOptions } from './validate';

export const CompaniesAction: React.FC<CompaniesActionProps> = ({ edit, permissions }) => {
    const [statuses, setStatuses] = useState<DictionariesResponseType>();

    const [field, form] = useForm<CompaniesEntityScheme>({});

    const navigate = useNavigate();

    const { id } = useParams();

    const breadcrumbs = edit
        ? [
              { name: 'Companies', link: LinkHelper.clientsCompanies },
              { name: form.state.name?.value ?? '', link: LinkHelper.clientsCompanyId(Number(id)) },
              { name: 'Edit', current: true },
          ]
        : [
              { name: 'Companies', link: LinkHelper.clientsCompanies },
              { name: 'Add', current: true },
          ];

    const formTitle = edit ? <h1>Editing company</h1> : <h1>Adding company</h1>;

    const serializeDataToRequest = (data: CompaniesEntityScheme): CreateCompaniesType => {
        const { latitude, longitude } = data;

        const dataRequest: CreateCompaniesType = {
            ...omit(data, ['latitude', 'longitude']),
        };

        if (latitude && longitude) {
            dataRequest.gps_coords = {
                lat: latitude,
                lng: longitude,
            };
        }

        return dataRequest;
    };

    const createValidateOptions = () => {
        const { agent_phone, latitude, longitude } = form.state;

        return validateOptions(agent_phone?.value, Boolean(latitude?.value || longitude?.value));
    };

    const handleCreate = () => {
        form.reset.errors();

        form.ifValid((data) => {
            Api.companies()
                .create(serializeDataToRequest(data))
                .onSuccess(() => navigate(LinkHelper.clientsCompanies))
                .onValidationError((res) => form.set.errors(res.errors));
        }, createValidateOptions());
    };

    const handleUpdate = () => {
        form.reset.errors();

        form.ifValid((data) => {
            Api.companies()
                .update(serializeDataToRequest(data), Number(id))
                .onSuccess(() => navigate(LinkHelper.clientsCompanies))
                .onValidationError((res) => form.set.errors(res.errors));
        }, createValidateOptions());
    };

    useEffect(() => {
        if (id) {
            Api.companies()
                .getById(Number(id))
                .onSuccess((res) => {
                    const { id, name, status, agent_email, agent_name, agent_phone, gps_coords, ...other } = res.data;

                    form.set.values({
                        name,
                        agent_email,
                        agent_name,
                        agent_phone,
                        status: status.id,
                        latitude: gps_coords?.lat,
                        longitude: gps_coords?.lng,
                        ...other,
                    });
                });
        }

        if (edit) {
            Api.dictionaries()
                .get({ by_name: 'company_statuses' })
                .onSuccess((res) => setStatuses(res.data));
        }
    }, []);

    return (
        <Layout name="clients" breadcrumbs={breadcrumbs} small>
            {formTitle}
            <div className="companies-action__form">
                <Input {...field('name').register()} text="Name" required />

                <Input {...field('country').register()} text="Country" required />

                <Input {...field('street_address_1').register()} text="Street address 1" required />

                <Input {...field('street_address_2').register()} text="Street address 2" />

                <Input {...field('region').register()} text="State/Province/Region" required />

                <Input {...field('postal').register()} text="Zip/Postal Code" required />

                <Input {...field('city').register()} text="City" required />

                <div className="companies-action__form_gps">
                    <h3>GPS Coordinates (Lat, Long)</h3>

                    <div className="companies-action__form_gps_coords">
                        <Input {...field('latitude').register()} text="Latitude" type="number" />

                        <Input {...field('longitude').register()} text="Longitude" type="number" />
                    </div>
                </div>

                <Input {...field('agent_name').register()} text="Agent name" required />

                <PhoneInput {...field('agent_phone').register()} label="Phone" required />

                <Input {...field('agent_email').register()} text="Email" required />

                {edit && (
                    <Select {...field('status').register<number>()} placeholder="Status" theme="white" required>
                        {statuses?.company_statuses?.map((status) => (
                            <Select.Option id={status.id} key={`status_${status.id}`}>
                                {status.name}
                            </Select.Option>
                        ))}
                    </Select>
                )}

                <Buttons text={edit ? 'Save' : 'Add'} onClickSave={edit ? handleUpdate : handleCreate} />
            </div>
        </Layout>
    );
};
