import { useState } from 'react';

import { useObjectMemo } from '@/Components/Hooks';
import { Request } from '@/Types';

interface Current {
    name: string;
    sort: 'asc' | 'desc';
}

export const useSort = (): [Partial<Request.Sort>, (column: string) => void, Current | undefined] => {
    const [current, setCurrent] = useState<Current>();

    const setColumn = (column: string) => {
        setCurrent((prev) => {
            if (!prev || prev.name !== column) return { name: column, sort: 'asc' };
            if (prev.sort === 'asc') return { ...prev, sort: 'desc' };

            return undefined;
        });
    };

    const query = useObjectMemo(() => {
        if (!current) return {};

        return {
            order_by: current.name,
            sort: current.sort,
        };
    }, [current]);

    return [query, setColumn, current];
};
