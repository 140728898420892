import { requiredRule, ValidateOptions } from '@.hooks';
import { CurrenciesData } from '@/Components/Pages/Data/Currencies/Action/types';

export const validateOptions: ValidateOptions<CurrenciesData> = {
    id: {},
    name_en: {
        type: 'string',
        required: requiredRule,
    },
    sign: {
        type: 'string',
        required: requiredRule,
    },
    iso_code: {
        type: 'string',
        required: requiredRule,
    },
};
