import { LanguageIcon } from './types';

export const Uae: LanguageIcon = ({ id = 'clip0_970_19661' }) => (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath={`url(#${id})`}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.43372 21.0099V15.0145H21.2442C19.6403 19.1042 15.6584 22 11 22C9.42443 22.0021 7.86694 21.6643 6.43372 21.0099Z"
                fill="#282828"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.43371 0.990128V21.0099C2.63785 19.2755 0 15.446 0 11C0 6.55402 2.63785 2.72452 6.43371 0.990128Z"
                fill="#FF0000"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.3092 7.15627H6.43372V0.990128C7.86694 0.335665 9.42443 -0.00205296 11 -2.34958e-07C15.7229 -2.34958e-07 19.7501 2.97679 21.3092 7.15627Z"
                fill="#00732F"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.43372 7.15627H21.3091C22.2522 9.69497 22.2291 12.4918 21.2442 15.0145H6.43372V7.15627Z"
                fill="white"
            />
        </g>
        <defs>
            <clipPath id={id}>
                <rect width="22" height="22" fill="white" />
            </clipPath>
        </defs>
    </svg>
);
