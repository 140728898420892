import React, { FC, useCallback } from 'react';

import { Api } from '@.api';
import { Layout } from '@.layout';
import { Table2 } from '@.partials';
import { LinkHelper } from '@/Services';
import { StatisticObjectEnum } from '@/Services/StatisticPermissionService';
import { Request } from '@/Types';

const useAllCompaniesAnalyticsPage = () => {
    const fetchData = useCallback((query: Request.All) => Api.analytics().getStatisticCompanies(query), []);

    return {
        fetchData,
    };
};

export const AllCompaniesAnalyticsPage: FC = () => {
    const { fetchData } = useAllCompaniesAnalyticsPage();

    return (
        <Layout name="All companies" breadcrumbs={[{ name: 'Analytics' }, { name: 'All companies', current: true }]}>
            <h1>All companies</h1>

            <Table2 get={fetchData} getData={(data) => data.companies}>
                {{
                    items: [
                        (item) => ({
                            name: 'Company name',
                            content: item.name,
                        }),
                        (item) => ({ name: 'Address', content: item.street_address_1 }),
                        (item) => ({
                            name: 'Company name',
                            content: item.name,
                        }),
                        (item) => ({ name: 'Number of store groups', content: item.store_groups_count }),
                        (item) => ({
                            name: 'Number of stores',
                            content: item.stores_count,
                        }),
                        (item) => ({ name: 'Number of users', content: item.users_count }),
                        (item) => ({
                            name: 'Contact person',
                            content: `${item.agent_name},${item.agent_phone},${item.agent_email}`,
                            max: 30,
                        }),
                    ],
                    actions: [
                        (item) => ({
                            type: 'analytics',
                            link: LinkHelper.analyticsDetailsPage(item.id, StatisticObjectEnum.COMPANY),
                        }),
                    ],
                }}
            </Table2>
        </Layout>
    );
};
