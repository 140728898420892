import { Companies, CompaniesAction, Company } from '@.pages';

import { RoutePage } from '../types';

export const CompaniesPage: RoutePage = {
    url: 'companies',
    component: <Companies />,
    permissions: {
        entity: 'Companies',
        right: 'read',
    },
    sidebar: {
        name: 'Companies',
    },
    children: [
        {
            url: 'add',
            component: <CompaniesAction />,
            permissions: {
                entity: 'Companies',
                right: 'create',
            },
        },
        {
            url: ':id',
            component: <Company />,
            children: [
                {
                    url: 'edit',
                    component: <CompaniesAction edit />,
                    permissions: {
                        entity: 'Companies',
                        right: 'update',
                    },
                },
            ],
        },
    ],
};
