import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Api } from '@.api';
import { IrcUserResponseType } from '@.api/methods';
import { Layout } from '@.layout';
import { Tab, Tabs } from '@.partials';
import { LinkHelper } from '@.services';
import { Button } from '@.ui';
import { useId } from '@/Components/Hooks';
import { PermissionProps } from '@/Types';

import { ScenticonProductsTable } from '../ScenticonProductsTable';

export const ScenticonUser: React.FC<PermissionProps> = (props) => {
    const [scenticonUser, setScenticonUser] = useState<IrcUserResponseType>();
    const navigator = useNavigate();

    const updateScenticonUser = (id: number) => {
        Api.ircUsers()
            .getById(id)
            .onSuccess((res) => setScenticonUser(res.data));
    };

    const id = useId(updateScenticonUser, true);

    const handleDelete = () => {
        Api.ircUsers()
            .deleteById(Number(id))
            .onSuccess(() => updateScenticonUser(id))
            .onError((res) => alert(res.message));
    };

    const handleRestore = () => {
        Api.ircUsers()
            .restoreById(Number(id))
            .onSuccess(() => updateScenticonUser(id));
    };

    const scenticonUserName = scenticonUser?.name || '';

    return (
        <Layout
            name="settings"
            breadcrumbs={[
                { name: 'Scenticon Users', link: LinkHelper.settingsIrcUsers },
                { name: scenticonUserName, current: true },
            ]}
        >
            {scenticonUser && (
                <>
                    <h1>
                        {scenticonUserName}
                        <div>
                            <Button
                                color="transparent"
                                text={scenticonUser.deleted_at ? 'Restore' : 'Archive'}
                                onClick={scenticonUser.deleted_at ? handleRestore : handleDelete}
                                disabled={!props.permissions?.delete}
                            />
                            <Button
                                color="brown"
                                text="Edit"
                                onClick={() => navigator(LinkHelper.settingsIrcUsersEditId(Number(id)))}
                                disabled={!!scenticonUser.deleted_at || !props.permissions?.update}
                            />
                        </div>
                    </h1>
                    <Tabs>
                        <Tab name="General" path="general">
                            <div className="entity-page">
                                <div className="entity-page__container">
                                    <div>
                                        <div className="entity-page__item">
                                            <div className="bold">Name</div>
                                            <div>{scenticonUserName}</div>
                                        </div>
                                        <div className="entity-page__item">
                                            <div className="bold">Login</div>
                                            <div>{scenticonUser?.login}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Tab>
                        <Tab name="Products" path="products">
                            <ScenticonProductsTable irc_user_id={scenticonUser?.id} hideActions={true} />
                        </Tab>
                    </Tabs>
                </>
            )}
        </Layout>
    );
};
