import { FC, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Api } from '@.api';
import { useDictionaries, useForm, useId } from '@.hooks';
import { Layout } from '@.layout';
import { Checkbox, DatePicker, LingualInput, NumberInput, Select } from '@.ui';
import { validateOptions } from '@/Components/Pages/Data/SpecialOffers/Action/validate';
import { Buttons } from '@/Components/Partials';
import { LingualData } from '@/Components/UI/LingualInput/types';
import { filterStoreByStoreGroupId } from '@/Lib';
import { getLingualErrors, LinkHelper, useLingualField } from '@/Services';

import { SpecialOfferData } from './types';

type SpecialOffersActionProps = {
    edit?: boolean;
};

export const SpecialOffersAction: FC<SpecialOffersActionProps> = ({ edit }) => {
    const DISCOUNT_DICTIONARY_ID = 2;
    const DISCOUNT_ON_SECOND = 4;

    const [dictionaries] = useDictionaries('Companies', 'StoreGroups', 'Stores', 'SpecialOfferTypes');
    const [field, form] = useForm<SpecialOfferData>();
    const navigator = useNavigate();

    const [data, setData] = useState<{ name: LingualData }>();

    const isStateTypesValueIncludes = (id: number): boolean => form.state.types?.value?.includes(id) || false;

    const options = useMemo(
        () => validateOptions(isStateTypesValueIncludes(DISCOUNT_DICTIONARY_ID), isStateTypesValueIncludes(DISCOUNT_ON_SECOND)),
        [isStateTypesValueIncludes]
    );

    const offerName = useLingualField(data);

    const breadcrumbs = edit
        ? [
              { name: 'Special offers', link: LinkHelper.dataSpecialOffers },
              {
                  name: offerName,
                  link: form.state.id?.value ? LinkHelper.dataSpecialOfferId(form.state.id?.value) : undefined,
              },
              { name: 'Edit', current: true },
          ]
        : [
              { name: 'Special offers', link: LinkHelper.dataSpecialOffers },
              { name: 'Add', current: true },
          ];

    const handleCreate = () => {
        form.reset.errors();

        form.ifValid((data) => {
            const [start_date, end_date] = data.date;

            Api.special_offers()
                .create({ ...data, start_date: start_date / 1000, end_date: end_date / 1000 })
                .onSuccess(() => navigator(LinkHelper.dataSpecialOffers))
                .onValidationError((res) =>
                    form.set.errors({
                        ...res.errors,
                        name: getLingualErrors(res.errors, 'name'),
                        date: [...(res.errors.start_date ?? []), ...(res.errors.end_date ?? [])],
                    })
                );
        }, options);
    };

    const handleUpdate = () => {
        form.reset.errors();

        form.ifValid((data) => {
            const [start_date, end_date] = data.date;

            Api.special_offers()
                .update({ ...data, start_date: start_date / 1000, end_date: end_date / 1000 }, id)
                .onSuccess(() => navigator(LinkHelper.dataSpecialOffers))
                .onValidationError((res) =>
                    form.set.errors({
                        ...res.errors,
                        name: getLingualErrors(res.errors, 'name'),
                        date: [...(res.errors.start_date ?? []), ...(res.errors.end_date ?? [])],
                    })
                );
        }, options);
    };

    const get = (id: number) =>
        Api.special_offers()
            .getById(id)
            .onSuccess((res) => {
                const { id, name, types, store_group, is_enabled, start_date, end_date, discount, store, ...otherData } = res.data;

                setData({ name });

                form.set.values({
                    id,
                    name,
                    types: types.map((type) => type.id),
                    is_enabled,
                    store_group_id: store_group.id,
                    discount,
                    date: [start_date * 1000, end_date * 1000],
                    store_id: store.id,
                    ...otherData,
                });
            });

    const id = useId(get, edit);

    const getDictionaryByID = (id: number) => dictionaries?.special_offer_types?.find((item) => item.id === id);

    const stores = filterStoreByStoreGroupId(dictionaries?.stores, form.state.store_group_id?.value);

    return (
        <Layout name="data" breadcrumbs={breadcrumbs} small>
            {edit ? <h1>Editing special offer</h1> : <h1>Creating special offer</h1>}
            <div className="action-form">
                <LingualInput {...field('name').register()} text="Name" required onlyEn />

                <Select {...field('store_group_id').register<number>()} placeholder="Store group" required>
                    {dictionaries?.store_groups?.map((group) => (
                        <Select.Option key={`group_${group.id}`} id={group.id}>
                            {group.name}
                        </Select.Option>
                    ))}
                </Select>

                <Select {...field('store_id').register<number>()} placeholder="Store" required>
                    {stores?.map((store) => (
                        <Select.Option key={store.id} id={store.id}>
                            {store.name}
                        </Select.Option>
                    ))}
                </Select>

                <Select {...field('types').register<number[]>()} placeholder="Type" multiple localSearch required>
                    {dictionaries?.special_offer_types?.map((type) => (
                        <Select.Option key={`type_${type.id}`} id={type.id}>
                            {type.name}
                        </Select.Option>
                    ))}
                </Select>

                {form.state.types?.value?.includes(DISCOUNT_DICTIONARY_ID) && (
                    <NumberInput {...field('discount').register()} text={getDictionaryByID(DISCOUNT_DICTIONARY_ID)?.name} required />
                )}

                {form.state.types?.value?.includes(DISCOUNT_ON_SECOND) && (
                    <NumberInput {...field('discount_on_second').register()} text={getDictionaryByID(DISCOUNT_ON_SECOND)?.name} required />
                )}

                <DatePicker {...field('date').register()} full />

                <Checkbox {...field('is_enabled').register()} text="Enabled" />

                {!!form.state.is_enabled?.errors?.length && <span className="chechbox_error">{form.state.is_enabled.errors[0]}</span>}

                <Buttons text={edit ? 'Save' : 'Add'} onClickSave={edit ? handleUpdate : handleCreate} />
            </div>
        </Layout>
    );
};
