import { File } from './file';
import { Image } from './image';

export const Upload = {
    Image: Image,
    File: File,
};

// import { useEffect, useMemo, useRef, useState } from 'react';
// import React from 'react';
// import { v4 as uuidv4 } from 'uuid';

// import { Api } from '@.api';
// import { useDrag, useObjectEffect, useRefEffect } from '@.hooks';
// import { Icon } from '@.ui';

// import { FileItem, ItemProps, UploadProps } from './types';

// const Item: React.FC<ItemProps> = ({ progress, url, name, id, onRemove, uuid }) => {
//     const loaderRef = useRef<HTMLDivElement>(null),
//         currentLoaderRef = useRef<HTMLDivElement>(null);

//     const [percent, setPercent] = useState<number | undefined>(id ? undefined : 0),
//         [loaded, setLoaded] = useState(false);

//     const handleLoad = () => setLoaded(true);
//     const handleRemove = () => onRemove(uuid, id);

//     const image = React.createElement('img', { onLoad: handleLoad, src: url, alt: name });

//     useEffect(() => {
//         if (loaded) setPercent(undefined);
//         else if (progress === 100) setPercent(100);
//         else if (progress) setPercent(progress);
//     }, [loaded, progress]);

//     useRefEffect(
//         (currentLoader) => {
//             if (progress) currentLoader.style.width = `${progress}%`;
//         },
//         [progress],
//         currentLoaderRef
//     );

//     return (
//         <div className={'uploader__item' + (percent !== undefined ? ' uploader__item_loading' : ' uploader__item_file')}>
//             {percent !== undefined ? (
//                 <>
//                     <div className="uploader__loader" ref={loaderRef}>
//                         <div className="uploader__loader-current" ref={currentLoaderRef} />
//                     </div>
//                     <p>Uploading</p>
//                 </>
//             ) : (
//                 <>
//                     <Icon name="close" className="uploader-icon uploader__item_file-remove" size={20} onClick={handleRemove} />
//                 </>
//             )}
//             {image}
//         </div>
//     );
// };

// export const Upload: React.FC<UploadProps> = ({
//     accepts = [{ name: 'Images', type: ['jpg', 'jpeg', 'png', 'gif', 'bmp'] }],
//     max,
//     single,
//     onChange,
//     value,
//     // maxSize = 1.048e8,
//     maxSize = 1048576,
//     errors,
// }) => {
//     const dropRef = useRef<HTMLDivElement>(null);
//     const inputRef = useRef<HTMLInputElement>(null);
//     const [files, setFiles] = useState<File[]>();
//     const [error, setError] = useState<string>();
//     const [items, setItems] = useState<FileItem[]>([]);
//     const [deleted, setDeleted] = useState<number[]>([]);

//     useObjectEffect(() => {
//         if (onChange) {
//             const uploaded = items.filter((item) => typeof item.id === 'number').map((item) => item.id) as number[];

//             if (single) onChange(uploaded[0]);
//             else onChange(uploaded);
//         }
//     }, [items]);

//     useEffect(() => {
//         if (!files) return;

//         new Promise<File[]>((resolve, reject) => {
//             setItems((prev) => {
//                 if (single) {
//                     prev.splice(0, undefined);
//                     resolve(files.splice(0, 1));
//                     return prev;
//                 }

//                 if (max) {
//                     if (prev.length >= max) return prev;
//                     resolve(files.splice(max - prev.length, undefined));
//                     return prev;
//                 }
//                 resolve(files);

//                 return prev;
//             });
//         }).then((resFiles) => {
//             resFiles.forEach((file) => {
//                 const uuid = uuidv4();

//                 setItems((prev) => [...prev, { uuid }]);

//                 Api.files()
//                     .upload({ file }, (progress) => handleProgress(uuid, progress))
//                     .onSuccess((res) => {
//                         setItems((prev) => {
//                             const found = prev.find((item) => item.uuid === uuid);

//                             if (found) {
//                                 const { id, name, url } = res.data;

//                                 found.id = id;
//                                 found.name = name;
//                                 found.url = url;
//                                 found.progress = 100;

//                                 return [...prev];
//                             }

//                             return prev;
//                         });
//                     })
//                     .onError(() => {
//                         setItems((prev) => {
//                             const index = prev.findIndex((item) => item.uuid === uuid);

//                             if (index) return prev.slice(index, 1);

//                             return prev;
//                         });
//                     });
//             });
//         });

//         setFiles(undefined);
//     }, [files]);

//     useObjectEffect(() => {
//         if (!value) return;

//         setItems((prev) => {
//             const exists = (id: number) => {
//                 return deleted.includes(id) || prev.find((item) => item.id === id);
//             };

//             if (Array.isArray(value)) return [...prev, ...value.filter((val) => !exists(val.id)).map((val) => ({ ...val, uuid: uuidv4() }))];
//             else if (!exists(value.id)) return [...prev, { ...value, uuid: uuidv4() }];

//             return prev;
//         });
//     }, [value, deleted]);

//     useRefEffect(
//         (input) => {
//             input.value = '';
//         },
//         [files],
//         inputRef
//     );

//     useEffect(() => {
//         if (error) dropRef.current?.classList.add('error');

//         const timeout = setTimeout(() => {
//             dropRef.current?.classList.remove('error');
//         }, 1000);

//         return () => clearTimeout(timeout);
//     }, [error]);

//     const acceptTypes = useMemo(
//         () =>
//             accepts
//                 ?.map((accept) => {
//                     return accept.type.map((type) => type.toLowerCase());
//                 })
//                 .flat(),
//         [accepts]
//     );

//     // const removeAll = () => setFiles([]);

//     const getSizeText = (size: number) => {
//         const sizes = ['b', 'Kb', 'Mb', 'Gb'];
//         let sizeText = '';
//         let i = 0;

//         while (size > 1) {
//             sizeText = Math.round(size * 10) / 10 + sizes[i];
//             size /= 1023;
//             i++;
//         }

//         return sizeText;
//     };

//     const handleDrop = (files: File[]) => {
//         setFiles(filterFiles(files));
//     };

//     const isDragging = useDrag(
//         {
//             areaRef: dropRef,
//             onDrop: handleDrop,
//         },
//         [single, items.length, max]
//     );

//     const handleClick = () => inputRef.current?.click();

//     const handleRemove = (uuid: string, id?: number) => {
//         if (id) setDeleted((prev) => [...prev, id]);

//         setItems((prev) => {
//             const index = prev.findIndex((item) => item.uuid === uuid);

//             if (index !== -1) prev.splice(index, 1);

//             return prev;
//         });
//     };

//     const handleProgress = (uuid: string, progress: number) => {
//         setItems((prev) => {
//             const found = prev?.find((item) => item.uuid === uuid);

//             if (found) {
//                 found.progress = progress;
//                 return [...prev];
//             }

//             return prev;
//         });
//     };

//     const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//         const files = event.currentTarget.files;

//         if (files) setFiles(filterFiles(Array.from(files)));
//     };

//     const filterFiles = (files: File[]) => {
//         setError(undefined);

//         if (acceptTypes) {
//             const filtered: File[] = [];

//             files.forEach((file) => {
//                 const fileExt = file.name.slice(file.name.lastIndexOf('.') + 1).toLowerCase();

//                 if (!acceptTypes.includes(fileExt)) {
//                     setError('File type not permitted');
//                 } else if (file.size >= maxSize) {
//                     setError('File exceeds size limit');
//                 } else {
//                     filtered.push(file);
//                 }
//             });

//             return filtered;
//         } else {
//             return files;
//         }
//     };

//     return (
//         <div className="uploader">
//             <div className="uploader__header">
//                 <h4>Add photo*</h4>
//                 <p>{`*Max size ${getSizeText(maxSize)}, best ratio 3:4 `}</p>
//                 {!!accepts.length && <p>{`Permitted formats: ${accepts.map((accept) => accept.type.join(', '))}`}</p>}
//                 {errors?.length || error ? <p className="uploader-error">{error ?? errors?.[0]}</p> : <></>}
//             </div>
//             <div className="uploader__items">
//                 {!((single && items.length) || (max && items.length >= max)) && (
//                     <div
//                         className={'uploader__item uploader__item_upload' + (isDragging ? ' dragging' : '') + (error ? ' error' : '')}
//                         onClick={handleClick}
//                         ref={dropRef}
//                     >
//                         <Icon name="download" className="uploader-icon " size={20} />
//                         <p>Upload</p>
//                         <input type="file" className="hidden" ref={inputRef} onChange={handleInputChange} />
//                     </div>
//                 )}
//                 {items.map((item) => (
//                     <Item key={`${item.uuid}`} id={item.id} uuid={item.uuid} name={item.name} progress={item.progress} url={item.url} onRemove={handleRemove} />
//                 ))}
//             </div>
//         </div>
//     );
// };
