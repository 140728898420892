import { FC, useEffect, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';

import { Icon } from '@.ui';
import { useOutsideAlerter } from '@/Components/Hooks';
import { Language } from '@/Config';
import { noop } from '@/Lib';

import { LanguageSelectProps } from './types';

export const LanguageSelect: FC<LanguageSelectProps> = ({ current, onChange: onSelect = noop }) => {
    const [isOpen, setOpen] = useState<boolean>(false);

    const listRef = useRef<HTMLDivElement>(null);
    const selectRef = useRef<HTMLDivElement>(null);

    useOutsideAlerter(selectRef, () => setOpen(false));

    useEffect(() => {
        if (isOpen) {
            listRef.current?.classList.add('open');
        } else {
            listRef.current?.classList.remove('open');
        }
    }, [isOpen]);

    const handleSelect = (code: string) => {
        setOpen(false);

        onSelect(code);
    };

    return (
        <div className="language-select" ref={selectRef}>
            <div className="language-select__visible" onClick={() => setOpen((prev) => !prev)}>
                {current && (
                    <>
                        <current.icon id={uuid()} />
                        {current.short}
                    </>
                )}
                <Icon name="chevron-down" color={'#000'} size={12} />
            </div>
            <div className="language-select__list" ref={listRef}>
                {Language.languagesList.map((lang) => (
                    <div className="language-select__list_item" onClick={() => handleSelect(lang.code)} key={`language-list-${lang.name}`}>
                        <lang.icon id={uuid()} />
                        <span>{lang.name}</span>
                    </div>
                ))}
            </div>
        </div>
    );
};
