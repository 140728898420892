import cn from 'classnames';
import React from 'react';

import { BaseInput } from '@/Components/UI';

import { Logic } from './logic';
import { NumberInputComponent } from './types';

export const NumberInput: NumberInputComponent = ({ errors, text, required, ...props }) => {
    const baseInputClassNames = cn(errors?.length && 'error');

    return (
        <BaseInput className={baseInputClassNames}>
            <label className="input-block__label">
                {errors?.[0] ?? text ?? ''}
                {required && <span>*</span>}
            </label>
            <Logic {...props} />
        </BaseInput>
    );
};

NumberInput.Logic = Logic;
