import { Auth } from '@.services';
import { Api } from '@/Services/api';
import { User } from '@/Services/Auth/types';

import { toUserType } from '../tools/toUserType';

export const refresh = (): Promise<User> =>
    new Promise((resolve, reject) => {
        const tokens = Auth.Cookies.get();

        if (!tokens) {
            reject(false);

            return;
        }

        Api.user()
            .refresh(tokens.refresh)
            .onSuccess((refreshRes) => {
                resolve(toUserType(refreshRes.data));
            })
            .onUnauthorized(() => reject(false));
    });
