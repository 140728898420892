import React, { FC, useCallback } from 'react';

import { Api } from '@.api';
import { Layout } from '@.layout';
import { Table2 } from '@.partials';
import { Filter3 } from '@.ui';
import { LinkHelper } from '@/Services';
import { StatisticObjectEnum } from '@/Services/StatisticPermissionService';
import { Request } from '@/Types';

import S from './styles.module.scss';

const useRetailStoresPage = () => {
    const fetchData = useCallback((query: Request.All) => Api.analytics().getStatisticStores(query), []);

    return {
        fetchData,
    };
};

export const RetailStoresPage: FC = () => {
    const { fetchData } = useRetailStoresPage();

    return (
        <Layout name="RetailStoresPage" breadcrumbs={[{ name: 'Analytics' }, { name: 'My retail stores', current: true }]}>
            <h1>My retail stores</h1>

            <Table2 get={fetchData} getData={(data) => data.stores} export="stores">
                {{
                    filters: (handleChange) => (
                        <Filter3 onChange={handleChange} className={S.search}>
                            <Filter3.Search placeholder="Search by name of store" />
                            <Filter3.StoreGroups visibleLabel="" />
                        </Filter3>
                    ),
                    items: [
                        (item) => ({
                            name: 'Name of store',
                            content: item.name,
                            sort: 'name',
                        }),
                        (item) => ({
                            name: 'Address of store',
                            content: item.street_address_1,
                        }),
                        (item) => ({
                            name: 'Contact person',
                            content: `${item.agent_name} ${item.agent_phone} ${item.agent_email}`,
                        }),
                    ],
                    actions: [
                        (item) => ({
                            type: 'analytics',
                            link: LinkHelper.analyticsDetailsPage(item.id, StatisticObjectEnum.STORE),
                        }),
                    ],
                }}
            </Table2>
        </Layout>
    );
};
