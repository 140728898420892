import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Api } from '@.api';
import { DictionariesResponseType } from '@.api/methods';
import { useForm } from '@.hooks';
import { Layout } from '@.layout';
import { LinkHelper } from '@.services';
import { Input, Select } from '@.ui';
import { StoreGroupsData } from '@/Components/Pages/Clients/StoreGroups/Action/types';
import { validateOptions } from '@/Components/Pages/Clients/StoreGroups/Action/validate';
import { Buttons } from '@/Components/Partials';

type StoreGroupActionProps = {
    edit?: boolean;
};

export const StoreGroupsAction: React.FC<StoreGroupActionProps> = ({ edit }) => {
    // const [group, setGroup] = useState<StoreGroupResponseType>();
    const [dictionaries, setDictionaries] = useState<DictionariesResponseType>();
    const { id } = useParams();
    const navigator = useNavigate();
    const [field, form] = useForm<StoreGroupsData>();

    const handleCreate = () => {
        form.reset.errors();

        form.ifValid((data) => {
            Api.store_groups()
                .create(data)
                .onSuccess(() => navigator(LinkHelper.clientsStoreGroups))
                .onValidationError((res) => form.set.errors(res.errors));
        }, validateOptions);
    };

    const handleUpdate = () => {
        form.reset.errors();

        form.ifValid((data) => {
            Api.store_groups()
                .update(data, Number(id))
                .onSuccess(() => navigator(LinkHelper.clientsStoreGroups))
                .onValidationError((res) => form.set.errors(res.errors));
        }, validateOptions);
    };

    useEffect(() => {
        Api.store_groups()
            .getById(Number(id))
            .onSuccess((res) => {
                const { id, name, status, company } = res.data;
                form.set.values({ id, name, status: status.id, company_id: company.id });
            });

        Api.dictionaries()
            .get({ by_name: 'companies,store_group_statuses' })
            .onSuccess((res) => {
                setDictionaries(res.data);
            });
    }, []);

    // useEffect(() => {
    //     Api.stores()
    //         .getAll({ company_id: selectedCompany })
    //         .onSuccess((res) => {
    //             setStores(res.data);
    //         });
    // }, [selectedCompany]);

    return (
        <Layout
            name="clients"
            breadcrumbs={
                edit
                    ? [
                          { name: 'Store groups', link: LinkHelper.clientsStoreGroups },
                          { name: form.state.name?.value ?? '', link: LinkHelper.clientsStoreGroupId(Number(id)) },
                          { name: 'Edit', current: true },
                      ]
                    : [
                          { name: 'Store groups', link: LinkHelper.clientsStoreGroups },
                          { name: 'Add', current: true },
                      ]
            }
            small
        >
            {edit ? <h1>Editing store group</h1> : <h1>Adding store group</h1>}
            <div className="store-groups-action__form">
                <Input {...field('name').register()} text="Name" required />
                <Select {...field('company_id').register<number>()} placeholder="Company" theme="white" required>
                    {dictionaries?.companies?.map((company) => (
                        <Select.Option key={`company_${company.id}`} id={company.id}>
                            {company.name}
                        </Select.Option>
                    ))}
                </Select>
                {edit && (
                    <Select {...field('status').register<number>()} placeholder="Status" theme="white" required>
                        {dictionaries?.store_group_statuses?.map((status) => (
                            <Select.Option key={`status_${status.id}`} id={status.id}>
                                {status.name}
                            </Select.Option>
                        ))}
                    </Select>
                )}
                {/*<Select placeholder="Store" search />*/}
                {/* <div className="store-groups-action__form-buttons">
                    <Button color="brown" text={edit ? 'Save' : 'Add'} onClick={edit ? handleUpdate : handleCreate} />
                    <Button color="transparent" text="Cancel" onClick={() => navigator(-1)} />
                </div> */}
                <Buttons text={edit ? 'Save' : 'Add'} onClickSave={edit ? handleUpdate : handleCreate} />
            </div>
        </Layout>
    );
};
