import { Brand, Brands, BrandsAction } from '@.pages';

import { RoutePage } from '../types';

export const BrandsPage: RoutePage = {
    url: 'brands',
    component: <Brands />,
    permissions: 'readOnly',
    sidebar: {
        name: 'Brands',
    },
    children: [
        {
            url: ':id',
            component: <Brand />,
            children: [
                {
                    url: 'edit',
                    component: <BrandsAction edit />,
                    permissions: 'admin',
                },
            ],
        },
        {
            url: 'add',
            component: <BrandsAction />,
            permissions: 'admin',
        },
    ],
};
