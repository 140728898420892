import React, { FC } from 'react';

import { Button, Textarea } from '@.ui';
import { createPreventDefault } from '@/Lib';
import { useLingualField } from '@/Services';
import { ProductProposal } from '@/Services/api/methods/ProductsProposals';

import S from './styles.module.scss';
import { useRejectProposalModal } from './useRejectProposalModal';

interface RejectProductProposalModalProps {
    productProposal: ProductProposal;
    updateProductProposal: () => void;
    close: () => void;
}

export const RejectProductProposalModal: FC<RejectProductProposalModalProps> = ({ productProposal, updateProductProposal, close }) => {
    const { reject, field, isProductWillChanged } = useRejectProposalModal({ updateProductProposal, close });

    const productName = useLingualField(productProposal);

    const onClickReject = createPreventDefault(() => reject(productProposal));

    const viewActualProductProposal = createPreventDefault(() => {
        updateProductProposal();
        close();
    });

    return (
        <div className={S.modal}>
            <h1>Reject product {productName}</h1>

            {isProductWillChanged && (
                <div>
                    <p className={S.modal_error}>The product has been changed, see the latest data?</p>

                    <div className={S.modal__actions}>
                        <Button color={'brown'} onClick={viewActualProductProposal}>
                            View actual product proposal
                        </Button>
                    </div>
                </div>
            )}

            {!isProductWillChanged && (
                <>
                    <Textarea {...field('reason').register<string>()} required text="Reason" />

                    <div className={S.modal__actions}>
                        <Button onClick={onClickReject} color="brown">
                            Reject
                        </Button>
                        <Button onClick={close}>Cancel</Button>
                    </div>
                </>
            )}
        </div>
    );
};
