import { requiredRule, ValidateOptions } from '@.hooks';

import { UsersData } from './types';

export const validateOptions = (edit?: boolean): ValidateOptions<UsersData> => ({
    name: {
        type: 'string',
        required: requiredRule,
    },
    username: {
        type: 'string',
        required: requiredRule,
    },
    email: {
        type: 'string',
        required: requiredRule,
        regex: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9-]+.+.[A-Z]{2,4}$/i,
            message: 'Field email is invalid',
        },
    },
    password: {
        type: 'string',
        required: edit ? false : requiredRule,
    },
    repeat_password: {
        type: 'string',
        required: !edit,
        requiredIfField: {
            value: 'password',
            message: 'Field $field is required',
        },
        sameAs: {
            value: 'password',
            message: 'Passwords do not match',
        },
    },
    company_id: {
        type: 'number',
        required: requiredRule,
    },
    store_groups_ids: {
        type: 'number[]',
    },
    role_id: {
        type: 'number',
        required: requiredRule,
    },
    status: {
        type: 'number',
        required: edit ? requiredRule : false,
    },
    statistic_object_type: {
        type: 'string',
        required: false,
    },
    statistic_object_type_id: {
        type: 'number',
        required: false,
    },
    statistic_object_store_group_id: {
        type: 'number',
        required: false,
    },
    statistic_object_store_id: {
        type: 'number',
        required: false,
    },
});
