import { useEffect, useRef } from 'react';

export const useStickyShadowHead = () => {
    const refComponent = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const component = refComponent.current;

        if (!component) {
            return;
        }

        component.classList.add('sticky');
    }, []);

    useEffect(() => {
        const calculateClassList = () => {
            const head = refComponent.current;

            if (!head) {
                return;
            }

            const offsetTop = head.getBoundingClientRect().top + document.body.scrollTop;

            if (offsetTop === 15) {
                head.classList.add('shadow');
            } else {
                head.classList.remove('shadow');
            }
        };

        window.addEventListener('scroll', calculateClassList);

        return () => {
            window.removeEventListener('scroll', calculateClassList);
        };
    }, []);

    return {
        refComponent,
    };
};
