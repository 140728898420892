import useModal from '@fipnooone/react-modal';
import React, { useState } from 'react';

import { Api } from '@.api';
import { ModalInfo } from '@.modal';
import { ChamberCard } from '@/Components/Pages/Stations/Station/tabs/firmware/ChamberCard';

import S from './styles.module.scss';

type UseDeviceCardParams = {
    deviceId: number;
};

export const useDeviceCard = ({ deviceId }: UseDeviceCardParams) => {
    const [modal, modalControls] = useModal();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const showChambers = async () => {
        setIsLoading(true);

        Api.devices()
            .getChambers(deviceId)
            .onSuccess(({ data }) => {
                setIsLoading(false);
                modalControls.set(
                    <ModalInfo
                        data={
                            <div className={S.Chambers}>
                                {data.chambers.map((chamber) => (
                                    <ChamberCard chamber={chamber} key={chamber.id} />
                                ))}
                            </div>
                        }
                    />
                );
            });
    };

    return {
        modal,
        isLoading,
        showChambers,
    };
};
