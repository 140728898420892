import { User as U } from '@.types';
import { User } from '@/Services/Auth/types';

export const toUserType = (response: U.RefreshResponse): NonNullable<User> => {
    const { access_token, refresh_token, is_company_admin, is_super, permissions, name, company, username, statistic_object, role } = response;

    return {
        tokens: { refresh: refresh_token, access: access_token },
        is_company_admin,
        is_super,
        permissions,
        name,
        company,
        username,
        statistic_object,
        role,
    };
};
