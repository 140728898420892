import React, { useEffect, useState } from 'react';

import { Api } from '@.api';
import { ProductsResponseType } from '@.api/methods';
import { usePagination } from '@.hooks';
import { Layout } from '@.layout';
import { ExportBlock, Filters, Table, TableAction, TableActions, TableColumn, TableIcon, TableRow } from '@.partials';
import { Pagination } from '@.ui';
import { LinkHelper } from '@/Services';

export const PopularProducts: React.FC = () => {
    const [products, setProducts] = useState<ProductsResponseType>();
    const [filters, setFilters] = useState<{ search?: string; status?: number }>();
    const { currentPage, lastPage, perPage, prevPage, nextPage, changePage, total, process } = usePagination();

    const updateProducts = () => {
        Api.dashboard()
            .productsDetailed({ page: currentPage, per_page: perPage, ...filters })
            .onSuccess((res) => {
                setProducts(res.data);
                process(res.data.pagination);
            });
    };

    useEffect(() => {
        updateProducts();
    }, [currentPage, filters]);

    return (
        <Layout
            breadcrumbs={[
                { name: 'Dashboard', link: LinkHelper.dashboard },
                { name: 'Popular products', current: true },
            ]}
        >
            <h1>Popular products</h1>
            <Filters
                filters={['Brands', 'ProductTypes', 'Ages', 'Sexes', 'Strengths', 'Occasions', 'Ingredients', 'Characters', 'Tags', 'ProductUdis']}
                onChange={setFilters}
            />
            <Table
                headers={[
                    'UDI',
                    'Name',
                    'Store groups',
                    'Brand',
                    'Type',
                    'Ages',
                    'Sex',
                    'Strength',
                    'Occasion',
                    'Ingredients',
                    'Character',
                    'Tags',
                    'Sales',
                    'Whiffs',
                ]}
                total={total}
            >
                {products?.products.map((product) => (
                    <TableRow key={`row_${product.id}`}>
                        <TableColumn maxLength={8} link={LinkHelper.dataProductId(product.id)}>
                            {product.udi}
                        </TableColumn>
                        <TableColumn maxLength={8}>{product.name}</TableColumn>
                        <TableColumn maxLength={8}>{product.companies?.map((company) => company.name).join(', ')}</TableColumn>
                        <TableColumn maxLength={8}>{product.brand.name}</TableColumn>
                        <TableColumn maxLength={8}>{product.type.name}</TableColumn>
                        <TableColumn maxLength={8}>{product.ages.map(({ name }) => name).join(', ')}</TableColumn>
                        <TableColumn maxLength={8}>{product.sexes.map(({ name }) => name).join(', ')}</TableColumn>
                        <TableColumn maxLength={8}>{product.strength.name}</TableColumn>
                        <TableColumn maxLength={8}>{product.occasions?.map(({ name }) => name).join(', ')}</TableColumn>
                        <TableColumn maxLength={8}>{product.ingredients.map(({ name }) => name).join(', ')}</TableColumn>
                        <TableColumn maxLength={8}>{product.characters.map(({ name }) => name).join(', ')}</TableColumn>
                        <TableColumn maxLength={8}>{product.tags.map(({ name }) => name).join(', ')}</TableColumn>
                        <TableColumn>{product.sales_count}</TableColumn>
                        <TableColumn>{product.whiffs_count}</TableColumn>
                        <TableActions>
                            <TableAction>
                                <TableIcon name="open" link={LinkHelper.dataProductId(product.id)} />
                            </TableAction>
                        </TableActions>
                    </TableRow>
                ))}
            </Table>
            <div className="page-table__footer">
                <Pagination
                    className={total && total <= perPage ? 'hide' : ''}
                    current={currentPage}
                    last={lastPage ?? 1}
                    prevPage={prevPage}
                    nextPage={nextPage}
                    changePage={changePage}
                />
                <ExportBlock model="dashboard_products" filters={filters} />
            </div>
        </Layout>
    );
};
