import { FC, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { Button, ButtonProps } from '@/Components/UI';

import { ButtonsProps } from './types';

export const Buttons: FC<ButtonsProps> = ({
    onClickSave,
    text,
    linkCancel,
    linkSave,
    onClickCancel,
    className,
    noCancel = false,
    noSave = false,
    saveProps = {},
    cancelProps = {},
}) => {
    const navigate = useNavigate();

    const wrap = (props: ButtonProps, handler?: () => void, link?: string) => {
        const button = <Button {...props} onClick={handler} />;

        if (link) return <Link to={link}>{button}</Link>;

        return button;
    };

    const back = () => navigate(-1);

    const cancel = useMemo(
        () =>
            noCancel
                ? undefined
                : wrap(
                      {
                          color: 'transparent',
                          text: 'Cancel',
                          ...cancelProps,
                      },
                      !onClickCancel && !linkCancel ? back : undefined,
                      linkCancel
                  ),
        [onClickCancel, linkCancel, noCancel]
    );
    const save = useMemo(
        () =>
            noSave
                ? undefined
                : wrap(
                      {
                          color: 'brown',
                          text,
                          ...saveProps,
                      },
                      onClickSave,
                      linkSave
                  ),
        [onClickSave, linkSave, text, noSave]
    );

    return (
        <div className={'form__buttons' + (className ? ` ${className}` : '')}>
            {cancel}
            {save}
        </div>
    );
};
