import React, { useCallback, useImperativeHandle, useMemo, useRef, useState } from 'react';

import { useTooltip } from '@/Components/Hooks';

import { OptionProps, RealOptionProps } from './types';

export const FakeOption: React.FC<OptionProps> = () => null;

export const Option = React.forwardRef<HTMLDivElement, RealOptionProps>(({ id, onClick, children }, ref) => {
    const localRef = useRef<HTMLDivElement | null>(null);
    const [tooltip, setTooltip, setOpen] = useTooltip({});
    const [isLoaded, setLoaded] = useState(false);

    useImperativeHandle(ref, () => localRef.current as HTMLDivElement);

    const isOver = useMemo(() => {
        const option = localRef.current;

        if (!option) return false;

        return option.clientWidth < option.scrollWidth;
    }, [children, isLoaded]);

    const handleClick = () => {
        onClick?.(id);
    };

    const handleIn = useCallback(() => {
        const option = localRef.current;

        if (!isOver || !option) {
            return;
        }

        const rect = option.getBoundingClientRect();

        setTooltip({
            content: children,
            coordinates: { x: rect.left + 10, y: rect.top },
            offsetX: rect.width,
            open: true,
        });
    }, [children, isOver]);

    const handleOut = () => setOpen(false);

    return (
        <>
            <div
                className="select__option text"
                onClick={handleClick}
                onMouseEnter={handleIn}
                onMouseOut={handleOut}
                ref={(ref) => {
                    setLoaded(true);

                    localRef.current = ref;
                }}
                data-value={children}
            >
                {children}
            </div>
            {tooltip}
        </>
    );
});
