import { DashboardItem } from '../types';
import { Offers } from './offers';
import { Products } from './products';
import { Searches } from './searches';
import { Stores } from './stores';

export const Popular: DashboardItem = (props) => (
    <>
        <Products {...props} />
        <Stores {...props} />
        <Searches {...props} />
        <Offers {...props} />
    </>
);
