import React, { useEffect, useRef, useState } from 'react';

import { useDrag } from '../../Hooks/useDrag';
// import { Lang } from '../../language/Language';
import { Button } from '../../UI/Button';
import { Icon } from '../../UI/Icon';

enum Events {
    Clear = 'clearUploads',
}

type Accept = {
    name: string;
    type: string[];
};

interface Props {
    accepts?: Accept[];
    onFile?: (files: File[]) => void;
    onExample?: () => void;
    multiple?: boolean;
    errors?: string[];
    maxSize?: number;
}

export const clearDragUpload = () => {
    document.dispatchEvent(new Event(Events.Clear));
};

export const DragUpload: React.FC<Props> = ({ accepts, onFile, maxSize = 209715200, multiple, errors, onExample }) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const areaRef = useRef<HTMLDivElement>(null);
    const [files, setFiles] = useState<File[] | null>(null);
    const [error, setError] = useState<string>();

    const acceptTypes = accepts
        ?.map((accept) => {
            return accept.type;
        })
        .flat();

    const filterFiles = (files: File[]) => {
        const filtered: File[] = [];
        setError(undefined);

        files.forEach((file) => {
            if (file.size >= maxSize) {
                setError('File exceeds size limit');
            } else {
                filtered.push(file);
            }
        });

        return filtered;
    };

    const handleDrop = (files: File[]) => {
        setFiles(filterFiles(files));
    };

    const isDragging = useDrag({
        areaRef: areaRef,
        onDrop: handleDrop,
    });

    const handleClick = (event: React.MouseEvent<Element, MouseEvent>) => {
        event.preventDefault();

        inputRef.current?.click();
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.currentTarget.files;

        if (files) {
            if (multiple) {
                setFiles(filterFiles([files[0]]));
            } else {
                setFiles(filterFiles(Array.from(files)));
            }
        }
    };

    const removeAll = () => {
        setFiles([]);
    };

    // const getSizeText = (size: number) => {
    //     const sizes = [Lang.trans('drag_size_byte'), Lang.trans('drag_size_kb'), Lang.trans('drag_size_mb'), Lang.trans('drag_size_gb')];
    //     let sizeText = '';
    //     let i = 0;
    //
    //     while (size > 1) {
    //         sizeText = Math.round(size * 10) / 10 + ' ' + sizes[i];
    //         size /= 1024;
    //         i++;
    //     }
    //
    //     return sizeText;
    // };

    useEffect(() => {
        const area = areaRef.current;

        if (area) {
            if (isDragging) {
                area.classList.add('drag');
            } else {
                area.classList.remove('drag');
            }
        }
    }, [isDragging]);

    useEffect(() => {
        if (onFile && files) {
            onFile(files);
        }

        if (inputRef.current) inputRef.current.value = '';
    }, [files]);

    // useEffect(() => {
    //     const area = areaRef.current;
    //
    //     if (area) {
    //         if (error) {
    //             area.classList.add('error');
    //
    //             const timeout = setTimeout(() => {
    //                 setError(undefined);
    //                 area.classList.remove('error');
    //             }, 3000);
    //
    //             return () => clearTimeout(timeout);
    //         } else {
    //             area.classList.remove('error');
    //         }
    //     }
    // }, [error]);

    useEffect(() => {
        document.addEventListener(Events.Clear, removeAll);

        return () => {
            document.removeEventListener(Events.Clear, removeAll);
        };
    }, []);

    useEffect(() => {
        if (!errors?.length) return;

        setError(errors[0]);
    }, [errors]);

    return (
        <>
            <div className="drag-n-drop">
                <p className="drag-n-drop__area__max-size">{`*Max size 200Mb`}</p>
                {error ? (
                    <span className="drag-n-drop__area-error text">{error}</span>
                ) : (
                    errors && <span className="drag-n-drop__area-error text">{errors}</span>
                )}
                <div className="drag-n-drop__area" ref={areaRef}>
                    <input type="file" className="hidden" ref={inputRef} onChange={handleChange} />

                    {isDragging ? (
                        <span className="drag-n-drop__area-drag text"></span>
                    ) : (
                        <>
                            <span className="drag-n-drop__area-title text"></span>
                            {accepts ? (
                                <span className="drag-n-drop__area-accepts text-small">
                                    {accepts.map((fileType) => {
                                        return fileType.name;
                                    })}
                                </span>
                            ) : (
                                <></>
                            )}
                            {/*<Button color="brown" text="Click to upload file" onClick={handleClick} />*/}
                            <button className={error || errors ? 'error' : ''} type="button" onClick={handleClick}>
                                <Icon name="download" />
                                Click to upload file
                            </button>
                        </>
                    )}
                </div>

                {files?.length ? (
                    <div className="drag-n-drop-file-list">
                        {files.map((file, i) => {
                            return (
                                <>
                                    <div className="drag-n-drop-file" key={`File_${i}}`}>
                                        <div className="file">
                                            <div className="file__info">
                                                <span className="file-name text">{file.name}</span>
                                                {/*<span className="file-size text-small">{getSizeText(file.size)}</span>*/}
                                            </div>
                                        </div>

                                        <Icon name="close" size={20} onClick={removeAll} />
                                    </div>
                                </>
                            );
                        })}
                    </div>
                ) : (
                    <></>
                )}

                {/*<div className="drag-n-drop-file-example" onClick={props.onExample}>*/}
                {/*    <Icon name="file" size={20} />*/}
                {/*    /!*<span className="text">{Lang.trans('drag_file_example')}</span>*!/*/}
                {/*</div>*/}
            </div>
        </>
    );
};
