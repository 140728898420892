import { Login, NewPassword, PasswordRecovery, PasswordRecoverySuccess } from '@.pages';
import { PageList } from '@.services';

export const AuthPages: PageList = [
    {
        url: 'login',
        component: <Login />,
    },
    {
        url: 'password-recovery',
        component: <PasswordRecovery />,
        children: [
            {
                url: 'success',
                component: <PasswordRecoverySuccess />,
            },
        ],
    },
    {
        url: 'reset-password',
        component: <NewPassword />,
    },
];
