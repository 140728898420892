import { Text } from '@visx/text';
import React, { FC, useMemo } from 'react';

import { Donut, DonutLegendType } from '@.partials';
import { DEFAULT_ANALYTICS_CHART_COLORS } from '@/Components/Partials/Analytics/constants';
import { ChartDatasetValues } from '@/Types/analytics/chartsTypes';

import S from './styles.module.scss';

type AgeChartProps = {
    data: ChartDatasetValues;
};

export const AgeChart: FC<AgeChartProps> = ({ data }) => {
    const donutData = useMemo(
        () =>
            data.map((datasetItem, index) => ({
                value: datasetItem.value,
                label: datasetItem.label,
                color: DEFAULT_ANALYTICS_CHART_COLORS[index],
            })),
        [data]
    );

    const legend = useMemo<DonutLegendType>(
        () => ({
            columns: 2,
            legendItems: donutData.map(({ color, value, label }) => ({
                color,
                label: `${label} ${value}`,
            })),
        }),
        [donutData]
    );

    const ContentCenter = (
        <Text fontFamily="DM Sans" fontWeight={500} fontSize={20} lineHeight={26} fill="#000" textAnchor="middle" verticalAnchor="middle">
            {donutData.length === 0 ? '0%' : '100%'}
        </Text>
    );

    return (
        <div>
            <h3>Age</h3>
            <div className={S.age_chart}>
                <Donut data={donutData} legend={legend} contentCenter={ContentCenter} />
            </div>
        </div>
    );
};
