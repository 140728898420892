import { Group } from '@visx/group';
import { Bar as BarBase } from '@visx/shape';
import { Text } from '@visx/text';
import cn from 'classnames';
import { CSSProperties, FC, useCallback, useRef } from 'react';

import { createTooltipDataDefault } from '@/Components/Partials/Charts/Bars/Bar/createTooltipDataDefault';

import { BarDataItem, TooltipData } from '../types';
import S from './styles.module.scss';
import { BarProps } from './types';

export const Bar = <BarData extends BarDataItem = BarDataItem>({
    color,
    hoverColor,
    data,
    gap,
    height,
    scale,
    width,
    hideTooltip,
    showTooltip,
    isHorizontal,
    createTooltipData = createTooltipDataDefault,
}: BarProps<BarData>) => {
    const barBorderRadius = 4;

    const tooltipTimeoutRef = useRef<number>(-1);

    const handleMouseLeave = () => {
        tooltipTimeoutRef.current = window.setTimeout(() => {
            hideTooltip();
        }, 300);
    };

    const handleMouseMove = useCallback(
        (dataItem: BarData, x: number, y: number, width: number) => {
            clearTimeout(tooltipTimeoutRef.current);

            const MARGIN_FROM_SIDE_CHART_FOR_TOOLTIP = 150;

            const coords = isHorizontal
                ? { tooltipTop: y - 20, tooltipLeft: width - MARGIN_FROM_SIDE_CHART_FOR_TOOLTIP }
                : {
                      tooltipTop: y - 20,
                      tooltipLeft: x - 20,
                  };

            const tooltipData: TooltipData = createTooltipData(dataItem);

            showTooltip({
                tooltipData,
                ...coords,
            });
        },
        [createTooltipData, isHorizontal, showTooltip]
    );

    return (
        <Group top={isHorizontal ? 15 : 0}>
            {data.map((dataItem, index) => {
                let barHeight = 0,
                    barWidth = 0,
                    barY = 0,
                    barX = 0;

                if (isHorizontal) {
                    barHeight = (height - 45 + gap / 2) / data.length - gap;
                    barWidth = width - (scale(dataItem.value) ?? 0);
                    barY = index * (barHeight + gap);
                } else {
                    barHeight = height - (scale(dataItem.value) ?? 0);
                    barWidth = (width + gap / 2) / data.length - gap;
                    barY = height - barHeight;
                    barX = index * (barWidth + gap);

                    barHeight += 4;
                }

                const classNames = cn(isHorizontal && 'horisontal');

                return (
                    <Group key={`bar-${dataItem.label}`}>
                        <BarBase
                            className={classNames}
                            x={barX}
                            y={barY}
                            width={barWidth}
                            height={barHeight}
                            fill={color}
                            rx={barBorderRadius}
                            ry={barBorderRadius}
                            onMouseLeave={handleMouseLeave}
                            onMouseMove={() => handleMouseMove(dataItem, barX, barY, barWidth)}
                            /**
                             * TODO: fix
                             */
                            style={{ '--hover-color': hoverColor } as CSSProperties & { '--hover-color': string }}
                        />
                        {isHorizontal && (
                            <Text className={S.bar_text} x={barX + 25} y={barY + barHeight / 1.75}>
                                {dataItem.label}
                            </Text>
                        )}
                    </Group>
                );
            })}
        </Group>
    );
};
