import React, { ReactElement, ReactNode, useMemo } from 'react';

import { useTabs } from '@/Components/Hooks';
import { TabItem } from '@/Components/Hooks/useTabs/types';

export type TabProps = {
    name: string;
    path: string;
    is_default?: boolean;
    children: React.ReactNode;
};

export type TabsProps = {
    children: ReactElement<TabProps> | ReactElement<TabProps>[];
    additional?: boolean;
};

export type TabType = { name: string; content: React.ReactNode; is_default?: boolean; id: number; path: string };

export const Tab: React.FC<TabProps> = () => {
    return <></>;
};

export const Tabs: React.FC<TabsProps> = ({ children, additional }) => {
    const tabs = useMemo<TabItem<ReactNode>[]>(
        () =>
            React.Children.map(children, (child) => {
                if (!React.isValidElement(child) || !child.props) return;

                const { children, name, path, is_default } = child.props;

                return {
                    name,
                    is_default,
                    path,
                    content: children,
                };
            }).filter(Boolean) as TabItem<ReactNode>[],
        []
    );

    const [controls, content] = useTabs<ReactNode>(tabs);

    return (
        <div className="tabs">
            {controls}
            {content}
        </div>
    );
};
