import React, { useRef } from 'react';

import Icons from '@.assets/Icons/icons.svg';

export type IconProps = {
    name: string;
    color?: string;
    size?: number;
    className?: string;
    onClick?: (event: React.MouseEvent) => void;
    onClickPrevent?: (event: React.MouseEvent) => void;
    disabled?: boolean;
};

export const Icon: React.FC<IconProps> = ({ name, color = '#fff', size = 25, className, onClick, onClickPrevent, disabled }) => {
    const iconRef = useRef<SVGSVGElement>(null);

    const handleClick = (event: React.MouseEvent) => {
        if (!disabled) {
            if (onClickPrevent) {
                event.preventDefault();
                event.stopPropagation();
                onClickPrevent(event);
            }

            if (onClick) onClick(event);
        }
    };

    return (
        <svg
            className={'icon' + (className ? ` ${className}` : '') + (onClick ? ' hoverable' : '') + (disabled ? ' disabled' : '')}
            width={size}
            height={size}
            ref={iconRef}
            color={color}
            onClick={handleClick}
        >
            <use xlinkHref={`${Icons}#${name}`} />
        </svg>
    );
};
