import { FC } from 'react';
import { Navigate } from 'react-router-dom';

import { createUserDefaultUrlPage } from '@/Lib';
import { Auth } from '@/Services';

export const RedirectPage: FC = () => {
    const auth = Auth.Use();

    return <Navigate to={createUserDefaultUrlPage(auth?.user?.role?.key)} />;
};
