import { requiredRule, ValidateOptions } from '@.hooks';
import { IngredientsData } from '@/Components/Pages/Data/Ingredients/Action/types';

export const validateOptions: ValidateOptions<IngredientsData> = {
    name: {
        type: 'lingual',
        required: requiredRule,
    },
    icon_id: {
        type: 'number',
    },
};
