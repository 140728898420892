import cn from 'classnames';
import React, { FC, ReactNode } from 'react';

interface AdminFieldProps {
    isAllowed?: boolean;
    children?: ReactNode;
    hidden?: boolean;
}

export const AdminFields: FC<AdminFieldProps> = ({ isAllowed, children, hidden }) => {
    const isDisabled = !isAllowed;

    const adminFieldsClassName = cn('action-form', isDisabled && 'disabled', hidden && 'hidden');

    return <div className={adminFieldsClassName}>{children}</div>;
};
