import React, { useCallback } from 'react';

import { Api } from '@.api';
import { Layout } from '@.layout';
import { Table2 } from '@.partials';
import { Checkbox, Filter3 } from '@.ui';
import { LinkHelper, Tools } from '@/Services';
import { SpecialOffersResponseType } from '@/Services/api/methods';
import { Request } from '@/Types';

export const PopularSpecialOffers: React.FC = () => {
    const get = useCallback((q?: Request.All) => Api.dashboard().specialOffersDetailed(q), []);
    const getData = useCallback((data: SpecialOffersResponseType) => data.special_offers, []);

    return (
        <Layout
            breadcrumbs={[
                { name: 'Dashboard', link: LinkHelper.dashboard },
                { name: 'Popular special offers', current: true },
            ]}
        >
            <h1>Popular special offers</h1>
            <Table2 get={get} getData={getData} export="dashboard_special_offers">
                {{
                    filters: (handler) => (
                        <Filter3 onChange={handler}>
                            <Filter3.Search />
                            <Filter3.Companies related />
                            <Filter3.StoreGroups related />
                            <Filter3.Stores related />
                            <Filter3.SpecialOfferTypes />
                            <Filter3.Boolean name="is_enabled" placeholder="Enabled" />
                            <Filter3.Date />
                        </Filter3>
                    ),
                    items: [
                        (item) => ({
                            link: LinkHelper.dataSpecialOfferId(item.id),
                            content: item.name,
                            name: 'Name',
                        }),
                        (item) => ({
                            content: item.company.name,
                            name: 'Company',
                        }),
                        (item) => ({
                            content: item.store_group.name,
                            name: 'Store group',
                        }),
                        (item) => ({
                            content: item.products.map((product) => product.name).join(', '),
                            name: 'Products',
                            max: 50,
                        }),
                        (item) => ({
                            content: item.types.map((type) => type.name).join(', '),
                            name: 'Type',
                        }),
                        (item) => ({
                            content: Tools.date.format(item.start_date * 1000, 'DD.MM.YYYY'),
                            name: 'Start date',
                        }),
                        (item) => ({
                            content: Tools.date.format(item.end_date * 1000, 'DD.MM.YYYY'),
                            name: 'End date',
                        }),
                        (item) => ({
                            content: <Checkbox value={item.is_enabled} isDisabled />,
                            name: 'Enabled',
                        }),
                        (item) => ({
                            content: item.views_count,
                            name: 'Views',
                        }),
                    ],
                    actions: [
                        (item) => ({
                            type: 'open',
                            link: LinkHelper.dataSpecialOfferId(item.id),
                        }),
                    ],
                }}
            </Table2>
        </Layout>
    );
};

/*
const [offers, setOffers] = useState<SpecialOffersResponseType>();
    const [filters, setFilters] = useState<FilterData>();
    const { currentPage, lastPage, perPage, prevPage, nextPage, changePage, total, process } = usePagination();
 const updateOffers = () => {
        Api.dashboard()
            .specialOffersDetailed({ page: currentPage, per_page: perPage, ...filters })
            .onSuccess((res) => {
                setOffers(res.data);
                process(res.data.pagination);
            });
    };

    useEffect(() => {
        updateOffers();
    }, [currentPage, filters]);

<Filters filters={['Companies', 'StoreGroups', 'Stores', 'SpecialOfferTypes', 'IsEnabled']} date onChange={setFilters} />
            <Table headers={['Name', 'Company', 'Store group', 'Products', 'Type', 'Start date', 'End date', 'Enabled', 'Views']}>
                {offers?.special_offers?.map((offer) => (
                    <TableRow key={`row_${offer.id}`}>
                        <TableColumn link={LinkHelper.dataSpecialOfferId(offer.id)}>{offer.name}</TableColumn>
                        <TableColumn>{offer.company?.name}</TableColumn>
                        <TableColumn>{offer.store_group.name}</TableColumn>
                        <TableColumn>{offer.products?.map((product) => product.name).join(', ')}</TableColumn>
                        <TableColumn>{offer.types?.map((type) => type.name).join(', ')}</TableColumn>
                        <TableColumn>{Tools.date.format(offer.start_date * 1000, 'DD.MM.YYYY')}</TableColumn>
                        <TableColumn>{Tools.date.format(offer.end_date * 1000, 'DD.MM.YYYY')}</TableColumn>
                        <TableColumn>
                            <Checkbox value={offer.is_enabled} isDisabled />
                        </TableColumn>
                        <TableColumn>{offer.views_count}</TableColumn>
                        <TableActions>
                            <TableAction>
                                <TableIcon name="open" link={LinkHelper.dataSpecialOfferId(offer.id)} />
                            </TableAction>
                        </TableActions>
                    </TableRow>
                ))}
            </Table>
            <div className="page-table__footer">
                <Pagination
                    className={total && total <= perPage ? 'hide' : ''}
                    current={currentPage}
                    last={lastPage ?? 1}
                    prevPage={prevPage}
                    nextPage={nextPage}
                    changePage={changePage}
                />
                <ExportBlock model="dashboard_special_offers" filters={filters} />
            </div>
*/
