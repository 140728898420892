import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Api } from '@.api';
import { useForm, useId } from '@.hooks';
import { Layout } from '@.layout';
import { LinkHelper } from '@.services';
import { Input } from '@.ui';
import { Buttons } from '@/Components/Partials';
import { IrcUserResponseType } from '@/Services/api/methods';

import { IrcUsersActionProps, IrcUsersData } from './types';
import { validateOptions } from './validate';

export const ScenticonUsersAction: React.FC<IrcUsersActionProps> = ({ edit }) => {
    const [ircUser, setIrcUser] = useState<IrcUserResponseType>();
    const [field, form] = useForm<IrcUsersData>();
    const navigate = useNavigate();

    const handleCreate = () => {
        form.reset.errors();

        form.ifValid((data) => {
            Api.ircUsers()
                .create(data)
                .onSuccess(() => {
                    navigate(LinkHelper.settingsIrcUsers);
                })
                .onValidationError((res) => form.set.errors(res.errors));
        }, validateOptions);
    };

    const handleUpdate = () => {
        form.reset.errors();

        form.ifValid((data) => {
            Api.ircUsers()
                .updateById(data, id)
                .onSuccess(() => navigate(LinkHelper.settingsIrcUsers))
                .onValidationError((res) => form.set.errors(res.errors));
        }, validateOptions);
    };

    const get = (id: number) => {
        if (!edit) return;

        return Api.ircUsers()
            .getById(id)
            .onSuccess((res) => {
                setIrcUser(res.data);
                const { name, login } = res.data;
                form.set.values({ name, login });
            });
    };

    const id = useId(get, edit);

    return (
        <Layout
            name="settings"
            breadcrumbs={
                edit
                    ? [
                          { name: 'Scenticon Users', link: LinkHelper.settingsIrcUsers },
                          { name: ircUser?.name ?? '', link: LinkHelper.settingsIrcUserId(Number(id)) },
                          { name: 'Edit', current: true },
                      ]
                    : [
                          { name: 'Scenticon Users', link: LinkHelper.settingsIrcUsers },
                          { name: 'Add', current: true },
                      ]
            }
            small
        >
            {edit ? <h1>Editing scenticon user</h1> : <h1>Adding scenticon user</h1>}
            <div className="brands-action__form">
                <Input {...field('name').register()} text="Name" />
                <Input {...field('login').register()} text="Login" />
                <Buttons text={edit ? 'Save' : 'Add'} onClickSave={edit ? handleUpdate : handleCreate} />
            </div>
        </Layout>
    );
};
